import React from 'react'
import ReactDOM from 'react-dom'
import { Dialog } from '@material-ui/core'

const BPMDialog = (props) => {
    return (
        <div onClick={e => {e.stopPropagation()}}>
            <Dialog {...props} />
        </div>
    )

}

export default  BPMDialog
