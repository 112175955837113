import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../components/BPMTable/PagesCreator/BPMBasicPage'
import GlAccountFormCreator from '../../tableDefinitions/GlAccount/GlAccountFormCreator'
import { GlAccountTableCreator } from '../../tableDefinitions/GlAccount/GlAccountTableCreator'

const GlAccount = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Gl Accounts')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Gl Accounts'}
            tableCreatorCreatorId={GlAccountTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={GlAccountFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default GlAccount