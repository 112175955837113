import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription, 
    DataMapper, 
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
query AllDeviceModels(
    $orderField:String,
    $first:Int,$last:Int,
    $after:String,
    $before:String,
    $brand:String,
    $model:String,
    $serviceType:String,){
    allDeviceModels(
        orderField:$orderField,
        first:$first,
        last:$last,
        after:$after,
        before:$before,
        brand:$brand,
        model:$model,
        serviceType:$serviceType){
      edges{
        node{
          id
          serviceType{
              id
              name
          }
          name
          brand{
            name
            id
          }
        }
      }
      count
      pageInfo {
          hasNextPage
          startCursor
          endCursor
      }
    }
  }`

const DeviceModelMinimalTableCreatorCreatorQueries = {tableQuery}

class DeviceModelMinimalTableCreatorCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "DeviceModelMinimalTableCreatorCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, DeviceModelMinimalTableCreatorCreatorQueries)
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'brand',
                new DataMapper("brand.name"),
                new f.FilterDescription(new f.TextFilter("brand")),
                {label: 'Brand'}
            ),
            new TableFieldDescription(
                'model',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("model")),
                {label: 'Model'}
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper("serviceType.name"),
                new f.FilterDescription(new f.TextFilter("serviceType")),
                {label: 'Service Type'}
            )
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(DeviceModelMinimalTableCreatorCreator)

export default DeviceModelMinimalTableCreatorCreator