import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
    DataTransformer,
    NullClickStrategy,
    OnlyFilterActionDisplayStrategy,
} from  '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query (
            $orderField: String,
            $dateFrom: String,
            $dateUntil: String,
            $firstName: String,
            $lastName: String,
            $email: String,
            $serviceNumber: String!
        ){
        userAssociations(
            orderField: $orderField,
            dateFrom: $dateFrom,
            dateUntil: $dateUntil,
            firstName: $firstName,
            lastName: $lastName,
            email: $email,
            serviceNumber: $serviceNumber
        ) {
            edges {
                node {
                    association {
                        user {
                            email
                            firstName
                            lastName
                        }
                        inventory {
                            serviceNumber
                        }
                    }
                    dateFrom
                    dateUntil
                }
            }
            count
        }
    }
`;

const queries = {tableQuery}

class InventoryUsersAssociationTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryUsersAssociationTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'dateFrom',
                new DataMapper("dateFrom"),
                new f.FilterDescription(new f.DateFilter("dateFrom")),
                {label: 'From', transform: new DataTransformer(({dateFrom})=> dateFrom && dateFrom !== '1900-01-01' ? dateFrom : null)}
            ),
            new TableFieldDescription(
                'dateUntil',
                new DataMapper("dateUntil"),
                new f.FilterDescription(new f.DateFilter("dateUntil")),
                {label: 'Until', transform: new DataTransformer(({dateUntil})=> dateUntil && dateUntil !== '1900-01-01' ? dateUntil : null)}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("association.user.firstName"),
                new f.FilterDescription(new f.TextFilter("firstName")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("association.user.lastName"),
                new f.FilterDescription(new f.TextFilter("lastName")),
                {label: 'Surname'}
            ),
            new TableFieldDescription(
                'email',
                new DataMapper("association.user.email"),
                new f.FilterDescription(new f.TextFilter("email")),
                {label: 'Email'}
            ),
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryUsersAssociationTableCreator)

export default InventoryUsersAssociationTableCreator;