import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import JobsProcessTableCreator from '../../../tableDefinitions/Jobs/Process/JobsProcessTableCreator'

const Process = (props) => {
    const {t} = useTranslation()
    document.title = `${t('jobs:Process Control')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Process Control'}
            tableCreatorCreatorId={JobsProcessTableCreator.getId()}
            path = {props.match.url}
        />
    )
}

export default Process