import React, { Component } from 'react'
import { TextField } from "@material-ui/core";
import { FormControl } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

export class DateInput extends Component {
  render() {
    const {required, classes, label, id, formState, onChangeHandler, inputId , t, defaultValue} = this.props;
    const error =  Boolean(formState.errors[id]);
    const inputLabel = required ? required(t(label)) : t(label);
    return (
      <FormControl className={classes.formControl}  key={"formControl"+id}>
        <TextField
          error={error}
          id={id}
          label={inputLabel}
          type="date"
          key={'picker'+label}
          value={formState.mappedData[inputId] || defaultValue}
          onChange={event => onChangeHandler([inputId], event.target.value)}
          className={classes.input}
          style={{width:250}}
          inputProps={{
            style: {fontSize: 15}
          }}
          InputLabelProps={{ shrink: true }} 
        />
      </FormControl>
    )
  }
}

export default withTranslation('fieldLabels')(DateInput);