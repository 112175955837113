/* eslint-disable eqeqeq */
import React from 'react'

import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    NullClickStrategy, 
    OnlyFilterActionDisplayStrategy, 
    TableFieldDescription, 
    DateComponentCreator,
    DateDisplayCreator,
} from '../../components/BPMTable/TableMapperCreator'
import {DownloadFile} from "../../components/FileDownloader/FileComponentDownloader"
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query LossDeviceReport(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $type:String,
        $serialNumber:String,
        $serialNumberType:String,
        $model:String,
        $brand:String,
        $description:String,
        $firstName:String,
        $lastName:String,
        $userId:String,
        $managerFirstName:String,
        $managerLastName:String,
        $managerId:String,
        $date:String,){
        lossDeviceReport(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            type:$type,
            serialNumber:$serialNumber,
            serialNumberType:$serialNumberType,
            model:$model,
            brand:$brand,
            description:$description,
            firstName:$firstName,
            lastName:$lastName,
            userId:$userId,
            managerFirstName:$managerFirstName,
            managerLastName:$managerLastName,
            managerId:$managerId,
            date:$date,
        ){   
            
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
            edges{
              node{
                id
                deviceLoss{
                  id
                  date
                  file
                  type{
                    name
                  }
                  device{
                    serialNumber
                    serialNumberType{
                      name
                    }
                    model{
                      name
                      brand {
                        name
                      }
                      serviceType{
                        name
                      }
                    }
                  }
                }
                user{
                  firstName
                  lastName
                  enterpriseId
                  manager{
                    firstName
                    lastName
                    enterpriseId
                  }
                }
              }
            }
          } 
    }`

const queries = {tableQuery};

class LossReportTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "LossReportTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setAdditionalFilters({first: 30})
        tableCreator.setInitialSortField('date', 'desc')
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'serialNumber',
                new DataMapper("deviceLoss.device.serialNumber"),
                new f.FilterDescription(new f.TextFilter("serialNumber")),
                {label: 'Serial Number'}
            ),
            new TableFieldDescription(
                'type',
                new DataMapper("deviceLoss.type.name"),
                new f.FilterDescription(new f.TextFilter("type")),
                {label: 'Type'}
            ),
            new TableFieldDescription(
                'date',
                new DataMapper("deviceLoss.date"),
                new f.FilterDescription(new f.DateFilter("date")),
                {label: 'Date', componentCreator: new DateComponentCreator(), excelDisplay: new DateDisplayCreator()}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("user.firstName"),
                new f.FilterDescription(new f.TextFilter("firstName")),
                {label: 'User Name'}
            ),
            new TableFieldDescription(
                'userId',
                new DataMapper("user.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("userId")),
                {label: 'User ID'}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("user.lastName"),
                new f.FilterDescription(new f.TextFilter("lastName")),
                {label: 'User Surname'}
            ),
            new TableFieldDescription(
                'managerId',
                new DataMapper("user.manager.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("managerId")),
                {label: 'Manager Id'}
            ),
            new TableFieldDescription(
                'managerFirstName',
                new DataMapper("user.manager.firstName"),
                new f.FilterDescription(new f.TextFilter("managerFirstName")),
                {label: 'Manager Name'}
            ),
            new TableFieldDescription(
                'managerLastName',
                new DataMapper("user.manager.lastName"),
                new f.FilterDescription(new f.TextFilter("managerLastName")),
                {label: 'Manager Surname'}
            ),
            new TableFieldDescription(
                'model',
                new DataMapper("deviceLoss.device.model.name"),
                new f.FilterDescription(new f.TextFilter("model")),
                {label: 'Model'}
            ),
            new TableFieldDescription(
                'brand',
                new DataMapper("deviceLoss.device.model.brand.name"),
                new f.FilterDescription(new f.TextFilter("brand")),
                {label: 'Brand'}
            ),
            new TableFieldDescription(
                'serialNumberType',
                new DataMapper("deviceLoss.device.serialNumberType.name"),
                new f.FilterDescription(new f.TextFilter("serialNumberType")),
                {label: 'Serial Number Type'}
            ),
            new TableFieldDescription(
                'downloadFile',
                new DataMapper("deviceLoss"),
                new f.FilterDescription(new f.NullCreator()),
                // eslint-disable-next-line eqeqeq
                {label: 'Loss File', hideSort:true, transform: new DataTransformer(({deviceLoss}) => deviceLoss.file ? <DownloadFile url={"/report/download/loss/"} id={deviceLoss.id} /> : null)}
            ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(LossReportTableCreator)

export default LossReportTableCreator;