import React from 'react'

import { Select, MenuItem, FormControl, InputLabel, Input } from '@material-ui/core'

export default function DropDownInput(props) {
    const { key, error, onChange, valueMapping, labelMapping, list, value, styles , label} = props
    const classes = props.classes || {}
    return (
        <div style={styles}>
            <FormControl className={classes.formControl} key={key} error={error} >
                <InputLabel htmlFor={label}>{label}</InputLabel>

                <Select
                    value={value ||  ''}
                    onChange={event => onChange(event.target.value)}
                    style={{width:250}}
                    className={classes.input}
                    input={<Input />}
                >
                    <MenuItem key={''} value={null} className={classes.input}>
                            -
                    </MenuItem>

                    {
                        list && 
                        list
                            // eslint-disable-next-line eqeqeq
                            .filter(d => d != undefined)
                            .map(item => (
                                <MenuItem key={valueMapping(item)} value={valueMapping(item)} className={classes.input}>
                                    {labelMapping(item)}
                                </MenuItem>
                            ))
                    }
                </Select>
            </FormControl>
        </div>
    )
}