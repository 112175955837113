import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import { DestinationTableCreatorQueries } from './DestinationTableCreator'

class DestinationFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "DestinationFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Destination', DestinationTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Name', modificationReadOnly: true}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(DestinationFormCreator)

export default DestinationFormCreator