/* eslint-disable eqeqeq */
import { 
    NullClickStrategy, 
    OnlyFilterActionDisplayStrategy, 
} from '../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import {getConsumptionsTableDefinitions, tableQuery} from './ConsumptionsTableDefinitions'

const queries = {tableQuery};

class ConsumptionsTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ConsumptionsTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        const excludedFields = [];
        const fieldDefinitions = getConsumptionsTableDefinitions(this.parentProps, excludedFields);
        tableCreator.setDescriptions(fieldDefinitions)

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ConsumptionsTableCreator)

export { ConsumptionsTableCreator, tableQuery }