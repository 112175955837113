import React from 'react'

import { useAuth } from '../../contexts/Auth'
import { useParams } from 'react-router-dom'

const SsoLogin = () => {
    const { authenticate } = useAuth();
    const { token } = useParams();

    authenticate(token, '/');

    return <></>
}

export default SsoLogin;