import React, { Component } from 'react'
import { FormControl, Popper, ClickAwayListener, TextField, InputAdornment } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { withTranslation } from 'react-i18next';
import PeriodsGrid from '../../../PeriodsGrid/PeriodsGrid';

export class PeriodInput extends Component {
    constructor(props) {
        super(props)
        this.currentDate = props.formState.mappedData[props.inputId] ? new Date(`${props.formState.mappedData[props.inputId]}-01`) : new Date()
        this.state = {
            anchorEl: null,
            year: props.year || this.currentDate.getUTCFullYear(),
            month: props.month || props.defaultMonth ? props.defaultMonth : this.currentDate.getUTCMonth() + 1,
            loading : true,
        }
    }

    componentDidMount(){
        if (this.state.loading){
            if (this.props.onChangeHandler) {
                this.props.onChangeHandler([this.props.inputId], this.generateDate(this.state.year, this.state.month))
            }

            this.setState({ loading: false })
        }
    }

    handleClick = (event) => {
        this.setState({ anchorEl: this.state.anchorEl ? null : event.currentTarget });
    }

    close = () => {
        this.setState({ anchorEl: null });
    }

    generateDate = (ayear, aMonth) => {
        return `${ayear}-${aMonth.toString().padStart(2, '0')}`;
    }

    onSetDate = (newYear, newMonth) => {
        if (this.props.onChangeHandler) {
            this.props.onChangeHandler([this.props.inputId], this.generateDate(newYear, newMonth))
        }

        this.setState({ anchorEl: null, year: newYear, month: newMonth });
    }

    render() {
        const { required, classes, label, id, formState, enterPressed, inputId, t } = this.props;
        const error = Boolean(formState.errors[id]);
        const inputLabel = required ? required(t(label)) : t(label);

        return (
            <FormControl className={classes.formControl} key={id} error={error}>
                <TextField
                    label={ inputLabel }
                    value={ this.generateDate(this.state.year, this.state.month) }
                    onClick={ this.handleClick }
                    style={{ width: 200, cursor: "pointer" }}
                    error={error}
                    className={ classes.input }
                    onKeyPress={ enterPressed }
                    key={"input" + id}
                    InputProps={{ 
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <CalendarTodayIcon
                                    cursor="pointer"
                                    onClick={this.handleClick}
                                />
                            </InputAdornment>
                        )
                    }}
                />
                <Popper
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    placement="bottom-start"
                >
                    <ClickAwayListener onClickAway={this.close}>
                        <div>
                            <PeriodsGrid
                                year={this.state.year}
                                setDate={this.onSetDate}
                                checkDisabledMonths={true}
                                maxYear={this.currentDate.getUTCFullYear() + 1}
                            />
                        </div>
                    </ClickAwayListener>
                </Popper>
            </FormControl>
        );
    }
}

export default withTranslation('fieldLabels')(PeriodInput);