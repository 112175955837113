import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    NumberComponentCreator,
    DefaultClickStrategy, 
    BusgetsDisplayStrategy,
    TableFieldDescription,
    DataTransformer,
    TreeNodeComponentCreator
} from '../../components/BPMTable/TableMapperCreator'
import { BackToBudgetsFooterCreator, ExportFooterItemCreator, MassiveUploadFooterItemCreator } from '../../components/BPMTable/TableFooterItems'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllBudgetPeriods (
        $orderField: String, 
        $first: Int, 
        $last: Int, 
        $after: String, 
        $before: String,
        $budgetId: String!
    ) {
        allBudgetPeriods (
            orderField: $orderField,
            first: $first, 
            last: $last, 
            after: $after, 
            before: $before,
            budgetId: $budgetId
        ) {
            edges {
                node {
                    id
                    budget {
                        id
                        currency {
                            id
                            code
                            symbol
                        }
                    }
                    glAccount {
                        id
                        name
                        displayName
                        level
                        isLeaf
                        treeId
                        lft
                        parent {
                            id
                        }
                    }
                    period {
                        id
                        date
                    }
                    value
                    assignedAmount
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editBudgetPeriodMutation(
        $id: String!,
        $glAccount: String!,
        $period: String!,
        $value: String!,
    ) {
        editBudgetPeriod(
            id: $id,
            glAccount: $glAccount,
            period: $period,
            value: $value,
        ) {
            budgetPeriod {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createBudgetPeriodMutation(
        $budget: String!,
        $glAccount: String!,
        $period: String!,
        $value: Float!,
    ) {
        createBudgetPeriod(
            budget: $budget,
            glAccount: $glAccount,
            period: $period,
            value: $value,
        ) {
            budgetPeriod {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation deleteBudgetPeriodMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        deleteBudgetPeriod(ids: $ids, allRecords: $allRecords, filters: $filters) {
            result
        }
    }
`

const unitQuery = `
    budgetPeriod(id: $id) {
        id
        budget {
            id
            currency {
                id
                code
                symbol
            }
        }
        glAccount {
            id
            name
            displayName
            level
            isLeaf
            treeId
            lft
            parent {
                id
            }
        }
        period {
            id
            date
        }
        value
        assignedAmount
    }
`

const BudgetPeriodTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class BudgetPeriodTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "BudgetPeriodTableCreator"
    }

    budgetsReadOnlyRowEvaluator(data) {
        return data.glAccountIsLeaf == "false"
    }

    budgetsCustomRowStyleEvaluator(data) {
        return data.glAccountIsLeaf == "false" ? {backgroundColor: '#cdcdcd'} : {}
    }

    budgetPeriodCellStyleEvaluator(data) {
        return (data.glAccountIsLeaf == "false") ? {fontWeight:'bold'} : {}
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName || 'Budget Periods', BudgetPeriodTableCreatorQueries, new DefaultClickStrategy())
        tableCreator.setDisplayStrategy(new BusgetsDisplayStrategy())
        tableCreator.setDefaultRowHeight(28.0);
        tableCreator.setTableFooterItems([new BackToBudgetsFooterCreator(), new ExportFooterItemCreator(), new MassiveUploadFooterItemCreator()])
        tableCreator.setReadOnlyRowEvaluator(this.budgetsReadOnlyRowEvaluator);
        tableCreator.setCustomRowStyleEvaluator(this.budgetsCustomRowStyleEvaluator);
        tableCreator.setCustomCellStyleEvaluator(this.budgetPeriodCellStyleEvaluator);
        tableCreator.setMassiveUploadTemplateUrlContext({budget_id: parseInt(this.filters.budgetId)})
    
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'glAccount',
                new DataMapper('glAccount.name', 'glAccount.level'),
                new f.FilterDescription(new f.TextFilter('glAccount')),
                {
                    label: 'Gl Account', 
                    hideSort: true, 
                    componentCreator: new TreeNodeComponentCreator(), 
                    transform: new DataTransformer(({name, level}) =>  ({name: name, level: level})),
                    excelTransform: new DataTransformer(({name, level})=>(name))
                }
            ),
            new TableFieldDescription(
                'glAccountIsLeaf',
                new DataMapper('glAccount.isLeaf'),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Gl Account Is Leaf', hideSort: true, hidenField: true}
            ),
            new TableFieldDescription(
                'period',
                new DataMapper('period.date'),
                new f.FilterDescription(new f.PeriodFilter("period")),
                {label: 'Period', hideSort: true}
            ),
            new TableFieldDescription(
                'value',
                new DataMapper('assignedAmount', 'budget.currency.symbol'),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Value', hideSort: true,
                    componentCreator: new NumberComponentCreator('', 2), 
                    transform: new DataTransformer(({assignedAmount, symbol}) => ({value: Number(assignedAmount).toFixed(2), symbol: symbol})),
                    excelTransform: new DataTransformer(({assignedAmount, symbol})=>(Number(assignedAmount).toFixed(2)))
                }
            ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(BudgetPeriodTableCreator)

export {
    BudgetPeriodTableCreator,
    BudgetPeriodTableCreatorQueries
};