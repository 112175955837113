import React, { useState } from 'react'

import { gql, useApolloClient } from '@apollo/client'
import { Typography, TextField, Button } from '@material-ui/core'

const MUTATION = gql`
    mutation createDestinationMutation($name: String!) {
        createDestination(name: $name) {
            destination {
                id
            }
        }
    }
`

export const nullQuery = gql`
    query {
        __type(name: "") {
            name
        }
    }
`

export const DestinationDescriptionsAssignation = gql `
    mutation($objects: [String!], $invoice: String!) {
        assignDestinationDescriptions(objects: $objects, invoice: $invoice) {
            response
        }
    }
`

export function DestinationCreationForm(props) {
    const { client } = useApolloClient()
    const [name, setName] = useState("");
    const nameError = false;

    const handleCreate = () => {
        client.mutate({
            variables : {name:name},
            mutation: MUTATION
        }).then(r => {
            props.refetch();
        }).then(r => {
            props.close()
        }).catch(err => console.log(err))
    }
    
    return (
        <div style={{padding:20}}>
            <Typography variant="h6">Destination Creation</Typography>
                <TextField  label={"Name"}
                            value={name}
                            onChange = {event =>  setName(event.target.value)}
                            style={{width:"100%", fontSize:15, textAlign:"left"}}
                            error={nameError}
                />

                <Button size="small"
                        color="primary"
                        style={{width:"100%", marginTop:5}}
                        onClick={handleCreate}
                        // eslint-disable-next-line eqeqeq
                        disabled={name == ""}
                        variant='contained'>
                    Create
                </Button>
        </div>
    )
}