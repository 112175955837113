import React, { useState } from 'react'

import {
    Card,
    Paper,
    //Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import BudgetReportTotalsTable from './BudgetReportTotalsTable'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import { BudgetReportTableCreator } from '../../tableDefinitions/Budget/BudgetReportTableCreator'

export default function BudgetReportTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(BudgetReportTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator("Budget Report")

    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        >
            {(data, tableManager) =>
                <BudgetReportTotalsTable
                    visibleColumns = {tableManager.getVisibleFields().length}
                    symbol= {props.symbol}
                    data={props.budgetByPeriods ? props.budgetByPeriods : { avg: 0, avg:0, avg: 0, avg:0, avg: 0, avg: 0, avg: 0}}
                />
            }
        </Table>
    )
}

{/*
const CustomTableHead = withStyles(theme => ({
    root: {
        backgroundColor: '#0A1F2E',
        height: '25px'
    }
}))(TableHead);

const TableHeaderCell = withStyles(theme => ({
    root: {
        padding: "0px 8px",
        color: 'white',
        align: 'right',
    }
}))(TableCell);

const LeafTableRow = withStyles(theme => ({
    root: {
        height: "25px",
        paddingTop: "0.8px",
        paddingBottom: "0px",
        backgroundColor: '#cdcdcd', 
        fontWeight: 'bold',
    }
}))(TableRow);

const CustomTableRow = withStyles(theme => ({
    root: {
        height: "25px",
        paddingTop: "0.8px",
        paddingBottom: "0px"
    }
}))(TableRow);

const BudgetReportTable = (props) => {
    const { t } = props
    const [symbol] = useState(props.symbol)
    const [data] = useState(props.data)
    const [assignedByPeriod] = useState(props.assignedByPeriod)

    console.log(data)
    console.log(assignedByPeriod)

    const formatValue = (number) => {
        const options = {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        };
        return symbol + " " + (number ? number.toLocaleString('en-US', options) : "0.00")
    }

    const formatPercent = (number) => {
        return number ? (number * 100).toFixed(2) + "%" : "0.00%"
    }

    const BudgetRow = props => {
        return props.data?.isLeaf ? LeafTableRow : CustomTableRow
    }

    const BudgetTableCell = props => {
        const level = props.level ? props.level : 0
        const cellFormat = props.isLeaf ? { padding: "0px 8px" } : { padding: "0px 8px", backgroundColor: '#cdcdcd', fontWeight: 'bold', }

        return (
            <TableCell style={cellFormat}>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span style={{ whiteSpace: 'nowrap', paddingLeft: level * 20 }}>
                        {props.value}
                    </span>
                </div>
            </TableCell>
        )
    }

    const PeriodValueCell = props => {
        const cellFormat = props.isLeaf ? { padding: "0px 8px" } : { padding: "0px 8px", backgroundColor: '#cdcdcd', fontWeight: 'bold', }

        return (
            <TableCell style={cellFormat}>
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span style={{ whiteSpace: 'nowrap' }}>
                        {props.value}
                    </span>
                </div>
            </TableCell>
        )
    }

    return (
        <Card id="budgetStatistics" >
            <div id="budgetTable">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <CustomTableHead>
                            <CustomTableRow>
                                <TableHeaderCell>Gl Column</TableHeaderCell>
                                <TableHeaderCell>Gl Description</TableHeaderCell>
                                {assignedByPeriod.map((row, index) => (
                                    <TableHeaderCell key={'head_' + index}>{row.period.date}</TableHeaderCell>
                                ))}
                            </CustomTableRow>
                        </CustomTableHead>
                        <TableBody>
                            {data.map((budget_row, row) => (
                                <CustomTableRow key={'row_' + row}>
                                    <BudgetTableCell value={budget_row.node.glAccountName} level={budget_row.node.level} isLeaf={budget_row.node.isLeaf}/>
                                    <BudgetTableCell value={budget_row.node.glAccountDescription} isLeaf={budget_row.node.isLeaf}/>
                                    {budget_row.node.costPerPeriod.map((periodCell, col) => (
                                        <BudgetTableCell key={'cell_' + col} value={periodCell.cost} isLeaf={budget_row.node.isLeaf}/>
                                    ))}
                                </CustomTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Card>
    )
}

export default BudgetReportTable
*/}