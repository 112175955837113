import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        color: '#158cba'
    },
    label: {
        textTransform: 'capitalize'
    }
})

const AppBarButton = (props) => {
    const classes = useStyles()

    return (
        <Button classes={{root: classes.root, label: classes.label}}>
            {props.label}
        </Button>
    )
}

export default AppBarButton