import React from 'react'
import ReactDOM from 'react-dom'

class Portal extends React.Component {
    constructor() {
        super()
        this.el = document.createElement('div')
    }

    componentDidMount() {
        this.portalRoot = document.getElementById('portal')
        this.portalRoot.appendChild(this.el)
    }

    componentWillUnmount() {
        this.portalRoot.removeChild(this.el)
    }

    render() {
        const { children } = this.props
        return ReactDOM.createPortal(children, this.el)
    }
}

export default Portal