/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import { GlAccountBudgetAssociationTableCreator } from '../../../tableDefinitions/Budget/Associations/GlAccountBudgetAssociationTableCreator'
import GlAccountBudgetAssociationFormCreator from '../../../tableDefinitions/Budget/Associations/GlAccountBudgetAssociationFormCreator'

const GlAccountBudgetAssociation = (props) => {
    const { t } = useTranslation('budget')
    document.title = `${t('Budgets Associations')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Gl Account Budget Associations'}
            tableCreatorCreatorId={GlAccountBudgetAssociationTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={GlAccountBudgetAssociationFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default GlAccountBudgetAssociation