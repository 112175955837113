import React from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import MutationSetup from './TableComponents/Mutation/MutationSetup'
import SetupTableData from './TableComponents/TableWrapper/SetupTableData'

function BPMTable(props) {
    const client = useApolloClient()
    const { TableCreator, FormCreator, path, DetailsTableCreator, DetailsFormCreator } = props

    var FormManager = FormCreator ? FormCreator.create() : null

    return (
        <Switch>
            <Route exact strict path='/:url*' render={props => <Redirect to={`${props.location.pathname}/`} />} />
            <Route exact path={path}>
                <SetupTableData
                    TableCreator={TableCreator}
                    stickyHeader
                />
            </Route>
            <Route exact path={`${path}/new`}>
                <MutationSetup 
                    client={client}
                    TableObject={FormManager}
                    create={true}
                    path={path}
                />
            </Route>
            <Route exact path={`${path}/edit/:id`}>
                <MutationSetup 
                    client={client}
                    TableObject={FormManager}
                    create={false}
                    path={path}
                />
            </Route>
            <Route exact path={`${path}/detail/:detailId`}>
                <SetupTableData
                    TableCreator={DetailsTableCreator}
                    stickyHeader
                />
            </Route>
            <Route exact path={`${path}/detail/:detailId/edit/:id`}>
                <MutationSetup 
                    client={client}
                    TableObject={DetailsFormCreator}
                    mutate='edit'
                    path={path}
                />
            </Route>
        </Switch>
    )
}

export default BPMTable