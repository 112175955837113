import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import { ChargeGroupConceptTableCreator } from '../../../tableDefinitions/Billing/ChargeGroupConcept/ChargeGroupConceptTableCreator'
import ChargeGroupConceptFormCreator from '../../../tableDefinitions/Billing/ChargeGroupConcept/ChargeGroupConceptFormCreator'

const ChargeGroupConcept = (props) => {
    const { t } = useTranslation('billManagement')
    document.title = `${t('Charge Groups Concept')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Charge Group Concept'}
            tableCreatorCreatorId={ChargeGroupConceptTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={ChargeGroupConceptFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default ChargeGroupConcept