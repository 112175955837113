import React from 'react'
import { CloudCircle as CloudDownloadIcon, Description as DescriptionIcon, AssignmentTurnedIn , KeyboardBackspace as KeyboardBackspaceIcon} from '@material-ui/icons'
import { 
    MenuItem, 
    ListItemIcon, 
    ListItemText, 
} from '@material-ui/core'


function DefaultFooterItemComponent({icon, label, onClick, t, index}) {
    return (
        <MenuItem key={index} onClick={onClick}>
            <ListItemIcon key={"lii_"+index} >
                {icon}
            </ListItemIcon>
            <ListItemText key={"lit_"+index} primary={t(`tableFooter:${label}`)} />
        </MenuItem>
    )
}

class MassiveUploadFooterItemCreator{
    constructor(){
        this.label = "Massive Load";
        this.icon = <DescriptionIcon />;
    }

    
    onClick(tableFooter){
        tableFooter.handleCloseMoreToolsOptions();
        tableFooter.handleMassiveUploadsDialog();
    }

    doFor(tableFooter, tableObject, t, index){
        return React.createElement(DefaultFooterItemComponent,{tableFooter:tableFooter, tableObject:tableObject, t:t, key:"a__"+index, index:index, onClick:()=>this.onClick(tableFooter), label:this.label, icon:this.icon})
    }
}

class ExportFooterItemCreator{
    constructor(){
        this.label = "Export";
        this.icon = <CloudDownloadIcon />;
    }

    onClick(tableFooter){
        tableFooter.handleCloseMoreToolsOptions();
        tableFooter.handleClickOpenExportDialog();
    }

    doFor(tableFooter, tableObject, t, index){
        return React.createElement(DefaultFooterItemComponent, {tableFooter:tableFooter,label:this.label, icon:this.icon, t:t, key:"b__"+index, index:index, onClick:()=>this.onClick(tableFooter)})
    }
}

class ValidatationFooterItemCreator{
    constructor(func){
        this.label = "Validate All";
        this.icon = <AssignmentTurnedIn />;
        this.onClick = func;
    }

    onClick(tableFooter){
        tableFooter.handleCloseMoreToolsOptions();
        this.onClick()
    }
    doFor(tableFooter, tableObject, t, index){
        return React.createElement(DefaultFooterItemComponent, {tableFooter:tableFooter, tableObject:tableObject, onClick:()=>this.onClick(tableFooter), t:t, key:"c__"+index, index:index, label:this.label, icon:this.icon})
    }
}

class CloseAPGLPaymentFooterCreator{
    constructor(){
        this.label = "Close payments with file";
        this.icon = null;
    }

    onClick(tableFooter){
        tableFooter.handleCloseMoreToolsOptions();
        tableFooter.handleMassiveUploadsDialog();
    }

    doFor(tableFooter, tableObject, t, index){
        return React.createElement(DefaultFooterItemComponent, {tableFooter:tableFooter, tableObject:tableObject, onClick:()=>this.onClick(tableFooter), t:t, key:"d__"+index, index:index, label:this.label})
    }
}

function BackToBudgetsFooterItemComponent({icon, label, onClick, t, index}) {
    return (
        <MenuItem key={index} onClick={onClick}>
            <ListItemIcon key={"lii_"+index} >
                {icon}
            </ListItemIcon>
            <ListItemText key={"lit_"+index} primary={t(`tableFooter:${label}`)} />
        </MenuItem>
    )
}

class BackToBudgetsFooterCreator{
    constructor(){
        this.label = "Back to Budgets";
        this.icon = <KeyboardBackspaceIcon />;
    }

    onClick(tableFooter){
       tableFooter.handleGoToBudgets();
    }

    doFor(tableFooter, tableObject, t, index){
        return React.createElement(BackToBudgetsFooterItemComponent, {tableFooter:tableFooter, tableObject:tableObject, t:t, key:"a__"+index, index:index, onClick:()=>this.onClick(tableFooter)})
    }
}

export{
    MassiveUploadFooterItemCreator,
    ExportFooterItemCreator,
    ValidatationFooterItemCreator,
    CloseAPGLPaymentFooterCreator,
    BackToBudgetsFooterCreator,
}