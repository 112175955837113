import React from 'react'

import { FormControl, Input, Typography } from '@material-ui/core'

export default function FileInput(props) {
    const { error, onChangeFunc, styles , label, id, extension} = props
    const classes = props.classes || {}
    return (
        <div style={styles}>
            <FormControl className={classes.formControl} error={error} >
                <Typography htmlFor={id} variant="subtitle1">{`${label} (${extension || ''})`}</Typography>
                <Input type="file" onChange={(event) => onChangeFunc(event)} label={label} id={id} name={extension} />
            </FormControl>
        </div>
    )
}