import * as f from '../../../components/BPMTable/BPMInputs'
import {
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import LegalEntityMinimalTable from '../../../pages/Inventory/LegalEntity/MinimalTable'
import SiteMinimalTable from '../../../pages/Inventory/Sites/MinimalTable'
import { AccountQueries } from './AccountTableCreator'

const allTechnologies = `
    allTechnologies {
        edges {
            node {
                id
                name
            }
        }
    }
`

const allVendors = `
    allVendors {
        edges {
            node {
                id
                name
            }
        }
    }
`

const allDomains =  `
    availableDomains {
        name
        id
    }

`

class AccountFormCreator extends FormCreatorCreator{
    constructor() {
        super()
    }

    static getId = () => {
        return "AccountFormCreator"
    }

    getFormInputs = () => {
        const leganEntityMinimalTable = new LegalEntityMinimalTable({first:15})
        const siteMinimalTable = new SiteMinimalTable({first:15, orderDirection: "desc", orderField: "isActive", isActive:true})

        const generalSection = new FormSection('General Seccion', 1)
        const legalEntitySection = new FormSection("Legal Entity",2)
        const siteSection = new FormSection('Site', 3)
        
        const formInputs = [
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Name'}
            ),
            new FormFieldDescription(
                'site',
                new f.InputDescription(new f.ModelCreator('site', siteMinimalTable), false),
                siteSection,
                1,
                {label: 'Site',transform: new DataTransformer(data => data, data=> data)}
            ),
            new FormFieldDescription(
                'legalEntity',
                new f.InputDescription(new f.ModelCreator('legalEntity', leganEntityMinimalTable), false),
                legalEntitySection,
                1,
                {label: 'Legal Entity', transform: new DataTransformer(data => data, data => data)}
            ),
            new FormFieldDescription(
                'provider',
                new f.InputDescription(new f.DropDownCreator('provider'), false),
                generalSection,
                4,
                {label: 'Vendor', queryAllDropdownOptions: allVendors, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'technology',
                new f.InputDescription(new f.DropDownCreator('technology'), false),
                generalSection,
                5,
                {label: 'Technology', queryAllDropdownOptions: allTechnologies, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'identifier',
                new f.InputDescription(new f.TextCreator('identifier'), true, new f.RequiredValidator(1)),
                generalSection,
                6,
                {label: 'Identifier'}
            ),
            new FormFieldDescription(
                'status',
                new f.InputDescription(new f.BoolCreator('status', true), false),
                generalSection,
                14,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'description',
                new f.InputDescription(new f.TextAreaCreator('description'), false),
                generalSection,
                10,
                {label: 'Description'}
            ),
            new FormFieldDescription(
                'domain',
                new f.InputDescription(new f.DropDownCreator('domain'), false),
                generalSection,
                4,
                {label: 'Domain', queryAllDropdownOptions: allDomains, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                12,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                13,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                14,
                {label: 'Custom Attribute 3'}
            )
        ];

        return formInputs
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Account', AccountQueries)
        formCreator.addInputs(this.getFormInputs());

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(AccountFormCreator)

export default AccountFormCreator;