import React from 'react'

import { withTranslation } from 'react-i18next'
import {Card, CardContent, Grid, Typography} from '@material-ui/core'

const Section = (props) => {
    const {section, inputs, classes, t} = props

    return (
        <Card style={{marginTop: '10px'}} elevation={6}>
            <Typography variant='h6' style={{padding: '10px 20px 0'}}>{t('seccionName:' + section)}</Typography>

            <CardContent classes={{root: classes.sectionContent}}>
                <Grid container>
                    {inputs[section].map(input => {
                        return (
                            <Grid item key={`grid ${input.key}`} zeroMinWidth>
                                {input}
                            </Grid>
                        )
                    })}
                </Grid>
            </CardContent>
      </Card>
    )
}

export default withTranslation()(Section)
