import { gql } from '@apollo/client'
import { NullClickStrategy,
         TableFieldDescription, 
         DataMapper,
         TablePickerDisplayStrategy,
         NumberComponentCreator } from '../../../components/BPMTable/TableMapperCreator';
import * as f from '../../../components/BPMTable/BPMInputs'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator';
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator';

const tableQuery = gql`
query($groupIdExact:String,
      $invoicePaymentId:String,
      $invoiceId:String,
      $invoiceAccount:String,){
    allInvoicePayments(groupIdExact:$groupIdExact,
                       invoicePaymentId:$invoicePaymentId,
                       invoiceId:$invoiceId,
                       invoiceAccount:$invoiceAccount,){
      edges{
        node{
          id
          group{
            id
          }
          invoice{
            account{
              identifier
            }
            identifier
            totalCost
          }
        }
      }
    }
  }
`;

const queries = {tableQuery}

class InvoiceGroupPopUpTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoiceGroupPopUpTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new TablePickerDisplayStrategy())
        tableCreator.setInitialSortField('invoicePaymentId', 'desc')
        tableCreator.addDescriptions ([
            new TableFieldDescription(
                'invoicePaymentId',
                new DataMapper("id"),
                new f.FilterDescription(new f.TextFilter("invoicePaymentId")),
                {label: 'Id'}
            ),
            new TableFieldDescription(
                'invoiceId',
                new DataMapper("invoice.identifier"),
                new f.FilterDescription(new f.TextFilter("invoiceId")),
                {label: 'Invoice Identifier'}
            ),
            new TableFieldDescription(
                'invoiceAccount',
                new DataMapper("invoice.account.identifier"),
                new f.FilterDescription(new f.TextFilter("invoiceAccount")),
                {label: 'Account Identifier'}
            ),
            new TableFieldDescription(
                'invoiceTotalCost',
                new DataMapper("invoice.totalCost"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Invoice Total Cost', componentCreator:new NumberComponentCreator(this.parentProps.currentUserData.currency.symbol)}
            ),
        ])

        return tableCreator
    }
}
    
tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoiceGroupPopUpTableCreator)
    
export default InvoiceGroupPopUpTableCreator
    
