/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { FormControl, MenuItem, Paper, InputLabel, Input, Popper, Typography, Popover } from '@material-ui/core';
import Downshift from 'downshift';
import { withTranslation } from 'react-i18next';

class DropDownSearch extends Component {
  state = {
    anchorEl: null,
    popper: null,
  }

  sortedItems(data, id) {
    return data.sort((a, b) => a[id].localeCompare(b[id]))
  }

  handlePopoverOpen = (target) => {
    this.setState({ popper: target })
  }

  handlePopoverClose = () => {
    this.setState({ popper: null })
  }
  setAnchor = e => {
    this.setState({ anchorEl: e.currentTarget })
  }

  render() {
    const { required, classes, label, id, formState, onChangeHandler, enterPressed, inputId, t } = this.props;
    const { anchorEl, popper } = this.state
    const error = Boolean(formState.errors[id]);
    const item = { id: formState.mappedData[id], [id]: formState.displayData[id] }
    const disabled = !Boolean(formState.formData[id] && formState.formData[id].length > 0)
    const inputText = item ? item[id] : "";
    const inputLabel = required ? required(t(label)) : t(label);

    return (
      <FormControl className={classes.formControl} key={"formControl" + id} error={error} disabled={disabled} >
        <Downshift
          onChange={selection => onChangeHandler(inputId, selection.id)}
          itemToString={item => (item ? item[id] : '')}
          initialInputValue={inputText || ''}
          initialSelectedItem={item || null}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            isOpen,
            openMenu,
            inputValue,

          }) => (
            <div>
              <InputLabel htmlFor={label} {...getLabelProps()}>{inputLabel}</InputLabel>
              <Input {...getInputProps()}
                value={inputValue}
                className={classes.input}
                style={{ width: 250 }}
                onMouseEnter={disabled ? (event) => this.handlePopoverOpen(event.target) : null}
                onMouseLeave={disabled ? this.handlePopoverClose : null}
                onFocus={event => { openMenu(); this.setAnchor(event) }}
              />
              <Popper {...getMenuProps({}, { suppressRefError: true })} open={isOpen} anchorEl={anchorEl} placement="bottom-start">
                <Paper style={{ maxHeight: 200, overflow: "auto" }}>
                  <MenuItem
                    {...getItemProps({
                      key: item.id,
                      index: item.id,
                      item,
                    })}
                  >
                    {item[id]}
                  </MenuItem>
                  {isOpen ?
                    formState.formData[id] &&
                    this.sortedItems(formState.formData[id], id).filter(d => d)
                      .filter(item => inputValue == "" || item[id].toLowerCase().includes(inputValue.toLocaleLowerCase()))
                      .map(item => (
                        <MenuItem
                          {...getItemProps({
                            key: item.id,
                            index: item.id,
                            item,
                          })}
                        >
                          {item[id]}
                        </MenuItem>
                      ))
                    : null}
                </Paper>
              </Popper>
            </div>
          )}
        </Downshift>
        <Popper name="tooltip" open={Boolean(popper)} anchorEl={popper}>
          <Typography>No options available</Typography>
        </Popper>
      </FormControl>
    )
  }
}
export default withTranslation('fieldLabels')(DropDownSearch);