import React from 'react'

import BillingAccountTableCreator from '../../../tableDefinitions/Billing/BillingAccount/BillingAccountTableCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import Table from '../../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import BillingAccountsTotals from './BillingAccountsTotals'

export default function BilingAccountsTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(BillingAccountTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('Billing Accounts')

    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        >
            {(data, tableManager) =>
                <BillingAccountsTotals 
                    visibleColumns={tableManager.getVisibleFields().length}
                    symbol={props.symbol}
                    nativeCurrency={props.nativeCurrency}
                    data={
                        data?.billingAccountsReport?.costs ? data.billingAccountsReport.costs : {cost: 0, cost2: 0, cost3: 0, cost4: 0, cost5: 0, cost6: 0}}
                />
            }
        </Table>
    )
}