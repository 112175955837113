/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import { gql } from '@apollo/client'
import { withTranslation } from 'react-i18next'
import { Select, MenuItem } from '@material-ui/core'

import Loading from '../../components/Loading/Loading'
import CurrencyPicker from '../../components/CurrencyPicker/CurrencyPicker'
import { useCurrentUser, useLastPublishedPeriod } from '../../redux/selectors'
import { BudgetTableCreatorQueries } from '../../tableDefinitions/Budget/BudgetTableCreator'
import BudgetReportStatistics from './BudgetReportStatistics'
import BudgetReportCharts from './BudgetReportCharts'
import BudgetReportTable from './BudgetReportTable'

const BUDGET_SUMARY_QUERY = gql`query BudgetReportStatistics (
        $budget: String, 
        $currency: String, 
) {
        budgetReportStatistics (
            budget: $budget,
            currency: $currency, 
    ) {
        budgetByPeriods {
            period
            assigned
            spent
            avg
        }
        generalStatistics {
            budgetExpense
            actualExpense
            budgetBalance
        }
        monthlyStatistics {
            lastMonth {
                budgetExpense
                actualExpense
                budgetBalance
            }
            last6MonthsAvg {
                budgetExpense
                actualExpense
                budgetBalance
            }
            fullPeriodMonthlyAvg {
                budgetExpense
                actualExpense
                budgetBalance
            }
        }
    }
}
`

const BudgetSelector = (props) => {
    const { t } = props

    const LastPublishedPeriod = useLastPublishedPeriod()
    const currentUser = useCurrentUser()


    const [currency, setCurrency] = useState({ symbol: currentUser.currency.symbol, code: currentUser.currency.id })
    const [budgets, setBudgets] = useState(props.budgetList || []);
    const [currentBudget, setCurrentBudget] = useState(props.budgetList ? props.budgetList[0] : null);

    var currentDate = new Date();
    const currentYear = currentDate.getUTCFullYear();
    const currentMonth = currentDate.getUTCMonth() + 1;

    const changeCurrency = (currencyCode, currencySymbol) => {
        setCurrency({ symbol: currencySymbol, code: currencyCode })
    }

    function onChangeBudget(event) {
        setCurrentBudget(budgets.filter(budget => budget.id === event.target.value)[0]);
    };

    const generateVariables = () => {
        return {
            budget: currentBudget.id,
            currency: currency.code,
        }
    }

    return (
        <div>
            <div>
                <div style={{ paddingBottom: 10, display: 'flex' }}>
                    <div key="budget_selector" style={{ display: 'flex', width: "40%" }}>
                        <span style={{ color: '#0000008a' }}>{props.t('budget:Select a Budget') + ": "}</span>
                        <Select style={{ marginLeft: '20px' }}
                            id="budget-select"
                            value={currentBudget?.id ? currentBudget.id : '0'}
                            onChange={onChangeBudget}
                            selected={currentBudget?.id ? currentBudget.description : props.t('budget:Select a Budget')}
                        >
                            {budgets.map(budget =>
                                <MenuItem 
                                    key={'budget_id_' + budget.id} 
                                    value={budget.id}
                                >
                                    {`${budget.description} (${props.t('budget:From')}: ${budget.startPeriod.date} ${props.t('budget:To')}: ${budget.endPeriod.date})`}
                                </MenuItem>
                            )}
                        </Select>
                    </div>
                    <div key="ccy" style={{ display: 'flex', width: "40%", paddingLeft: 30 }}>
                        <span style={{ float: "right", fontSize: 18, width: '100%' }}>
                            <CurrencyPicker
                                selectedSymbol={currency.symbol}
                                selected={currency.code}
                                onChangeCurrency={changeCurrency}
                                month={currentMonth}
                                year={currentYear} />
                        </span>
                    </div>
                </div>
                {currentBudget ? 
                <Loading query={BUDGET_SUMARY_QUERY} variables={generateVariables()}>
                    {(data) =>
                        <div>
                            <BudgetReportStatistics
                                t={t} 
                                generalStatistics={data?.budgetReportStatistics?.generalStatistics ? data.budgetReportStatistics.generalStatistics : {}}
                                monthlyStatistics={data?.budgetReportStatistics?.monthlyStatistics ? data.budgetReportStatistics.monthlyStatistics : {}}
                                symbol={currency.symbol}
                            />
                            <BudgetReportCharts
                                t={t} 
                                symbol={currency.symbol}
                                generalStatistics={data?.budgetReportStatistics?.generalStatistics?.budgetExpense ? data.budgetReportStatistics.generalStatistics : {}}
                                budgetByPeriods={data?.budgetReportStatistics?.budgetByPeriods ? data.budgetReportStatistics.budgetByPeriods : []}
                            />
                            <BudgetReportTable
                                t={t} 
                                filters={generateVariables()}  
                                symbol={currency.symbol}
                                budgetByPeriods={data?.budgetReportStatistics?.budgetByPeriods ? data.budgetReportStatistics.budgetByPeriods : []}
                            />
                        </div>
                    }
                </Loading>
                : null}
            </div>
        </div>

    )
}

const BudgetReport = (props) => {
    document.title = props.t('Budget Report - BubbleBPM')

    return (
        <div id="app">
            <Loading query={BudgetTableCreatorQueries.tableQuery} variables={{ orderField: '-startPeriod' }}>
                {(data) => {
                    const budgets = []
                    for (let budget of data.allBudgets.edges) {
                        budgets.push(budget.node)
                    }

                    return (
                        <BudgetSelector
                            t={props.t}
                            budgetList={budgets}
                        />
                    )
                }}
            </Loading>
        </div>
    )
}

export default withTranslation('budget')(BudgetReport)
