import React from 'react'

import TotalTableEstructure from './TotalTable'

export default function RankingTotals(props) {
    return (
        <TotalTableEstructure
            data={[
                {symbol:props.symbol, unit:props.unit, value:props.data.cost},
                {value:null},
            ]}
            visibleColumns={props.visibleColumns}
        />
    )
}