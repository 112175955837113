import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { DestinationConverterQueries } from './DestinationConverterTableCreator'

const allDestinations = `
    allDestinations {
        edges {
            node {
                id
                name
            }
        }
    }
`

const allDestinationDescriptions = `
    allDestinationDescriptions(onlyAvailable:true) {
        edges {
            node {
                id
                name
            }
        }
    }
`

class DestinationConverterFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "DestinationConverterFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Destination Converter', DestinationConverterQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'destination',
                new f.InputDescription(new f.DropDownCreator('destination'), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'Destination', queryAllDropdownOptions: allDestinations, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'destinationDescription',
                new f.InputDescription(new f.DropDownSearchCreator('destinationDescription'), true, new f.RequiredValidator(1)),
                generalSection,
                3,
                {label: 'Destination Description', queryAllDropdownOptions: allDestinationDescriptions, transform: new DataTransformer(data => data.name)}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(DestinationConverterFormCreator)

export default DestinationConverterFormCreator