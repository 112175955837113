import React from 'react'
import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../components/BPMTable/PagesCreator/BPMBasicPage'
import { ContractTableCreator } from '../../tableDefinitions/Contract/ContractTableCreator'
import ContractFormCreator from '../../tableDefinitions/Contract/ContractFormCreator'

const Contract = (props) => {
    const { t } = useTranslation(['contracts'])
    document.title = `${t('Contracts')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Contracts'}
            tableCreatorCreatorId={ContractTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={ContractFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Contract
