import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ClickAwayListener, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, Popper } from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'

import classes from './SubMenu.module.css'

const useStyles = makeStyles({
    root: {
        minWidth: '10px'
    }
})

const SubMenu = (props) => {
    const { t } = useTranslation(['drawer', 'billManagement', 'invoiceProcessing', 'inventoryManagement'])
    const muiClasses = useStyles()
    const [anchor, setAnchor] = useState(null)

    const handlePopper = (event) => {
        setAnchor(anchor ? null : event.currentTarget)
    }

    return (
        <>
            <ListItem
                button
                style={{color: 'white', cursor: 'pointer'}}
                onClick={handlePopper}
            >
                {props.icon ? <ListItemIcon>{props.icon}</ListItemIcon> : null}
                <ListItemText>{t(props.label)}</ListItemText>
                <ListItemIcon classes={{root: muiClasses.root}}>
                    <ArrowRight style={{color: 'white'}} />
                </ListItemIcon>
            </ListItem>

            <Popper open={Boolean(anchor)} anchorEl={anchor} style={{ zIndex: 1500}} placement='right-start'>
                <ClickAwayListener onClickAway={() => setAnchor(null)}>
                    <Paper className={classes.Paper}>
                        {props.items.map((item, index) => {
                            return (
                                <Link key={index} to={item.link}>
                                    <ListItem onClick={() => {setAnchor(null); props.handleDrawer()}}>
                                        <ListItemText style={{paddingRight: '16px'}}>
                                            {t(`${props.translation}:${item.name}`)}
                                        </ListItemText>
                                    </ListItem>
                                </Link>
                            )
                        })}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default SubMenu