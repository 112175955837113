/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { gql } from '@apollo/client'
import { FormControl, Button, InputLabel, Select, MenuItem, Card, Chip, Typography, TextField, ListItem, Checkbox, ListItemText, LinearProgress, Avatar } from '@material-ui/core'
import { Dialog, DialogContent, DialogTitle, Input, Paper, InputAdornment } from "@material-ui/core"

//import { ListItemIcon } from '@material-ui/core/es';
import { ListItemIcon } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import { Warning as WarningIcon } from '@material-ui/icons'

//import Loading from "../../../../shared/components/loading"
import Loading from '../../../Loading/Loading'
import { CustomChip } from '../../TableMapperCreator'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuprops = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const notAssignedAssociationID = 'unassigned'

const getInitialAsociations = (props) => {
    return props.formState.mappedData[props.inputId] ? 
        JSON.parse(props.formState.mappedData[props.inputId]).filter(item => item.id !== notAssignedAssociationID && parseFloat(item.ptc) > 0)
        : [{label: props.t("Not Assigned"), id: notAssignedAssociationID, ptc: "100.00"}]
}

const getInitialSelected = (props) => {
    return props.formState.mappedData[props.inputId] ? 
        JSON.parse(props.formState.mappedData[props.inputId]).reduce((obj, item) => {
            if (item.id !== notAssignedAssociationID && parseFloat(item.ptc)) {
                return { ...obj, [item.id]: item.label }
            }
            return obj
        }, {})
        : {}
}

class InputForInventoryAssociation extends Component {
    state = {
        dialogOpen: false,
        associationFilter: '',
        associations: getInitialAsociations(this.props),
        selected: getInitialSelected(this.props)
    }

    handleOpenDialog = () => {
        this.setState({ dialogOpen: true })
    }

    handleCLoseDialog = () => {
        this.setState({ associations: getInitialAsociations(this.props), selected: getInitialSelected(this.props), dialogOpen: false })
    }

    setAssociationFilter = filter => {
        this.setState({ associationFilter: filter })
    }

    handleSelected = (id, label) => {
        this.setState(state => {
            const selected = state.selected;
            if (selected[id]) {
                delete selected[id]
                const associations = JSON.parse(JSON.stringify(state.associations)).filter(a => a.id != id)
                return ({ selected, associations })
            }
            selected[id] = label
            return ({ selected })
        })
    }

    handelChangeAssociation = (id, event, label) => {
        const {target} = event;
        const ptc = event.target.value;
        const cursorPosition = event.target.selectionStart;

        this.setState(state => {
            const associations = JSON.parse(JSON.stringify(state.associations)).filter(a => a.id != id)
            if (ptc == '') {
                return { associations }
            }
            associations.push({ id: id, label: label, ptc: parseFloat(ptc) || 0 })
            return { associations }
        })

        setTimeout(() => {
            target.selectionStart = cursorPosition;
            target.selectionEnd = cursorPosition;
        }, 0);
    }

    getCurrentAssociatonPtc = () => {
        let ptc = 0
        this.state.associations.filter(association => association.id != notAssignedAssociationID).map(association => ptc += parseFloat(association.ptc) || 0)
        return ptc
    }

    handleConfirm = () => {
        const unAssignedAssociation = {label: this.props.t("Not Assigned"), id: notAssignedAssociationID, ptc: "100.00"}
        const id = this.props.inputId;
        const total_ptc = this.getCurrentAssociatonPtc();
        const associations = JSON.parse(JSON.stringify(this.state.associations.filter(association => association.id !== unAssignedAssociation.id && parseFloat(association.ptc) > 0)))

        if (total_ptc < 100) {
            const unassigned_ptc = 100 - total_ptc;
            const unassigned_label = unAssignedAssociation.label
            associations.push({ id: unAssignedAssociation.id, ptc: unassigned_ptc, label: unassigned_label })
        }

        const displayData = associations.filter(association => association.ptc > 0).map((association, index) => (
            <div key={associations.indexOf(association) + association.label}  >
                <CustomChip
                    label={`${association.label}`}
                    key={association.id || 'Association_' + index + '_chip'}
                    color={association.id != unAssignedAssociation.id ? 'primary' : 'secondary'}
                    ptc={association.ptc}
                />
            </div>
        ))

        const associations_string = JSON.stringify(associations)

        this.props.onChangeHandler(id, associations_string, displayData)
        this.setState({ dialogOpen: false })
    }

    checkData = data => {
        data = Object.values(data)[0]
        if (data.edges) {
            return data.edges
        }
        return data
    }

    checkItem = item => {
        if (typeof (item) == "string") {
            return JSON.parse(item)
        }
        return item.node
    }

    getFieldValue = id => {
        let r = '';
        this.state.associations.map(association => {
            if (association.id == id && parseFloat(association.ptc) > 0) {
                r = parseFloat(association.ptc).toFixed(2)
            }
        })
        return r
    }

    isSelectedAssignation = id => this.state.selected[id] != null;

    render() {
        const { classes,
            label,
            id,
            t,
            labelMapper,
            required,
            formState,
            query,
            inputId
        } = this.props;

        const unAssignedAssociation = {label: t("Not Assigned"), id: notAssignedAssociationID, ptc: "100.00"}
        const { selected, dialogOpen, associationFilter } = this.state;
        const inputLabel = required ? required(t(label)) : t(label);
        const error = Boolean(formState.errors[id]);

        const assignedPtc = this.getCurrentAssociatonPtc();
        const _query = gql`query{
            ${query}
        }`;

        return (

            <div>
                <div style={{ width: '42vw', margin: 8 }}>
                    <FormControl style={{ width: '100%' }} className={classes.formControl} error={error}>
                        <InputLabel htmlFor={label}>{inputLabel}</InputLabel>
                        <Select value={''}
                            IconComponent={SearchIcon}
                            onOpen={this.handleOpenDialog}
                            open={false}
                            menuprops={menuprops}
                            style={{ width: '100%' }}
                            className={classes.input}
                            id={id}
                            input={<Input />} >

                            <MenuItem key={''} value={null} className={classes.input}>
                                -
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <Paper elevation={1} style={{ width: '100%', margin: "-8px 0px 8px 8px" }}>
                        <div style={{ padding: 10, overflow: 'auto', maxHeight: 250 }}>
                            {formState.displayData[inputId] ||
                                <CustomChip
                                    label={unAssignedAssociation.label}
                                    key={'UnassignedAssociation_chip'}
                                    color={'secondary'}
                                    ptc={unAssignedAssociation.ptc}
                                />
                            }
                        </div>
                    </Paper>
                </div>

                <Dialog
                    open={dialogOpen}
                    maxWidth='lg'
                    scroll="paper"
                    onClose={this.handleCLoseDialog}
                    id={'pickerDialog' + label}>
                    <span onClick={this.handleCLoseDialog} style={{ fontSize: '1.5em', cursor: 'pointer', position: 'absolute', right: '10px', top: '5px' }}>&times;</span>

                    <DialogTitle id="scroll-dialog-title" style={{ fontSize: 15 }}>
                        <span style={{ fontSize: 25 }}>  {label}  </span>
                    </DialogTitle>
                    <DialogContent>
                        <Loading query={_query}>
                            {(data) => {
                                if (data) { document.getElementById("body").className = "loaded" };
                                return (
                                    data &&
                                    <div>
                                        <div style={{ display: 'flex', padding: 15 }}>
                                            <Card style={{ display: 'column', height: 250, overflow: 'auto', width: '40vw' }}>
                                                <TextField value={associationFilter}
                                                    style={{ margin: 8, width: '90%' }}
                                                    onChange={event => this.setAssociationFilter(event.target.value)}
                                                    label={t('Filter')}
                                                />
                                                <div style={{ height: 185, overflow: 'auto', padding: 5 }}>
                                                    {
                                                        this.checkData(data)
                                                            .filter(item => {
                                                                const label = labelMapper(this.checkItem(item)).toLowerCase();
                                                                return label.includes(associationFilter.toLowerCase())
                                                            })
                                                            .map(item => {
                                                                item = this.checkItem(item);
                                                                return (
                                                                    <ListItem key={item.id}
                                                                        style={{ padding: 0 }}
                                                                        onClick={() => this.handleSelected(item.id, labelMapper(item))}>
                                                                        <ListItemIcon style={{ margin: 0 }}>
                                                                            <Checkbox
                                                                                style={{ padding: 3 }}
                                                                                checked={this.isSelectedAssignation(item.id)}
                                                                                disableRipple
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={labelMapper(item)} />
                                                                    </ListItem>
                                                                )
                                                            })
                                                    }
                                                </div>
                                            </Card>

                                            <Card style={{ display: 'column', height: 250, overflow: 'auto', width: '40vw', marginLeft: 20, padding: 20 }}>
                                                {Object.keys(selected).map(id => (
                                                    <label key={id} style={{ width: '100%' }}>
                                                        <TextField placeholder={((100 - assignedPtc).toFixed(2))}
                                                            value={this.getFieldValue(id)}
                                                            onChange={event => this.handelChangeAssociation(id, event, selected[id])}
                                                            InputProps={{
                                                                inputProps: {
                                                                    style: { textAlign: "right" }
                                                                },
                                                                style: { width: '80px' },
                                                                endAdornment: (
                                                                    <InputAdornment position="end" style={{ marginLeft: '2px', opacity: 0.9 }}>
                                                                        %
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            className={classes.input}
                                                            key={"input-assoc " + id}
                                                        />
                                                        {' ' + selected[id]}
                                                    </label>
                                                ))}
                                                {(assignedPtc < 100) ?
                                                    <label key={id} style={{ width: '100%', }}>
                                                        <TextField placeholder={((100 - assignedPtc).toFixed(2))}
                                                            value={(100 - assignedPtc).toFixed(2)}
                                                            InputProps={{
                                                                inputProps: {
                                                                    style: { textAlign: "right", readOnly: true }
                                                                },
                                                                style: { width: '80px' },
                                                                endAdornment: (
                                                                    <InputAdornment position="end" style={{ marginLeft: '2px', opacity: 0.9 }}>
                                                                        %
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            className={classes.input}
                                                            key={"input-assoc_null"}
                                                        />
                                                        {' '.concat(unAssignedAssociation.label)}
                                                    </label>
                                                    : null}
                                            </Card>
                                        </div>

                                        <div>
                                            <LinearProgress variant="determinate"
                                                style={{ marginTop: 10 }}
                                                color={assignedPtc > 100 ? 'secondary' : 'primary'}
                                                value={assignedPtc > 100 ? 100 : assignedPtc} />
                                        </div>
                                        <div style={{ minHeight: 35, display: 'flex', justifyContent: 'space-between' }} >
                                            <Typography variant='h6' style={{ padding: 10 }} >{`${assignedPtc.toFixed(2)}% ${t('Assigned')}`}</Typography>
                                            {
                                                assignedPtc < 100 &&
                                                <Chip style={{ padding: 8, margin: 8 }} variant='outlined' color='secondary' icon={<WarningIcon />} label={`${t('Warning')} %${(100 - assignedPtc).toFixed(2)} ${t('will remain unassigned')}`} />
                                            }
                                        </div>
                                        <Button disabled={assignedPtc > 100} onClick={this.handleConfirm} variant='contained' size='small' color='primary' style={{ width: '100%' }}>
                                            {t('Confirm')}
                                        </Button>

                                    </div>
                                )
                            }}
                        </Loading>

                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default withTranslation('fieldLabels')(InputForInventoryAssociation);
