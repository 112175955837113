import React from 'react'

import { makeStyles, TableBody, TableRow, TableCell } from '@material-ui/core'
import BPMLottie from '../../../../Loading/BPMLottie'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiTableRow-root': {
            height: '35px',
        },
    },
    dialogPaper: {
        width: '80%',
        minWidth: '80%',
        minHeight: '80%',
        maxHeight: '80%',
    },
    dialogWidth: {
        width: 'fit-content',
    }
}))

function TableContent({
    TableObject, 
    tableRows,
    client,
    clickedId,
    popupDisplay,
    hidePopup,
    handleSetFilters,
    loadingFirstPageOfData,
    lastRowBlankDisplay,
}) {
    const muiClasses = useStyles()
  
    return (
        <>
            <TableBody>
                {loadingFirstPageOfData ? 
                <TableRow>
                    <TableCell colSpan={TableObject.getVisibleFields().length}>
                        <BPMLottie lottiePosition={200}/>
                    </TableCell>
                </TableRow>
                :
                <>
                    {tableRows}
                    {lastRowBlankDisplay ?
                    <TableRow key='LAST_ROW' >
                        <TableCell/>
                    </TableRow>
                    : null
                    }
                </>
                }
            </TableBody>
            {TableObject.onClickStrategy.createPopUpWith(
                client,
                TableObject,
                clickedId,
                popupDisplay,
                handleSetFilters,
                hidePopup,
                muiClasses
            )}
        </>
    )
}

export default TableContent