let instance;

class FormCreatorCreatorFactoryCreator {
    constructor() {
        if (instance) {
            throw new Error("New instance of FormCreatorCreatorFactoryCreator cannot be created!!");
        }

        this.formCreatorCreators = {}
        this.formCreatorCreatorFactory = new FormCreatorCreatorFactory(this.formCreatorCreators)
        instance = this;
    }

    addFormCreatorCreator = (formCreatorCreator) => {
        this.formCreatorCreators[formCreatorCreator.getId()] = formCreatorCreator
    }

    //createFormCreatorCreatorFactory = () => {
    //    return new FormCreatorCreatorFactory(this.formCreatorCreators)
    //}

    getFormCreatorCreatorFromIdentifier = (identifier, parameters) => {
        return this.formCreatorCreatorFactory.getFormCreatorCreatorFromIdentifier(identifier, parameters)
    }
}


class FormCreatorCreatorFactory {
    constructor(formCreatorCreators) {
        this.formCreatorCreators = formCreatorCreators
    }

    getFormCreatorCreatorFromIdentifier = (identifier, parameters) => {
        return new this.formCreatorCreators[identifier](parameters)
    }
}

const formCreatorCreatorFactoryCreator = Object.freeze(new FormCreatorCreatorFactoryCreator());

export default formCreatorCreatorFactoryCreator;
