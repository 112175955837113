/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react'

import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import EmployeesTotals from './EmployeesTotals'
import MyTeamEmployeesTableCreator from '../../tableDefinitions/MyTeam/MyTeamEmployeesTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const Employees = (props) => {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(MyTeamEmployeesTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('Employees')
  
    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        >
            {(data, managerTable) =>
                <EmployeesTotals
                    symbol={props.symbol}
                    visibleColumns={managerTable.getVisibleFields().length}
                    data={data ? data.myTeamInventoryReport.costs : {cost: 0, cost2: 0, cost3: 0, average: 0}}
                />
            }
        </Table>
    )
}

export default Employees