import React, { useState, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: 300
    },
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
}));
  
const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        minWidth: 350,
        marginLeft: 0
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
};

function VendorsDropDown(props) {
    const classes = useStyles();

    const [selectedVendors, setSelectedVendors] = useState(props.vendors ? props.vendors.map(vendor => vendor.name) : []);
    const [isAllSelected, setIsAllSelected] = useState(props.vendors.length > 0 && selectedVendors.length === props.vendors.length);

    useEffect(() => {
        setIsAllSelected(props.vendors.length > 0 && selectedVendors.length === props.vendors.length)
        props.handleChange(selectedVendors);
    }, [selectedVendors] )

    const handleSelectedChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            setSelectedVendors(selectedVendors.length === props.vendors.length ? [] : props.vendors.map(vendor => vendor.name));
            return;
        }
        setSelectedVendors(value);
    };

    return (
        <Select  style={{marginLeft: '10px', width: 350}}
            multiple
            value={selectedVendors}
            onChange={handleSelectedChange}
            renderValue={(selectedVendors) => selectedVendors.join(", ")}
            MenuProps={MenuProps}
        >
            <MenuItem
                value="all"
                classes={{
                    root: isAllSelected ? classes.selectedAll : ""
                }}
            >
                <ListItemIcon>
                    <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={selectedVendors.length > 0 && selectedVendors.length < props.vendors.length}
                    />
                </ListItemIcon>
                <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary={props.t('fieldLabels:Select All')}
                />
            </MenuItem>
            {props.vendors.map((vendor) => (
            <MenuItem key={vendor.name} value={vendor.name}>
                <ListItemIcon>
                    <Checkbox checked={selectedVendors.indexOf(vendor.name) > -1} />
                </ListItemIcon>
                <ListItemText primary={vendor.name} />
            </MenuItem>
            ))}
        </Select>
    )
}

export default VendorsDropDown;
