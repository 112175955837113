import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
    DataTransformer,
    NullClickStrategy,
    OnlyFilterActionDisplayStrategy,
} from  '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query (
            $orderField: String,
            $dateFrom: String,
            $dateUntil: String,
            $enterpriseId: String,
            $name: String,
            $address: String,
            $postalCode: String,
            $state: String,
            $type: String,
            $country: String,
            $serviceNumber: String!
        ){
        siteAssociations(
            orderField: $orderField,
            dateFrom: $dateFrom,
            dateUntil: $dateUntil,
            enterpriseId: $enterpriseId,
            name: $name,
            address: $address,
            postalCode: $postalCode,
            state: $state,
            type: $type,
            country: $country,
            serviceNumber: $serviceNumber
        ) {
            edges {
                node {
                    association {
                        site {
                            id
                            name
                            address
                            postalCode
                            enterpriseId
                            state
                            type
                            country {
                                id
                                name
                            }
                        }
                        inventory {
                            serviceNumber
                        }
                    }
                    dateFrom
                    dateUntil
                }
            }
            count
        }
    }
`;

const queries = {tableQuery}

class InventorySitesAssociationTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventorySitesAssociationTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'dateFrom',
                new DataMapper("dateFrom"),
                new f.FilterDescription(new f.DateFilter("dateFrom")),
                {label: 'From', transform: new DataTransformer(({dateFrom})=> dateFrom && dateFrom !== '1900-01-01' ? dateFrom : null)}
            ),
            new TableFieldDescription(
                'dateUntil',
                new DataMapper("dateUntil"),
                new f.FilterDescription(new f.DateFilter("dateUntil")),
                {label: 'Until', transform: new DataTransformer(({dateUntil})=> dateUntil && dateUntil !== '1900-01-01' ? dateUntil : null)}
            ),
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper('association.site.enterpriseId'),
                new f.FilterDescription(new f.TextFilter('enterpriseId')),
                {label: 'Enterprise Id'}
            ),
            new TableFieldDescription(
                'name',
                new DataMapper('association.site.name'),
                new f.FilterDescription(new f.TextFilter('name')),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'address',
                new DataMapper('association.site.address'),
                new f.FilterDescription(new f.TextFilter('address')),
                {label: 'Address'}
            ),
            new TableFieldDescription(
                'postalCode',
                new DataMapper('association.site.postalCode'),
                new f.FilterDescription(new f.TextFilter('postalCode')),
                {label: 'Postal Code'}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper('association.site.state'),
                new f.FilterDescription(new f.TextFilter('state')),
                {label: 'State'}
            ),
            new TableFieldDescription(
                'country',
                new DataMapper('association.site.country.name'),
                new f.FilterDescription(new f.TextFilter('country')),
                {label: 'Country'}
            ),
            new TableFieldDescription(
                'type',
                new DataMapper('association.site.type'),
                new f.FilterDescription(new f.TextFilter('type')),
                {label: 'Type'}
            ),
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventorySitesAssociationTableCreator)

export default InventorySitesAssociationTableCreator;