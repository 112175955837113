import React from 'react'

import { Route, Redirect, useLocation } from 'react-router-dom'

import { useAuth } from '../../contexts/Auth'

// Wrapper for <Route /> that redirects to the login screen if you're not yet authenticated
const PrivateRoute = ({component: Component, ...rest}) => {
    const { user } = useAuth()
    const location = useLocation()

    return (
        <Route
            {...rest}
            render={props => 
                user.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{pathname: '/', state: {from: location} }} />
                )
            }
        />
    )
}

export default PrivateRoute