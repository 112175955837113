/* eslint-disable eqeqeq */
import React, { useState } from 'react'

import { gql, useApolloClient } from '@apollo/client'
import {Typography, MenuItem, Input, Select, TextField, InputLabel, Button, FormControl} from '@material-ui/core'

const MUTATION = gql`
    mutation createConceptMutation($name: String!, $parent: String) {
        createChargeGroup(parent: $parent, name: $name) {
            chargeGroup {
                id
            }
        }
    }
`

export const ChargeGroupCreationQuery = gql`
    query {
        availableChargeGroups {
            id
            name
        }
    }
`

export const ConceptsAssignation = gql `
    mutation($objects: [String!], $invoice: String!) {
        assignConcepts(objects: $objects, invoice: $invoice) {
            response
        }
    }
`

export function ChargeGroupCreationForm(props) {
    const { client } = useApolloClient()
    const [name, setName] = useState("");
    const [parent, setParent] = useState(null);
    const availableChargeGroups = props.data.availableChargeGroups;
    const nameError = false;

    const handleCreate = () => {
        client.mutate({
            variables : {name:name, parent:parent},
            mutation: MUTATION
        }).then(r => {
            props.refetch();
        }).then(r => {
            props.close()
        }).catch(err => console.log(err))
    }
    
    return (
        <div style={{padding:20}}>
            <Typography variant="h6">Charge Group Creation</Typography>
                <TextField  label={"Name"}
                            value={name}
                            onChange = {event =>  setName(event.target.value)}
                            style={{width:"100%", fontSize:15, textAlign:"left"}}
                            error={nameError}
                />
                <FormControl style={{width:"100%"}}>
                    <InputLabel htmlFor={"parent"}>Parent</InputLabel>
                    <Select value={parent ||  ''}
                            onChange={ event => setParent(event.target.value)}
                            style={{width:"100%", fontSize:15, textAlign:"left"}}
                            input={<Input id="parent" />}
                    >
                        <MenuItem key={''} value={null} >
                                -
                        </MenuItem>
                        {availableChargeGroups &&
                          availableChargeGroups.map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <Button size="small"
                        color="primary"
                        style={{width:"100%", marginTop:5}}
                        onClick={handleCreate}
                        disabled={name == ""}
                        variant='contained'>
                    Create
                </Button>
        </div>
    )
}