/* eslint-disable eqeqeq */
/* eslint-disable import/first */
import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const AveragesCard = (props) => {
    const { t } = props

    const [last3] = useState(props.data.threeMonths)
    const [last6] = useState(props.data.sixMonths)
    const [current] = useState(props.data.current)

    const currentTransformer = (n) => {
        n = Number(n)
        if (n <= 999) {
            return n
        } else if (n > 999 && n < 99999) {
            return (String((n / 1000).toFixed(1)) + "K")
        } else if (n >= 100000 && n <= 999999) {
            return (String((n / 1000).toFixed(0)) + "K")
        } else {
            return (String((n / 1000000).toFixed(0)) + "M")
        }
    }

    const variationCalculator = () => {
        if (Number(last3) - Number(current) == 0) {
            return (
                <span style={{ color: "#e1b955", fontSize: 20, marginTop: "3%" }}><ArrowDownwardIcon style={{ color: "transparent" }} fontSize="small" />{"= 0%"}</span>
            )
        } else if (Number(last3) - Number(current) > 0) {
            let subtraction = Number(last3) - Number(current)
            let value = !(last3 == 0 && subtraction != 0) ? ((subtraction * 100) / last3).toFixed(0) + "%" : ""
            return (
                <span style={{ color: "green", fontSize: 20, marginTop: "3%" }}><ArrowDownwardIcon fontSize="small" />{value}</span>
            )
        } else if (Number(last3) - Number(current) < 0) {
            let subtraction = Number(last3) - Number(current)
            let value = !(last3 == 0 && subtraction != 0) ? (-1 * (subtraction * 100) / last3).toFixed(0) + "%" : ""
            return (
                <span style={{ color: "red", fontSize: 20, marginTop: "3%" }}><ArrowUpwardIcon fontSize="small" />{value}</span>
            )
        }
    }

    return (
        <div className="average-card-container">
            {/* Total expense, Count of service numbers, Coust per service number */}
            <div>
                <Typography variant='h6' className="avarage-card-title">
                    {props.title}
                </Typography>
            </div>


            {/* R$0 0 R$0 */}
            <div>
                <Typography className="average-card-value" variant='h4'>
                    {(props.currencySymbol ? props.currencySymbol : "") + currentTransformer(current)}
                </Typography>

                {/* = 0% */}
                <Typography><span className="text-center calibri-light">{variationCalculator()}</span></Typography>
            </div>

            {/* Rolling Averages */}
            <div>
                <h4 className="rolling-averages-title">
                    {t("Rolling Averages")}:
                </h4>

                {/* 3 months line */}
                <div className="rolling-averages" >
                    <div className="w-30">
                        3 {t("months")}
                    </div>
                    <div className="w-30">
                        {(props.currencySymbol ? props.currencySymbol : "") + currentTransformer(last3)}
                    </div>
                </div>

                {/* 6 months line */}
                <div className="rolling-averages">
                    <div className="w-30">
                        6 {t("months")}
                    </div>
                    <div className="w-30">
                        {(props.currencySymbol ? props.currencySymbol : "") + currentTransformer(last6)}
                    </div>
                </div>
            </div>

        </div>
    )

}

export default withTranslation("averageCards")(AveragesCard)