import React, { useEffect, useRef } from 'react'

import { useLazyQuery, useQuery } from '@apollo/client'

import BPMLottie from './BPMLottie'
import classes from './Loading.module.css'
import Error from '../CustomSnackBars/Error'


export const ShowLoading = (props) => {
    return (
        <div className={props.fullScreen ? classes.Background : null}>
            <BPMLottie/>
        </div>
    )
}

export const LazyLoading = (props) => {
    const [launchQuery, { called, loading, error, data, refetch, fetchMore }] = useLazyQuery(props.query, {fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first'})

    useEffect(() => {
        if (!called) {
            launchQuery({variables: props.variables})
        }
    })

    if ((loading && props.showLoading !== false) || props.loadingParameters) {
        return (<BPMLottie/>)
    } else if (loading && props.showLoading === false) {
        return ''
    }

    if (error) {
        console.log(error)
        return <Error show message={error.message} />
    }

    return (
        props.children(data, refetch, fetchMore)
    )
}

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export const Loading = (props) => {
    const { loading, error, data, refetch, fetchMore } = useQuery(props.query, {variables: props.variables, fetchPolicy: 'network-only'})

    const previousData = usePrevious(data);

    if (loading) {
        if (props.showPreviousDataWhileLoading === true && previousData) {
            return (
                props.children(previousData, refetch, fetchMore)
            )
        } else if ((props.showLoading !== false) || props.loadingParameters) {
            return (<BPMLottie/>)
        } else if (props.showLoading === false) {
            return ''
        }
    } else {
        if (error) {
            console.log(error)
            return <Error show message={error.message} />
        } else {
            return (
                props.children(data, refetch, fetchMore)
            )
        }
    }
}

export default Loading