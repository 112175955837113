import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { DataMapper, TableFieldDescription } from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
  query AllUsers(
    $orderField:String,
    $first:Int,
    $last:Int,
    $after:String,
    $before:String,
    $isActive:Boolean,
    $enterpriseId:String,
    $firstName:String,
    $dateHired:String,
    $dateTerminated:String,
    $language:String,
    $lastName:String,
    $role:String,
    $managerEnterpriseId:String,
    $managerFirstName:String,
    $managerLastName:String
    ) {
		allUsers(
			orderField:$orderField,
			first:$first,
			last:$last,
			after:$after,
			before:$before,
			dateHired:$dateHired,
			dateTerminated:$dateTerminated,
			language:$language,
			enterpriseId:$enterpriseId,
			isActive:$isActive,
			firstName:$firstName,
			lastName:$lastName,
			role:$role,
			managerEnterpriseId:$managerEnterpriseId,
			managerFirstName:$managerFirstName,
			managerLastName:$managerLastName,            
		) {
			edges {
				node {
					id
					enterpriseId
					firstName
					lastName
				}
			}
			count
			pageInfo {
				hasNextPage
				startCursor
				endCursor
			}
		}
  }
`

const unitQuery = `
	user(id: $id) {
		id
		enterpriseId
		firstName
		lastName
	}
`

const queries = {tableQuery, unitQuery};

class InventoryUserMinimalTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryUserMinimalTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
		tableCreator.setAdditionalFilters(this.filters)
		tableCreator.addDescriptions([
			new TableFieldDescription(
				'enterpriseId',
				new DataMapper("enterpriseId"),
				new f.FilterDescription(new f.TextFilter("enterpriseId")),
				{label: 'Enterprise Id'}
			),
			new TableFieldDescription(
				'firstName',
				new DataMapper("firstName"),
				new f.FilterDescription(new f.TextFilter("firstName")),
				{label: 'First Name'}
			),
			new TableFieldDescription(
				'lastName',
				new DataMapper("lastName"),
				new f.FilterDescription(new f.TextFilter("lastName")),
				{label: 'Last Name'}
			)
		])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryUserMinimalTableCreator)

export default InventoryUserMinimalTableCreator;