import React, { useState, useEffect } from 'react'

import {
    useTableCount,
    useTableFilters,
    useTableSelectAllState,
    useTableSelectedRows,
    useTableColumns,
} from '../../../../../redux/selectors';
import { useTranslation } from 'react-i18next'
import { IconButton, TableHead, TableRow, Tooltip, Checkbox } from '@material-ui/core'
import { Tune as TuneIcon, Block as BlockIcon, Sync as SyncIcon } from '@material-ui/icons'

import classes from './TableHeader.module.css'
import ColumnDisplayDialog from '../../Dialogs/ColumnDisplayDialog'
import {
    CustomEmptyTableCell,
    CustomTableCell,
    CustomTableHeaderCell,
    CustomTableCellInput,
    CustomTableRow,
    CustomTableSortLabel
} from '../CustomTableComponents/CustomTableComponents'
import { TableHeaderTooltip } from '../../../../Tooltips/BPMTooltips'
import { run_filter_delay } from '../../../Properties'

const TableHeader = (props) => {
    const {
        TableObject,
        filtersDisplay,
        handleClearFilters,
        checkboxDisplay,
        preColumnDisplay,
        handleSelectAll,
        onRequestSort,
        refreshTable,
        refreshButtonDisplay,
    } = props;

    const { t } = useTranslation()

    const [columnHandlerDisplay, setColumnHandlerDisplay] = useState(false);
    const [timer, setTimer] = useState(null)

    const tableRowsLength = useTableCount(TableObject.tableName)
    const selectedRows = useTableSelectedRows(TableObject.tableName)
    const allRowsSelected = useTableSelectAllState(TableObject.tableName)

    const storedTableSettings = useTableFilters(TableObject.tableName)
    const [localFilters, setLocalFilters] = useState(storedTableSettings.filters)
    const orderDirection = storedTableSettings.orderDirection;
    const orderField = storedTableSettings.orderField;
    const currentTableColumns = useTableColumns(TableObject.tableName)

    useEffect(() => {
        return () => {
            if (timer){
                clearTimeout(timer)
            }
        }
    }, [])

    useEffect(() => {
        checkHidenFilters();
    }, [currentTableColumns])

    const commitFilters = (filters) => {
        let newTableConfig = {...storedTableSettings}
        newTableConfig.filters = filters

        TableObject.setTableFiltersState(newTableConfig)
    }

    const toggleColumnHandler = () => {
        setColumnHandlerDisplay(!columnHandlerDisplay)
    }

    const handleSelectAllAction = () => {
        if (getCheckBoxIndeterminateState()) {
            handleSelectAll(false);
        } else {
            handleSelectAll(!allRowsSelected);
        }
    }

    const onChangeFilter = (event, showNull) => {
        if (timer){
            clearTimeout(timer)
        }

        let new_filters = {...localFilters}
        const value = event.target.value
        new_filters[event.target.name] = value
        if (typeof(value) !== 'number' && typeof(value) !== 'boolean' &&  typeof(value) !== 'object' && value.replace(/^\s+/,"").length == 0) {
            delete new_filters[event.target.name]
        }

        setTimer(setTimeout(() => commitFilters(new_filters), run_filter_delay))
        setLocalFilters(new_filters);
    }

    const handleClearLocalFilters = () => {
        setLocalFilters({})
        handleClearFilters()
    }

    const createSortHandler = (event, fieldId, column) => {
        if (!column.hideSort) {
            onRequestSort(event, fieldId)
        } else {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    const allowColumnDrawingInHeader =(column) => {
        if (!column.section) {
            return true;
        }

        return TableObject.fieldDescriptions.filter(col => (col.section && col.section.id === column.section.id))[0] === column;
    }

    const isSectionRowVisible = () => {
        return TableObject.fieldDescriptions.filter(col => (col.section && col.section.label)).length > 0;
    }

    const getSectionlength = (column) => {
        if (!column.section) {
            return 0;
        }

        return TableObject.fieldDescriptions.filter(col => (col.section && col.section.id === column.section.id)).length;
    }

    const getCheckBoxIndeterminateState = () => {
        return selectedRows.length !== 0 && selectedRows.length !== tableRowsLength
    }

    const checkHidenFilters = () => {
        let new_filters = {...localFilters};
        const notVisibleColumns = currentTableColumns.filter(f => (f.visibility));

        let changeFilters = false;
        notVisibleColumns.forEach(column => {
            if (new_filters.hasOwnProperty(column.id)) {
                delete new_filters[column.id];
                changeFilters=true;
            }
        });

        if (changeFilters) {
            commitFilters(new_filters)
            setLocalFilters(new_filters);
        }
    }

    return (
        <>
            <ColumnDisplayDialog
                open={columnHandlerDisplay}
                TableObject={TableObject}
                onClose={toggleColumnHandler}
            />
            <TableHead className={classes.StickyHeader} id="stickyTableHeader">
                {isSectionRowVisible() ?
                <CustomTableRow key='rowA'>
                    {/* 'Column Setup' Button */}
                    {preColumnDisplay && (TableObject.getfiltersDisplay(filtersDisplay) || checkboxDisplay) ?
                    <CustomTableCell  key='DUMMY_CELL' style={{width: 50}} />
                    :null
                    }
                    {/* Column Headers */}
                    {TableObject.fieldDescriptions.filter(column => (column.getVisibility(currentTableColumns))).map(column => (
                        allowColumnDrawingInHeader(column) ?
                        <CustomTableHeaderCell colSpan={ getSectionlength(column) }
                            key={column.id}
                            style={{fontSize: 14, textAlign: 'center', padding: 0}}
                        >
                            {column.section?.label ?
                                <span style={{fontSize: 16}}>{column.section.translateLabel ? TableObject.tableTranslation.translate(column.section.label, t) : column.section.label}</span>
                            :
                            null}
                        </CustomTableHeaderCell>
                        : null
                    ))}
                    {/* 'Edit' Column Header */}
                    <CustomTableCell  key='EDIT_COLUMN' style={{fontSize: 16, paddingRight: 30, width: 30, textAlign: 'center'}} />
                </CustomTableRow>
                :null
                }

                {/* First Header Row */}
                <CustomTableRow key='rowB'>
                    {/* 'Column Setup' Button */}
                    {preColumnDisplay && (TableObject.getfiltersDisplay(filtersDisplay) || checkboxDisplay) ?
                        <CustomEmptyTableCell key='setupSection'>
                            <div style={{display: 'flex'}}>
                            {TableObject.getfiltersDisplay(filtersDisplay) ?
                                <Tooltip  key='ColumnSetup' title={t('Column Setup')}>
                                    <IconButton onClick={toggleColumnHandler} style={{padding: '0px 0px 0px 0px', margin: 0, color: 'white'}}>
                                        <TuneIcon style={{fontSize: 20}} />
                                    </IconButton>
                                </Tooltip>
                            : null}
                            {checkboxDisplay ?
                                <Tooltip  key='SelectAll' title={t('Select all rows')}>
                                    <Checkbox
                                        checked={allRowsSelected}
                                        indeterminate={getCheckBoxIndeterminateState()}
                                        style={{padding: '0px 0px 0px 5px', margin: 0, fontSize: 20, color: 'white'}}
                                        onClick={event => {
                                            event.stopPropagation()
                                            handleSelectAllAction()}
                                        }
                                    />
                                </Tooltip>
                            : null}
                            </div>
                        </CustomEmptyTableCell>
                        :
                        null
                    }
                    {/* Column Headers */}
                    {TableObject.fieldDescriptions.filter(column => (column.getVisibility(currentTableColumns))).map(column => (
                        <CustomTableCell
                            key={column.id}
                            sortDirection={orderField === column.orderIdentifier ? orderDirection : false}
                            style={{fontSize: 12, textAlign: 'center', padding: 0}}
                        >
                            <TableHeaderTooltip title={!column.hideSort ? t('Sort') : column.getLabel(TableObject.tableTranslation, t)} placement='bottom-start' enterDelay={300}>
                                <CustomTableSortLabel
                                    active={orderField === column.orderIdentifier}
                                    hideSortIcon={column.hideSort}
                                    direction={orderDirection}
                                    onClick={(event) => createSortHandler(event, column.id, column)}
                                >
                                    {column.getLabel(TableObject.tableTranslation, t)}
                                </CustomTableSortLabel>
                            </TableHeaderTooltip>
                        </CustomTableCell>
                    ))}
                    {/* 'Edit' Column Header with refresh table button*/}
                    <CustomTableCell  key='EDIT_COLUMN' style={{width: 30, textAlign: 'center'}}>
                        {refreshButtonDisplay ?
                        <Tooltip title={t('Refresh table')}>
                            <IconButton onClick={() => refreshTable(false)} style={{ padding: 0, margin: 0, color: 'white'}}>
                                <SyncIcon style={{fontSize: 20}} />
                            </IconButton>
                        </Tooltip>
                        : null}
                    </CustomTableCell>
                </CustomTableRow>
                {/* Second Header Row */}
                {TableObject.getfiltersDisplay(filtersDisplay) &&
                    <TableRow>
                        {/* Checkboxes */}
                        <CustomTableCellInput padding='checkbox' style={{width: 30, textAlign: 'center'}} />
                        {/* Filters */}
                        {TableObject.getFilters({
                            onChange : ((event, showNull) => onChangeFilter(event, showNull)),
                            filters : localFilters || {},
                        })}
                        {/* 'Edit' Column */}
                        <CustomTableCellInput key='FILTER_BUTTON' style={{width: 30, textAlign: 'center'}}>
                            <Tooltip title={t('Clear Filters')}>
                                <IconButton onClick={handleClearLocalFilters} style={{padding: 0, margin: 0, color: 'white'}}>
                                    <BlockIcon style={{fontSize: 18}} />
                                </IconButton>
                            </Tooltip>
                        </CustomTableCellInput>
                    </TableRow>
                }
            </TableHead>
        </>
    )
}

export default TableHeader
