import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { FormControl, TextField } from '@material-ui/core'

class PasswordInput extends Component {
  render() {
    const {required, classes, label, id, formState, onChangeHandler, enterPressed, inputId, t, defaultValue} = this.props;
    const error =  Boolean(formState.errors[id]);
    const inputLabel = required ? required(t(label)) : t(label);
    return (
        <FormControl className={classes.formControl}  key={"formControl"+id}>

                <TextField
                    label={inputLabel}
                    value={formState.mappedData[inputId] || (defaultValue ? defaultValue : '')}
                    onChange = {event =>  onChangeHandler(inputId, event.target.value)}
                    style={{width:250}}
                    error={error}
                    type="password"
                    className={classes.input}
                    inputProps={{
                    'aria-label': label,
                    }}
                    key={"input"+id}
                    onKeyPress={enterPressed}
                />
    </FormControl>

    )
  }
}
export default withTranslation('fieldLabels')(PasswordInput);