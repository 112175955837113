/* eslint-disable eqeqeq */
import React, { useState } from 'react'

import { Button, Accordion, AccordionDetails, AccordionSummary, Typography, Select, MenuItem } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone'

import { withTranslation } from 'react-i18next'
import { gql } from "@apollo/client"

import MonthPicker from '../../components/MonthPicker/MonthPicker'
import CurrencyPicker from '../../components/CurrencyPicker/CurrencyPicker'
import { ButtonTooltip } from '../../components/Tooltips/BPMTooltips'

import { longMonthNames } from '../../components/DateTools/MonthsNames'
import { useCurrentUser, useLastPublishedPeriod } from '../../redux/selectors'

import VendorsDropDown from './VendorsDropDown'
import Loading from '../../components/Loading/Loading'
import RankingSpendsTable from './RankingSpendsTable'
import RankingUsagesTable from './RankingUsagesTable'

const periodsAndRankings = gql`
query($period: Period) {
    activeCompanyVendors(period: $period) {
        id
        name
        country {
            id
            name
        }
    }
    rankingSpendReports {
        id
        name
        allConcepts
        concepts {
            id
            name
        }
    }
    rankingUsageReports {
        id
        name
        allConcepts
        concepts {
            id
            name
        }
        unit {
            name
        }
    }
}
`

function RankingFilters(props) {
    const currentUser = useCurrentUser()
    const LastPublishedPeriod = useLastPublishedPeriod()

    const [date, setDate] = useState({ year: LastPublishedPeriod.year, month: LastPublishedPeriod.month })
    const [currency, setCurrency] = useState({ symbol: currentUser.currency.symbol, code: currentUser.currency.id })
    const [rankingUsageReports] = useState(props.rankingUsageReports ? props.rankingUsageReports : [])
    const [rankingSpendReports] = useState(props.rankingSpendReports ? props.rankingSpendReports : [])
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [currentTabIsSpendTab, setCurrentTabIsSpendTab] = useState(true);
    const [currentRanking, setcurrentRanking] = useState(props.rankingSpendReports ? props.rankingSpendReports[0] : null);
    const [selectedVendors, setSelectedVendors] = useState(props.vendors ? props.vendors.map(vendor => vendor.name) : []);
    const [vendors] = useState(props.vendors);

    const ConceptsIncluded = props.t("Concepts included in this ranking") + ":";
    const NoConceptsInRanking = props.t("This Ranking has no associated concepts");
    const AllConceptsInRanking = props.t("This Ranking includes all concepts");

    const handleTabChange = (isSpendButton) => {
        if (isSpendButton) {
            setcurrentRanking(rankingSpendReports[0]);
        } else {
            setcurrentRanking(rankingUsageReports[0]);
        }
        setCurrentTabIsSpendTab(isSpendButton);
    }

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        if (typeof (event.target.className) === 'string' && event.target.className?.toLowerCase().includes('button')) {
            return;
        }

        setAccordionExpanded(isExpanded ? panel : false);
    };

    const changeCurrency = (currencyCode, currencySymbol) => {
        setCurrency({ symbol: currencySymbol, code: currencyCode })
    }

    function onChangeDate(year, month) {
        setDate({ year: year, month: month })
    }

    function generateFilters() {
        return {
            period: `${date.year}-${date.month}`,
            ...(currentTabIsSpendTab) && { currency: currency.code },
            vendors: vendors.filter(vendor => selectedVendors.indexOf(vendor.name) >= 0).map(vendor => (vendor.id)),
            ranking: currentRanking?.id ? currentRanking.id : null,
            first: 20
        }
    }

    function onChangeRanking(event) {
        if (currentTabIsSpendTab === true) {
            setcurrentRanking(rankingSpendReports.filter(ranking => ranking.id === event.target.value)[0]);
        } else {
            setcurrentRanking(rankingUsageReports.filter(ranking => ranking.id === event.target.value)[0]);
        }
    };

    function getCurrentRankingsList() {
        if (currentTabIsSpendTab === true) {
            return rankingSpendReports
        }

        return rankingUsageReports
    }

    function getCurrentRankingToolTip() {
        if (currentRanking.allConcepts === true) {
            return AllConceptsInRanking
        }

        if (currentRanking.concepts.length < 1) {
            return NoConceptsInRanking
        }

        return <>
            <p style={{ fontWeight: "bold" }}>{ConceptsIncluded}</p>
            <span style={{ whiteSpace: 'pre-line' }}>
                {currentRanking.concepts.map(concept => ('   - ' + concept.name + '.')).join('\n')}
            </span>
        </>
    }

    const periodTransformer = longMonthNames(props.t)

    const rankingsButtons = [
        {
            label: props.t("Spend"),
            color: !currentTabIsSpendTab ? "gray" : "#55758C",
            isSpendButton: true,
            variant: currentTabIsSpendTab ? "outlined" : "contained"
        },
        {
            label: props.t("Usage"),
            color: currentTabIsSpendTab ? "gray" : "#55758C",
            isSpendButton: false,
            variant: !currentTabIsSpendTab ? "outlined" : "contained"
        }
    ]

    return (
        <div>
            <div style={{ paddingBottom: 10 }}>
                <Accordion expanded={accordionExpanded === 'filtersSection'} onChange={handleAccordionChange('filtersSection')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="filtersSection-content"
                        id="filtersSection-header"
                    >
                        <div key='texts_sect' style={{ display: 'flex', paddingBottom: 0, width: "100%" }}>
                            <Typography style={{ width: '20%' }}>{props.t('reportFilter:Filters & Options')}</Typography>
                            {accordionExpanded ?
                                null
                                :
                                <div style={{ width: '80%', display: 'flex' }}>
                                    <Typography style={{ marginLeft: '150px', color: "gray" }}>{props.t('fieldLabels:Ranking By') + ': ' + (currentRanking?.name ? currentRanking.name : '')}</Typography>
                                    <Typography style={{ marginLeft: '50px', color: "gray" }}>{props.t('fieldLabels:Period') + ': ' + periodTransformer[date.month - 1] + ' ' + date.year}</Typography>
                                    <Typography style={{ marginLeft: '50px', color: "gray" }}>{props.t('fieldLabels:Currency') + ': ' + currency.code}</Typography>
                                </div>
                            }
                        </div>
                        <div key='btns_sect' style={{ marginLeft: 'auto', marginRight: 0, display: 'flex' }}>
                            {rankingsButtons.map((button, index) =>
                                <Button
                                    key={'rbtn_' + index}
                                    style={{ backgroundColor: button.color, color: "white", marginLeft: "5px" }}
                                    variant={button.variant}
                                    onClick={() => handleTabChange(button.isSpendButton)}
                                >
                                    {button.label}
                                </Button>)
                            }
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-100">
                            <div key="ranking_vendor" style={{ display: 'flex', paddingBottom: 20, width: "100%" }}>
                                <div key="ranking" style={{ display: 'flex', width: "40%" }}>
                                    <Typography align='right' style={{ color: "gray", marginTop: '7px', width: '200px', }}>
                                        {props.t('fieldLabels:Ranking By') + ': '}
                                    </Typography>
                                    <Select style={{ marginLeft: '20px' }}
                                        id="ranking-select"
                                        value={currentRanking?.id ? currentRanking.id : null}
                                        onChange={onChangeRanking}
                                        selected=''
                                    >
                                        {getCurrentRankingsList().map(report =>
                                            <MenuItem key={'ranking_id_' + report.id} value={report.id} >{report.name}</MenuItem>
                                        )}
                                    </Select>
                                    <ButtonTooltip
                                        title={getCurrentRankingToolTip()}
                                        placement="right-start">
                                        <InfoTwoToneIcon style={{ marginLeft: '20px', color: '#085F9B' }} />
                                    </ButtonTooltip>
                                </div>
                                <div key="vendor" style={{ display: 'flex', width: "60%" }}>
                                    <Typography style={{ color: "gray", marginTop: '7px' }}>
                                        {props.t('fieldLabels:Vendors') + ': '}
                                    </Typography>
                                    <VendorsDropDown
                                        selectedVendors={selectedVendors}
                                        handleChange={setSelectedVendors}
                                        vendors={vendors}
                                        t={props.t}
                                    />
                                </div>
                            </div>

                            <div key="period_ccy" style={{ display: 'flex', width: "100%" }}>
                                <div key="period" style={{ display: 'flex', width: "40%" }}>
                                    <Typography align='right' style={{ color: "gray", marginTop: '7px', width: '200px', }}>
                                        {props.t('reportFilter:Reference Period') + ': '}
                                    </Typography>
                                    <MonthPicker
                                        month={date.month}
                                        year={date.year}
                                        onChangeDate={onChangeDate}
                                        checkDisabledMonths={true}
                                        t={props.t}
                                    />
                                </div>
                                {currentTabIsSpendTab ?
                                    <div key="ccy" style={{ display: 'flex', width: "60%" }}>
                                        <span style={{ float: "right", fontSize: 18, color: "gray", width: '100%' }}>
                                            <CurrencyPicker
                                                selectedSymbol={currency.symbol}
                                                selected={currency.code}
                                                onChangeCurrency={changeCurrency}
                                                month={date.month}
                                                year={date.year} />
                                        </span>
                                    </div>
                                    :
                                    null}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            {currentTabIsSpendTab ?
                <RankingSpendsTable
                    t={props.t}
                    symbol={currency.symbol}
                    currencyCode={currency.code}
                    filters={generateFilters()}
                />
                :
                <RankingUsagesTable
                    t={props.t}
                    unit={currentRanking?.unit?.name ? currentRanking.unit.name : ''}
                    filters={generateFilters()}
                />
            }
        </div>
    )
}

const RankingSetup = (props) => {
    const { t } = props
    const LastPublishedPeriod = useLastPublishedPeriod()

    document.title = `${t('Ranking')} - BubbleBPM`

    const [period] = useState(LastPublishedPeriod.year + "-" + LastPublishedPeriod.month)

    return (
        <div id="app">
            <Loading query={periodsAndRankings} variables={{ period: period }}>
                {(data) => {
                    let vendorByCountry = {}
                    const vendors = []
                    for (let vendor of data.activeCompanyVendors) {
                        if (!(vendor.country.name in vendorByCountry)) {
                            vendorByCountry[vendor.country.name] = [];
                        }
                        vendorByCountry[vendor.country.name].push({
                            id: vendor.id,
                            name: vendor.name,
                        })
                        vendors.push(vendor)
                    }

                    var rankingSpendReports = []
                    if (data.rankingSpendReports) {
                        data.rankingSpendReports.map(report => {
                            rankingSpendReports.push(report);
                        })
                    }

                    var rankingUsageReports = []
                    if (data.rankingUsageReports) {
                        data.rankingUsageReports.map(report => {
                            rankingUsageReports.push(report);
                        })
                    }

                    return (
                        <RankingFilters
                            t={t}
                            vendors={vendors}
                            rankingSpendReports={rankingSpendReports}
                            rankingUsageReports={rankingUsageReports}
                        />
                    )
                }}
            </Loading>
        </div>
    )
}

export default withTranslation("ranking")(RankingSetup)