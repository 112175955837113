import InventorySitesMinimalTableCreator from "../../../tableDefinitions/Inventory/Sites/InventorySitesMinimalTableCreator"
import tableCreatorCreatorFactoryCreator from "../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator"

export default class SiteMinimalTable {
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.enterpriseId, label:"ID"},
            {mapper:data=>data.name, label:"Name"},
            {mapper:data=>data.postalCode, label:"Postal Code"},
            {mapper:data=>data.address, label:"Address"}
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventorySitesMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Site')
    }
}