import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { FormControl } from '@material-ui/core'
//import TextField from '@material-ui/core/es/TextField/TextField'
import { TextField } from '@material-ui/core'

class TextInput extends Component {
  render() {
    const {readOnly, required, classes, label, id, formState, onChangeHandler, enterPressed, inputId, t, defaultValue} = this.props;
    const error =  Boolean(formState.errors[id]);
    const inputLabel = required ? required(t(label)) : t(label);

    return (
        <FormControl className={classes.formControl} key={"formControl"+id}>
          <TextField
            label={inputLabel}
            value={formState.mappedData[inputId] || (defaultValue ? defaultValue : '')}
            onChange = {event =>  onChangeHandler(inputId, event.target.value)}
            style={{width:250}}
            error={error}
            className={classes.input}
            inputProps={{'aria-label': label }}
            key={"input"+id}
            onKeyPress={enterPressed}
            InputProps={readOnly ? {readOnly: true, style:{color: 'gray'}} : null}
          />
        </FormControl>
    )
  }
}

export default withTranslation('fieldLabels')(TextInput);