import React from 'react'

import { BrowserRouter as Switch, Route } from 'react-router-dom'

import Login from '../pages/Login/Login'
import PasswordReset from '../pages/PasswordReset/PasswordReset'
import NewPassword from '../pages/NewPassword/NewPassword'
import SsoFailed from '../pages/Login/SsoFailed'
import SsoLogin from '../pages/Login/SsoLogin'

export default (
    <Switch>
        {/* Public Routes */}
        <Route exact path='/pr' component={PasswordReset} />
        <Route exact path='/pr/:userID/:token' component={NewPassword} />
        <Route exact path='/sso-login/:token' component={SsoLogin} />
        <Route exact path='/sso-failed' component={SsoFailed} />
        <Route exact path='/' component={Login} />
    </Switch>
);