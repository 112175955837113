import tableCreatorCreatorFactoryCreator from "../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator"
import InventoryTypeMinimalTableCreator from "../../../tableDefinitions/Inventory/Inventory/InventoryTypeMinimalTableCreator"

export default class InventoryTypeMinimalTable{
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.name, label:"Name"},
            {mapper:data=>data.technology?.name, label:"Technology"}
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventoryTypeMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Inventory Type')
    }
}