import React , {useState} from 'react'
import {Card, Checkbox, Divider, Typography} from "@material-ui/core"
import Loading from '../../components/Loading/Loading'
import { gql, useMutation} from '@apollo/client'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { AddBPMApplicationMessages } from '../../redux/reducers/BPMReducer'

const AVAILABLE_FILTERS = gql`
{
    availableVendors{
      edges{
        node{
          name
          id
        }
      }
    }
    availableInvoicePeriods{
      edges{
        node{
          id
          date
        }
      }
    }

}`

const RUN_REPORT = gql`
mutation($vendorIds: [String], $periodIds: [String]) {
  runConsolidatedSbo(vendorIds: $vendorIds, periodIds: $periodIds) {
      succeeded
    }
}`

function CheckBoxItem(props){
    return(
        <>
            <Checkbox checked={props.checked} onChange={props.onChange} color="primary" />
            {props.label}
        </>
    )
}

function ScheduleFilters(props) {
    const [vendorSelected, setVendorSelected] = useState([])
    const [periodSelected, setPeriodSelected] = useState([])
    const [runReport, {loading, error}] = useMutation(RUN_REPORT)
    const [isRunning, setIsRunning] = useState(false)

    const {t, data} = props
    const selectedType = {vendor:{function:setVendorSelected, state:vendorSelected}, period:{function:setPeriodSelected, state:periodSelected}}

    const dispatch = useDispatch()

    const handleSelect = (type, selectedId) => {
      if(selectedType[type]["state"].includes(selectedId)){

        let selectedArray = selectedType[type]["state"]
        const index = selectedArray.indexOf(selectedId);
        selectedArray.splice(index, 1)
        selectedType[type].function([...selectedArray])

      }else{
          selectedType[type].function([...(selectedType[type].state), selectedId])
      }
    }

    const handleSelectAllVendors = () => {
      let vendors = data.availableVendors.edges.map(vendorNode => vendorNode.node.id)
      setVendorSelected(vendors)
    }

    const handleSelectAllPeriods = () => {
      let periods = data.availableInvoicePeriods.edges.map(periodNode => periodNode.node.id)
      setPeriodSelected(periods)
    }

    const handleRunReport = () => {
      if(vendorSelected.length > 0 && periodSelected.length > 0){
        runReport({variables:{vendorIds:vendorSelected, periodIds:periodSelected}})
        createMessageSnack()
        setTimeout(()=>{
          setIsRunning(true)
        },4000)
      }
    }

    const createMessageSnack = () => {
      dispatch(AddBPMApplicationMessages({
        text: t("Consolidated SBO has been uploaded, Redirecting..."),
        variant: "success",
      }))
    }

   function arrayDivider(arr, chunkSize) {
      var R = [];
      for (var i = 0; i < arr.length; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize));
      return R;
    }
      
    const years = new Set()
    data.availableInvoicePeriods.edges.forEach(periodNode => {
      years.add(periodNode.node.date.split("-")[0])
    })

    const yearsRowData = []

    years.forEach(year=>{
      const yearData = [];
      data.availableInvoicePeriods.edges.forEach(periodNode=>{
        if(periodNode.node.date.includes(year)){
          yearData.push(periodNode)
        }
      })
      yearsRowData.push(yearData)
    })


    const periodVendorData =  arrayDivider(data.availableVendors.edges, 4) // 4 is the column quantity

    if(isRunning) return <Redirect to="/consolidatedSBO/results" />
    return(
        <div>
            <Card key='card_vendors' style={{minHeight:200}}>
                <center style={{margin:"2%"}}>
                    <CustomPrimaryButton  variant="contained" size="small" color="primary" onClick={handleSelectAllVendors} style={{float:"right"}} label={t("fieldLabels:Select All")}/>
                    <Typography variant="h5">{t("Vendors")}</Typography>
                    <Divider style={{margin:"2%"}} />
                </center>
  
                <center style={{margin:"2%"}}>
                  {periodVendorData.map((vendorArr, index) => <div key={index} style={{width:"100%"}}>
                  {vendorArr.map(vendorNode =><CheckBoxItem key={vendorNode.node.id} checked={vendorSelected.includes(vendorNode.node.id)} onChange={()=>handleSelect("vendor", vendorNode.node.id)} label={vendorNode.node.name} />) }
                  </div>)}
                </center>  

            </Card>
            <Card key='card_periods' style={{marginTop:10, minHeight:200}}>
                <center style={{margin:"2%"}}>
                    <CustomPrimaryButton  variant="contained" size="small" color="primary" onClick={handleSelectAllPeriods} style={{float:"right"}} label={t("fieldLabels:Select All")} />
                    <Typography variant="h5">{t("Periods")}</Typography>
                    <Divider style={{margin:"2%"}} />
                </center>

                <center style={{margin:"2%"}}>
                    {yearsRowData.map((periodArr, index) => <div key={index} style={{width:"100%"}}>
                      {periodArr.map(periodNode => <CheckBoxItem key={periodNode.node.id} checked={periodSelected.includes(periodNode.node.id)} onChange={()=>handleSelect("period", periodNode.node.id)} label={periodNode.node.date} />)}
                      </div>)}
                </center>  

            </Card>
            <CustomPrimaryButton onClick={handleRunReport} style={{float:"right", margin:"2%"}} variant="contained" color="primary" label={t("Run Report")}/>
        </div>
    )
}

export default function Schedule(props){
  props.onSelected(false)
  return(
    <Loading query={AVAILABLE_FILTERS}>
      {(data)=>
        <ScheduleFilters data={data} t={props.t} />
      }
    </Loading>
  )

}