import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import InventoryLegalEntityMinimalTableCreator from '../../../tableDefinitions/Inventory/LegalEntity/InventoryLegalEntityMinimalTableCreator'

export default class LegalEntityMinimalTable {
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.name, label:"Name"},
            {mapper:data=>data.taxId, label:"Tax ID"},
            {mapper:data=>data.description, label:"Description"}
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventoryLegalEntityMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Legal Entity')
    }
}