/* eslint-disable no-unused-vars */
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    GeneralChipComponentCreator, 
    ManagerExcelDisplayCreator, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription 
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllCostCenters(
        $orderField:String,
        $first:Int,$last:Int,
        $after:String,
        $before:String,
        $isActive:Boolean,
        $customAttr1:String,
        $enterpriseId:String,
        $domain:String,
        $customAttr2:String,
        $customAttr3:String,
        $name:String,
        $parent:String,
        $type:String,
        $businessUnit:String,
        $department:String,
        $managerEnterpriseId:String,
        $managerFirstName:String,
        $managerLastName:String,
    ) {
        allCostCenters(
            orderField:$orderField,
                        first:$first,
                        last:$last,
                        after:$after,
                        domain:$domain,
                        before:$before,
                        enterpriseId:$enterpriseId,
                        isActive:$isActive,
                        customAttr1:$customAttr1,
                        customAttr2:$customAttr2,
                        customAttr3:$customAttr3,
                        name:$name,
                        parent:$parent,
                        type:$type,
                        businessUnit:$businessUnit,
                        department:$department,
                        managerEnterpriseId:$managerEnterpriseId,
                        managerFirstName:$managerFirstName,
                        managerLastName:$managerLastName
        ) {
            edges {
                node {
                    id
                    isActive
                    name
                    enterpriseId
                    domain {
                        id
                        name
                    }
                    parent {
                        id
                        enterpriseId
                    }
                    type
                    businessUnit
                    department
                    customAttr1
                    customAttr2
                    customAttr3
                    managers {
                        edges {
                            node {
                                enterpriseId
                                firstName
                                lastName
                                email
                                id
                            }
                        }
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editCostCenterMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $name: String,
        $isActive: Boolean,
        $parent: String,
        $type: String,
        $businessUnit: String,
        $enterpriseId:String!,
        $department: String,
        $managers: [Int],
        $id: String!,
        $domain:String
    ) {
        editCostCenter(
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            name:$name,
            isActive:$isActive,
            parent:$parent,
            enterpriseId:$enterpriseId,
            type:$type,
            businessUnit:$businessUnit,
            department:$department,
            managers:$managers,
            id:$id,
            domain:$domain
        ) {
            costCenter {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createCostCenterMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $enterpriseId:String!,
        $name: String,
        $isActive: Boolean,
        $parent: String,
        $type: String,
        $businessUnit: String,
        $department: String,
        $managers: [Int],
        $domain:String
    ) {
        createCostCenter(
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            name:$name,
            isActive:$isActive,
            parent:$parent,
            type:$type,
            businessUnit:$businessUnit,
            enterpriseId:$enterpriseId,
            department:$department,
            managers:$managers,
            domain:$domain
        ) {
            costCenter {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeCostCenterMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeCostCenter(ids: $ids, allRecords: $allRecords, filters: $filters) {
            costCenter {
                id
            }
        }
    }
`

const unitQuery = `
    costCenter(id: $id) {
        id
        isActive
        enterpriseId
        domain {
            id
            name
        }    
        name
        parent {
            id
            displayName
            enterpriseId
        }
        type
        businessUnit
        department
        customAttr1
        customAttr2
        customAttr3
        managers {
            edges {
                node {
                    id
                    enterpriseId
                    firstName
                    lastName
                    email
                }
            }
        }
    }
`

const InventoryCostCenterTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class InventoryCostCenterTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryCostCenterTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InventoryCostCenterTableCreatorQueries)
        tableCreator.setInitialSortField('isActive', 'desc')
        tableCreator.addDescriptions ([
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'Code'}
            ),
            new TableFieldDescription(
                'parent',
                new DataMapper("parent.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("parent")),
                {label: 'Parent'}
            ),
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'managers',
                new DataMapper("managers"),
                new f.FilterDescription(new f.TextFilter("managerEnterpriseId")),
                {label: 'Managers',
                excelDisplay : new ManagerExcelDisplayCreator(),
                componentCreator: new GeneralChipComponentCreator(["enterpriseId","firstName","lastName"], ["enterpriseId","email","firstName","lastName"], null, false),
                transform: new DataTransformer(({managers})=>(managers.edges.map(data => data.node))),}
            ),
            new TableFieldDescription(
                'type',
                new DataMapper("type"),
                new f.FilterDescription(new f.TextFilter("type")),
                {label: 'Type'}
            ),
            new TableFieldDescription(
                'businessUnit',
                new DataMapper("businessUnit"),
                new f.FilterDescription(new f.TextFilter("businessUnit")),
                {label: 'Business Unit'}
            ),
            new TableFieldDescription(
                'department',
                new DataMapper("department"),
                new f.FilterDescription(new f.TextFilter("department")),
                {label: 'Department'}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain'}
            ),
            new TableFieldDescription(
                'isActive',
                new DataMapper("isActive"),
                new f.FilterDescription(new f.BooleanFilter("isActive")),
                {label: 'Status',  componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper("customAttr1"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label:'Custom Attribute 1' , onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper("customAttr2"),
                new f.FilterDescription(new f.TextFilter("customAttr2")),
                {label:'Custom Attribute 2' , onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper("customAttr3"),
                new f.FilterDescription(new f.TextFilter("customAttr3")),
                {label:'Custom Attribute 3' , onlyForm: true}
            )
        ])
        return tableCreator
    }
}
    
tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryCostCenterTableCreator)

export { 
    InventoryCostCenterTableCreator,
    InventoryCostCenterTableCreatorQueries
}