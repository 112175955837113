import * as f from '../../components/BPMTable/BPMInputs'
import { useParams } from 'react-router-dom'
import { 
    FormFieldDescription, 
    FormSection,
    DataTransformer,
} from '../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { BudgetPeriodTableCreatorQueries } from './BudgetPeriodTableCreator'

const glAccountsQuery = `
    allGlAccounts {
        edges {
            node {
                id
                name
            }
        }
    }
`

const periodsQuery = `
    allPeriods {
        edges {
            node {
                id
                startDate
                endDate
            }
        }
    }
`

class BudgetPeriodFormCreator extends FormCreatorCreator {
    constructor(params) {
        super(params)
    }

    static getId = () => {
        return "BudgetPeriodFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Budget Period', BudgetPeriodTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)

        formCreator.addInputs([
            new FormFieldDescription(
                'glAccount',
                new f.InputDescription(new f.DropDownCreator("glAccount"), true),
                generalSection,
                1,
                {
                    label: 'Gl Account', 
                    transform: new DataTransformer(data => data?.name? data.name : null), 
                    queryAllDropdownOptions: glAccountsQuery,
                    readOnly: true, 
                }
            ),
            new FormFieldDescription(
                'period',
                new f.InputDescription(new f.DropDownCreator("period"), false),
                generalSection,
                1,
                {label: 'Period', readOnly: true, transform: new DataTransformer(data=> data.startDate), queryAllDropdownOptions: periodsQuery}
            ),
            new FormFieldDescription(
                'currency',
                new f.InputDescription(new f.TextCreator("currency"), false),
                generalSection,
                1,
                {label: 'Currency', readOnly: true, dataMapper: (data => data?.budget?.currency?.code ? data.budget.currency.code : null)}
            ),
            new FormFieldDescription(
                'value',
                new f.InputDescription(new f.TextCreator('value'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Value', dataMapper: (data => data?.value ? Number(data.value).toFixed(2) : 0)}
            ),
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(BudgetPeriodFormCreator)

export default BudgetPeriodFormCreator