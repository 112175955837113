import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import DeviceModelMinimalTableCreatorCreator from '../../../tableDefinitions/Inventory/Devices/DeviceModelMinimalTableCreator'

export default class DeviceModelMinimalTable {
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper : data=> data.name, label : "Model Name"},
            {mapper : data=> data.brand?.name, label : "Brand Name"},
            {mapper : data=> data.serviceType?.name, label : "Service Type"}
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(DeviceModelMinimalTableCreatorCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Device Models')
    }
}
