/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { withTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogTitle, Input, Button } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import SetupTableData from '../TableWrapper/SetupTableData'
import { TablePickerModelClikStrategy as clickStrategy, TablePickerDisplayStrategy as displayStrategy } from '../../TableMapperCreator'

const TablePopUpPicker = (props) => {
    const [tableCreator, setTableCreator] = useState(props.tableCreator);


    useEffect(() => {

        tableCreator.onClickStrategy = new clickStrategy(
            data => {
                props.onChange(props.inputId, data);
                handleCloseTable();
            }
        )

        tableCreator.displayStrategy = new displayStrategy();
    }) 

    const handleCloseTable = () => {
        props.handleClosePopUp(false);
    }

    const clearOption = (inputId) => {
        props.onChange(props.inputId, null);
    }

    const {
        label,
        id,
        t,
        required,
        formState,
        inputId,
        isOpen,
    } = props;

    const inputLabel = required ? required(t(label)) : t(label);

    const error =  Boolean(formState.errors[id]);

    return (

        <>
            <Dialog
                open={isOpen}
                maxWidth='xl'
                scroll="paper"
                onClose={handleCloseTable}
                id={'pickerDialog'+ label }>
                    <DialogTitle id="scroll-dialog-title" style={{fontSize:15}}>
                        <span style={{fontSize:25}}>  {label}  </span>
                        <Button style={{postion:"relative",float:"right"}} onClick={ () =>{handleCloseTable(); clearOption(inputId) }}  > Clear <DeleteIcon  /></Button> 
                    </DialogTitle>    
                    <DialogContent style={{height:450, padding: "0px 24px", overflowY: "overlay"}}>
                        <SetupTableData
                            key={label}
                            dialogId={'pickerDialog'+ label }
                            TableCreator={tableCreator}
                            stickyHeader
                        />
                    </DialogContent>
            </Dialog>
        </>
    )
}

export default withTranslation('fieldLabels')(TablePopUpPicker);