import React from 'react'

import Lottie from 'react-lottie'
import animationData from './lf30_editor_d6SNTD.json'


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

function BPMLottie({lottiePosition}) {
    return (
        <>
            <Lottie 
                options={defaultOptions} 
                height={lottiePosition ? 100 : 150} 
                width={lottiePosition ? 100 : 150} 
                isClickToPauseDisabled={true} 
                style={lottiePosition ? {top: `${lottiePosition}px`} : {
                    position: 'fixed',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '9999'
                }}
            />
        </>
    )
}

export default BPMLottie;