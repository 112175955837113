import React, { useState, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { FormControl, Input, InputLabel, InputAdornment, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import {DownloadFile} from "../../../../components/FileDownloader/FileComponentDownloader"

function UploadInput(props) {

    const [showDefaultFile, setDefaultFile] = useState(props.formState.mappedData[props.inputId] ? Boolean(props.formState.mappedData[props.inputId]["file"]) : false)
    const [hasFile, setHasFile] = useState(props.formState.mappedData[props.inputId] ? Boolean(props.formState.mappedData[props.inputId]["file"]) : false)
    const { classes, label, id, formState, onChangeHandler, inputId, t, downloadUrl} = props;
    const fileInput = useRef(null)

    const onChange = ({ target: { validity, files: [file] }}) => {
       if(validity.valid){        
        onChangeHandler(inputId, file );
        setHasFile(true)
        setDefaultFile(false)
       }
    }

    const onDelete = () => {
      onChangeHandler(inputId, null);
      if(hasFile && fileInput.current &&  fileInput.current.childNodes[0]){
        fileInput.current.childNodes[0].value = ""
      }
      setHasFile(false)
      setDefaultFile(false)
    }

    return (
        <FormControl className={classes.formControl}  key={"formControl"+id}>
            <InputLabel>{t(label)}</InputLabel>
                <Input
                    ref={fileInput}
                    variant="contained"
                    type="file"
                    onChange={onChange}
                    style={{width:250}}
                    endAdornment={
                      <InputAdornment>
                        {(formState.mappedData[inputId] && showDefaultFile) ?  <DownloadFile url={downloadUrl} id={formState.mappedData[inputId].downloadId}/> : null }

                        {hasFile ?
                          <IconButton onClick={onDelete}>
                            <DeleteIcon />
                          </IconButton>
                          : null}

                      </InputAdornment>
                    }
                />
    </FormControl>

    )
  
}

export default withTranslation('fieldLabels')(UploadInput);