import * as f from '../../components/BPMTable/BPMInputs'
import { 
    FormFieldDescription, 
    FormSection,
    DataTransformer,
} from '../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { BudgetTableCreatorQueries } from './BudgetTableCreator'

const currencyQuery = `
    allCurrency {
        edges {
            node {
                id
                code
            }
        }
    }
`

class BudgetFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "BudgetFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Budget', BudgetTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)

        formCreator.addInputs([
            new FormFieldDescription(
                'description',
                new f.InputDescription(new f.TextCreator('description'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Description'}
            ),
            new FormFieldDescription(
                'startPeriod',
                new f.InputDescription(new f.PeriodCreator('startPeriod', 1), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Start Period', dataMapper: (data => data?.startPeriod?.date ? data.startPeriod.date : null)}
            ),
            new FormFieldDescription(
                'endPeriod',
                new f.InputDescription(new f.PeriodCreator('endPeriod', 12), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'End Period', dataMapper: (data => data?.endPeriod?.date ? data.endPeriod.date : null)}
            ),
            new FormFieldDescription(
                'currency',
                new f.InputDescription(new f.DropDownCreator("currency"), true, new f.RequiredValidator(1)),
                generalSection,
                3,
                {label: 'Currency', transform: new DataTransformer(data=> data.code), queryAllDropdownOptions: currencyQuery}
            ),
            new FormFieldDescription(
                'amount',
                new f.InputDescription(new f.TextCreator('amount'), true, new f.RequiredValidator(1)),
                generalSection,
                4,
                {label: 'Amount', dataMapper: (data => data?.amount ? Number(data.amount).toFixed(2) : 0)}
            ),
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(BudgetFormCreator)

export default BudgetFormCreator