import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import ChargeGroupUssageFormCreator from '../../../tableDefinitions/Billing/CharGroupUssage/ChargeGroupUssageFormCreator'
import { ChargeGroupUssageTableCreator } from '../../../tableDefinitions/Billing/CharGroupUssage/ChargeGroupUssageTableCreator'

const Concept = (props) => {
    const { t } = useTranslation('billManagement')
    document.title = `${t('Charge Groups Ussage')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Charge Group Ussage'}
            tableCreatorCreatorId={ChargeGroupUssageTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={ChargeGroupUssageFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Concept