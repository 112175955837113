/* eslint-disable no-use-before-define */
import React, { useState, useRef, useEffect } from 'react'

import { withTranslation } from 'react-i18next'

import { Popper, ClickAwayListener, Button, OutlinedInput, InputAdornment } from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { withStyles } from '@material-ui/core/styles'

import PeriodsGrid from '../PeriodsGrid/PeriodsGrid'

const classes = {
    calendarIcon: {
      color: 'white',
      position:"absolute",
      justifyContent:"none",
      left:2,
    },
    deleteIcon : {
      color: 'white',
      cursor:"pointer"
    }
  }

export const CustomOutlinedInput= withStyles (theme => ({
    input: {
        padding: '9px 14px',
        height: '17px',
        color:'white',
    },
    root: {
        "& $notchedOutline": {
        },
        "&:hover $notchedOutline": {
            borderColor: '#205982'
        },
        "&$focused $notchedOutline": {
            borderColor: '#205982'
        }
    },
    focused: {},
    notchedOutline: {}
}))(OutlinedInput);

function MonthPickerFilter(props) {

    const [anchorEl, setAnchorEl] = useState(null)
    const [year, setYear] = useState(props.year)
    const [month, setMonth] = useState(props.month)
    const [initialYear,setInitialYear] = useState(props.year)
    const input = useRef(null)

    useEffect(() => {
        if (!props.filters[props.filterId]) {
            setYear(null)
            setMonth(null)
        }
    }, [props.filters])

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    };

    const close = () => {
        setInitialYear(initialYear)
        setAnchorEl(null)
    } 

    const addYear = () => {
        year ? setYear(year+1) : setYear(props.defaultDate+ 1)
    }

    const reduceYear = ()=> {
        year ? setYear(year - 1) : setYear(props.defaultDate-1)
    }

    const generateDate = () => {
        let date_string = null
        const year = initialYear|| Number(props.defaultDate)
        if(month && year){
            const month_string = (month < 10) ? "0"+month :month ;
            date_string = (year + "/" + month_string)
        }else{
            date_string = " "
        }
        return date_string
    }

    const handleCleanDate = () => {
        setYear(null)
        setMonth(null)
        props.onChangeDate({target:{name:props.name ,value:""}})
    }

    const onSetDate = (newYear, newMonth) =>{
        const month_string = (newMonth < 10) ? "0"+newMonth : newMonth ;   
        const selectedYear = newYear || Number(props.defaultDate)
        setMonth(newMonth)
        setAnchorEl(null)
        setYear(selectedYear)
        setInitialYear(selectedYear)
        props.onChangeDate({target:{name:props.name ,value:`${selectedYear}-${month_string}`}})
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <CustomOutlinedInput
                ref={input}
                style={{width:'100%',fontSize:15}}
                disabled={true}
                id="input-with-icon-adornment"
                startAdornment={
                <InputAdornment>
                    <Button size="small" style={classes.calendarIcon} onClick={handleClick}  ><CalendarTodayIcon fontSize="small" />{generateDate()}</Button>
                </InputAdornment>
                }
 
            />
            <Popper anchorEl={input ? input.current: null} open={open} popperOptions={{onCreate: (data) => data.instance.popper.style.zIndex = 1}}>
                <ClickAwayListener onClickAway={close}> 
                    <div>        
                    <PeriodsGrid 
                        onDelete={handleCleanDate}
                        showInnerIcon={year && month}
                        year={year || props.defaultDate} 
                        setDate={onSetDate}
                        checkDisabledMonths={false}
                    />
                    </div>
                </ClickAwayListener> 
            </Popper>            
            </>
        )
    
}

export default withTranslation("monthPickerFilter")(MonthPickerFilter);