import { gql } from '@apollo/client'
import { 
  OnlyFilterActionDisplayStrategy, 
  GeneralChipComponentCreator, 
  DataTransformer, 
  TableFieldDescription, 
  DataMapper,  
  NullClickStrategy,
  DateTimeComponentCreator,
  DateTimeDisplayCreator
} from  '../../components/BPMTable/TableMapperCreator'
import * as f from '../../components/BPMTable/BPMInputs'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator';
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator';

const tableQuery = gql`
    query($changedBy:String,
        $fieldName:String,
        $changedAt:String,
        $first:Int,$last:Int,
        $after:String,
        $before:String,
        $orderField:String,
        ){
      allModelChange(
        orderField:$orderField,
        first:$first,
        last:$last,
        after:$after,
        before:$before,
        changedBy:$changedBy,
        fieldName:$fieldName,
        changedAt:$changedAt,){
        edges {
          node {
            changedBy {
              enterpriseId
            }
            changedAt
            reason
            objectId
            objectContentType {
              appLabel
              model
            }
            fields {
              fieldName
              valuesFrom
              valuesTo
            }
          }
        }
      }
    }
`;

const HistoryTableCreatorQueries = {tableQuery}

class HistoryTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "HistoryTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, HistoryTableCreatorQueries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy());
        tableCreator.addDescriptions ([
            new TableFieldDescription(
                'changedBy',
                new DataMapper("changedBy.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("changedBy")),
                {label: 'Changed By'}
            ),
            new TableFieldDescription(
              'fields',
              new DataMapper("fields"),
              new f.FilterDescription(new f.TextFilter("fieldName")),
              {
                label: 'Field Data',
                componentCreator : new GeneralChipComponentCreator(["fieldName"],["fieldName","valuesFrom","valuesTo"], null, false), 
                transform: new DataTransformer(({fields})=> Object(fields))
              }
          ),
          new TableFieldDescription(
              'changedAt',
              new DataMapper("changedAt"),
              new f.FilterDescription(new f.DateFilter("changedAt")),
              {
                label: 'Changed At',
                componentCreator: new DateTimeComponentCreator(),
                excelDisplay: new DateTimeDisplayCreator()
              }
          ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(HistoryTableCreator)

export default HistoryTableCreator;