import React from 'react'

import TotalTableEstructure from './TotalTable'

export default function BudgetReportTotalsTable(props) {
    return (
        <TotalTableEstructure
            data={props.data}
            visibleColumns={props.visibleColumns} 
        />
    )
}