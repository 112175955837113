import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import { AccountTableCreator } from '../../../tableDefinitions/Inventory/Account/AccountTableCreator'
import AccountFormCreator from '../../../tableDefinitions/Inventory/Account/AccountFormCreator'

const Account = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Accounts')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Accounts'}
            tableCreatorCreatorId={AccountTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={AccountFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Account