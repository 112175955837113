import React from 'react'

import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import SetupTableData from '../../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import SubProcessTableCreatorCreator from '../../../tableDefinitions/Jobs/Process/SubProcessTableCreatorCreator'
import tableCreatorCreatorFactoryCreator from "../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator"

export default function PopupContent (processControlId, handleClose) {
    const { t } = useTranslation()
    var filters = { processControlId: processControlId}

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(SubProcessTableCreatorCreator.getId(), {tcFilters: filters})
    const tableCreator = tableCreatorCreator.getTableCreator('Sub-Processes')

    return (
        <>
            <SetupTableData
                TableCreator={tableCreator}
                stickyHeader>
                {() =>
                    <div style={{ position: 'sticky', top: 2, background: 'white', height:'35px' }}>
                        <span onClick={(event, reason) => handleClose(event, reason)} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '5px', top: '-5px'}}>&times;</span>
                        <Typography variant="h6" style={{ marginLeft:'10px' }}>{t(`Sub Processes`)}</Typography>
                    </div>
                }
            </SetupTableData>                                
        </>
    )
}
