import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import { ChargeGroupTableCreator } from '../../../tableDefinitions/Billing/ChargeGroup/ChargeGroupTableCreator'
import ChargeGroupFormCreator from '../../../tableDefinitions/Billing/ChargeGroup/ChargeGroupFormCreator'

const ChargeGroup = (props) => {
    const { t } = useTranslation('billManagement')
    document.title = `${t('Charge Groups')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Charge Groups'}
            tableCreatorCreatorId={ChargeGroupTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={ChargeGroupFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default ChargeGroup