import React from 'react'

import TotalTableEstructure from './TotalTable'

export default function BillingAccountsTotals(props) {
    return (
        <TotalTableEstructure
            data={[
                {symbol:props.symbol, value:props.data.cost6},
                {symbol:props.symbol, value:props.data.cost5},
                {symbol:props.symbol, value:props.data.cost4},
                {symbol:props.symbol, value:props.data.cost3},
                {symbol:props.symbol, value:props.data.cost2},
                {symbol:props.symbol, value:props.data.cost}
            ]}
            visibleColumns={props.visibleColumns} 
        />
    )
}