import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import InventoryUserMinimalTableCreator from '../../../tableDefinitions/Inventory/User/InventoryUserMinimalTableCreator';

export default class UserMinimalTable {
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.enterpriseId, label:"ID"},
            {mapper:data=>data.firstName, label:"First Name"},
            {mapper:data=>data.lastName, label:"Last Name"},
            {mapper:data=>data.email, label:"Email"}
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventoryUserMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('User')
    }
}