/* eslint-disable eqeqeq */
import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import InvoiceInvoicePeriodsFormCreator from '../../../tableDefinitions/Invoice/Periods/InvoiceInvoicePeriodsFormCreator'
import { InvoiceInvoicePeriodsTableCreator } from '../../../tableDefinitions/Invoice/Periods/InvoiceInvoicePeriodsTableCreator'

const Periods = (props) => {
    const { t } = useTranslation()
    document.title = `${t('Periods')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Periods'}
            tableCreatorCreatorId={InvoiceInvoicePeriodsTableCreator.getId()}
            formCreatorCreatorId={InvoiceInvoicePeriodsFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Periods