import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription, 
    DataMapper, 
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query(
        $orderField:String,
        $startDate:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
    ) {
        allPeriods (
        orderField:$orderField,
        startDate:$startDate
        first:$first,
        last:$last,
        after:$after,
        before:$before
        ) {
            edges{
                node {
                    id
                    startDate
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const unitQuery = `
    Period(id: $id) {
        id
        startDate
    }
`

const queries = {tableQuery, unitQuery}

class InvoicePeriodMinimalTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoicePeriodMinimalTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.setAdditionalFilters(this.filters)
        tableCreator.setInitialSortField('startDate', 'desc')

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'startDate',
                new DataMapper('startDate'),
                new f.FilterDescription(new f.DateFilter('startDate')),
                {label: 'Period'}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoicePeriodMinimalTableCreator)

export default InvoicePeriodMinimalTableCreator;