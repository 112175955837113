import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    FormFieldDescription, 
    FormSection,
    DataTransformer,
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { GlAccountBudgetAssociationTableCreatorQueries } from './GlAccountBudgetAssociationTableCreator'

const glAccountsQuery = `
    allGlAccounts {
        edges {
            node {
                id
                name
            }
        }
    }
`

const billingAccountsQuery = `
    allAccounts {
        edges {
            node {
                id
                name
                identifier
                provider{
                    name
                }
                technology {
                    name
                }
            }
        }
    }
`

const conceptsQuery = `
    allConcepts {
        edges {
            node {
                id
                name
                technology {
                    id
                    name
                }
            }
        }
    }
`

class GlAccountBudgetAssociationFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "GlAccountBudgetAssociationFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Gl Account Budget Association', GlAccountBudgetAssociationTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'glAccount',
                new f.InputDescription(new f.DropDownCreator("glAccount"), true),
                generalSection,
                1,
                {label: 'Gl Account', transform: new DataTransformer(data => data?.name? data.name : null), queryAllDropdownOptions: glAccountsQuery, }
            ),
            new FormFieldDescription(
                'account',
                new f.InputDescription(new f.DropDownCreator("account"), true),
                generalSection,
                2,
                {label: 'Billing Account', transform: new DataTransformer(data => data?.id? `${data.identifier || 'Undefined'} (${data.provider? data.provider.name : ' '} - ${data.technology? data.technology.name : ' '})` : null), queryAllDropdownOptions: billingAccountsQuery, }
            ),
            new FormFieldDescription(
                'concept',
                new f.InputDescription(new f.DropDownCreator("concept"), true),
                generalSection,
                3,
                {label: 'Concept', transform: new DataTransformer(data => data?.technology?.name? `${data.name} (${data.technology.name})` : null), queryAllDropdownOptions: conceptsQuery, }
            ),
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(GlAccountBudgetAssociationFormCreator)

export default GlAccountBudgetAssociationFormCreator