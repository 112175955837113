/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import {FormControl,
        TextField,
        Card,
        Dialog,
        DialogContent,
        DialogTitle,
        List,
        Button,
        Checkbox,
        ListItem,
        ListItemIcon,
        ListItemText,
        Chip,
        InputLabel,
        Select,
        MenuItem,
        Paper,
        Input} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
//import { ClientContext } from '../../../../root/react_app/contexts/Client';

class CheckboxListInput extends Component {
    //static contextType = ClientContext;

    state = {
        searchField : "",
        choices :  [],
        selectedItems : [],
        loading : true,
        dialogOpen : false,
    }

    handleOpenDialog = () => this.setState({dialogOpen : true, selectedItems: this.getParsedActualItems()})
    handleCloseDialog = () => this.setState({dialogOpen : false, selectedItems:[]})

    componentDidMount(){
        if (this.state.loading){
            this.getChoices()
        }
    }

    componentWillUnmount(){
        this.setState = {
            searchField : "",
            loading : true,
            selectedItems : [],
            choices :  [],
        }
    }

    getChoices = () => {
        this.context.client.query({
            query: this.props.query,
            variables : this.props.variables,
            fetchPolicy: 'network-only'
        }).then(res => {
            let choices = res.data;
            if (this.props.mapper){
                choices = this.props.mapper(choices).map(choice => JSON.parse(choice))
            }
            this.setState({choices, loading:false})
        }).catch( error => {
            console.log(error);
            const label = this.props.t('Error Loading Choices');
        })
    }

    setSearchField = filter => {
        this.setState({searchField : filter})
    }

    getParsedActualItems = () => {
        const fieldId = this.props.id;
        const state = JSON.parse(JSON.stringify(this.props.formState.mappedData[fieldId])) || [];
        return state.map(choice => (JSON.parse(choice)))

    }

    handleItemClick = (clickedItemId, clickedItemLabel) => {
        const parsedState = this.state.selectedItems;
        if (parsedState.filter(t => t.id == clickedItemId).length > 0){
               this.setState({selectedItems: parsedState.filter(t => t.id != clickedItemId)});
               return null;
        }
        parsedState.push({id:clickedItemId, label:clickedItemLabel})
        this.setState({selectedItems:parsedState});
    }

    handleConfirm = () => {
        const selections = JSON.parse(JSON.stringify(this.state.selectedItems));
        const stringifySelections = selections.map(item => JSON.stringify(item))
        this.props.onChangeHandler(this.props.id, stringifySelections)
        this.handleCloseDialog();
    }

    checkSelected = itemId => {
        const currentState = this.state.selectedItems;
        return currentState.filter(t => t.id == itemId).length > 0;
    }

    getSelectedChips = () => {
        const selected = JSON.parse(JSON.stringify(this.props.formState.mappedData[this.props.id])) || [];
        return selected.map(selection => {
            selection = JSON.parse(selection)
            return (
                <div key={selection.id + selection.label + 'Chip'}>
                    <Chip style={{margin:2}}
                        color='primary'
                        size='small'
                        variant='outlined'
                        label={selection.label}
                        />
                </div>
            )
        })
    }

    getDialogSelectedChips = () => {
        const selected = this.state.selectedItems;
        return selected.map(selection => {
            return (
                <div key={selection.id + selection.label + 'Chip'}>
                    <Chip style={{margin:2}}
                        color='primary'
                        size='small'
                        variant='outlined'
                        onDelete={() => this.handleItemClick(selection.id)}
                        label={selection.label}
                        />
                </div>
            )
        })

    }

  render() {
    const {required, classes, label, id, formState, t} = this.props;
    const { choices, searchField, dialogOpen } = this.state;
    const error =  Boolean(formState.errors[id]);
    const inputLabel = required ? required(t(label)) : t(label);
    return (
        <div style={{width:250, margin:8}}>

            <FormControl style={{width:'100%'}} className={classes.formControl} error={error}>
                <InputLabel htmlFor={label}>{inputLabel}</InputLabel>
                <Select value={''}
                        IconComponent={SearchIcon}
                        onOpen={this.handleOpenDialog}
                        open={false}
                        style={{width:'100%'}}
                        className={classes.input}
                        id={id}
                        input={<Input />} >

                    <MenuItem key={''} value={null} className={classes.input}>
                            -
                    </MenuItem>
                </Select>
                <Paper elevation={1} style={{width:'100%'}}>
                    <div style={{padding:10, overflow:'auto'}}>
                        { this.getSelectedChips() }
                    </div>
                </Paper>
            </FormControl>

            <Dialog open={dialogOpen} onClose={this.handleCloseDialog} maxWidth='xl'>
                <span onClick={this.handleCloseDialog} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>
                <DialogTitle>{inputLabel}</DialogTitle>
                <DialogContent>
                    <div style={{display:'flex', padding:15}}>

                        <Card style={{width:300, height:250}}>
                            <TextField style={{margin: 8, width:284}} label={t('Filter')} value={searchField} onChange={event => this.setSearchField(event.target.value)} />
                            <List style={{padding:10, overflow:'auto', height:186}}>
                                {
                                    choices.filter(choice => (
                                        choice.label.toLowerCase().includes(searchField.toLowerCase())
                                    )).map(item => (
                                        <ListItem
                                            style={{padding:8}}
                                            button disabled={!item.id}
                                            key={"item-id-"+item.id}
                                            onClick={() => this.handleItemClick(item.id, item.label)}
                                        >
                                            <ListItemIcon>
                                                <Checkbox style={{padding:0, margin:0}} checked={this.checkSelected(item.id)} disableRipple />
                                            </ListItemIcon>
                                            <ListItemText primary={item.label} />
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Card>
                        <Card style={{marginLeft: 30, display:'column', height:250, overflow:'auto', width:300,padding:10}}>
                            {this.getDialogSelectedChips()}
                        </Card>
                    </div>
                        <Button
                            color='primary'
                            variant='contained'
                            style={{margin:8, width:'100%'}}
                            onClick={this.handleConfirm}
                        >
                            {t('Confirm')}
                        </Button>

                </DialogContent>
            </Dialog>
        </div>
    )
  }
}

export default withTranslation('fieldLabels')(CheckboxListInput);
