import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import MassiveUploadControlTableCreator from '../../../tableDefinitions/Jobs/MassiveUploadControl/MassiveUploadControlTableCreator'

const MassiveUploadControl = (props) => {
    const { t } = useTranslation('tableNames')
    document.title = `${t('Massive Uploads')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Massive Uploads'}
            tableCreatorCreatorId={MassiveUploadControlTableCreator.getId()}
            path = {props.match.url}
        />
    )
}

export default MassiveUploadControl