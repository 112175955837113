/* eslint-disable no-dupe-keys */
/* eslint-disable import/first */
import React, {useState} from 'react'

import { useTranslation } from 'react-i18next'
import ChargeGroupsFilters from "./chargeFilters"
import CanvasJSReact from '../../utils/canvasjs.react'
var CanvasJSChart = CanvasJSReact.CanvasJSChart
import AveragesCard from "./AveragesCard"
import { gql, useQuery  } from '@apollo/client';
  
export default function ViewUsageTab(props) {
    const {data} = props
    const {t} = useTranslation()

    function toggleDataSeries(e) {
        if (typeof(e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
            e.dataSeries.visible = false;
        } else {
            e.dataSeries.visible = true;
        }
        e.chart.render();
    }
    
    const usageDataObtainer = (ussageType) =>  {
        const dataArray = data.totalUsageByPeriod.map(item=> (item && item["totalUsage"] && item["totalUsage"][ussageType] && item["totalUsage"][ussageType].count) ? item["totalUsage"][ussageType].count : 0)
        const threeMonthArray = dataArray.slice(0,4)
        const current = dataArray[0]
        const threeMonths = threeMonthArray.reduce((previous, current)=>previous+current)/3
        const sixMonths = dataArray.reduce((previous, current)=>previous+current)/6

        return({
            current: current.toFixed(2),
            threeMonths:threeMonths.toFixed(2),
            sixMonths:sixMonths.toFixed(2),
        })
    }
    
    return(
        <div className="view-expense-container p-5">
            <div className="average-cards-div-container">
                <AveragesCard title="Voice" data={usageDataObtainer("voice")}/>
                <AveragesCard title="Messages" data={usageDataObtainer("sms")}/>
                <AveragesCard title="Data" data={usageDataObtainer("internet")}/>         
            </div>

            <TotalUsageByVendorChart 
                dateTitle={props.dateTitle}
                period={props.period} 
                toggleDataSeries={toggleDataSeries} 
                currencySymbol={props.currencySymbol}  
                vendors={props.vendors} />
            <UsageTrending                 
                dateTitle={props.dateTitle}
                period={props.period} 
                toggleDataSeries={toggleDataSeries} 
                currencySymbol={props.currencySymbol}  
                vendors={props.vendors} />
        </div>
    )
}


const TOTAL_USAGE_BY_VENDOR_QUERY = gql`query TotalUsageByVendor($vendors:[Int], $period:Period){
    totalUsageByVendor(vendors:$vendors, period:$period){
      period
      dataByVendor{
        vendor{
          name
        }
        domestic{
            voice{
                unit{
                    name
                }
                count
            }
            sms{
                unit{
                    name
                }
                count
            }
            internet{
                unit{
                    name
                }
                count
            }
        }
        roaming{
            voice{
                unit{
                    name
                }
                count
            }
            sms{
                unit{
                    name
                }
                count
            }
            internet{
                unit{
                    name
                }
                count
            }
        }
      }
    }
  }`

function TotalUsageByVendorChart({vendors, period, dateTitle, toggleDataSeries}) {
    const {t} = useTranslation()

    const { loading, error, data, refetch } = useQuery(TOTAL_USAGE_BY_VENDOR_QUERY, {variables:{vendors:vendors, period:period}});
    const [selected, setSelected] = useState("voice")

    if(loading)return("loading..")

    const setFilters = (filters) => {
        const selections = {
            Voice:"voice",
            Messages:"sms",
            Data:"internet",
        }
        Object.keys(filters).map(filter=> filters[filter] ? setSelected(selections[filter]) : null)            
    }

    let unit = ""
    for(let usage of data.totalUsageByVendor){
        if(usage.dataByVendor.domestic[selected].unit){
            unit = usage.dataByVendor.domestic[selected].unit.name
            break
        }
    }

    const usageBarGraphDomestic = {
        colorSet:"bubbleColors",
        title: {
            text: `${dateTitle} ${unit ? `(${unit})` : "" }`,
            fontSize: 20,
            verticalAlign: "top",
            horizontalAlign: "left",
        },
        subtitles: [
            {
                text: "Total Usage by Vendor",
                fontSize: 15,
                verticalAlign: "top",
                horizontalAlign: "left",
                fontColor:"gray",
                fontFamily: "Calibri"
            },
            {
                text: "Domestic",
                fontSize: 15,
                margin:10,
                verticalAlign: "top",
                horizontalAlign: "center",
                fontFamily: "Calibri"
            }
        ],
        toolTip: {
            shared: true
        },
        legend: {
            cursor: "pointer",
            itemclick: toggleDataSeries
        },
        data: [
            {
                type: "stackedBar",
                dataPoints: data.totalUsageByVendor.map(item => ({label:item.dataByVendor.vendor.name, y:item.dataByVendor.domestic[selected].count}))
            },
        ]
    }


    const usageBarGraph = {
        colorSet:"bubbleColors",
        title: {      
            text:" ",
            margin:50
        },
        subtitles: [{
            text: "Roaming",
            fontSize: 15,
            margin:10,
            verticalAlign: "top",
            horizontalAlign: "center",
            fontFamily: "Calibri"
        }],
        toolTip: {
            shared: true
        },
        legend:{
            cursor: "pointer",
            itemclick: toggleDataSeries
        },
        data: [
            {
                type: "stackedBar",
                dataPoints: data.totalUsageByVendor.map(item => ({label:item.dataByVendor.vendor.name, y:item.dataByVendor.roaming[selected].count}))

            }
        ],
    }

    return (
        <div>            
        <ChargeGroupsFilters className="mt-3" onChange={setFilters} data={[{label:"Voice", selected:true},{label:"Data"},{label:"Messages"}]}/>
        <div className="d-flex w-100 chart-border">
            <CanvasJSChart options = {usageBarGraphDomestic}/>
            <CanvasJSChart options = {usageBarGraph}/>
        </div>
    </div>
    )
}


const TOTAL_USAGE_BY_VENDOR_TRENDING_QUERY = gql`query TotalUsageByVendorTrending($vendors:[Int], $period:Period){
    totalUsageByVendorTrending(vendors:$vendors, period:$period){
        vendor{
            name
          }
            periodData{
            period
            dataByVendor{
              roaming{
                internet{
                    unit{
                        name
                    }
                    count
                }
                sms{
                    unit{
                        name
                    }
                    count
                }
                voice{
                    unit{
                        name
                    }
                    count
                }
              }
              domestic{
                internet{
                    unit{
                        name
                    }
                    count
                }
                sms{
                    unit{
                        name
                    }
                    count
                }
                voice{
                    unit{
                        name
                    }
                    count
                }
              }
            }
          }
    }
  }`

function UsageTrending({vendors, period, dateTitle, toggleDataSeries}) {
    const {t} = useTranslation()

    const { loading, error, data, refetch } = useQuery(TOTAL_USAGE_BY_VENDOR_TRENDING_QUERY, {variables:{vendors:vendors, period:period}});
    const [selected, setSelected] = useState("voice")
    if(loading)return("loading..")

    const setFilters = (filters) => {
        const selections = {
            Voice:"voice",
            Messages:"sms",
            Data:"internet",
        }
        Object.keys(filters).map(filter=> filters[filter] ? setSelected(selections[filter]) : null)            
    }

    let unit = ""
    for(let usage of data.totalUsageByVendorTrending){
        for(let i = 0; i < (usage.periodData.length - 1 );i++){
            if(usage.periodData[i].dataByVendor.domestic[selected].unit){
                unit = usage.periodData[i].dataByVendor.domestic[selected].unit.name
                break
            }
        }
    }

    const usageTrendingDomestic = {
        colorSet:"bubbleColors",
        animationEnabled: true,
        exportEnabled: true,
        title: {
            text:`${t('Usage Trending')} ${unit}`,
            fontSize: 20,
            verticalAlign: "top",
            horizontalAlign: "left",
        },
        subtitles: [
            {
                text:"Usage by Vendor",
                fontSize: 15,
                verticalAlign: "top",
                horizontalAlign: "left",
                fontColor:"gray",
                fontFamily: "Calibri",
            },
            {
                text:"Domestic",
                fontSize: 25,
                verticalAlign: "top",
                horizontalAlign: "center",
                fontFamily: "Calibri"
            }
        ],
        axisY: {
            interlacedColor: "#3f51b514",
        },
        axisY2: {
            margin:10,
        },
        toolTip: {
            shared: true,
            reversed: true
        },
        legend: {
            verticalAlign: "center",
            horizontalAlign: "right",
            reversed: true,
            cursor: "pointer",
            itemclick: toggleDataSeries
        },
        data: data.totalUsageByVendorTrending.map(vendorData => (
            {
            type: "stackedColumn",
            name: vendorData.vendor.name,
            showInLegend: "true",
            dataPoints:vendorData.periodData.map(pd=>({label:pd.period, y:pd.dataByVendor.domestic[selected].count})).reverse()
            }
            ))
                
    }
    
    const usageTrendingRoaming = {
        colorSet:"bubbleColors",
        animationEnabled: true,
        exportEnabled: true,
        subtitles: [{
            text: `Roaming`,
            fontSize: 25,
            margin:10,
            verticalAlign: "top",
            horizontalAlign: "center",
            fontFamily: "Calibri"
        }],
        axisY: {
            interlacedColor: "#3f51b514",
        },
        axisY2: {
            margin:10,
        },
        toolTip: {
            shared: true,
            reversed: true
        },
        legend: {
            verticalAlign: "center",
            horizontalAlign: "right",
            reversed: true,
            cursor: "pointer",
            itemclick: toggleDataSeries
        },
        data:data.totalUsageByVendorTrending.map(vendorData => (
            {
                type: "stackedColumn",
                name: vendorData.vendor.name,
                showInLegend: "true",
                dataPoints:vendorData.periodData.map(pd=>({label:pd.period, y:pd.dataByVendor.roaming[selected].count})).reverse()
            })
        )
    }
    return (
        <div className="mt-3">
            <ChargeGroupsFilters className="w-100" onChange={setFilters} data={[{label:t("Voice"), selected:true},{label:t("Data")},{label:t("Messages")}]}/>
            <div className="chart-border d-flex flex-column">
                <div>
                    <CanvasJSChart options={usageTrendingDomestic}/>
                </div>
                <div>
                    <CanvasJSChart options={usageTrendingRoaming}/>
                </div>
            </div>
        </div>

    )
}
