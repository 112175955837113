import React, {useState} from 'react'

import { Typography } from '@material-ui/core'

import ExcelFileTask from '../../components/BPMTable/TableComponents/ExcelFileTask'
import SetupTableData from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import ExpenseBySitePopupContentTableCreator from '../../tableDefinitions/ExpensesBySite/ExpenseBySitePopupContentTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import { useDispatch } from 'react-redux'
import { AddBPMApplicationMessages } from '../../redux/reducers/BPMReducer'

export default function ExpenseBySitePopupContent(props) {
    const dispatch = useDispatch()

    const handleQueryExecuted = (success, message) => {
        dispatch(AddBPMApplicationMessages({
            text: message,
            variant: success ? 'success' : 'error',
            snakBarCursor: success ? {cursor: 'pointer'} : null,
            duration: 8000,
            clckRedirect: '/excelExports'
        }))
    }

    var filters = {period: String(props.period), currency: props.currency, first: 50}
    filters['report'] = props.popupData.reportId ? props.popupData.reportId : "NULL"

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(ExpenseBySitePopupContentTableCreator.getId(), {tcFilters: filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('ExpensesBySite');

    return (
        <>
            <SetupTableData
                TableCreator={tableCreator}
                style={{margin:"2%"}}
            >
                {(data, managerTable) =>
                    <div>
                        <span onClick={props.handleClose} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>

                        <ExcelFileTask TableObject={managerTable} filters={filters} handleClose={handleQueryExecuted} typeButton={true}/>
                        <div style={{margin:"2%"}}>
                            <Typography variant="h5">{` ${props.periodString} - ${props.popupData.site.name ? props.popupData.site.name : 'No_Site'} `}</Typography>
                        </div>
                    </div>
                }
            </SetupTableData>
        </>
    )
}