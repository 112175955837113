/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react'

export default function TabPanel(props) {
    const { children, value, index, validated, ...other } = props

    return (
        <div key={`tp_content_${index}`} style={{width: "100%"}}>
            {value == index &&
                <div key={`tp_value_${index}`} style={{width: "100%", display:"flex", justifyContent:"center"}}>
                { !validated &&  children }
                </div>
            }
        </div>
    )
}