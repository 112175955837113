/* eslint-disable eqeqeq */
import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useCurrentUser, useLastPublishedPeriod } from '../../redux/selectors'
import ReportsFilter from '../../components/ReportsFilter/ReportsFilter'
import ConsumptionsTable from './ConsumptionsTable'

function Consumptions(props){
    const currentUser = useCurrentUser()
    const LastPublishedPeriod = useLastPublishedPeriod()

    document.title = `${props.t('root:Consumptions')} - BubbleBPM`

    const [currency_symbol, SetSymbol] = useState(currentUser.currency ? currentUser.currency.symbol : null )
    const [currency_id, SetCurrencyId] = useState(currentUser.currency ? currentUser.currency.code : null)
    const [date, setDate] = useState({year: LastPublishedPeriod.year, month: LastPublishedPeriod.month})
  
    const handleChangeCurrency = (currencyCode, symbol) => {
        SetSymbol(symbol)
        SetCurrencyId(currencyCode)
    }
  
    function onChangeDate(year, month) {
        setDate({year: year, month: month});
    }
  
    function generateFilters(){
        return {period:`${date.year}-${date.month}`, currency:currency_id}
    }
  
    return (
        <>
            <ReportsFilter 
                date={date}
                onChangeDate={onChangeDate}
                info={{label: props.t("Consumption and costs of your lines and of the employees who report to you")}}
                onChangeCurrency={handleChangeCurrency}
                currencySelected = {currency_id}
                checkDisabledMonths={true}
            />
            <ConsumptionsTable
                t={props.t} 
                filters={generateFilters()}  
                symbol={currency_symbol}
            />
        </>
    )
}

export default withTranslation("consumptions")(Consumptions)