/* eslint-disable eqeqeq */
import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import DataLoadTemplatesTableCreator from '../../../tableDefinitions/Jobs/DataLoadTemplates/DataLoadTemplatesTableCreator'

function DataLoadTemplates(props){
    const { t } = useTranslation(['MassiveLoadFiles'])
    document.title = `${t('My Massive load files exports')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'My Data Load Templeates'}
            tableCreatorCreatorId={DataLoadTemplatesTableCreator.getId()}
            path={props.match.url}
            filters={{first: 30}} 
        />
    )
}

export default DataLoadTemplates