import React from 'react'

import { withTranslation } from 'react-i18next'
import { print, } from 'graphql';
import { gql, useMutation } from '@apollo/client'
import { Button } from '@material-ui/core'
import {ReactComponent as XLSIcon} from '../../BPMTable/TableComponents/Icons/fileIcons/xls-black_and_white.svg'


const excelQueryMutation = gql`
mutation ExecuteExcelQueryMutation(
    $query:String!,
    $variables:String!,
    $tableColumns:String!,
    $tableCreatorId:String!,
    $tableName:String!,
    $parentFormProps:String,
) {
    executeExcelQuery (
        query:$query,
        variables:$variables,
        tableColumns:$tableColumns,
        tableCreatorId:$tableCreatorId,
        tableName:$tableName,
        parentFormProps:$parentFormProps,
    )
    {
      excelExport{
        id
        user {
            id
            firstName
            lastName
        }
        createdAt
        state
        message
        tableQuery
        tableCreatorId
        tableName
        queryVariables
        queryData
      }
    }
}`

const ExcelFileTask = (props) => {
    const { TableObject, filters, handleClose, t, typeButton} = props
    const [ runQuery, {loading} ] = useMutation(excelQueryMutation)

    filters.first = null

    const createExcelExportTask = () => {
        runQuery({
            variables: {
                query: print(TableObject.tableQuery), 
                variables: JSON.stringify(filters), 
                tableColumns: JSON.stringify(TableObject.getVisibleFields()),
                tableCreatorId: TableObject.tableCreatorId,
                tableName: TableObject.tableName,
                parentFormProps: JSON.stringify(TableObject.tableCreatorCreatorParentProps),
            }
        })
        .then(result => {
            const success = result.data;
            if (success) {
                handleClose(true, t("Report job has been initiated, go to Jobs->Excel Reports, and download your report when finished"))
            } else {;
                handleClose(false, t('An error occurred trying to execute Excel Query'))
            }
        })
        .catch(error => {
            handleClose(false, JSON.stringify(error))
        })
    };

    return (
        typeButton ?
            <XLSIcon onClick={createExcelExportTask} style={{position:"absolute", right:35, cursor:"pointer", width:30}}/>
            :
            <Button variant="contained" color="secondary" onClick={createExcelExportTask} autoFocus>
                {t('excel:Run Query')}
            </Button>
    )
}

export default withTranslation('excel') (ExcelFileTask)
