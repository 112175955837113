import React, { useState } from 'react'

import { gql } from '@apollo/client';
import _ from "lodash";

import { useTableColumns } from '../../../../redux/selectors';

import { withTranslation } from 'react-i18next'
import { Dialog, Card, MenuItem, IconButton, Typography, Divider, Button } from '@material-ui/core'
import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon } from '@material-ui/icons'

const CUSTOM_CONFIG_MUTATION = gql`
    mutation($tableName:String, $columnList: String){
        addCustomConfiguration(tableName:$tableName, columnList: $columnList){
            response
        }
    }
`;

function ColumnDisplayDialog(props) {
    const currentTableColumns = useTableColumns(props.TableObject.tableName);

    const [selected, setSelected] = useState(null);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [draggedOverIndex , setDraggedOverIndex] = useState(null);
    const [fieldsOrder, setFieldsOrder] = useState(props.TableObject.getFieldsOrder(currentTableColumns));
    const {open, onClose, TableObject, t} = props;

    const saveConfig = () => {
        const fields = fieldsOrder.map( field => {
            return {id: field.id, visibility: field.visibility} 
        })

        applyConfig(fields)
    }

    const resetSetup = () =>{
        const fields = TableObject.resetColumnSetup();
        setFieldsOrder(props.TableObject.getFieldsOrder());
        applyConfig(fields);
    }

    const applyConfig = (fields) => {
        TableObject.client.mutate(
            {
                mutation: CUSTOM_CONFIG_MUTATION, 
                variables: {tableName: TableObject.tableName, columnList: JSON.stringify(fields)}
            }
        ).then(() => {
            TableObject.addApplicationSnackMessage({
                text: t('Configuration has been saved'), 
                variant: 'success', 
            })
            TableObject.setColumnConfiguration(fields);
            TableObject.setTableColumnsState(fields);
        }).catch(err => {
            console.log(err);
            TableObject.addApplicationSnackMessage({
                text: t('Error saving configuration'), 
                variant: 'error',
            })
        })
        onClose();
    }

    const onDragColumnStart = (e, index) => {
        setDraggedIndex(index);
    };

    const onDragColumnOver = (e, index) => {
        e.preventDefault();
        setDraggedOverIndex(index);
    };

    const onDragColumnEnd = () => {
        const newColumnsConfig = JSON.parse(JSON.stringify(fieldsOrder));

        if (draggedOverIndex >= newColumnsConfig.length) {
            var k = draggedOverIndex - newColumnsConfig.length + 1;
            while (k--) {
                newColumnsConfig.push(undefined);
            }
        } else {
            var column = newColumnsConfig.at(draggedOverIndex);
            if (column.section) {
                return;
            }
        }

        newColumnsConfig.splice(draggedOverIndex, 0, newColumnsConfig.splice(draggedIndex, 1)[0]);
        setFieldsOrder(newColumnsConfig)
        setSelected(null);
    };

    const toggleVisibility = (toggle) => {
        const newColumnsConfig = JSON.parse(JSON.stringify(fieldsOrder))

        const index = newColumnsConfig.findIndex(c=>c.id===selected)
        newColumnsConfig[index].visibility = toggle

        setFieldsOrder(newColumnsConfig)
        setSelected(null);
    }

    const select = id =>{
        setSelected(id)
    }

    const visibleColumns = fieldsOrder.filter(f => (!f.visibility && !f.section && !f.hidenField));
    const notVisibleColumns = fieldsOrder.filter(f => (f.visibility && !f.section && !f.hidenField));
    const disableVisible =  !Boolean(notVisibleColumns.find(d => d.id === selected));
    const disableNotVisible =  (!Boolean(visibleColumns.find(d => d.id === selected)) ||  visibleColumns.length < 3);
    return (
        <Dialog open={open} onClose={onClose} maxWidth='md'>
            <span onClick={onClose} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>

            <div key={'dlg_div_01'} style={{padding:"24px 24px 20px", display:"flex", justifyContent:"space-between", width:'100%'}}>
                <div key={'dlg_div_01A'} style={{width:'50%'}}>
                    <Typography variant='h6'> {t('columnSetup:Columns Setup')} </Typography>
                </div>
                <div key={'dlg_div_01B'} style={{display:'flex', width:'50%', justifyContent: "flex-end"}}>
                    <Button variant="contained" size="small" onClick={resetSetup} style={{marginRight: 20}}>
                        {t('columnSetup:Reset Default')}
                    </Button>
                    <Button variant="contained" size="small" color="primary" onClick={saveConfig} style={{marginRight: 10}}>
                        {t('columnSetup:Save')}
                    </Button>
                </div>
            </div>

            <div key={'dlg_div_02'} style={{display:"flex", padding: "10px 30px 30px", maxHeight: 500, justifyContent:"space-between"}}> 
                <Card style={{width : 300}} raised>
                    <Typography variant="h6" style={{padding:"10px 20px"}}><b>{t('columnSetup:Hidden Columns')}</b></Typography>
                    <Divider/>
                    <div style={{padding: 10, maxHeight:300, overflow:"auto", height:"100%"}}>
                        {notVisibleColumns.map(column => (
                            <MenuItem key={column.id}
                                    onClick={() => select(column.id)}
                                    draggable
                                    onDragOver={e => onDragColumnOver(e, fieldsOrder.findIndex(c=>c.id===column.id))}
                                    onDragStart={e => onDragColumnStart(e, fieldsOrder.findIndex(c=>c.id===column.id))}
                                    onDragEnd={onDragColumnEnd}
                                    selected={selected === column.id}>
                                    { column.translateLabel ? TableObject.tableTranslation.translate(column.section ? column.section.label : column.label, t) : column.section ? column.section.label : column.label }
                            </MenuItem>
                        ))}
                    </div>
                </Card>
                <div style={{display:"flex",alignItems:'center'}} >
                    <IconButton onClick={() => toggleVisibility(false)}
                                disabled={disableVisible}> 
                        <ArrowRightIcon />
                    </IconButton>
                    <br/>
                    <IconButton onClick={() => toggleVisibility(true)}
                                disabled={disableNotVisible}> 
                        <ArrowLeftIcon />
                    </IconButton>
                </div>

                <Card style={{width : 300}} raised>
                    <Typography variant="h6" style={{padding:"10px 20px"}}><b>{t('columnSetup:Visible Columns')}</b></Typography>
                    <Divider/>
                    <div style={{padding: 10, maxHeight:300, overflow:"auto", height:"100%"}}>
                        {visibleColumns.map(column => (
                            <MenuItem key={column.id}
                                    onClick={() => select(column.id)}
                                    draggable
                                    onDragOver={e => onDragColumnOver(e, fieldsOrder.findIndex(c=>c.id===column.id))}
                                    onDragStart={e => onDragColumnStart(e, fieldsOrder.findIndex(c=>c.id===column.id))}
                                    onDragEnd={onDragColumnEnd}
                                    selected={selected === column.id}>
                                    { column.translateLabel ? TableObject.tableTranslation.translate(column.section ? column.section.label : column.label, t) : column.section ? column.section.label : column.label }
                            </MenuItem>
                        ))}
                    </div>
                </Card>
            </div>
        </Dialog>
    )
}
export default withTranslation()(ColumnDisplayDialog);