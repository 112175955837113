import React from 'react'

import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription,
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import { DownloadFile } from '../../../components/FileDownloader/FileComponentDownloader'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'


const tableQuery = gql`
    query AllDevices(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $isActive: Boolean,
        $domain: String,
        $description: String,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $brand: String,
        $model: String,
        $serviceType: String,
        $serialNumberType: String,
        $serialNumber: String,
        $loanStart: String,
        $loanEnd: String,
        $acquisitionDate: String,
        ) {
        allDevices(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            domain: $domain,
            before: $before,
            description: $description,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            isActive: $isActive,
            brand: $brand,
            model: $model,
            serviceType: $serviceType,
            serialNumberType: $serialNumberType,
            serialNumber: $serialNumber,
            loanStart: $loanStart,
            loanEnd: $loanEnd,
            acquisitionDate: $acquisitionDate,
        ) {
            edges {
                node {
                    id
                    domain {
                        id
                        name
                    }
                    model {
                        id
                        name
                        brand {
                            name
                        }
                        serviceType {
                            name
                        }
                    }
                    isActive
                    serialNumber
                    serialNumberType {
                        name
                    }
                    loanStart
                    loanEnd
                    acquisitionDate
                    value
                    loss {
                        id
                        file
                        type {
                            id
                            name
                        }
                        date
                        description
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editDeviceMutation(
        $id: String!,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $serialNumberType: Int,
        $serialNumber: String,
        $description: String,
        $isActive: Boolean,
        $loanStart: Date,
        $loanEnd: Date,
        $value: String,
        $lossType: String,
        $lossDate: Date,
        $lossDescription: String,
        $model: String,
        $domain: String,
        $lossFile:Upload,
        $acquisitionDate: Date,
    ) {
        editDevice(
            id: $id,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            serialNumberType: $serialNumberType,
            serialNumber: $serialNumber,
            description: $description,
            loanStart: $loanStart,
            loanEnd: $loanEnd,
            value: $value,
            isActive: $isActive,
            domain: $domain,
            lossType: $lossType,
            lossDate: $lossDate,
            lossDescription: $lossDescription,
            model: $model,
            acquisitionDate: $acquisitionDate,
            lossFile: $lossFile,
        ) {
            device {
                id
            }
        }
    }
`

const newMutator= gql`
    mutation createDeviceMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $serialNumberType: Int,
        $serialNumber: String,
        $description: String,
        $loanStart: Date,
        $loanEnd: Date,
        $value: String,
        $isActive: Boolean,
        $lossType: String,
        $lossDate: Date,
        $lossDescription: String,
        $model: String,
        $domain:String,
        $acquisitionDate: Date,,
        $lossFile:Upload,
    ) {
        createDevice(
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            serialNumberType: $serialNumberType,
            serialNumber: $serialNumber,
            description: $description,
            loanStart: $loanStart,
            loanEnd: $loanEnd,
            value: $value,
            isActive: $isActive,
            lossType: $lossType,
            model: $model,
            domain: $domain,
            lossFile: $lossFile,
            lossDate: $lossDate,
            acquisitionDate: $acquisitionDate,
            lossDescription: $lossDescription,
        ) {
            device {
                id
            }
        }
    }
`

const deleteMutator= gql`
    mutation removeDeviceMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeDevice(ids: $ids, allRecords: $allRecords, filters: $filters) {
            device {
                id
            }
        }
    }
`

const unitQuery = `
    device(id: $id) {
        id
        model {
            id
            name
            brand {
                name
            }
            serviceType {
                name
            }
        }
        isActive
        domain {
            id
            name
        }
        description
        serialNumber
        serialNumberType {
            id
            name
        }
        customAttr1
        customAttr2
        customAttr3
        loanStart
        loanEnd
        acquisitionDate
        value
        loss {
            id
            type {
                id
                name
            }
            file
            date
            description
        }
    }
`

const devicesQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class DevicesTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
        this.queries = devicesQueries
    }

    static getId = () => {
        return "DevicesTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, this.queries)
        tableCreator.setInitialSortField('isActive', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'brand',
                new DataMapper('model.brand.name'),
                new f.FilterDescription(new f.TextFilter('brand')),
                {label: 'Brand'}
            ),
            new TableFieldDescription(
                'model',
                new DataMapper('model.name'),
                new f.FilterDescription(new f.TextFilter('model')),
                {label: 'Model'}
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper('model.serviceType.name'),
                new f.FilterDescription(new f.TextFilter('serviceType')),
                {label: 'Service Type'}
            ),
            new TableFieldDescription(
                'description',
                new DataMapper('description'),
                new f.FilterDescription(new f.TextFilter('description')),
                {label: 'Description', onlyForm: true}
            ),
            new TableFieldDescription(
                'serialNumber',
                new DataMapper('serialNumber'),
                new f.FilterDescription(new f.TextFilter('serialNumber')),
                {label: 'Serial Number'}
            ),
            new TableFieldDescription(
                'serialNumberType',
                new DataMapper('serialNumberType.name'),
                new f.FilterDescription(new f.TextFilter('serialNumberType')),
                {label: 'Serial Number Type'}
            ), 
            new TableFieldDescription(
                'domain',
                new DataMapper('domain.name'),
                new f.FilterDescription(new f.TextFilter('domain')),
                {label: 'Domain'}
            ),
            new TableFieldDescription(
                'isActive',
                new DataMapper('isActive'),
                new f.FilterDescription(new f.BooleanFilter('isActive')),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper('customAttr1'),
                new f.FilterDescription(new f.TextFilter('customAttr1')),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper('customAttr2'),
                new f.FilterDescription(new f.TextFilter('customAttr2')),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper('customAttr3'),
                new f.FilterDescription(new f.TextFilter('customAttr3')),
                {label: 'Custom Attribute 3', onlyForm: true}
            ),
            new TableFieldDescription(
                'value',
                new DataMapper('value'),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Value', onlyForm: true}
            ),
            new TableFieldDescription(
                'loanStart',
                new DataMapper('loanStart'),
                new f.FilterDescription(new f.TextFilter('loanStart')),
                {label: 'Loan Start', onlyForm: true}
            ),
            new TableFieldDescription(
                'loanEnd',
                new DataMapper('loanEnd'),
                new f.FilterDescription(new f.TextFilter('loanEnd')),
                {label: 'Loan End', onlyForm: true}
            ),
            new TableFieldDescription(
                'acquisitionDate',
                new DataMapper('acquisitionDate'),
                new f.FilterDescription(new f.TextFilter('acquisitionDate')),
                {label: 'Acquisition Date', onlyForm: true}
            ),
            new TableFieldDescription(
                'downloadFile',
                new DataMapper("loss"),
                new f.FilterDescription(new f.NullCreator()),
                // eslint-disable-next-line eqeqeq
                {label: 'Loss File', hideSort:true, transform: new DataTransformer(({loss}) => loss && loss.file ? <DownloadFile url={"/report/download/loss/"} id={loss.id}/> : null)}
            ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(DevicesTableCreator)

export { 
    DevicesTableCreator,
    devicesQueries
};