import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import DestinationConverterFormCreator from '../../../tableDefinitions/Billing/Converters/DestinationConverterFormCreator'
import { DestinationConverterTableCreator } from '../../../tableDefinitions/Billing/Converters/DestinationConverterTableCreator'

const Destination = (props) => {
    const { t } = useTranslation('billManagement')
    document.title = `${t('Destinations Converter')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Destination Converters'}
            tableCreatorCreatorId={DestinationConverterTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={DestinationConverterFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Destination