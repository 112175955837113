/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'

import { withTranslation } from 'react-i18next'
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { Dialog, DialogContent, DialogTitle, Input, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'


import SetupTableData from '../TableWrapper/SetupTableData'

import { TablePickerClickStrategy as clickStrategy, TablePickerDisplayStrategy as displayStrategy } from '../../TableMapperCreator'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuprops = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        },
    },
};

const TablePicker = (props) => {
    const [tableOpen, setTableOpen] = useState(false);
    const [tableCreator, setTableCreator] = useState(props.tableCreator);


    useEffect(() => {
        //this.tableCreator = this.props.tableCreator
        
        // importe TablePickerStrategy como strategy
        /*this.tableCreator.onClickStrategy = new clickStrategy (
            //  TablePickerStrategy acepta una funcion (la del onChangeHandler y la de cerrar el popup)
            data => {
                const displayData = this.props.transformer.transform(data)
                this.props.onChangeHandler(this.props.inputId, data.id, displayData);
                this.handleCloseTable();
            }
        )*/
        tableCreator.onClickStrategy = new clickStrategy (
            //  TablePickerStrategy acepta una funcion (la del onChangeHandler y la de cerrar el popup)
            data => {
                const displayData = props.transformer.transform(data)
                props.onChangeHandler(props.inputId, data.id, displayData);
                handleCloseTable();
            }
        )

        tableCreator.displayStrategy = new displayStrategy();
    }) 

    const handleOpenTable = () => {
        //this.setState({tableOpen : true})
        setTableOpen(true);
    }

    const handleCloseTable = () => {
        //this.setState({tableOpen : false})
        setTableOpen(false);
    }

    const clearOption = (inputId) => {
        //this.props.onChangeHandler(this.props.inputId, null, '');
        props.onChangeHandler(props.inputId, null, '');
    }


      const { classes,
              label,
              id,
              t,
              required,
              formState,
              inputId,
            } = props;
        


    //const { tableOpen, } = this.state;
    const inputLabel = required ? required(t(label)) : t(label);

    //const tableCreator = this.tableCreator || this.props.tableCreator;
    const error =  Boolean(formState.errors[id]);

    return (

        <>

            <FormControl  className={classes.formControl} >
                <InputLabel htmlFor={label}>{inputLabel}</InputLabel>
                <Select
                    value={formState.mappedData[inputId] ||  ''}
                    error={error ? true : false}
                    IconComponent={SearchIcon}
                    //onOpen={this.handleOpenTable}
                    onOpen={handleOpenTable}
                    style={{width:250}}
                    open={false}
                    menuprops={menuprops}
                    className={classes.input}
                    id={id}
                    input={<Input />}
                >
                    <MenuItem key={''} value={''} className={classes.input}>
                            -
                    </MenuItem>
                    <MenuItem key={formState.mappedData[inputId]} value={formState.mappedData[inputId]} className={classes.input}>
                        { formState.displayData[inputId] }
                    </MenuItem>
                </Select>
            </FormControl>
            <Dialog
                open={tableOpen}
                maxWidth='xl'
                scroll="paper"
                //onClose={this.handleCloseTable}
                onClose={handleCloseTable}
                id={'pickerDialog'+ label }
                >
                    <DialogTitle id="scroll-dialog-title" style={{fontSize:15}}>
                        <span style={{fontSize:25}}>  {label}  </span>
                        {/*<Button style={{postion:"relative",float:"right"}} onClick={ () =>{this.handleCloseTable(); this.clearOption(inputId) }}  > Clear <DeleteIcon  /></Button>*/} 
                        <Button style={{postion:"relative",float:"right"}} onClick={ () =>{handleCloseTable(); clearOption(inputId) }}  > Clear <DeleteIcon  /></Button> 
                    </DialogTitle>    
                    <DialogContent>
                        <SetupTableData
                            style={{ height: '70vh', margin: 'auto' }}
                            key={label}
                            dialogId={'pickerDialog'+ label }
                            TableCreator={tableCreator}
                            stickyHeader
                        />
                    </DialogContent>
            </Dialog>
        </>
    )
}

export default withTranslation('fieldLabels')(TablePicker);