const isDeepEqual = (object1, object2) => {
    if (typeof object1 !== typeof object2) {
        return false;
    }

    if (object1 === null || object2 === null) {
        return object1 === object2;
    }

    if (typeof object1 !== 'object' || typeof object2 !== 'object') {
        return object1 === object2;
    }

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (!isDeepEqual(object1[key], object2[key])) {
            return false;
        }
    }

    return true;
}

export default isDeepEqual;