import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import DeviceMinimalTableCreatorCreator from '../../../tableDefinitions/Inventory/Devices/DeviceMinimalTableCreator'

export default class DeviceMinimalTable {
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.model?.name, label:"Model"},
            {mapper:data=> data.model?.brand?.name, label:"Brand"},
            {mapper:data=> data.serialNumber, label:"Serial Number"},
            {mapper:data=> data.model?.serviceType?.name, label:"Service Type"},
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(DeviceMinimalTableCreatorCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Device')
    }
}