/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'

import { gql, useApolloClient } from '@apollo/client'
import { Typography, MenuItem, Input, Select, TextField, InputLabel, Button, FormControl, Switch, FormControlLabel } from '@material-ui/core'

const MUTATION = gql`
    mutation createConceptMutation($name: String!, $technology: String, $unit: String, $isTax: Boolean, $trafficClass:String) {
        createConcept(name: $name, technology: $technology, unit: $unit, isTax: $isTax,trafficClass:$trafficClass) {
            concept {
                id
            }
        }
    }
`

export const ConceptCreationQuery = gql`
    query {
        allUnits {
            edges {
                node {
                    id
                    name
                }
            }
        }
        allTechnologies {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

export const ChargeDescriptionsAssignation = gql `
    mutation($objects: [String!], $invoice: String!) {
        assignChargeDescriptions(objects: $objects, invoice: $invoice) {
            response
        }
    }
`

export function ConceptCreationForm(props) {
    const client  = useApolloClient()
    const [name, setName] = useState("");
    const [isTax, setIsTax] = useState(false);
    const [unit, setUnit] = useState(null);
    const [technology, setTechnology] = useState(null);
    const [trafficClass, setTrafficClass] = useState("");

    const currentUnits = props.data.allUnits.edges;
    const currentTechnologies = props.data.allTechnologies.edges;
    const nameError = false;
    const unitError = false;
    const technologyError = false;

    const handleCreate = () => {
        client.mutate({
            variables : {name, unit, technology, isTax, trafficClass},
            mutation: MUTATION
        }).then(r => {
            props.refetch();
        }).then(r => {
            props.close()
        }).catch(err => console.log(err))
    }
    return (
        <div style={{padding:20}}>
            <Typography variant="h6">Concept Creation</Typography>
                <TextField  label={"Name"}
                            value={name}
                            onChange = {event =>  setName(event.target.value)}
                            style={{width:"100%", fontSize:15, textAlign:"left"}}
                />
                <FormControl style={{width:"100%"}}>
                    <InputLabel htmlFor={"unit"}>Unit</InputLabel>
                    <Select value={unit ||  ""}
                            onChange={ event => setUnit(event.target.value)}
                            style={{width:"100%", fontSize:15, textAlign:"left"}}
                            input={<Input id="unit" />}
                    >
                        <MenuItem key={''} value={null} >
                                -
                        </MenuItem>
                        {currentUnits &&
                        currentUnits.map(item => (
                                <MenuItem key={item.node.id} value={item.node.id}>
                                    {item.node.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl style={{width:"100%"}}>
                    <InputLabel htmlFor={"technology"}>Technology</InputLabel>
                    <Select value={technology ||  ""}
                            onChange={ event => setTechnology(event.target.value)}
                            style={{width:"100%", fontSize:15, textAlign:"left"}}
                            input={<Input id="technology"/>}
                    >
                        <MenuItem key={''} value={null} >
                                -
                        </MenuItem>
                        {currentTechnologies &&
                        currentTechnologies.map(item => (
                                <MenuItem key={item.node.id} value={item.node.id}>
                                    {item.node.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl style={{width:"100%"}}>
                    <InputLabel htmlFor={"trafficClass"}>Traffic Class</InputLabel>
                    <Select value={trafficClass ||  ""}
                            onChange={ event => setTrafficClass(event.target.value)}
                            style={{width:"100%", fontSize:15, textAlign:"left"}}
                            input={<Input id="trafficClass" />}
                    >
                        <MenuItem key={''} value={null} >
                                -
                        </MenuItem>
                        <MenuItem key={'Domestic'} value={"Domestic"} >
                                Domestic
                        </MenuItem>
                        <MenuItem key={'Roaming'} value={"Roaming"} >
                                Roaming
                        </MenuItem>
                        
                    </Select>
                </FormControl>

                <FormControl style={{width:"100%"}}>
                  <FormControlLabel
                        control={<Switch checked={Boolean(isTax)}
                                         onChange={event =>  setIsTax(event.target.checked)}
                                />}
                        style={{margin:0,width:"100%", height:56}}
                        label={<Typography>Is Tax</Typography>}
                  />

                </FormControl>
                <Button size="small"
                        color="primary"
                        style={{width:"100%", marginTop:5}}
                        onClick={handleCreate}
                        disabled={name == "" || unit == null || technology == null}
                        variant='contained'>
                    Create
                </Button>
        </div>
    )
}
