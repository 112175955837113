import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import GlAccountMinimalTableCreator from '../../tableDefinitions/GlAccount/GlAccountMinimalTableCreator'

export default class GlAccountMinimalTable{
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.name, label:"Name"},
            {mapper:data=>data.type, label:"Type"},
            {mapper:data=>data.parent?.displayName? data.parent.displayName : null, label:"Parent"},
            {mapper:data=>data.manager?.enterpriseId? data.manager.enterpriseId : null, label:"Manager"},
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(GlAccountMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Gl Account')
    }
}