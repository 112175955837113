import React from 'react'

import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { AddBPMApplicationMessages, RemoveBPMApplicationMessages } from '../../../../redux/reducers/BPMReducer'

const SnackMessage = props => {
    const { snackMessages, RemoveBPMApplicationMessages } = props;
    const history = useHistory();

    const onClickAlert = (message) => {
        if (message.clckRedirect) {
            RemoveBPMApplicationMessages(message.id)
            history.push(message.clckRedirect)
        }
    }

    return (
        <div>
            {snackMessages.map((message, index) => (
                <Snackbar
                    key={index}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={true}
                    autoHideDuration={message.duration || 3000}
                    onClose={() => RemoveBPMApplicationMessages(message.id)}
                    style={ message.clckRedirect ? {cursor: 'pointer'} : message.snakBarCursor }
                >
                    <Alert onClick={() => onClickAlert(message)} variant='filled' severity={message.variant}>
                        {message.text}
                    </Alert>
                </Snackbar>
            ))}
        </div>
    )
}

const mapStateToProps = (state) => ({
    snackMessages: state.applicationSnackMessages,
});

const mapDispatchToProps = {
    AddBPMApplicationMessages,
    RemoveBPMApplicationMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackMessage);