/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React from 'react'

import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import OptimizationTableCreator from '../../tableDefinitions/optimization/OptimizationTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'


export default function OptimizationTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(OptimizationTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('Optimization')

    return (
        <Table
            TableCreator={tableCreator}
        />
    )
}


