import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { primaryColor } from '../../utils/constants'

const useStyles = makeStyles({
    root: {
        color: '#ffffff',
        backgroundColor: primaryColor,
        '&:hover': {
            backgroundColor: primaryColor,
        },
        '&:active': {
            backgroundColor: primaryColor,
        }
    },
    label: {
        textTransform: 'none'
    }
})

const CustomPrimaryButton = (props) => {
    const classes = useStyles()

    return (
        <Button classes={{ root: classes.root, label: classes.label }} variant='contained' {...props}>
            {props.label}
        </Button>
    )
}

export default CustomPrimaryButton