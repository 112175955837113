import React, {useState} from 'react'
import { Box, Divider, Typography, TextField } from '@material-ui/core';
import NumericTextField from '../../../components/Inputs/NumericTextField';
import { gql, useMutation } from '@apollo/client'
import { withTranslation } from 'react-i18next';
import CustomPrimaryButton from '../../../components/CustomPrimaryButton/CustomPrimaryButton'

import { useDispatch } from 'react-redux'
import { AddBPMApplicationMessages } from '../../../redux/reducers/BPMReducer';

const CLOSE_PAYMENT = gql`
  mutation ClosePayment($id:String ,
                        $value:Float,
                        $couponIdentifier:String,
                        $date:Date,){
    closePayment(
            id: $id,
            couponIdentifier:$couponIdentifier,
            date:$date,
            value:$value, 
            ){
         
                invoicePayment {
                    id
                  }
    }
  }
`;


function InvoiceClosePaymentePopUp(props) {
    const [value, setValue] = useState(props.rowData.invoiceTotalCost)
    const [cuponIdentifier, setCuponId] = useState("")
    const [date , setDate ] = useState(new Date().toISOString().slice(0, 10))
    const [closePayment] = useMutation(CLOSE_PAYMENT)
    const [showError, setError] = useState(false)

    const { t } = props

    const dispatch = useDispatch()

    const createMessageSnack = (message, variant) => {
        dispatch(AddBPMApplicationMessages({
            text: message,
            variant: variant,
        }))
    }

    const handleSave = (e) => {
        closePayment({variables:{id: props.rowData.id, value: parseFloat(value.replace(',', '')), couponIdentifier: cuponIdentifier, date: date}})
        .then(response =>{
            createMessageSnack(t("Payment Has been closed"), "success")
            setTimeout(function() {
                window.location.reload()
            }, 1200);
        })
        .catch(error => {
            createMessageSnack(JSON.stringify(error), "error")
            if(!value || !date || !cuponIdentifier){
               setError(true)
            }
        });
    }
    
    const handleChangeCuponId = (e) =>{
        e.stopPropagation();
        setCuponId(e.target.value)
    }

    const handleChangeDate = (e) =>{
        e.stopPropagation();
        setDate(new Date(e.target.value).toISOString().slice(0, 10))
    }

    const handleChangeValue = (e) =>{
        setValue(e.target.value);
    }

    return(
        <Box
            component="form"
            sx={{
            '& > :not(style)': { m: 1, width: '500' },
            }}
            noValidate
            autoComplete="off"
        >
            <span onClick={props.handleClose} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>
            <center>
                <Typography style={{margin:"4%"}} variant="h4">{t("Close Payment")}</Typography>
                <Divider />
            </center>
            <TextField error={!cuponIdentifier} style={{margin:"4%"}} onChange={handleChangeCuponId} label={t("Cupon Identifier")} />
            <NumericTextField error={!value} style={{margin:"4%"}} onChange={handleChangeValue} label={t("Value")} value={value} />
            <TextField error={!date} style={{margin:"4%"}} onChange={handleChangeDate} type="date" label={t("Date")} value={date} />
                        
            <div>
                <CustomPrimaryButton
                disabled={!cuponIdentifier||!value||!date}
                style={{bottom:"4%",right:"4%",position:"absolute"}}
                color="primary" 
                variant="contained"
                onClick={event => handleSave(event)}
                label={t("Close Payment")} />   
            </div>
 
        </Box>
    )

}

export default withTranslation("InvoicePaymentPopUp")(InvoiceClosePaymentePopUp)