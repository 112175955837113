import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { devicesQueries } from './DevicesTableCreator'
import DeviceModelMinimalTable from '../../../pages/Inventory/Devices/DeviceModelMinimalTable'

const allDeviceSerialNumberTypes = `
    allDeviceSerialNumberTypes {
        edges {
            node {
                id
                name
            }
        }
    }
`

const allDeviceLossTypes = `
    allDeviceLossTypes {
        edges {
            node {
                id
                name
            }
        }
    }
`

const allDomain = `
    availableDomains {
        name
        id
    }
`

class DevicesFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "DevicesFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Device', devicesQueries)
        //New/Edit Form Sections
        const generalSection = new FormSection('General Seccion', 1)
        const modelSection = new FormSection('Model', 2)

        const loanSection = new FormSection('Loan', 3)
        const lossSection = new FormSection('Loss', 4)

        formCreator.addInputs([
            new FormFieldDescription(
                'model',
                new f.InputDescription(new f.ModelCreator('model', new DeviceModelMinimalTable()), false),
                modelSection,
                1,
                {label: 'Model', transform: new DataTransformer((data) => data, data=>data)}
            ),
            new FormFieldDescription(
                'domain',
                new f.InputDescription(new f.DropDownCreator('domain'), false),
                generalSection,
                1,
                {label: 'Domain', queryAllDropdownOptions: allDomain, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'description',
                new f.InputDescription(new f.TextAreaCreator('description'), false),
                generalSection,
                7,
                {label: 'Description'}
            ),
            new FormFieldDescription(
                'serialNumber',
                new f.InputDescription(new f.TextCreator('serialNumber'), true, new f.RequiredValidator(1)),
                generalSection,
                4,
                {label: 'Serial Number'}
            ),
            new FormFieldDescription(
                'serialNumberType',
                new f.InputDescription(new f.DropDownCreator('serialNumberType'), false),
                generalSection,
                5,
                {label: 'Serial Number Type', queryAllDropdownOptions: allDeviceSerialNumberTypes, transform: new DataTransformer(data => data.name), prepareData: data=>parseInt(data)}
            ),
            new FormFieldDescription(
                'isActive',
                new f.InputDescription(new f.BoolCreator('isActive', false), false),
                generalSection,
                6,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'acquisitionDate',
                new f.InputDescription(new f.DateCreator('acquisitionDate'), false),
                generalSection,
                8,
                {label: 'Acquisition Date'}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                9,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                10,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                11,
                {label: 'Custom Attribute 3'}
            ),
            new FormFieldDescription(
                'loanStart',
                new f.InputDescription(new f.DateCreator('loanStart'), false),
                loanSection,
                1,
                {label: 'Loan Start'}
            ),
            new FormFieldDescription(
                'loanEnd',
                new f.InputDescription(new f.DateCreator('loanEnd'), false),
                loanSection,
                2,
                {label: 'Loan End'}
            ),
            new FormFieldDescription(
                'value',
                new f.InputDescription(new f.TextCreator('value'), false),
                loanSection,
                3,
                {label: 'Value'}
            ),
            new FormFieldDescription(
                'lossType',
                new f.InputDescription(new f.DropDownCreator('lossType'), false),
                lossSection,
                1,
                {label: 'Type', queryAllDropdownOptions: allDeviceLossTypes, transform: new DataTransformer(data => data.name), dataMapper: (data => data.loss ? data.loss.type.id : null)}
            ),
            new FormFieldDescription(
                'lossDate',
                new f.InputDescription(new f.DateCreator('lossDate'), false),
                lossSection,
                2,
                {label: 'Date', dataMapper: (data => data.loss ? data.loss.date : null)}
            ),
            new FormFieldDescription(
                'lossDescription',
                new f.InputDescription(new f.TextAreaCreator('lossDescription'), false),
                lossSection,
                3,
                {label: 'Description', dataMapper: (data => data.loss ? data.loss.description : null)}
            ),
            new FormFieldDescription(
                'lossFile',
                new f.InputDescription(new f.UploadCreator('lossFile',"/report/download/loss/"), false),
                lossSection,
                4,
                {label: 'Description', dataMapper: (data => data.loss ? {downloadId:data.loss.id, file: data.loss.file}  : null)}
            ),
        ]);

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(DevicesFormCreator)

export default DevicesFormCreator;