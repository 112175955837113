import React from 'react'
import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import InventorySitesFormCreator from '../../../tableDefinitions/Inventory/Sites/InventorySitesFormCreator'
import { InventorySitesTableCreator } from '../../../tableDefinitions/Inventory/Sites/InventorySitesTableCreator'

const Sites = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Sites')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Sites'}
            tableCreatorCreatorId={InventorySitesTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={InventorySitesFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Sites