import React from 'react'

import { gql } from "@apollo/client"

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper,
    DataTransformer,
    DefaultComponentCreator,
    GeneralChipComponentCreator,
    ManagerExcelDisplayCreator,
    NullClickStrategy,  
    NumberComponent, 
    NumberComponentCreator, 
    OnlyFilterActionDisplayStrategy, 
    TableFieldDescription
} from '../../components/BPMTable/TableMapperCreator'
import PopUpComponentCreator from '../../components/BPMTable/TableComponents/CellComponents/PopupCellComponent'
import PopupContent from '../../pages/MyTeam/PopupContent'
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query MyTeamCostCenterReport(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,  
        $period:String!,
        $name:String,
        $parentEnterpriseId:String,
        $parentName:String,
        $enterpriseId:String,
        $businessUnit:String
        $manager:String,
        $managerFirstName:String,
        $managerLastName:String,
        $currency:String,
    ) {
        myTeamCostCenterReport(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            period:$period,
            parentEnterpriseId: $parentEnterpriseId,
            parentName: $parentName,
            enterpriseId: $enterpriseId,
            name:$name,
            businessUnit:$businessUnit
            managerLastName:$managerLastName,
            managerFirstName:$managerFirstName,
            manager:$manager,
        ) {
            edges {
                node {
                    costCenter {
                        id
                        businessUnit
                        displayName 
                        parent {
                            enterpriseId
                            displayName
                        }
                        enterpriseId
                    }
                    managers {
                        edges {
                            node {
                                id
                                firstName
                                enterpriseId
                                lastName
                                email
                            }
                        }
                    }
                    costs(currency:$currency) {
                        cost
                        cost2
                        cost3
                        average
                    }
                }
            }
            costs(currency:$currency) {
                cost
                cost2
                cost3
                average
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

export const queries = {tableQuery}

class MyTeamCostCentersTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "MyTeamCostCentersTableCreator"
    }

    getTableCreator = (tableName) => {
        const PeriodCells = (props) => {
            return( <NumberComponent value={props.data} style={{textAlign:"right"}} currencySymbol={this.parentProps.symbol}/>)
        }

        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);
        tableCreator.setDescriptions([
            new TableFieldDescription(
                'parentEnterpriseId',
                new DataMapper("costCenter.parent.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("parentEnterpriseId")),
                {label: 'Parent Enterprise Id', onlyForm:true}
            ),
            new TableFieldDescription(
                'parentName',
                new DataMapper("costCenter.parent.displayName"),
                new f.FilterDescription(new f.TextFilter("parentName")),
                {label: 'Parent Name', onlyForm:true}
            ),
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("costCenter.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'Enterprise Id',
                excelTransform: new DataTransformer(({costCenter}) => costCenter ? costCenter.enterpriseId : null)}
            ),
            new TableFieldDescription(
                'costCenter',
                new DataMapper("costCenter.displayName"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Cost Center',
                excelTransform: new DataTransformer(({costCenter}) => costCenter ? costCenter.displayName : null)}
            ),
            new TableFieldDescription(
                'managers',
                new DataMapper("managers"),
                new f.FilterDescription(new f.TextFilter("manager")),
                {label: 'Manager', 
                componentCreator: new GeneralChipComponentCreator(["enterpriseId","firstName","lastName"], ["enterpriseId","email","firstName","lastName"], null, false), 
                transform: new DataTransformer(({managers})=>(managers.edges.map(data => data.node))), 
                excelDisplay : new ManagerExcelDisplayCreator()}
            ),     
            new TableFieldDescription(
                'businessUnit',
                new DataMapper("costCenter.businessUnit"),
                new f.FilterDescription(new f.TextFilter("businessUnit")),
                {label: 'Business Unit'}
            ),
            new TableFieldDescription(
                'period-2',
                new DataMapper("costs.cost3", "costCenter"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per2, 
                    translateLabel: false, 
                    componentCreator: new PopUpComponentCreator(PeriodCells, PopupContent, {periodString: this.parentProps.per2, symbol: this.parentProps.symbol, period: this.parentProps.periods.period2, currency: this.parentProps.currencyCode, t: this.parentProps.t}),
                    transform: new DataTransformer(({cost3, costCenter})=> ({value: cost3, popupData: {costCenter: costCenter}})),
                }
            ),
            new TableFieldDescription(
                'period-1',
                new DataMapper("costs.cost2", "costCenter"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per1, 
                    translateLabel: false, 
                    componentCreator: new PopUpComponentCreator(PeriodCells, PopupContent, {periodString: this.parentProps.per1, period: this.parentProps.periods.period1, symbol: this.parentProps.symbol, currency: this.parentProps.currencyCode, t: this.parentProps.t}),
                    transform: new DataTransformer(({cost2, costCenter})=> ({value: cost2, popupData: {costCenter: costCenter}})),
                }
            ),
            new TableFieldDescription(
                'period',
                new DataMapper("costs.cost", "costCenter"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per, 
                    translateLabel: false, 
                    componentCreator: new PopUpComponentCreator(PeriodCells, PopupContent, {periodString: this.parentProps.per, symbol: this.parentProps.symbol, period: this.parentProps.periods.period0, currency: this.parentProps.currencyCode, t: this.parentProps.t}),
                    transform: new DataTransformer(({cost, costCenter})=> ({value: cost, popupData: {costCenter: costCenter}})),
                }
            ),
            new TableFieldDescription(
                'average',
                new DataMapper("costs.average"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Average', componentCreator:new NumberComponentCreator(this.parentProps.symbol) }
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(MyTeamCostCentersTableCreator)

export default MyTeamCostCentersTableCreator;
