import React from 'react'
import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'

import { InventoryUserTableCreator } from '../../../tableDefinitions/Inventory/User/InventoryUserTableCreator'
import InventoryUserFormCreator from '../../../tableDefinitions/Inventory/User/InventoryUserFormCreator'

const User = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Users (Employees)')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Users'}
            tableCreatorCreatorId={InventoryUserTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={InventoryUserFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )

}

export default User