import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import AvailableChargeGroupsMinimalTableCreator from '../../../tableDefinitions/Billing/CharGroupUssage/AvailableChargeGroupsTablePickerTableCreato'

export default class AvailableChargeGroupsMinimalTable {
    constructor(filters) {
        this.filters = filters
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(AvailableChargeGroupsMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Available Charge Groups')
    }
}
