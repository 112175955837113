import AvailableChargeGroupsMinimalTable from '../../../pages/Billing/CharGroupUssage/AvailableChargeGroupsTablePicker'
import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import { ChargeGroupUssageTableCreatorQueries } from './ChargeGroupUssageTableCreator'


const chargeGroups = `
    unassignedUssageChargeGroups{
            edges{
                node{
                    id
                    name
            }
        }
    }
`

const options = ['SMS', 'Voice', 'Internet']

const AvailableChargeGroupsTablePicker = new AvailableChargeGroupsMinimalTable({first:30})

class ChargeGroupUssageFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "ChargeGroupUssageFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Charge Group Ussage', ChargeGroupUssageTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs( [
            new FormFieldDescription(
                'chargeGroup',
                new f.InputDescription(new f.TablePickerCreator('chargeGroup',AvailableChargeGroupsTablePicker.getTableCreator(), new DataTransformer((data) => data.name)), true),
                generalSection,
                1,
                {label: 'Charge Group', queryAllDropdownOptions: chargeGroups, transform: new DataTransformer(data=> data.name)}
            ),
            new FormFieldDescription(
                'ussageType',
                new f.InputDescription(new f.OptionsDropDownCreator('ussage', options), true, true),
                generalSection,
                2,
                {label: 'Ussage'}
            ),
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(ChargeGroupUssageFormCreator)

export default ChargeGroupUssageFormCreator