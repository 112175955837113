/* eslint-disable no-unused-vars */
import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import { InventoryCostCenterTableCreator } from '../../../tableDefinitions/Inventory/CostCenter/InventoryCostCenterTableCreator'
import InventoryCostCenterFormCreator from '../../../tableDefinitions/Inventory/CostCenter/InventoryCostCenterFormCreator'

const CostCenter = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Cost Centers')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Cost Centers'}
            tableCreatorCreatorId={InventoryCostCenterTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={InventoryCostCenterFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default CostCenter