import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { InventorySitesTableCreatorQueries } from './InventorySitesTableCreator'

const countryQuery = `
    allCountries {
        edges {
            node {
                id
                name
            }
        }
    }
`

const allDomain = `
    availableDomains {
        id
        name
    }
`

class InventorySitesFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "InventorySitesFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Sites', InventorySitesTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'enterpriseId',
                new f.InputDescription(new f.TextCreator('enterpriseId'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Enterprise Id'}
        ),
        new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'Name'}
        ),
        new FormFieldDescription(
                'address',
                new f.InputDescription(new f.TextCreator('address'), true, new f.RequiredValidator(1)),
                generalSection,
                3,
                {label: 'Address'}
        ),
        new FormFieldDescription(
                'postalCode',
                new f.InputDescription(new f.TextCreator('postalCode'), true, new f.RequiredValidator(1)),
                generalSection,
                4,
                {label: 'Postal Code'}
        ),
        new FormFieldDescription(
                'state',
                new f.InputDescription(new f.TextCreator('state'), true, new f.RequiredValidator(1)),
                generalSection,
                5,
                {label: 'State'}
        ),
        new FormFieldDescription(
                'country',
                new f.InputDescription(new f.DropDownCreator('country'), true, new f.RequiredValidator(1)),
                generalSection,
                6,
                {label: 'Country', transform: new DataTransformer(data => data.name), queryAllDropdownOptions: countryQuery}
        ),
        new FormFieldDescription(
                'isActive',
                new f.InputDescription(new f.BoolCreator('isActive', true), false),
                generalSection,
                7,
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
        ),
        new FormFieldDescription(
                'type',
                new f.InputDescription(new f.TextCreator('type'), true, new f.RequiredValidator(1)),
                generalSection,
                8,
                {label: 'Type'}
        ),
        new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                9,
                {label: 'Custom Attribute 1'}
        ),
        new FormFieldDescription(
                'domain',
                new f.InputDescription(new f.DropDownCreator('domain'), true, new f.RequiredValidator(1)),
                generalSection,
                10,
                {label: 'Domain', queryAllDropdownOptions: allDomain, transform: new DataTransformer(data => data.name)}
        ),
        
        new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                10,
                {label: 'Custom Attribute 2'}
        ),
        new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                11,
                {label: 'Custom Attribute 3'}
        )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(InventorySitesFormCreator)

export default InventorySitesFormCreator