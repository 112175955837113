import React from 'react'
import { useDispatch } from "react-redux";
import { useApolloClient } from '@apollo/client'

import * as reducers from '../../../../redux/reducers/BPMReducer';
import * as bpmSelectors from '../../../../redux/selectors';

function TableObjectCreator(TableCreator) {
    const client = useApolloClient()
    const dispatch = useDispatch()

    let filters = {...TableCreator.initialFilters};

    if (TableCreator.initialSortFieldName) {
        filters['orderField'] = (TableCreator.initialSortFieldDirection == 'asc' ? "": "-") + TableCreator.initialSortFieldName;
    }

    const tableSteate = {
        count: 0,
        tableName: TableCreator.tableName,
        filters: {
            filters: filters,
            orderField: TableCreator.initialSortFieldName,
            orderDirection: TableCreator.initialSortFieldDirection
        },
        selectAllState: {
            selectAll: false, 
            selectedRows: [], 
            selectedRefs: []
        }
    }
    dispatch(reducers.addBPMTable(tableSteate))

    const tableStatesSelector = bpmSelectors.useTableStates(TableCreator.tableName)
    const tableCountSelector = bpmSelectors.useTableCount(TableCreator.tableName)
    const tableFiltersSelector = bpmSelectors.useTableFilters(TableCreator.tableName)
    const tableOrderFieldSelector = bpmSelectors.useTableOrderField(TableCreator.tableName)
    const tableOrderDirectionSelector = bpmSelectors.useTableOrderDirection(TableCreator.tableName)
    const tableSlectAllStateSelector = bpmSelectors.useTableSelectAllState(TableCreator.tableName)
    const tableSelectedRowsSelector = bpmSelectors.useTableSelectedRows(TableCreator.tableName)
    const tableSelectedRefsSelector = bpmSelectors.useTableSelectedRefs(TableCreator.tableName)
    const tableColumnsSelector = bpmSelectors.useTableColumns(TableCreator.tableName)

    const currentUserSelector = bpmSelectors.useCurrentUser()
    const currentUserCompanySelector = bpmSelectors.useCurrentUserCompany()
    const currentUserMembershipSetSelector = bpmSelectors.useCurrentUserMembershipSet()

    const selectors = {
        'tableStatesSelector': tableStatesSelector,
        'tableCountSelector': tableCountSelector,
        'tableFiltersSelector': tableFiltersSelector, 
        'tableOrderFieldSelector': tableOrderFieldSelector,
        'tableOrderDirectionSelector': tableOrderDirectionSelector,
        'tableSlectAllStateSelector': tableSlectAllStateSelector,
        'tableSelectedRowsSelector': tableSelectedRowsSelector,
        'tableSelectedRefsSelector': tableSelectedRefsSelector,
        'tableColumnsSelector': tableColumnsSelector,
        'currentUserSelector': currentUserSelector,
        'currentUserCompanySelector': currentUserCompanySelector,
        'currentUserMembershipSetSelector': currentUserMembershipSetSelector,
    }

    return TableCreator.create(
        client,
        dispatch,
        reducers,
        selectors
    )
}

export default TableObjectCreator