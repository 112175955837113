import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription,
    FormSection
} from '../../../components/BPMTable/TableMapperCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import { ChargeGroupConceptQueries } from './ChargeGroupConceptTableCreator'

const allConcepts = `
    allConcepts {
        edges {
            node {
                id
                name
                technology {
                    id
                    name
                }
            }
        }
    }
`

const allChargeGroups = `
    allChargeGroup {
        edges {
            node {
                id
                name
            }
        }
    }
`

class ChargeGroupConceptFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "ChargeGroupConceptFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Charge Group Concept', ChargeGroupConceptQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'concept',
                new f.InputDescription(new f.DropDownCreator('concept'), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'Concept', queryAllDropdownOptions: allConcepts, transform: new DataTransformer(data => `${data.name} (${data.technology.name})`)}
            ),
            new FormFieldDescription(
                'chargeGroup',
                new f.InputDescription(new f.DropDownCreator('chargeGroup'), true, new f.RequiredValidator(1)),
                generalSection,
                3,
                {label: 'Charge Group', queryAllDropdownOptions: allChargeGroups, transform: new DataTransformer(data => data.name)}
            )
        ])
    
        return formCreator;
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(ChargeGroupConceptFormCreator)

export default ChargeGroupConceptFormCreator;