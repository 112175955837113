import React from 'react'

import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import HomeConsumptionsTableCreator from '../../tableDefinitions/Consumptions/HomeConsumptionsTableCreator'

export default function HomeConsumptionsTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(HomeConsumptionsTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator("My Consumptions")

    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        />
    )
}

