import React from 'react'

import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
  DataMapper,
  DataTransformer,
  NullClickStrategy,
  StatusComponentCreator,
  BooleanExcelDisplayCreator,
  NumberComponentCreator,
  OnlyFilterActionDisplayStrategy,
  TableFieldDescription,
} from '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from "../../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
  query InventoryByPeriodReport(
    $orderField:String,
    $first:Int,
    $last:Int,
    $after:String,
    $before:String,
    $period:String!, 
    $currency:String,
    $vendor:String, 
    $account:String,
    $serviceNumber:String
    $serviceType:String
    $status:Boolean
    $startDate:String
    $endDate:String
    $deviceSerial:String
    $model:String
    $brand:String
    $siteId:String
    $siteName:String
    $costCenter:String
    $invoiceDate:String
    $invoiceNumber:String
    $userEnterpriseId:String
    $userName:String
    ){
    inventoryByPeriodReport(
      orderField:$orderField,
      first:$first,
      last:$last,
      after:$after,
      before:$before,
      period:$period, 
      vendor:$vendor, 
      account:$account
      serviceNumber:$serviceNumber
      serviceType:$serviceType
      status:$status
      startDate:$startDate
      endDate:$endDate
      deviceSerial:$deviceSerial
      model:$model
      brand:$brand
      siteId:$siteId
      siteName:$siteName
      costCenter:$costCenter
      invoiceDate:$invoiceDate
      invoiceNumber:$invoiceNumber
      userEnterpriseId:$userEnterpriseId
      userName:$userName
      ) {
      edges{
        node {
          id
          user{
            firstName
            lastName
            enterpriseId
          }
          inventory{
            serviceNumber
            status
            startDate
            endDate
            serviceType{
              name
            }
          }
          invoice{
            id
            identifier
            date
            billingRequest{
              driver{
                vendor{
                  name
                }
              }
            }
            account{
              name
              identifier
            }
          }
          device {
            id
            serialNumber
            model {
              id
              name
              brand{
                name
              }
            }
          }
          site {
            enterpriseId
            name
          }
          costCenter{
            enterpriseId
            name
          }
          amount(currency:$currency)
        }
      }
      count
      pageInfo {
          hasNextPage
          startCursor
          endCursor
      }
    }
  }`

const queries = { tableQuery };

class IventoryReportTableCreator extends TableCreatorCreator {
  constructor(filters) {
    super(filters)
  }

  static getId = () => {
    return "IventoryReportTableCreator"
  }

  getTableCreator = (tableName) => {
    const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
    tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

    tableCreator.setDescriptions([
      new TableFieldDescription(
        'vendor',
        new DataMapper("invoice.billingRequest.driver.vendor.name"),
        new f.FilterDescription(new f.TextFilter("vendor")),
        { label: 'Vendor' }
      ),
      new TableFieldDescription(
        'account',
        new DataMapper("invoice.account.identifier"),
        new f.FilterDescription(new f.TextFilter("account")),
        { label: 'Account ID' }
      ),
      new TableFieldDescription(
        'serviceNumber',
        new DataMapper("inventory.serviceNumber"),
        new f.FilterDescription(new f.TextFilter("serviceNumber")),
        { label: 'Service Number' }
      ),
      new TableFieldDescription(
        'serviceType',
        new DataMapper("inventory.serviceType.name"),
        new f.FilterDescription(new f.TextFilter("serviceType")),
        { label: 'Service Type' }
      ),
      new TableFieldDescription(
        'status',
        new DataMapper("inventory.status"),
        new f.FilterDescription(new f.BooleanFilter('status')),
        {label: 'Service Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
      ),
      new TableFieldDescription(
        'startDate',
        new DataMapper("inventory.startDate"),
        new f.FilterDescription(new f.DateFilter("startDate")),
        { label: 'Start Date', onlyForm: true }
      ),
      new TableFieldDescription(
        'endDate',
        new DataMapper("inventory.endDate"),
        new f.FilterDescription(new f.DateFilter("endDate")),
        { label: 'End Date', onlyForm: true }
      ),
      new TableFieldDescription(
        'deviceSerial',
        new DataMapper("device.serialNumber"),
        new f.FilterDescription(new f.TextFilter("deviceSerial")),
        { label: 'Serial Number' }
      ),
      new TableFieldDescription(
        'model',
        new DataMapper("device.model.name"),
        new f.FilterDescription(new f.TextFilter("model")),
        { label: 'Model', onlyForm: true }
      ),
      new TableFieldDescription(
        'brand',
        new DataMapper("device.model.brand.name"),
        new f.FilterDescription(new f.TextFilter("brand")),
        { label: 'Brand', onlyForm: true }
      ),
      new TableFieldDescription(
        'siteId',
        new DataMapper("site.enterpriseId"),
        new f.FilterDescription(new f.TextFilter("siteId")),
        { label: 'Site ID' }
      ),
      new TableFieldDescription(
        'siteName',
        new DataMapper("site.name"),
        new f.FilterDescription(new f.TextFilter("siteName")),
        { label: 'Site Name' }
      ),
      new TableFieldDescription(
        'userEnterpriseId',
        new DataMapper("user.enterpriseId"),
        new f.FilterDescription(new f.TextFilter("userEnterpriseId")),
        { label: 'User ID' }
      ),
      new TableFieldDescription(
        'firstName',
        new DataMapper("user"),
        new f.FilterDescription(new f.TextFilter("userName")),
        { label: 'User Name', transform: new DataTransformer(({ user }) => user ? user.firstName + " " + user.lastName : null)}
      ),
      new TableFieldDescription(
        'costCenter',
        new DataMapper("costCenter.enterpriseId"),
        new f.FilterDescription(new f.TextFilter("costCenter")),
        { label: 'Cost Center' }
      ),
      new TableFieldDescription(
        'amount',
        new DataMapper("amount"),
        new f.FilterDescription(new f.NullCreator("amount")),
        { label: 'Amount', componentCreator:new NumberComponentCreator(this.parentProps.symbol, 2), excelTransform: new DataTransformer(({amount})=>Number(amount).toFixed(2))}
      ),
      new TableFieldDescription(
        'invoiceNumber',
        new DataMapper("invoice.identifier"),
        new f.FilterDescription(new f.TextFilter("invoiceNumber")),
        { label: 'Invoice Number', onlyForm: true }
      ),
      new TableFieldDescription(
        'invoiceDate',
        new DataMapper("invoice.date"),
        new f.FilterDescription(new f.DateFilter("invoiceDate")),
        { label: 'Invoice Date', onlyForm: true }
      ),
    ])

    return tableCreator;
  }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(IventoryReportTableCreator)

export default IventoryReportTableCreator;