let instance;

class TableCreatorCreatorFactoryCreator {
    constructor() {
        if (instance) {
            throw new Error("New instance of TableCreatorCreatorFactoryCreator cannot be created!!");
        }

        this.tableCreatorCreators = {}
        this.tableCreatorCreatorFactory = new TableCreatorCreatorFactory(this.tableCreatorCreators)
        instance = this;
    }

    addTableCreatorCreator = (tableCreatorCreator) => {
        this.tableCreatorCreators[tableCreatorCreator.getId()] = tableCreatorCreator
    }

    getTableCreatorCreatorFromIdentifier = (identifier, parameters) => {
        return this.tableCreatorCreatorFactory.getTableCreatorCreatorFromIdentifier(identifier, parameters)
    }
}

class TableCreatorCreatorFactory {
    constructor(tableCreatorCreators) {
        this.tableCreatorCreators = tableCreatorCreators
    }

    getTableCreatorCreatorFromIdentifier = (identifier, parameters) => {
        return new this.tableCreatorCreators[identifier](parameters)
    }
}

const tableCreatorCreatorFactoryCreator = Object.freeze(new TableCreatorCreatorFactoryCreator());

export default tableCreatorCreatorFactoryCreator;
