import React from 'react'

export const publicTranslations = {
    en: {
        userName: "Identifier",
        password: "Password",
        login: "Login",
        forgot: "Forgot your password?",
        reset: "Click here to reset it.",
        havingProblems: "Having problems logging in? contact info@bubblebpm.com",
        advertisement: "Do not attempt to login unless you are an authorized user. Unauthorized access is prohibited by law.",
        succesfulReset: "Reset Password Succesful",
        emailed: "We've emailed you instructions for setting your password. You should be receiving them shortly.",
        notRecieve: "If you don't receive an email, please make sure you've entered the address you registered with, and check your spam folder.",
        goToLogin: "Go to login",
        resetPassword: "Reset Password",
        forgotPassword: "Forgot your password? Enter your email address below, and we'll email instructions for setting a new one.",
        email: "Email",
        emailIsNotValid: "Email is not valid",
        resetMyPassword: "Reset my password",
        passwordRequirements: 'The password must',
        strLowercase: 'Have one lowercase letter',
        strUppercase: 'Have one uppercase letter',
        strDigit: 'Have one number',
        strSpecialCharacter: 'Have one special character',
        strMinLength: 'Have at least 8 Characters',
        //strNoSamePassword: 'Current and New password must be different',
        strPasswordMatch: 'New and repeat passwords match',
        errorPasswordValidation: 'The password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character',
    },
    es: {
        userName: "Email",
        password: "Contraseña",
        login: "Iniciar sesión",
        forgot: "¿Olvidaste tu contraseña?",
        reset: "Haz clic aquí para restablecerla.",
        havingProblems: "¿Problemas para iniciar sesión? Contacta a info@bubblebpm.com",
        advertisement: "Este es un sistema restringido. No intente iniciar sesión si no es un usuario autorizado.",
        succesfulReset: "Restablecimiento de contraseña exitoso",
        emailed: "Te hemos enviado instrucciones por correo electrónico para establecer tu contraseña. Deberías recibirlos en breve.",
        notRecieve: "Si no recibes un correo electrónico, asegúrate de haber ingresado la dirección con la que te registraste y revisa tu carpeta de correo no deseado.",
        goToLogin: "Ir al inicio de sesión",
        resetPassword: "Restablecer Contraseña",
        forgotPassword: "¿Olvidaste tu contraseña? Ingresa tu dirección de correo electrónico a continuación y te enviaremos instrucciones para establecer una nueva.",
        email: "Correo electrónico",
        emailIsNotValid: "El correo electrónico no es válido",
        resetMyPassword: "Restablecer mi contraseña",
        passwordRequirements: 'La contraseña debe',
        strLowercase: 'Tener una letra minúscula',
        strUppercase: 'Tener una letra mayúscula',
        strDigit: 'Tener un número',
        strSpecialCharacter: 'Tener un carácter especial',
        strMinLength: 'Tener al menos 8 caracteres',
        //strNoSamePassword: 'La contraseña actual y la nueva deben ser diferentes',
        strPasswordMatch: 'Las contraseñas nueva y de repetición coinciden',
        errorPasswordValidation: 'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una letra minúscula, un número y un carácter especial',
    },
    pt: {
        userName: "Email",
        password: "Senha",
        login: "Login",
        forgot: "Esqueceu sua senha?",
        reset: "Clique aqui para resetá-la.",
        havingProblems: "Problemas para fazer login? Contate info@bubblebpm.com",
        advertisement: "Este é um sistema restrito. Não tente fazer login se você não for um usuário autorizado.",
        succesfulReset: "Redefinição de senha bem-sucedida",
        emailed: "Enviamos instruções por e-mail para definir sua senha. Você deverá recebê-las em breve.",
        notRecieve: "Se você não receber um e-mail, verifique se inseriu o endereço com o qual se registrou e verifique sua pasta de spam.",
        goToLogin: "Ir para o login",
        resetPassword: "Redefinir Senha",
        forgotPassword: "Esqueceu sua senha? Insira seu endereço de e-mail abaixo e enviaremos instruções para definir uma nova.",
        email: "E-mail",
        emailIsNotValid: "O e-mail não é válido",
        resetMyPassword: "Redefinir minha senha",
        passwordRequirements: 'A senha deve',
        strLowercase: 'Ter uma letra minúscula',
        strUppercase: 'Ter uma letra maiúscula',
        strDigit: 'Ter um número',
        strSpecialCharacter: 'Ter um caractere especial',
        strMinLength: 'Ter pelo menos 8 caracteres',
        //strNoSamePassword: 'A senha atual e a nova devem ser diferentes',
        strPasswordMatch: 'As senhas nova e de repetição coincidem',
        errorPasswordValidation: 'A senha deve ter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial',
    }
};

export const getLanguageCaptions = () => {
    const userLanguage = navigator.language || navigator.userLanguage
    const languageCode = userLanguage.split('-')[0];
    return publicTranslations[languageCode] ? publicTranslations[languageCode] : publicTranslations.en;
};
