import React from 'react';
import { useTranslation } from 'react-i18next';


const TranslationProvider = ({ children }) => {
  const { t } = useTranslation();

  return children(t);
};

export default TranslationProvider