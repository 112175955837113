/* eslint-disable eqeqeq */
import * as f from '../../../components/BPMTable/BPMInputs'
import PeriodMinimalTable from '../../../pages/Invoice/Periods/periodMinimalTable'
import { 
    DataTransformer,
    FormFieldDescription,
    FormSection,
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { InvoiceInvoicePeriodsTableCreatorQueries } from './InvoiceInvoicePeriodsTableCreator'

const allPeriods = `
    allPeriods {
        edges {
            node {
                id
                startDate
                endDate
            }
        }
    }
`

class InvoiceInvoicePeriodsFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "InvoiceInvoicePeriodsFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Period', InvoiceInvoicePeriodsTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        const periodPicker = new PeriodMinimalTable({first: 15})
        formCreator.addInputs([
            new FormFieldDescription(
                'periodId',
                new f.InputDescription(new f.TablePickerCreator('periodId', periodPicker.getTableCreator(), new DataTransformer(data => data.startDate))),
                generalSection,
                1,
                {label: 'Period', transform: new DataTransformer(data => data.startDate), queryAllDropdownOptions: allPeriods}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(InvoiceInvoicePeriodsFormCreator)

export default InvoiceInvoicePeriodsFormCreator