import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query UnassignedUssageChargeGroups(
        $orderField:String,
        $first:Int,$last:Int,
        $after:String,
        $before:String,
        $name:String,
    ) {
        unassignedUssageChargeGroups(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            name:$name){
            edges{
              node{
                id
                name
              }
            }
          }
    }
`

const unitQuery = `
    chargeGroup(id: $id) {
        id
        name
    }
`
const queries = {tableQuery, unitQuery}

class AvailableChargeGroupsMinimalTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "AvailableChargeGroupsMinimalTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.setAdditionalFilters(this.filters)
        tableCreator.setDescriptions ([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(AvailableChargeGroupsMinimalTableCreator)

export default AvailableChargeGroupsMinimalTableCreator