import React from 'react'
import { useTranslation } from 'react-i18next';


export const TextTranslator = (props) => {
    const {t} = useTranslation();
    const text = props.ns ? props.ns + ":" + props.text : props.text

    return t(text)
}

export const FieldLabelsTranslator = (props) => {
    const {t} = useTranslation();
    return t("fieldLabels:" + props.text)
}

export const getTranslationEntry = (text, namespace) => {
    return (
        <StateTranslationEntry text={text} ns={namespace}/>
    )
}

export const getStateTranslationEntry = (text) => {
    return (
        <StateTranslationEntry text={text} ns={"stateFilter"}/>
    )
}

const StateTranslationEntry = (props) => {
    const {t} = useTranslation();
    return props.ns ? t(props.ns + ":" + props.text) : t(props.text)
}