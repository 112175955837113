const thousandSeparator = (n, digits=0) => {
    n = Number(n).toFixed(digits)
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const quantityTransformer = (n, precision=2) => {
    n = Number(n)
    if (n < 999) {
        return n.toFixed(precision)
    } else if(n > 999 && n < 99999) {
        return `${String((n / 1000).toFixed(precision))}K`
    } else if(n >= 100000 && n <= 999999) {
        return `${String((n / 1000).toFixed(precision))}K`
    } else {
        return `${String((n / 1000000).toFixed(precision))}M`
    }
}

const calculateTrendline = (dataPoints) => {
    let sumX = 0,
      sumY = 0,
      sumXY = 0,
      sumXX = 0,
      count = dataPoints.length;
  
    for (let i = 0; i < count; i++) {
      const x = i + 1;
      const y = dataPoints[i].y;
  
      sumX += x;
      sumY += y;
      sumXY += x * y;
      sumXX += x * x;
    }
  
    const m = (count * sumXY - sumX * sumY) / (count * sumXX - sumX * sumX);
    const b = (sumY - m * sumX) / count;
  
    const trendlinePoints = dataPoints.map((_, i) => ({
      x: i + 1,
      y: m * (i + 1) + b,
    }));
  
    return trendlinePoints;
};  

function NotImplementedError(message) {
    this.name = 'NotImplementedError'
    this.message = message || ''
}

NotImplementedError.prototype = Error.prototype

export {
    thousandSeparator,
    quantityTransformer,
    calculateTrendline,
    NotImplementedError
}