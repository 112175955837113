import { Button, TableCell, TableRow, TableSortLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const CustomTableRow = withStyles(() => ({
    root: {
        height: '30px !important',
    }
}))(TableRow)

export const CustomEmptyTableCell = withStyles(() => ({
    head: {
        backgroundColor: '#0A1F2E',
        //color: theme.palette.common.white,
        color: 'white',
        width: 50,
        borderBottom: '0px',
        textAlign: 'left'
    },
    root: {
        padding: '0px 8px 0px 10px',
    }
}))(TableCell)

export const CustomTableHeaderCell = withStyles(() => ({
    root: {
        padding: '4px 0px',
        height: 20,
    },
    head: {
        backgroundColor: '#0A1F2E',
        //color: theme.palette.common.white,
        color: 'white',
        borderBottom: '0px',
        fontWeight: 500,
        padding: 0,
    },
    body: {
        fontSize: 14,
        textAlign: 'left',
    },
}))(TableCell)

export const CustomTableCell = withStyles(() => ({
    root: {
        padding: '4px 0px',
        height: 20,
    },
    head: {
        backgroundColor: '#0A1F2E',
        //color: theme.palette.common.white,
        color: 'white',
        width: 86,
        borderBottom: '0px',
        fontWeight: 500,
        padding: 0,
    },
    body: {
        fontSize: 14,
        textAlign: 'left',
    },
}))(TableCell)

export const CustomTableSortLabel = withStyles(() => ({
    root: {
        color: 'white !important',
    },
    icon: {
        color: 'white !important'
    }
}))(TableSortLabel)

export const CustomTableCellInput = withStyles(() => ({
    root: {
        padding: '4px 0px',
        borderBottom: '1px solid #0A1F2E',
    },
    head: {
        backgroundColor: '#55758C',
        //color: theme.palette.common.white,
        color: 'white',
    },
    body: {
        fontSize: 14,
    }
}))(TableCell)

export const CustomSelectedButton = withStyles(() => ({
    root: {
        padding: '0px 5px',
        fontWeight: 800,
        color: 'white',
        margin: '2px 0',
        height: '20px',
        width: 100,
        backgroundColor: '#4aaae1',
        '&:hover': {
            backgroundColor: '#94c5e1'
        }
    },
    label: {
        fontSize: 10
    },
    disabled: {
        backgroundColor: '#4aaae180'
    },
}))(Button)

export const CustomAdditionButton = withStyles(() => ({
    root: {
        padding: '0px 5px',
        fontWeight: 700,
        color: 'white',
        marginTop: '5px',
        height: '20px',
        width: 90,
        backgroundColor: '#4aaae1',
        '&:hover': {
            backgroundColor: '#94c5e1'
        }
    },
    disabled: {
        backgroundColor: '#4aaae180',
    }
}))(Button)