import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import InventoryLegalEntityFormCreator from '../../../tableDefinitions/Inventory/LegalEntity/InventoryLegalEntityFormCreator'
import { InventoryLegalEntityTableCreator } from '../../../tableDefinitions/Inventory/LegalEntity/InventoryLegalEntityTableCreator'

const LegalEntity = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Legal Entity')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Legal Entities'}
            tableCreatorCreatorId={InventoryLegalEntityTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={InventoryLegalEntityFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default LegalEntity