/* eslint-disable eqeqeq */
import React from 'react'

import { useTranslation, withTranslation } from 'react-i18next'

import SetupTableData from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import LossReportTableCreator from '../../tableDefinitions/LossReport/LossReportTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

function LossDevicesReport(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(LossReportTableCreator.getId(), null)
    const tableCreator = tableCreatorCreator.getTableCreator('Loss Devices Report')

    return (
        <SetupTableData
            TableCreator={tableCreator}
            stickyHeader
        />
    )
}

function TableSetup(props){
    const { t } = useTranslation(['deviceLossReport', 'root'])
    document.title = `${t('root:DeviceLossReport')} - BubbleBPM`
  
    return (
        <LossDevicesReport
            t={props.t}
        />
    )
}

export default withTranslation("LossDeviceReport")(TableSetup)