import React from "react"
import { gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    NumberComponentCreator,
    NumberIconedComponentCreator,
    DefaultClickStrategy, 
    TableFieldDescription,
    DataTransformer,
    DeleteSelectBudgetsActions,
} from '../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import {  Loupe as LoupeIcon } from '@material-ui/icons';
import { ButtonTooltip } from '../../components/Tooltips/BPMTooltips'
import { Link } from 'react-router-dom'

const tableQuery = gql`
    query AllBudgets(
        $orderField: String, 
        $first: Int, 
        $last: Int, 
        $after: String, 
        $before: String,
        $description: String,
        $startPeriod: String,
        $endPeriod: String,
        $currency: String
    ) {
        allBudgets(
            orderField: $orderField,
            first: $first, 
            last: $last, 
            after: $after, 
            before: $before,
            description: $description,
            startPeriod: $startPeriod,
            endPeriod: $endPeriod,
            currency: $currency
        ) {
            edges {
                node {
                    id
                    description
                    amount
                    startPeriod {
                        date
                    }
                    endPeriod {
                        date
                    }
                    currency {
                        id
                        code
                        symbol
                    }
                    unassignedAmount
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editBudgetMutation(
        $id: String!,
        $description: String,
        $amount: String!,
        $currency: String!,
        $startPeriod: String!,
        $endPeriod: String!
    ) {
        editBudget(
            id: $id,
            description: $description,
            amount: $amount,
            currency: $currency,
            startPeriod: $startPeriod,
            endPeriod: $endPeriod,
        ) {
            budget {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createBudgetMutation(
        $description: String,
        $amount: String!,
        $currency: String!,
        $startPeriod: String!,
        $endPeriod: String!
    ) {
        createBudget(
            description: $description,
            amount: $amount,
            currency: $currency,
            startPeriod: $startPeriod,
            endPeriod: $endPeriod,
        ) {
            budget {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation deleteBudgetMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        deleteBudget(ids: $ids, allRecords: $allRecords, filters: $filters) {
            result
        }
    }
`

const unitQuery = `
    budget(id: $id) {
        id
        description
        amount
        startPeriod {
            date
        }
        endPeriod {
            date
        }
        currency {
            id
            code
            symbol
        }
        budgetperiodSet {
            edges {
                node {
                    id
                    glAccount {
                        id
                        name
                        displayName
                    }
                    period {
                        id
                        date
                    }
                    value
                }
            }
        }
        unassignedAmount
    }
`

const BudgetTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class BudgetTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "BudgetTableCreator"
    }

    budgetDetail(id) {
        const { t } = useTranslation('fieldLabels')
    
        return ( 
            <Link to={`/budgetPeriod/${id}`}>
                <ButtonTooltip title={t("View Budget details")}>
                    <LoupeIcon style={{ color:'0A1F2E', fontSize:'22px' }}/>
                </ButtonTooltip>
            </Link>
        )
    }
    
    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, BudgetTableCreatorQueries, new DefaultClickStrategy())
        tableCreator.setSelectedRowActionsStrategy(new DeleteSelectBudgetsActions(deleteMutator));

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'description',
                new DataMapper('description'),
                new f.FilterDescription(new f.TextFilter('description')),
                {label: 'Description'}
            ),
            new TableFieldDescription(
                'startPeriod',
                new DataMapper('startPeriod.date'),
                new f.FilterDescription(new f.PeriodFilter("startPeriod")),
                {label: 'Start Period'}
            ),
            new TableFieldDescription(
                'endPeriod',
                new DataMapper('endPeriod.date'),
                new f.FilterDescription(new f.PeriodFilter("endPeriod")),
                {label: 'End Period'}
            ),
            new TableFieldDescription(
                'currency',
                new DataMapper('currency.code'),
                new f.FilterDescription(new f.TextFilter("currency")),
                
                {label: 'Currency'}
            ),
            new TableFieldDescription(
                'amount',
                new DataMapper('amount'),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Amount',
                    componentCreator: new NumberComponentCreator('', 2),
                    excelTransform: new DataTransformer(({amount})=>Number(amount).toFixed(2)),
                    fieldIconCretator: this.budgetDetail,
                }
            ), 
            new TableFieldDescription(
                'unassignedAmount',
                new DataMapper('unassignedAmount'),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Unassigned Amount',
                    componentCreator: new NumberComponentCreator('', 2), 
                    excelTransform: new DataTransformer(({unassignedAmount})=>Number(unassignedAmount).toFixed(2)),
                }
            ),
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(BudgetTableCreator)

export {
    BudgetTableCreator,
    BudgetTableCreatorQueries
};