import React from 'react'

import { BrowserRouter as Switch } from 'react-router-dom'

import classes from './MainLayout.module.css'
import NavBar from '../../components/NavBar/NavBar'
import { useCurrentUser } from '../../redux/selectors'
import Password from '../../pages/Password/Password'

const MainLayout = (props) => {
    const currentUser = useCurrentUser()

    return (
        <Switch>
            <NavBar disabled={ currentUser.mustChangePassword }/>
            <div style={{height:"79px"}}></div>
            <div className={classes.MainLayout}>
                {(currentUser.mustChangePassword || currentUser.lastPasswordChangeRequest) ?
                <Password/>
                :
                props.children}
            </div>
        </Switch>
    )
}

export default MainLayout