import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllCostCenters(
        $orderField:String,
        $first:Int,$last:Int,
        $after:String,
        $before:String,
        $isActive:Boolean,
        $enterpriseId:String,
        $name:String,
        $parent:String
    ) {
        allCostCenters(
            orderField:$orderField,
            first:$first,
                    last:$last,
                    after:$after,
                    before:$before,
                    enterpriseId:$enterpriseId,
                    isActive:$isActive,
                    name:$name,
                    parent:$parent
        ) {
            edges {
                node {
                    id
                    isActive
                    name
                    enterpriseId
                    parent {
                        id
                        enterpriseId
                    }
                }
            }
            count
            pageInfo {
            hasNextPage
            startCursor
            endCursor
            }
        }
    }
`

const unitQuery = `
    costCenter(id: $id) {
        id
        isActive
        enterpriseId
        name
        parent {
            id
            enterpriseId
        }
        manager {
            id
            enterpriseId
        }
    }
`
const queries = {tableQuery, unitQuery}

class InventoryCostCenterMinimalTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryCostCenterMinimalTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.addDescriptions ([
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'Code'}
            ),
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'parent',
                new DataMapper("parent.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("parent")),
                {label: 'Parent'}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryCostCenterMinimalTableCreator)

export default InventoryCostCenterMinimalTableCreator