import React from 'react'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { gql, useQuery } from '@apollo/client'
import { ShowLoading } from '../Loading/Loading'
import { getBrowserLanguage } from '../../pages/Password/Utils'

const PUBLIC_TRANSLATIONS_QUERIES = gql`
    query($lng: String) {
        publicTranslations(lng: $lng) {
            language {
                codename
            }
            definitions
        }
    }
`

class BPMMissingKeysBackend {
    type = '3rdParty'

    init = instance => {
        instance.options.parseMissingKeyHandler = (key) => {
            return '*'+key+'*'
        };
    }
}

const Publici18n = (props) => {
    const languageCode = getBrowserLanguage();
    const {loading, error, data} = useQuery(PUBLIC_TRANSLATIONS_QUERIES, {variables: {lng: languageCode}, fetchPolicy: 'network-only'})

    console.log(data)
    if (loading) return <ShowLoading fullScreen />

    if (error) return <p>error</p>

    const resources = {}

    resources[data.publicTranslations.language.codename] = JSON.parse(data.publicTranslations.definitions)

    i18n
        .use(new BPMMissingKeysBackend())
        .use(initReactI18next)
        .init({
            resources: resources,
            lng: data.publicTranslations.language.codename,
            fallbackLng: [data.publicTranslations.language.codename],
            ns: 'common',
            defaultNS: 'common',
            interpolation: {
                escapeValue: false
            }
        })

    return (
        <>
            {props.children(data)}
        </>
    )
}

export default Publici18n;