import React from 'react'

import { useApolloClient } from '@apollo/client'

import BPMTable from '../BPMTable'
import tableCreatorCreatorFactoryCreator from '../TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import formCreatorCreatorFactoryCreator from '../FormCreatorFactory/FormCreatorCreatorFactoryCreator'

const BPMBasicPage = (props) => {
    const client = useApolloClient()
    
    var tableCreator = null;
    var formCreator = null;

    if (props.tableCreatorCreatorId) {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(props.tableCreatorCreatorId, props.tableCreatorParams)

        tableCreatorCreator.setClient(client)
        tableCreator = tableCreatorCreator.getTableCreator(props.tableName)
    }

    if (props.formCreatorCreatorId) {
        var formCreatorCreator = formCreatorCreatorFactoryCreator.getFormCreatorCreatorFromIdentifier(props.formCreatorCreatorId, props.formCreatorParams)
        formCreator = formCreatorCreator.getFormCreator()
    }
     
    return (
        <BPMTable
            TableCreator={tableCreator}
            FormCreator={formCreator}
            path={props.path}
        />
    )
}

export default BPMBasicPage