import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    DataMapper, 
    DataTransformer, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription,
} from '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query allAccounts(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $status: Boolean,
        $name: String,
        $site: String,
        $legalEntity: String,
        $provider: String,
        $technology: String,
        $description: String,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $domain: String,
        $identifier: String
    ) {
        allAccounts(
            orderField:$orderField,
            first:$first,
            domain:$domain,
            last:$last,
            after:$after,
            before:$before,
            status:$status,
            name:$name,
            site:$site,
            legalEntity:$legalEntity,
            provider:$provider,
            technology:$technology,
            description:$description,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            identifier:$identifier
        ) {
            edges {
                node {
                    status
                    id
                    name
                    domain {
                        id
                        name
                    }
                    site {
                        id
                        name
                    }
                    legalEntity {
                        id
                        name
                    }
                    provider {
                        id
                        name
                    }
                    technology {
                        id
                        name
                    }
                    identifier
                    description
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`


const editMutator = gql`
    mutation editAccountMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $description: String,
        $legalEntity: String,
        $id: String!,
        $identifier: String,
        $name: String,
        $status: Boolean,
        $site: String,
        $technology: String,
        $provider: String,
        $domain: String,
    ) {
        editAccount(
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            description: $description,
            legalEntity: $legalEntity,
            id: $id,
            domain: $domain,
            identifier: $identifier,
            name: $name,
            status: $status,
            site: $site,
            technology: $technology,
            provider: $provider
        ) {
            account {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createAccoutMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $description: String,
        $legalEntity: String,
        $identifier: String!,
        $name: String!,
        $status: Boolean,
        $site: String,
        $technology: String,
        $provider: String,
        $domain:String
    ) {
        createAccount(
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            description: $description,
            legalEntity: $legalEntity,
            identifier: $identifier,
            name: $name,
            status: $status,
            site: $site,
            technology: $technology,
            provider: $provider,
            domain: $domain
        ) {
            account {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeAccountMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeAccount(ids: $ids, allRecords: $allRecords, filters: $filters) {
            account {
                id
            }
        }
    }
`

const unitQuery= `
    account(id: $id) {
        id
        name
        description
        domain {
            id
            name
        }
        site {
            id
            name
            postalCode
            address
            enterpriseId
        }
        identifier
        legalEntity {
            id
            name
            taxId
            description
        }
        provider {
            id
            name
        }
        technology {
            id
            name
        }
        status
        customAttr1
        customAttr2
        customAttr3
        status
    }
`

const AccountQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class AccountTableCreator extends TableCreatorCreator{
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "AccountTableCreator"
    }

    getQueries = () => {
        return AccountQueries
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, AccountQueries)

        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
        tableCreator.setInitialSortField('status', 'desc')

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'site',
                new DataMapper("site.name"),
                new f.FilterDescription(new f.TextFilter("site")),
                {label: 'Site'}
            ),
            new TableFieldDescription(
                'legalEntity',
                new DataMapper("legalEntity.name"),
                new f.FilterDescription(new f.TextFilter("legalEntity")),
                {label: 'Legal Entity'}
            ),
            new TableFieldDescription(
                'provider',
                new DataMapper("provider.name"),
                new f.FilterDescription(new f.TextFilter("provider")),
                {label: 'Vendor'}
            ),
            new TableFieldDescription(
                'technology',
                new DataMapper("technology.name"),
                new f.FilterDescription(new f.TextFilter("technology")),
                {label: 'Technology'}
            ),
            new TableFieldDescription(
                'identifier',
                new DataMapper("identifier"),
                new f.FilterDescription(new f.TextFilter("identifier")),
                {label: 'Identifier'}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain'}
            ),
            new TableFieldDescription(
                'status',
                new DataMapper("status"),
                new f.FilterDescription(new f.BooleanFilter("status")),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'description',
                new DataMapper("description"),
                new f.FilterDescription(new f.TextFilter("description")),
                {label: 'Description', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper("customAttr1"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper("customAttr2"),
                new f.FilterDescription(new f.TextFilter("customAttr2")),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper("customAttr3"),
                new f.FilterDescription(new f.TextFilter("customAttr3")),
                {label: 'Custom Attribute 3', onlyForm: true}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(AccountTableCreator)

export {
    AccountTableCreator,
    AccountQueries};