import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
    DataTransformer,
    NullClickStrategy,
    OnlyFilterActionDisplayStrategy,
    GeneralExcelDisplayCreator,
    GeneralChipComponentCreator,
} from  '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query (
            $orderField: String,
            $dateFrom: String,
            $dateUntil: String,
            $costCenters: String,
            $serviceNumber: String!
        ){
        costcentersAssociations(
            orderField: $orderField,
            dateFrom: $dateFrom,
            dateUntil: $dateUntil,
            costCenters: $costCenters,
            serviceNumber: $serviceNumber
        ) {
            edges {
                node {
                    association {
                        id
                        items {
                            id
                            costCenter {
                                id
                                enterpriseId
                                displayNameWithParents
                            }
                            percentage
                        }
                        inventory {
                            serviceNumber
                        }
                    }
                    dateFrom
                    dateUntil
                }
            }
            count
        }
    }
`;

const queries = {tableQuery}

class InventoryCostCentersAssociationTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryCostCentersAssociationTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'dateFrom',
                new DataMapper("dateFrom"),
                new f.FilterDescription(new f.DateFilter("dateFrom")),
                {label: 'From', transform: new DataTransformer(({dateFrom})=> dateFrom && dateFrom !== '1900-01-01' ? dateFrom : null)}
            ),
            new TableFieldDescription(
                'dateUntil',
                new DataMapper("dateUntil"),
                new f.FilterDescription(new f.DateFilter("dateUntil")),
                {label: 'Until', transform: new DataTransformer(({dateUntil})=> dateUntil && dateUntil !== '1900-01-01' ? dateUntil : null)}
            ),
            new TableFieldDescription(
                'costCenters',
                new DataMapper("association.items"),
                new f.FilterDescription(new f.TextFilter("costCenters")),
                {
                    label: "Cost Centers",
                    excelDisplay : new GeneralExcelDisplayCreator('Cost Center', 
                        [{label: 'Code', path: 'costCenter.enterpriseId'}, 
                        {label: 'Name', path: 'costCenter.displayName', defaultValue: 'Not Assigned'}, 
                        {label: '%', path: 'percentage', defaultValue: 100.00}], 3),
                    componentCreator: new GeneralChipComponentCreator(['costCenter.displayNameWithParents'], null, 'Not Assigned'),
                    transform: new DataTransformer(({items}) => items ? items : []),
                }
            ),
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryCostCentersAssociationTableCreator)

export default InventoryCostCentersAssociationTableCreator;