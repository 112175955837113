import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    HideDeletionDisplayStrategy, 
    TableFieldDescription,
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'


const tableQuery = gql`
    query AllChargeGroupConcepts(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $concept: String,
        $chargeGroup: String
    ) {
        allChargeGroupConcepts(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            concept: $concept,
            chargeGroup: $chargeGroup
        ) {
            edges {
                node {
                    id
                    concept {
                        id
                        name
                        technology {
                            id
                            name
                        }
                    }
                    chargeGroup {
                        id
                        name
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation modifyChargeGroupConcept($id: String!, $concept: String!, $chargeGroup: String!) {
        editChargeGroupConcept(id: $id, concept: $concept, chargeGroup: $chargeGroup) {
            chargeGroupConcept {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createConceptConverterMutation($concept: String!, $chargeGroup: String!) {
        createChargeGroupConcept(concept: $concept, chargeGroup: $chargeGroup) {
            chargeGroupConcept {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeChargeGroupConcept($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeChargeGroupConcept(ids: $ids, allRecords: $allRecords, filters: $filters) {
            account {
                id
            }
        }
    }
`

const unitQuery = `
    chargeGroupConcept(id: $id) {
        id
        concept {
            id
            name
            technology {
                id
                name
            }
        }
        chargeGroup {
            id
            name
        }
    }
`

const ChargeGroupConceptQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class ChargeGroupConceptTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ChargeGroupConceptTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, ChargeGroupConceptQueries)
        tableCreator.setDisplayStrategy(new HideDeletionDisplayStrategy())
        tableCreator.setInitialSortField('concept', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'concept',
                new DataMapper("concept"),
                new f.FilterDescription(new f.TextFilter("concept")),
                {label: 'Concept',transform: new DataTransformer(({concept})=> `${concept.name} (${concept.technology.name})` )}
            ),
            new TableFieldDescription(
                'chargeGroup',
                new DataMapper("chargeGroup.name"),
                new f.FilterDescription(new f.TextFilter("chargeGroup")),
                {label: 'Charge Group'}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ChargeGroupConceptTableCreator)

export {
    ChargeGroupConceptTableCreator,
    ChargeGroupConceptQueries
};