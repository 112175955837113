/* eslint-disable eqeqeq */
import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'

import ReportsFilter from '../../components/ReportsFilter/ReportsFilter'
import { useCurrentUser, useLastPublishedPeriod } from '../../redux/selectors'
import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import ExpensesBySiteTotals from './ExpensesBySiteTotals'
import ExpensesBySiteTableCreator from '../../tableDefinitions/ExpensesBySite/ExpensesBySiteTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import { shortMonthNames } from '../../components/DateTools/MonthsNames'


function ExpensesBySiteTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(ExpensesBySiteTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('Expenses by Site')
    
    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        >
            {(data, managerTable) =>
                <ExpensesBySiteTotals 
                    visibleColumns={managerTable.getVisibleFields(true).length}
                    symbol={props.symbol}
                    data={data ? data.expenseBySite.costs : { cost: 0, qty:0, cost2: 0, qty2:0, cost3: 0, qty3: 0}}
                />
            }
        </Table>
    )
}

function ExpensesBySite(props){
    const lastPublishedPeriod = useLastPublishedPeriod()
    const currentUser = useCurrentUser()

    const [date, setDate] = useState({year: lastPublishedPeriod.year, month: lastPublishedPeriod.month})
    const [currency_symbol, SetSymbol] = useState(currentUser.currency ? currentUser.currency.symbol : null)
    const [currency_id, SetCurrencyId] = useState(currentUser.currency ? currentUser.currency.code : null)

    const handleChangeCurrency = (currencyCode, symbol) =>{
        SetSymbol(symbol)
        SetCurrencyId(currencyCode)
    }
  
    function onChangeDate(year, month) {
        setDate({year: year, month: month})
    }
  
    function generateFilters(){
        return {period:`${date.year}-${date.month}`,currency:currency_id, first: 50}
    }
  
    const periodTransformer = shortMonthNames(props.t)
    
    const month0 = date.month - 1
    const month1 = (month0 - 1 < 0)? month0 + 11 : month0 -1
    const month2 = (month0 - 2 < 0)? month0 + 10 : month0 -2
    const year0 = date.year
    const year1 = ( month1 > month0) ? year0- 1 : year0
    const year2 = ( month2 > month0) ? year0 - 1 : year0
  
    const period2 = String(periodTransformer[month2]+ "-" + year2)
    const period1 = String(periodTransformer[month1]+ "-" + year1)
    const period0 = String(periodTransformer[month0]+ "-" + year0)
    
    document.title = props.t('Expenses by Site - BubbleBPM')
  
    return (
        <>
            <ReportsFilter 
                onChangeDate={onChangeDate}
                date={date}
                info={{label: props.t(`Quarterly expenses of your sites`)}}
                onChangeCurrency={handleChangeCurrency}
                currencySelected = {currency_id}
                checkDisabledMonths={true}
            />
            <ExpensesBySiteTable
                t={props.t}
                symbol={currency_symbol}
                currencyCode={currency_id}
                filters={generateFilters()}
                periods={{period0:year0 + "-" + (month0+1) ,period1:year1 + "-" +  (month1+1),period2:year2 + "-" + (month2+1)}}
                per1={period1}
                per2={period2}
                per={period0}
            />
        </>
    )
}

export default withTranslation("expensesBySite'")(ExpensesBySite)