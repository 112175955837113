import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import ConceptFormCreator from '../../../tableDefinitions/Billing/Concept/ConceptFormCreator'
import { ConceptTableCreator } from '../../../tableDefinitions/Billing/Concept/ConceptTableCreator'

const Concept = (props) => {
    const { t } = useTranslation('billManagement')
    document.title = `${t('Concepts')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Concepts'}
            tableCreatorCreatorId={ConceptTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={ConceptFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Concept