import React from 'react'

import { Typography } from '@material-ui/core'

import { useDispatch } from 'react-redux'
import { AddBPMApplicationMessages } from '../../redux/reducers/BPMReducer'

import ExcelFileTask from '../../components/BPMTable/TableComponents/ExcelFileTask'
import SetupTableData from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import MyTeamPopupContentTableCreator from '../../tableDefinitions/MyTeam/MyTeamPopupContentTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

export default function PopupContent(props) {
    const dispatch = useDispatch()

    const handleQueryExecuted = (success, message) => {
        dispatch(AddBPMApplicationMessages({
            text: message, 
            variant: success ? 'success' : 'error',
            duration: 8000,
            snakBarCursor: success ? {cursor: 'pointer'}: null, 
            clckRedirect:'/excelExports'
        }))
    }

    var filters = { period: String(props.period), currency: props.currency}
    filters['costCenter'] = props.popupData.costCenter !== "" ? props.popupData.costCenter.id : "NULL";
    const costCenterDisplayName = props.popupData.costCenter !== "" ? props.popupData.costCenter.displayName : "";

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(MyTeamPopupContentTableCreator.getId(), {tcFilters: filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('MyTeamCostCenters')

    return (
        <>
            <SetupTableData
                TableCreator={tableCreator}
                style={{margin:"2%"}}
                stickyHeader
            >
                {(data, managerTable) =>
                    <div style={{ position: 'sticky', top: 2, background: 'white', height:'64px', zIndex: 1 }}>
                        <span onClick={props.handleClose} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '5px', top: '-5px'}}>&times;</span>

                        <ExcelFileTask TableObject={managerTable} filters={filters} handleClose={handleQueryExecuted} typeButton={true}/>
                        <div style={{margin:"2%"}}>
                            <Typography variant="h5">{` ${props.periodString} - ${costCenterDisplayName } `}</Typography>
                        </div>
                    </div>
                }
            </SetupTableData>                
        </>
    )
}
