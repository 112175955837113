import React from 'react'

import { withTranslation } from 'react-i18next'
import { Table, TableCell, TableHead, TableRow } from '@material-ui/core'

import { NumberComponent } from '../../components/BPMTable/TableMapperCreator'

const TotalTableEstructure = (props) => {
    const { data, t } = props

    return (
        <div style={{ marginBottom: 15 }}>
            <Table style={{ tableLayout: "fixed" }} aria-label="sticky table" padding={'none'}>
                <TableHead style={{ width: 86 }}>
                    <TableRow style={{ height: 30 }}>
                        <TableCell
                            colSpan={2} 
                            style={{
                                fontWeight: 'bold',
                                textAlign: 'center',                        
                                fontSize: 13,
                                padding: 0,
                                backgroundColor: "#0A1F2E",
                                color: "white",
                                borderBottom: "0px"
                            }}
                        >
                            {t("% ACTUAL / BUDGET")}
                        </TableCell>
                        {data.map((item, index) =>
                            <TableCell
                                key={index}
                                style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    fontSize: 13,
                                    backgroundColor: "white",
                                    color: '#0000008a'
                                }}
                            >
                                <span>
                                    <NumberComponent
                                        currencySymbol={''}
                                        value={item.avg}
                                        sufix={'%'}
                                        style={{alignContent:"center", textAlign:"center"}}
                                    />
                                </span>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>
        </div>
    )
}

export default withTranslation("totalTable")(TotalTableEstructure) 