import InvoicePeriodMinimalTableCreator from '../../../tableDefinitions/Invoice/Periods/InvoicePeriodMinimalTableCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

export default class PeriodMinimalTable {
    constructor(filters) {
        this.filters = filters
    }

    getTableCreator = (tableName) => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InvoicePeriodMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Picker')
    }
}