import React from 'react'

import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    HideMassiveUploadsDisplayStrategy,
    TableFieldDescription,
    DefaultClickStrategy, 
    StatusComponentCreator,
    BooleanExcelDisplayCreator,
} from '../../components/BPMTable/TableMapperCreator'
import { DownloadFile } from '../../components/FileDownloader/FileComponentDownloader'
import { ExportFooterItemCreator } from '../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query allContracts(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String
        $identifier:String,
        $name:String,
        $vendor:String,
        $contractKind:String,
        $status:Boolean,
        $startDate:String,
        $endDate:String,
        $signed:Boolean,
        $renewal:Boolean,
        $contractFile:String,
        $description:String,
        $observations:String,
        $signatory:String,
        $signatureDate:String,
        $customAttr1:String,
        $customAttr2:String,
        $customAttr3:String,
        $accountName:String,
        $accountId:String,
        $accountDescription:String,
        $accountProviderId:String,
) {
        allContracts(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            identifier:$identifier,
            name:$name,
            vendor:$vendor,
            contractKind:$contractKind,
            status:$status,
            startDate:$startDate,
            endDate:$endDate,
            signed:$signed,
            renewal:$renewal,
            contractFile:$contractFile,
            description:$description,
            observations:$observations,
            signatory:$signatory,
            signatureDate:$signatureDate,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            accountName:$accountName,
            accountId:$accountId,
            accountDescription:$accountDescription,
            accountProviderId:$accountProviderId,
        ) {
            edges {
                node {
                    id
                    identifier
                    name
                    vendor {
                        id
                        name
                    }
                    contractKind {
                        id
                        name
                    }
                    status
                    startDate
                    endDate
                    signed
                    renewal
                    contractFile
                    description
                    observations
                    signatory
                    signatureDate
                    customAttr1
                    customAttr2
                    customAttr3
                    accounts {
                        edges {
                            node {
                                id
                                name
                                description
                           }
                        }
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editContractMutation(
        $id: String!,
        $identifier:String!,
        $name:String!,
        $vendor:String!,
        $contractKind:String,
        $status:Boolean,
        $startDate:Date,
        $endDate:Date,
        $signed:Boolean,
        $renewal:Boolean,
        $contractFile:Upload,
        $description:String,
        $observations:String,
        $signatory:String,
        $signatureDate:Date,
        $customAttr1:String,
        $customAttr2:String,
        $customAttr3:String,
        $accounts:[Int],
    ) {
        editContract(
            id:$id,
            identifier:$identifier,
            name:$name,
            vendor:$vendor,
            contractKind:$contractKind,
            status:$status,
            startDate:$startDate,
            endDate:$endDate,
            signed:$signed,
            renewal:$renewal,
            contractFile:$contractFile,
            description:$description,
            observations:$observations,
            signatory:$signatory,
            signatureDate:$signatureDate,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            accounts:$accounts,
        ) {
            contract {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createContractMutation(
        $identifier:String!,
        $name:String!,
        $vendor:String!,
        $contractKind:String,
        $status:Boolean,
        $startDate:Date,
        $endDate:Date,
        $signed:Boolean,
        $renewal:Boolean,
        $contractFile:Upload,
        $description:String,
        $observations:String,
        $signatory:String,
        $signatureDate:Date,
        $customAttr1:String,
        $customAttr2:String,
        $customAttr3:String,
        $accounts:[Int],
    ) {
        createContract(
            identifier:$identifier,
            name:$name,
            vendor:$vendor,
            contractKind:$contractKind,
            status:$status,
            startDate:$startDate,
            endDate:$endDate,
            signed:$signed,
            renewal:$renewal,
            contractFile:$contractFile,
            description:$description,
            observations:$observations,
            signatory:$signatory,
            signatureDate:$signatureDate,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            accounts:$accounts,
        ) {
            contract {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation changeContractMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        changeContract(ids: $ids, allRecords: $allRecords, filters: $filters) {
            contract {
                id
            }
        }
    }
`

const unitQuery = `
    contract(id: $id) {
        id
        identifier
        name
        vendor {
            id
            name
        }
        contractKind {
            id
            name
        }
        status
        startDate
        endDate
        signed
        renewal
        contractFile
        description
        observations
        signatory
        signatureDate
        customAttr1
        customAttr2
        customAttr3
        accounts {
            edges {
                node {
                    id
                    name
                    description
                }
            }
        }
    }
`

const ConstractQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class ContractTableCreator extends TableCreatorCreator{
    constructor(filters) {
        super(filters)
        this.filters = filters
        this.queries = ConstractQueries
    }

    static getId = () => {
        return "ContractTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, this.queries, new DefaultClickStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
        tableCreator.setDisplayStrategy(new HideMassiveUploadsDisplayStrategy())
        tableCreator.setInitialSortField('name', 'desc')
        tableCreator.addDescriptions ([
            new TableFieldDescription(
                'identifier',
                new DataMapper("identifier"),
                new f.FilterDescription(new f.TextFilter("identifier")),
                {label: 'Contract ID'}
            ),
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Contract Name'}
            ),
            new TableFieldDescription(
                'vendor',
                new DataMapper("vendor.name"),
                new f.FilterDescription(new f.TextFilter("vendor")),
                {label: 'Vendor'}
            ),
            new TableFieldDescription(
                'contractKind',
                new DataMapper("contractKind.name"),
                new f.FilterDescription(new f.TextFilter("contractKind")),
                {label: 'Type'}
            ),
            new TableFieldDescription(
                'status',
                new DataMapper("status"),
                new f.FilterDescription(new f.BooleanFilter("status")),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'starDate',
                new DataMapper("startDate"),
                new f.FilterDescription(new f.DateFilter("startDate")),
                {label: 'Start Date'}
            ),
            new TableFieldDescription(
                'endDate',
                new DataMapper("endDate"),
                new f.FilterDescription(new f.DateFilter("endDate")),
                {label: 'End Date'}
            ),
            new TableFieldDescription(
                'signed',
                new DataMapper("signed"),
                new f.FilterDescription(new f.BooleanFilter("signed", "Yes", "No")),
                {label: 'Signed',  componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator("Yes", "No")}
            ),
            new TableFieldDescription(
                'renewal',
                new DataMapper("renewal"),
                new f.FilterDescription(new f.BooleanFilter("renewal", "Yes", "No")),
                {label: 'Automatic renewal', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator("Yes", "No")}
            ),
            new TableFieldDescription(
                'contractFile',
                new DataMapper("id", "contractFile"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'File', hideSort:true, transform: new DataTransformer(({id, contractFile}) => contractFile ? <DownloadFile url={"/report/download/contracts/"} id={id}/> : null)}
            ),
            new TableFieldDescription(
                'description',
                new DataMapper("description"),
                new f.FilterDescription(new f.TextFilter("description")),
                {label: 'Description', onlyForm: true}
            ),
            new TableFieldDescription(
                'observations',
                new DataMapper("observations"),
                new f.FilterDescription(new f.TextFilter("observations")),
                {label: 'Observations', onlyForm: true}
            ),
            new TableFieldDescription(
                'signatory',
                new DataMapper("signatory"),
                new f.FilterDescription(new f.TextFilter("signatory")),
                {label: 'Signatory', onlyForm: true}
            ),
            new TableFieldDescription(
                'signaturDate',
                new DataMapper("signaturDate"),
                new f.FilterDescription(new f.DateFilter("signaturDate")),
                {label: 'Signatur Date', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper("customAttr1"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper("customAttr2"),
                new f.FilterDescription(new f.TextFilter("customAttr2")),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper("customAttr3"),
                new f.FilterDescription(new f.TextFilter("customAttr3")),
                {label: 'Custom Attribute 3', onlyForm: true}
            ),
        ]);

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ContractTableCreator)

export {
    ContractTableCreator,
    ConstractQueries
}