import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from "@material-ui/core/styles";
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux';
import BPMStore from './redux/store/BPMStore';
import App from './App';
import "./index.scss";
import { theme } from "./materialTheme"

import { BrowserRouter as Router } from 'react-router-dom'

const SENTRY_CFG = window['runtimeConfig']?.SENTRY_CFG ? window['runtimeConfig'].SENTRY_CFG : null

if (SENTRY_CFG) Sentry.init(SENTRY_CFG);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <Provider store={BPMStore}>
            <Router>
                <App/>
            </Router>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
)
