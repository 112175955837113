import React from 'react'

import { withTranslation } from 'react-i18next'
import { Table, TableCell, TableHead, TableRow } from '@material-ui/core'

import { NumberComponent } from '../../components/BPMTable/TableMapperCreator'

const TotalTableEstructure = (props) => {
    const { data, t } = props

    const widthCalculator = (tableLength, totalColumnLength) => {
        return String(((100 / tableLength) * (tableLength - totalColumnLength))) + "%"
    }

    return (
        <div style={{ marginBottom: 15 }}>
            <Table style={{ tableLayout: "fixed" }} aria-label="sticky table" padding={'none'}>
                <TableHead style={{ width: 86 }}>
                    <TableRow style={{ height: 30 }}>
                        <TableCell
                            style={{
                                fontSize: 16,
                                padding: 0,
                                textAlign: "center",
                                backgroundColor: "#0A1F2E",
                                width: widthCalculator(props.visibleColumns, data.length),
                                color: "white",
                                fontWeight: 500,
                                borderBottom: "0px"
                            }}
                        >
                            {t("Totals")}
                        </TableCell>
                        {data.map((item, index) =>
                            <TableCell
                                key={index}
                                style={{
                                    fontSize: 13,
                                    fontWeight: 400,
                                    textAlign: "right",
                                    backgroundColor: "white",
                                    color: '#0000008a'
                                }}
                            >
                                <span>
                                    <NumberComponent
                                        marginLeft={45}
                                        currencySymbol={item.symbol}
                                        value={item.value}
                                    />
                                </span>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
            </Table>
        </div>
    )
}

export default withTranslation("totalTable")(TotalTableEstructure) 