/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import BPMBasicPage from '../../components/BPMTable/PagesCreator/BPMBasicPage'
import { BudgetPeriodTableCreator } from '../../tableDefinitions/Budget/BudgetPeriodTableCreator'
import BudgetPeriodFormCreator from '../../tableDefinitions/Budget/BudgetPeriodFormCreator'

const BudgetPeriod = (props) => {
    const { budgetId } = useParams()
    const { t } = useTranslation('budget')
    document.title = `${t('Budget Periods')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Budget Periods'}
            tableCreatorCreatorId={BudgetPeriodTableCreator.getId()}
            tableCreatorParams={{tcFilters: {budgetId: budgetId}}}
            formCreatorCreatorId={BudgetPeriodFormCreator.getId()}
            formCreatorParams={{budgetId: budgetId}}
            path = {props.match.url}
        />
    )
}

export default BudgetPeriod