import React from 'react'

import ConsumptionsTotals from './ConsumptionsTotal'
import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import { ConsumptionsTableCreator } from '../../tableDefinitions/Consumptions/ConsumptionsTableCreator'

export default function ConsumptionsTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(ConsumptionsTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator("Consumptions")

    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        >
            {(data, tableManager) =>
                <ConsumptionsTotals
                    visibleColumns = {tableManager.getVisibleFields().length}
                    symbol= {props.symbol}
                    data={data ? data.consumptions.totalCost : 0}
                />
            }
        </Table>
    )
}

