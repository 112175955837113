import { TableCreator } from "../TableMapperCreator"

export default class TableCreatorCreator {
    constructor(params) {
        this.filters = params?.tcFilters? params.tcFilters : null;
    }

    createTableCreator = (tableCreatorId, tableName, queries, onClickStrategy, displayStrategy, popupContentStrategy) => {
        const tableCreator = new TableCreator(tableCreatorId, tableName, queries, onClickStrategy, displayStrategy, popupContentStrategy)

        if (this.filters){
            tableCreator.setAdditionalFilters(this.filters)
        }

        if (this.parentProps){
            tableCreator.setTableCreatorCreatorParentProps(this.parentProps)
        }

        return tableCreator;
    }

    setfillters(filters) {
        this.filters = filters;
    }
    
    setClient = (client) => {
        this.client = client
    }

    setParentProps = (parentProps) => {
        this.parentProps = parentProps;
    }
}