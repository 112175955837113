/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { gql } from "@apollo/client"

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    GeneralChipComponentCreator,
    DataMapper,
    DataTransformer,
    NullClickStrategy, 
    NumberComponentCreator, 
    OnlyFilterActionDisplayStrategy, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription
} from '../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query MyTeamInventoryReport(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,  
        $costCenter:String,
        $period:String!,
        $manager:String,
        $enterpriseId:String,
        $title:String,
        $serviceNumber:String,
        $status:Boolean,
        $managerFirstName:String,
        $managerLastName:String,
        $firstName:String,
        $lastName:String,
        $currency:String,
    ) {
        myTeamInventoryReport(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            costCenter:$costCenter,
            period:$period,
            firstName:$firstName,
            lastName:$lastName,
            managerFirstName:$managerFirstName,
            managerLastName:$managerLastName,
            manager:$manager,
            enterpriseId:$enterpriseId,
            title:$title,
            serviceNumber:$serviceNumber,
            status:$status
        ) {
            edges {
                node {
                    inventory {
                        serviceNumber
                        status
                    }
                    items {
                        costCenter {
                            id
                            displayNameWithParents
                            displayName
                        }
                        percentage
                    }
                    manager {
                        lastName
                        firstName
                        enterpriseId
                    }
                    user {
                        firstName
                        lastName
                        title
                        enterpriseId
                    }
                    costs(currency:$currency) {
                        cost
                        cost2
                        cost3
                        average
                    }
                }
            }
            costs(currency:$currency) {
                cost
                cost2
                cost3
                average
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const queries = {tableQuery}

class MyTeamEmployeesTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "MyTeamEmployeesTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'managerFirstName',
                new DataMapper("manager.firstName"),
                new f.FilterDescription(new f.TextFilter("managerFirstName")),
                {label: 'Manager Name'}
            ),
            new TableFieldDescription(
                'managerLastName',
                new DataMapper("manager.lastName"),
                new f.FilterDescription(new f.TextFilter("managerLastName")),
                {label: 'Manager Last Name'}
            ),
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("user.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'Enterprise Id', onlyForm: true}
            ),
            new TableFieldDescription(
                'manager',
                new DataMapper("manager.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("manager")),
                {label: 'Manager Id',onlyForm: true}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("user.firstName"),
                new f.FilterDescription(new f.TextFilter("firstName")),
                {label: 'User Name'}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("user.lastName"),
                new f.FilterDescription(new f.TextFilter("lastName")),
                {label: 'User Last Name'}
            ),
            new TableFieldDescription(
                'title',
                new DataMapper("user.title"),
                new f.FilterDescription(new f.TextFilter("title")),
                {label: 'Title'}
            ),
            new TableFieldDescription(
                'status',
                new DataMapper("inventory.status"),
                new f.FilterDescription(new f.BooleanFilter("status")),
                {label: 'Line status',  componentCreator:new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'costcenters',
                new DataMapper("items"),
                new f.FilterDescription(new f.TextFilter("costCenter")),
                {
                    label: "Cost Centers", 
                    excelTransform :new DataTransformer(({items}) => items && items[0] && items[0].costCenter ? items[0].costCenter.displayName : "Not Assigned"), 
                    componentCreator: new GeneralChipComponentCreator(['costCenter.displayNameWithParents'], null, 'Not Assigned'),
                    transform: new DataTransformer(({items}) => items ? items : [] ) }
            ),
            new TableFieldDescription(
                'serviceNumber',
                new DataMapper("inventory.serviceNumber"),
                new f.FilterDescription(new f.TextFilter("serviceNumber")),
                {label: 'Line Number'}
            ),
            new TableFieldDescription(
                'period-2',
                new DataMapper("costs.cost3"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per2,
                    translateLabel: false,
                    componentCreator: new NumberComponentCreator(this.parentProps.symbol)
                }
            ),
            new TableFieldDescription(
                'period-1',
                new DataMapper("costs.cost2"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per1,
                    translateLabel: false,
                    componentCreator: new NumberComponentCreator(this.parentProps.symbol)
                }
            ),
            new TableFieldDescription(
                'period',
                new DataMapper("costs.cost"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per,
                    translateLabel: false,
                    componentCreator: new NumberComponentCreator(this.parentProps.symbol)
                }
            ),
            new TableFieldDescription(
                'average',
                new DataMapper("costs.average"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Average', componentCreator: new NumberComponentCreator(this.parentProps.symbol)}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(MyTeamEmployeesTableCreator)

export default MyTeamEmployeesTableCreator;
