import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper,
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription,
    DateComponentCreator,
    DateDisplayCreator,
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllLegalEntities(
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $createdAt: String,
        $taxId: String,
        $status: Boolean,
        $name: String,
        $domain: String,
        $orderField: String,
        $description: String,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
    ) {
        allLegalEntities(
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            name:$name,
            domain:$domain,
            description:$description,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            status:$status,
            taxId:$taxId,
            createdAt:$createdAt,
            orderField:$orderField,
        ) {
            count
            edges {
                node {
                    domain {
                        id
                        name
                    }
                    status
                    id
                    name
                    createdAt
                    taxId
                    description
                }
            }
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const queries = {tableQuery}

class InventoryLegalEntityMinimalTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryLegalEntityMinimalTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.setAdditionalFilters(this.filters)
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'taxId',
                new DataMapper("taxId"),
                new f.FilterDescription(new f.TextFilter("taxId")),
                {label: 'Tax Id'}
            ),
            new TableFieldDescription(
                'createdAt',
                new DataMapper("createdAt"),
                new f.FilterDescription(new f.DateFilter("createdAt")),
                {label: 'Creation Date', onlyForm: true, componentCreator: new DateComponentCreator(), excelDisplay: new DateDisplayCreator()}
            ),
            new TableFieldDescription(
                'status',
                new DataMapper("status"),
                new f.FilterDescription(new f.BooleanFilter("status")),
                {label: 'Status',  componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'description',
                new DataMapper("description"),
                new f.FilterDescription(new f.TextFilter("description")),
                {label: 'Description', onlyForm: true}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain'}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryLegalEntityMinimalTableCreator)

export default InventoryLegalEntityMinimalTableCreator;