import { createContext, useContext } from 'react'

export class UserAuthorizer {
    constructor(permissions) {
        this.permissions = permissions
    }

    has_permission(permission) {
        return this.permissions.includes(permission)
    }

    has_all_permissions(permissions) {
        return permissions.every(e => this.permissions.includes(e))
    }

    has_any_permission(permissions) {
        return permissions.some(e => this.permissions.includes(e))
    }
}

export const UserContext = createContext()

export const useUser = () => useContext(UserContext)