import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import { DevicesTableCreator } from '../../../tableDefinitions/Inventory/Devices/DevicesTableCreator'
import DevicesFormCreator from '../../../tableDefinitions/Inventory/Devices/DevicesFormCreator'

const Devices = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Devices')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Devices'}
            tableCreatorCreatorId={DevicesTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={DevicesFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Devices