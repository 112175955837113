/* eslint-disable no-unused-vars */
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import CostCenterMinimalTable from '../../../pages/Inventory/CostCenter/CostCenterMinimalTable'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { InventoryCostCenterTableCreatorQueries } from './InventoryCostCenterTableCreator'

const allDomain = `
    availableDomains {
        name
        id
    }
`

const managerPickerQuery = gql`query{
    allUsers(role:"Manager"){
        edges{
            node{
                id
                enterpriseId
                email
            }
        }
      }
    }`;

const managerEnterpriseId = data => data.enterpriseId
const managerEmail = data => data.email
const managerTransformer = new DataTransformer(managers => managers ? managers.edges.map(edge => edge.node) : [], managers => managers.edges.map(edge => edge.node.id), [])

class InventoryCostCenterFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "InventoryCostCenterFormCreator"
    }

    getFormCreator = () => {
        const formCreator  = this.createFormCreator(this.constructor.getId(), 'Cost Center', InventoryCostCenterTableCreatorQueries)
    
        const CostCenterTablePicker = new CostCenterMinimalTable({first: 15, orderDirection: "desc", orderField: "isActive",isActive:true})
        const generalSection = new FormSection('General Seccion', 1)
        const associationSection = new FormSection('Associations', 2)
        const parentSection = new FormSection('Parent', 3)
    
        formCreator.addInputs([
            new FormFieldDescription(
                'enterpriseId',
                new f.InputDescription(new f.TextCreator("enterpriseId"), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Code'}
            ),
            new FormFieldDescription(
                'parent',
                new f.InputDescription(new f.ModelCreator('parent', CostCenterTablePicker), false),
                parentSection,
                2,
                {label: 'Parent',transform: new DataTransformer((data) => data, data=>data)}
            ),
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                3,
                {label: 'Name'}
            ),
            new FormFieldDescription(
                'managers',
                new f.InputDescription(new f.MultiPickerCreator('managers', managerPickerQuery, "allUsers", [managerEnterpriseId, managerEmail]), false),
                associationSection,
                4,
                {label: 'Manager', transform: managerTransformer, prepareData: data => (data ? data.map(id => parseInt(id)) : [])}
            ),
            new FormFieldDescription(
                'type',
                new f.InputDescription(new f.TextCreator('type'), false),
                generalSection,
                5,
                {label: 'Type'}
            ),
            new FormFieldDescription(
                'businessUnit',
                new f.InputDescription(new f.TextCreator('businessUnit'), false),
                generalSection,
                6,
                {label: 'Business Unit'}
            ),
            new FormFieldDescription(
                'department',
                new f.InputDescription(new f.TextCreator('department'), false),
                generalSection,
                7,
                {label: 'Department'}
            ),
            new FormFieldDescription(
                'isActive',
                new f.InputDescription(new f.BoolCreator('isActive', false), false),
                generalSection,
                8,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'domain',
                new f.InputDescription(new f.DropDownCreator('domain'), true, new f.RequiredValidator(1)),
                generalSection,
                8,
                {label: 'Domain', queryAllDropdownOptions: allDomain, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                9,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                10,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                11,
                {label: 'Custom Attribute 3'}
            )
        ])

        return formCreator
    }
}
    
formCreatorCreatorFactoryCreator.addFormCreatorCreator(InventoryCostCenterFormCreator)

export default InventoryCostCenterFormCreator

