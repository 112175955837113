import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import ConceptConverterFormCreator from '../../../tableDefinitions/Billing/Converters/ConceptConverterFormCreator'
import { ConceptConverterTableCreator } from '../../../tableDefinitions/Billing/Converters/ConceptConverterTableCreator'

const Concept = (props) => {
    const { t } = useTranslation('billManagement')
    document.title = `${t('Concept Converters')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Concept Converters'}
            tableCreatorCreatorId={ConceptConverterTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={ConceptConverterFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Concept