import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    HideDeletionDisplayStrategy, 
    TableFieldDescription 
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllDestinationConverters(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $destination: String,
        $destinationDescription: String
    ) {
        allDestinationConverters(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            destination: $destination,
            destinationDescription: $destinationDescription
        ) {
            edges {
                node {
                    id
                    destination {
                        id
                        name
                    }
                    destinationDescription {
                        id
                        name
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation modifyDestinationConverterMutation($id: String!, $destination: String!, $destinationDescription: String!) {
        modifyDestinationConverter(id: $id, destination: $destination, destinationDescription: $destinationDescription) {
            destinationConverter {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createDestinationConverterMutation($destination: String!, $destinationDescription: String!) {
        createDestinationConverter(destination: $destination, destinationDescription: $destinationDescription) {
            destinationConverter {
                id
            }
        }
    }
`

const deleteMutator = null

const unitQuery = `
    destinationConverter(id: $id) {
        id
        destination {
            id
            name
        }
        destinationDescription {
            id
            name
        }
    }
`

const DestinationConverterQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class DestinationConverterTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "DestinationConverterTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, DestinationConverterQueries)
        tableCreator.setDisplayStrategy(new HideDeletionDisplayStrategy())
        tableCreator.setInitialSortField('destination', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'destination',
                new DataMapper("destination.name"),
                new f.FilterDescription(new f.TextFilter("destination")),
                {label: 'Destination'}
            ),
            new TableFieldDescription(
                'destinationDescription',
                new DataMapper("destinationDescription.name"),
                new f.FilterDescription(new f.TextFilter("destinationDescription")),
                {label: 'Destination Description'}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(DestinationConverterTableCreator)

export {
    DestinationConverterTableCreator,
    DestinationConverterQueries
}