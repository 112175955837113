import React from 'react';
import { TextField } from '@material-ui/core';

class NumericTextField extends React.Component {
  constructor(props) {
    super(props)
    this.savedValue = props.value;
  }

  formatCurrency = (value, inputRef=null) => {
    const decimalSeparator = '.'; 
    const thousandSeparator = ','; 

    const cursorPosition = inputRef ? inputRef.selectionStart : 0;

    const numberParts = value.split(decimalSeparator);
    if (numberParts.length > 2) {
      if (inputRef) {
        inputRef.selectionStart = inputRef.selectionEnd = cursorPosition-1;
      }
      return this.savedValue;
    }
  
    const isNegative = numberParts[0].charAt(0)==='-';
    const formattedValue = numberParts[0].replace(/[^\d]/g, '');

    const floatValue = parseFloat(formattedValue.replace(thousandSeparator, '')) * (isNegative ? -1 : 1);
    const localeString = isNaN(floatValue) ? "" : floatValue.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    
    const formattedNumber = localeString.concat((numberParts.length > 1) ? ".".concat(numberParts[1].replace(/[^\d]/g, '').substring(0,2)) : "");

    if (inputRef) {
      inputRef.value = formattedNumber;
      if (cursorPosition==value.length) {
        inputRef.selectionStart = inputRef.selectionEnd = formattedNumber.length;
      } else if (this.savedValue.length != formattedNumber.length) {
        const lengthDiff = Math.abs(this.savedValue.length - formattedNumber.length);
        inputRef.selectionStart = inputRef.selectionEnd = (this.savedValue.length > formattedNumber.length) ? cursorPosition-1 : cursorPosition+lengthDiff-1;
      } else {
        inputRef.selectionStart = inputRef.selectionEnd = cursorPosition-1;
      }
    }

    this.savedValue = formattedNumber;
    return formattedNumber;
  };

  handleChange = (event) => {
    const formattedValue = this.formatCurrency(event.target.value, event.target);

    this.props.onChange({
      target: {
        value: formattedValue,
      },
    });
  };

  render() {
    const formattedValue = this.formatCurrency(this.props.value || '');
    return (
      <TextField
        {...this.props}
        value={formattedValue}
        onChange={this.handleChange}
        InputProps={{
          //startAdornment: <span>$</span>,
          inputProps: { autoFocus: false },
        }}
      />
    );
  }
}

export default NumericTextField;