import React from "react"
import { useAxiosClient } from '../../contexts/Axios'
import { IconButton } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'
import {downloadFileFromUrl} from "./FileDownloader"

export const DownloadFile = props => {
    const { axiosClient } = useAxiosClient()
    return (
        <div key={`fileKey=${props.id}`} style={{textAlign:'center'}}>
        <IconButton onClick={ event => { event.stopPropagation(); downloadFileFromUrl(axiosClient, props.url ,props.id)}}>
            <DescriptionIcon />
        </IconButton>
        </div>
    )
}