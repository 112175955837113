
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'


export const ButtonTooltip = withStyles({
    tooltip: {
        fontSize: 16,
        backgroundColor: '#FFFFFF',
        color: '#000000',
        borderRadius:'5px 5px 5px 5px',
        border: "1px solid #103146",
    }
})(Tooltip)


export const TableHeaderTooltip = withStyles({
    tooltip: {
        fontSize: 18,
        marginTop: '-5px'
    }
})(Tooltip)