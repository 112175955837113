import React from 'react'

import { gql } from '@apollo/client'

import TableObjectCreator from '../../../../components/BPMTable/TableComponents/TableWrapper/TableObjectCreator'

import InvoiceContent from './InvoiceContentValidation'
import Loading from '../../../../components/Loading/Loading'

export default function InvoiceValidationQuery(props) {
    const {match, TableCreator} = props

    const TableObject = TableObjectCreator(TableCreator)

    const _QUERY = gql`
        query($id: String!) {
            ${TableCreator.unitQuery}
        }
    `
    return (
        <div>
            <Loading query={_QUERY} variables={{ id: match.params.id }}>
                {( data, refetch ) => {
                    return (
                        <div>
                            <InvoiceContent 
                                data={TableObject.popupMapper(data)}
                                refetch={refetch}
                                TableObject={TableObject}
                                client={TableObject.client}
                            />
                        </div>
                    )
                }}
            </Loading>
        </div>
    )
}