/* eslint-disable no-unused-vars */
import React, { Component } from 'react'

import { gql } from '@apollo/client'
import { Dialog, Slide } from '@material-ui/core'

//import Loading from '../../../components/loading'
import Loading from '../../../../components/Loading/Loading'
import HistoryOpener from "./HistoryTable"
import InventoryAssociationsHistoryOpener from './InventoryAssociationsHistory'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class PopupData extends Component {
    state = {
        open: false,
        data: '',
    }

    handleClose = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.setState({
            open: false,
        });

        this.props.close()
    };

    render() {
        const { client, openD, close, id, TableObject, handleSetFilters, classes, inventoryAssociations } = this.props;
        const handleClose = this.handleClose;
        const Get_data = gql`
    query ($id:String!){
        ${TableObject.unitQuery}
    }
    `;
        return (
            <Dialog
                open={openD}
                maxWidth={false}
                scroll="body"
                fullWidth
                TransitionComponent={Transition}
                disableEnforceFocus
                classes={{ paperFullWidth: classes.dialogWidth }}
                onClose={handleClose}
                id={'pickerDialog' + id}
            >
                <div onClick={(event) => event.stopPropagation()}>
                    <Loading query={Get_data} variables={{ id: id }} fetchPolicy="network-only">
                        {(data, refetch) => {
                            return (
                                <div>
                                    <span onClick={handleClose} style={{ fontSize: '1.5em', cursor: 'pointer', position: 'absolute', right: '10px', top: '5px' }}>&times;</span>
                                    {TableObject.popupContentStrategy({
                                        data: TableObject.popupMapper(data),
                                        refetch: refetch,
                                        close: {handleClose},
                                        handleSetFilters: handleSetFilters,
                                        TableObject: TableObject
                                    })}
                                    {inventoryAssociations ?
                                        <div style={{ float: "left" }}>
                                            <InventoryAssociationsHistoryOpener data={data} />
                                        </div>
                                        : null}
                                    <div style={{ float: "right" }}>
                                        <HistoryOpener TableObject={TableObject} query={TableObject.unitQuery} data={data} id={id} />
                                    </div>
                                </div>
                            )
                        }
                        }
                    </Loading>
                </div>
            </Dialog>
        )
    }
}

export default PopupData
