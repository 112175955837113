import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
} from  '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllInventoryTypes(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $technology:String,
        $name:String
    ) {
        allInventoryTypes(
            name:$name,
            technology:$technology,
            first:$first,
            last:$last,
            orderField:$orderField,
            after:$after,
            before:$before
        ) {
            edges {
                node {
                    name
                    id
                    technology {
                        name
                        id
                    }
                }
            }
        }
    }
`

const unitQuery = `
    inventoryTypeModel(id: $id) {
        id
        type
        technology {
            name
            id
        }
    }
`

const queries = {tableQuery, unitQuery}

class InventoryTypeMinimalTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryTypeMinimalTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.setAdditionalFilters(this.filters)
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'technology',
                new DataMapper("technology.name"),
                new f.FilterDescription(new f.TextFilter("technology")),
                {label: 'Technology'}
            )
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryTypeMinimalTableCreator)

export default InventoryTypeMinimalTableCreator;