/* eslint-disable eqeqeq */
import React from 'react'

import Table from '../../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'

import InventoryReportTableCreator from '../../../tableDefinitions/Inventory/InventoryReport/InventoryReportTableCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

function InventoryReportTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventoryReportTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('Inventory Report')

    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        />
    )
}

export default InventoryReportTable