const BPMTableStructure = {
    count: 0,
    filters: {filters: {}, orderField: null, orderDirection: null},
    //orderField: null, 
    //orderDirection: 'asc',
    selectAllState: {selectAll: false, selectedRows:[], selectedRefs: []}
    //selectedRows:[], 
    //selectedRefs: []
};

export default BPMTableStructure;