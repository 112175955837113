import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'

import { FormControl, Button, InputLabel, Select, MenuItem, Card, Typography } from '@material-ui/core'
import { Dialog, DialogContent, DialogTitle, Input, Box, Chip, Paper } from '@material-ui/core'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'  
import DeleteIcon from '@material-ui/icons/Delete'

import Loading from '../../../Loading/Loading'
import { DataMapper } from '../../TableMapperCreator'

import SetupTableData from '../TableWrapper/SetupTableData'
import { TableMultiPickerClickStrategy as clickStrategy } from '../../TableMapperCreator'

const ITEM_HEIGHT = 35;
const ITEM_PADDING_TOP = 8;

const menuprops = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        },
    },
};

function CustomTable(props) {
  const {
    items,
    labelTransformer,
    onToggle,
    side,
  } = props;

  const headerStyle = {
    backgroundColor:"#0A1F2E",
    height: '24 !important',
  }

  const rowStyle = {
    height: '24 !important',
  }

  const tableLabels = [
    {mapper:new DataMapper('name'), label:"Account"},
    {mapper:new DataMapper('description'), label:"Description"},
    {mapper:new DataMapper('provider.name'), label:"Provider"},
  ]

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead style={headerStyle}>
            <TableRow style={rowStyle}>
              {tableLabels.map(item=><TableCell align="center" style={{color:"white", height:"auto !important"}}>{item.label}</TableCell>)}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow onClick={() => onToggle(item.id, side)}>
                {labelTransformer.map(transformer => {
                  return (
                    <TableCell>
                      {String(transformer(item.item))}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

function TableMultiPickerSelector(props) {
  const {
    items,
    label,
    assigned,
    tblCreator,
    onSave,
    labelTransformer,
    inputLabel,
  } = props;

  const allItemIds = items.map(item => item.id);
  const initialLeft = allItemIds.filter(id => assigned.indexOf(id) < 0);

  const [tableCreator, setTableCreator] = useState(tblCreator);
  const [checked, setChecked] = useState([]);
  const [filter, setFilter] = useState("");
  const [currentLeft, setCurrentLeft] = useState([...initialLeft]);
  const [currentRight, setCurrentRight] = useState([...assigned]);
 
  tableCreator.onClickStrategy = new clickStrategy (
    data => {
      if (currentRight.indexOf(data.id) === -1) {
        var newSelected = [...currentRight]
        newSelected.push(data.id)
        setChecked(newSelected)
      }
    }
  )

  const onClear = () => {
    setChecked([]);
  }

  const handleSave = () => {
    const selectedItems = items.filter(item => currentRight.indexOf(item.id) >= 0);
    onSave(selectedItems);
  }

  const handleToggle = (id, side) => {
    if (side === "SELECTED") {
      var srcItems = [...currentRight];
      var dstItems = [...currentLeft];

    }
    else {
      var srcItems = [...currentLeft];
      var dstItems = [...currentRight];
    }

    const index = srcItems.indexOf(id);
    if (index > -1) {
      dstItems.push(id);
      srcItems.splice(index, 1);

      if (side === "SELECTED") {
        setCurrentLeft(dstItems);
        setCurrentRight(srcItems);
      }
      else {
        setCurrentLeft(srcItems);
        setCurrentRight(dstItems);
      }
    }
  }

  const buildFilterRegexp = () => {
    return RegExp(filter, "i");
  }
  
  const filterWith = (item, filter) => {
    return (
      !filter ||
      item.labels[0].match(buildFilterRegexp()) || 
      item.labels[1].match(buildFilterRegexp())
    )
  }

  return (
    <Box>
      <Card>
      <Box style={{margin:"0.5%"}}>
        <Box> 
          <Typography align="center" style={{ textAlign: "center", verticalAlign: "middle", fontWeight: "bold"}}>Selected data</Typography>
          <CustomTable labelTransformer={labelTransformer} items={items.filter(item => currentLeft.indexOf(item.id) >= 0).filter(item => filterWith(item, filter))} checked={checked} onToggle={handleToggle}/>
        </Box>
      </Box>
      <Box style={{margin:"2.0%"}}>
        <div>
          <Button onClick={handleSave} style={{ float:"left", display: "flex", justifyContent: "center", alignItems: "center", margin: "4.0" }} color="primary" variant="contained">Save</Button>        
          <Button onClick={onClear} style={{ float:"right", display: "flex", justifyContent: "center", alignItems: "center", margin: "4.0" }}>Clear<DeleteIcon/></Button> 
        </div>
      </Box>
      </Card>
      <Box className='mt-3'>
          <SetupTableData
              key={label}
              dialogId={'pickerDialog'+ label }
              TableCreator={tableCreator}
        />
      </Box>
    </Box>
  )
}


function createDataItem(item, labelTransformers) {
  return {
    id:item.id,
    item: item,
    labels: labelTransformers.map(transformer => String(transformer(item))),
  }
}

function getSelected(selectedList) {
  var control;
  control = selectedList.current.control;

  return control.selectedItems[0];
}

function TableMultiPicker2(props) {
  const {
    classes,
    label,
    id,
    t,
    required,
    query,
    formState,
    queryName,
    labelTransformer,
    inputId,
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(formState.displayData[inputId].map(item=>(createDataItem(item, labelTransformer))));

  const initialRender = useRef(true);

  useEffect(()=> {
    if (initialRender.current) {
      initialRender.current=false;
    } else {
      setSelectedData([]);
    }
  }, props.watchedStateIdentifierForSelectionClear ? [formState.mappedData[props.watchedStateIdentifierForSelectionClear]] : null);

  
  const getQueryVariables = () => {
    return props.queryVariablesTransformer ? props.queryVariablesTransformer(formState.mappedData) : null;
  }

  const handleSave = (selectedItems) => {
    const ids = selectedItems.map(item=> parseInt(item.id));
    const labels = selectedItems.map(item=>item.item);
    props.onChangeHandler(props.inputId, Object(ids), labels);
    setSelectedData(selectedItems);
    setDialogOpen(false);
  }

  const inputLabel = required ? required(t(label)) : t(label);
  const handleOpenDialog = () => {
      setDialogOpen(true);
  }

  return (
    <div>
      <div>
        <FormControl style={{width:300}} className={classes.formControl} >
          <InputLabel htmlFor={label}>{inputLabel}</InputLabel>
          <Select
            value={" "}
            IconComponent={SearchIcon}
            onOpen={handleOpenDialog}
            open={false}
            menuprops={menuprops}
            style={{width:'100%'}}
            className={classes.input}
            id={id}
            input={<Input />}
          >
            <MenuItem key={''} value={null} className={classes.input}> - </MenuItem>
          </Select>
        </FormControl>
        <Paper elevation={1} style={{width:300,margin:"-8px 0px 8px 8px"}}>
          <div style={{padding:10, overflow:'auto', maxHeight:300}}>
            {selectedData.length > 0 ? 
              <>
                {
                  selectedData.map(item=>
                    <Chip
                      style={{margin:2}}
                      color={'primary'}
                      size='small'
                      variant='outlined'
                      key={item.id}
                      label={item.labels[0]}
                    />
                  )
                }
              </>
            :
              <Chip style={{margin:2}}
              color={'primary'}
              size='medium'
              variant='outlined'
              label={"Unassigned"}
              />
            }
          </div>
        </Paper>
      </div>
      <Dialog 
        open={dialogOpen} 
        maxWidth='xl' 
        scroll="paper" 
        onClose={setDialogOpen(false)} 
        id={'pickerDialog'+ label }
      >
        <span onClick={()=>{setDialogOpen(false)}} style={{fontSize: '1.5em',  cursor: 'pointer', position: 'absolute', right: '10px', top: '5px'}}>&times;</span>
        <DialogTitle id="scroll-dialog-title" style={{fontSize:15}}>
          <span style={{fontSize:25}}>  {label}  </span>
        </DialogTitle>    
        <DialogContent>
          <Loading query={query} variables={getQueryVariables()}>
            {(data)=> { 
              const items = data[queryName].edges.map(edge => createDataItem(edge.node, labelTransformer))

              return(
                <TableMultiPickerSelector items={items} label={label} tblCreator={props.tableCreator} labelTransformer={labelTransformer} assigned={selectedData.map(item => String(item.id))} onSave={handleSave} inputLabel={inputLabel}/>
              )
            }}
          </Loading>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default withTranslation("TableMultiPicker2")(TableMultiPicker2)

