import React, { useState } from 'react'

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { ButtonTooltip } from '../../../Tooltips/BPMTooltips';
import { useTranslation } from 'react-i18next';

import SetupTableData from '../TableWrapper/SetupTableData';
import HistoryTableCreator from '../../../../tableDefinitions/HistoryTableCreator/HistoryTableCreator';
import tableCreatorCreatorFactoryCreator from '../../TableCreatorFactory/TableCreatorCreatorFactoryCreator';

function HistoryTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(HistoryTableCreator.getId(), null)
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('History');

    return (
        <SetupTableData
            filters={{ "id": props.id }}
            TableCreator={tableCreator}
            stickyHeader
        />
    )
}

function HistoryOpener(props) {
    const { t } = useTranslation('History')
    const [isOpen, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <div>
            <ButtonTooltip title={t('Change History')} onClick={handleOpen}>
                <SearchIcon style={{ color: '#6c6c6c', fontSize: '24px' }}/>
            </ButtonTooltip>
            <Dialog open={isOpen} maxWidth='lg' onClose={handleClose} id={'HistoryPickerDialog'}>
                <DialogTitle id="scroll-dialog-title" style={{ fontSize: 15 }}>
                    <span style={{ fontSize: 25 }}>  {t("Change History")}  </span>
                    <span onClick={handleClose} style={{ fontSize: '1.5em', cursor: 'pointer', position: 'absolute', right: '10px', top: '5px' }}>&times;</span>
                </DialogTitle>
                <DialogContent>
                    <HistoryTable id={props.id} t={t} />
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default HistoryOpener