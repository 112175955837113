/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'

import {Button} from '@material-ui/core'

export default function ChargeGroupsFilters(props) {
    const {data} = props
    const initialSelection = {}
    const allUnSelected = {}
    if (props.type == "selection") {
        data.map(item => (initialSelection[item.label] = true))
        data.map(item=> allUnSelected[item.label] = false)
    } else {
        data.map(item => (item.selected ? initialSelection[item.label] = true : initialSelection[item.label] = false))
        data.map(item=> allUnSelected[item.label] = false)
    }   
    
    const [selected,setSelected] = useState(initialSelection)
    
    useEffect(() => {
        props.onChange(selected)
    }, [selected])
    
    const handleClick = (label) =>{
        props.type == "selection" ? setSelected({...selected, [label]: !selected[label]}) : setSelected({...allUnSelected, [label]: true})
    }
    
    const setAll = () => {
        let areAllSelected = true
        for(let k in selected){
            if(!selected[k]){
                areAllSelected = false
                break
            }
        }

        if(!areAllSelected){
            setSelected(initialSelection)
        }
        else{
            setSelected(allUnSelected)
        }

    }


    return(
        <div className={props.className} style={{...props.style}}>
            {props.type == "selection" ?
                <Button 
                    variant="contained" 
                    className={JSON.stringify(selected) == JSON.stringify(initialSelection) ? "selected-charge" : "unselected-charge"} 
                    onClick={setAll}
                >
                    All
                </Button>
            : null}
            {data.map((item, index) => (
                <Button 
                    variant="contained" 
                    className={`${selected[item.label]? "" : "un"}selected-charge`}
                    onClick={() => handleClick(item.label)}
                    key={index}
                >
                    {item.label}
                </Button>
            ))}
        </div>
    )
}