import React from 'react'

import { useParams } from 'react-router-dom'

import Form from './Mutation'
import Loading from '../../../Loading/Loading'

const MutationSetup = (props) => {
    const { id } = useParams()
    const {client, TableObject, path, create, extraVariables} = props

    const mutationName = create ? 'new': 'edit';

    return (
        <Loading
            query={TableObject.mutationQuery(mutationName)}
            variables={{id: id, ...extraVariables}}
            fetchPolicy='network-only'
        >
            {data => {
                if (data) {
                    return (
                        <div>
                            <Form 
                                data={data}
                                client={client}
                                TableObject={TableObject}
                                create={create}
                                mutationQuery={TableObject.getMutation(mutationName)}
                                path={path}
                            />
                        </div>
                    )
                }
            }}
        </Loading>
    )
}

export default MutationSetup