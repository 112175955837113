import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    HideDeletionDisplayStrategy, 
    TableFieldDescription 
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllConcepts(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $name: String,
    ) {
        allChargeGroupUssageTypes(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            name:$name){
            edges{
                node{
                    id
                    ussageType
                    chargeGroup{
                        name
                        id
                    }
                }
            }
        }
    }
`

const unitQuery = `
    chargeGroupUssage(id: $id) {
        id
        chargeGroup{
            id
            name
        }
        ussageType
    }
`

const editMutator = gql`
    mutation editChargeGroupUssage($id: String!, $chargeGroup: String!, $ussage: String!) {
        editChargeGroupUssage(id: $id, chargeGroup: $chargeGroup, ussage: $ussage) {
            chargeGroupUssage {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createChargeGroupUssage($chargeGroup: String!, $ussage: String!) {
        createChargeGroupUssage(chargeGroup: $chargeGroup, ussage: $ussage) {
            chargeGroupUssage {
                id
            }
        }
    }
`

const ChargeGroupUssageTableCreatorQueries = {tableQuery, unitQuery, editMutator, newMutator}

class ChargeGroupUssageTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ChargeGroupUssageTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, ChargeGroupUssageTableCreatorQueries)
        tableCreator.setDisplayStrategy(new HideDeletionDisplayStrategy())
        tableCreator.addDescriptions ( [
            new TableFieldDescription(
                'name',
                new DataMapper("chargeGroup.name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Charge Group'}
            ),
            new TableFieldDescription(
                'ussageType',
                new DataMapper("ussageType"),
                new f.FilterDescription(new f.TextFilter("")),
                {label: 'Ussage Type'}
            ),
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ChargeGroupUssageTableCreator)

export {
    ChargeGroupUssageTableCreator,
    ChargeGroupUssageTableCreatorQueries
}