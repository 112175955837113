import React, { useState, useEffect } from 'react'

import { Link, useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { InputAdornment, TextField } from '@material-ui/core'
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@material-ui/icons'
import { primaryColor, disabledColor  } from '../../utils/constants'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { AddBPMApplicationMessages } from '../../redux/reducers/BPMReducer'
import { ButtonTooltip } from '../../components/Tooltips/BPMTooltips'

import classes from './NewPassword.module.css'
import CustomPrimaryButton from '../../components/CustomPrimaryButton/CustomPrimaryButton'
import { validatePassword, passwordValidationMessages, getNewPasswordValidationMessage } from '../Password/Utils'

const PASSWORD_CHANGE = gql`
    mutation($uid: String!, $token: String!, $password: String!) {
        passwordChange(uid: $uid, token: $token, password: $password) {
            success
        }
    }
` 

const NewPassword = () => {
    const { userID, token } = useParams()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [newPassword, setNewPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [errMsg, setErrMsg] = useState('')

    const validationMessages = passwordValidationMessages(t)

    const [passwordChange, {loading}] = useMutation(PASSWORD_CHANGE)

    useEffect(() => {
        if (errMsg.length > 0) {
            dispatch(AddBPMApplicationMessages({
                text: errMsg,
                variant: 'error',
            }))
        }
    }, [errMsg])

    const handleSubmit = (event) => {
        event.preventDefault()

        if (!validatePassword(newPassword)) {
            setErrMsg(validationMessages.errorPasswordValidation)
            setError(true)
            return;
        }

        if (repeatPassword != newPassword) {
            setErrMsg(validationMessages.strPasswordMatch)
            setError(true)
            return;
        }

        setErrMsg('')
        setError(false)

        passwordChange({
            variables: {
                uid: userID, 
                token: token, 
                password: newPassword
            }
        })
        .then(result => {
            const _success = result.data.passwordChange.success
            if (_success) {
                dispatch(AddBPMApplicationMessages({
                    text: t('yourPasswordHasBeenSet'),
                    variant: 'success',
                }))
                setSuccess(true);
                localStorage.clear();
                window.location.href = '/';
            } else {
                setErrMsg(t('passwordResetError'))
                setError(true)
            }
        })
        .catch(err => {
            setErrMsg(t('passwordResetError'))
            setError(true)
        })
    }

    return (
        <div className={classes.Container}>
            <form className={classes.NewPasswordForm} onSubmit={handleSubmit}>
                <div key={'login_logo'} className={classes.Logo}>
                    <img src={'//d20xc7q49jirs4.cloudfront.net/static/img/login.png'} alt='bubble_logo' style={{width: '280px'}} />
                </div>

                {success ? 
                    <>
                        <h4 style={{fontWeight: 'bold', marginTop: '10px'}}>{t('passwordResetComplete')}</h4>
                        <p style={{textAlign: 'justify', marginTop: '5px', marginBottom: '15px'}}>{t('yourPasswordHasBeenSet')}</p>
                        <p style={{textAlign: 'center', margin: '10px 0'}}><Link to='/login'>{t('login')}</Link></p>
                    </>
                        :
                    <>
                        <h4 style={{fontWeight: 'bold', marginTop: '10px'}}>{t('enterNewPassword')}</h4>
                        <p style={{textAlign: 'justify', marginTop: '5px', marginBottom: '15px'}}>
                            {t('pleaseEnterNewPassword')}
                        </p>

                        <TextField
                            label={t("newPassword")}
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className={classes.InputText}
                            InputProps={{
                                endAdornment:
                                    <ButtonTooltip title={getNewPasswordValidationMessage({newPassword: newPassword, repeatPassword: repeatPassword}, validationMessages)} placement={'right'} open={repeatPassword.length > 0 || (newPassword.length > 0 && !validatePassword(newPassword))}>
                                        <InputAdornment position='end'>
                                            <CheckCircleOutlineIcon style={{ color: validatePassword(newPassword) ? primaryColor : disabledColor }} />
                                        </InputAdornment>
                                    </ButtonTooltip>
                            }}
                        />

                        <TextField
                            label={t("repeatPassword")}
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            className={classes.InputText}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position='end'>
                                        <CheckCircleOutlineIcon style={{ color: validatePassword(newPassword) ? primaryColor : disabledColor }} />
                                    </InputAdornment>
                            }}
                        />

                        <div className={classes.Error}>
                            {error ? (
                                <p>{errMsg}</p>
                            ) : <p>&nbsp;</p>}
                        </div>

                        <CustomPrimaryButton
                            type='submit' 
                            label={t('changeMyPassword')}
                            disabled={loading || newPassword !== repeatPassword || newPassword.length < 6 }
                            className={classes.Button}
                        />
                    </>    
                }
            </form>
        </div>
    )
}

export default NewPassword