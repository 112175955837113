import React, { useState } from 'react'

import { Card, Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { thousandSeparator } from '../../utils/tools'

const BudgetReportStatistics = (props) => {
    const { t } = props
    const [symbol] = useState(props.symbol)
    const [generalStatistics] = useState(props.generalStatistics)
    const [lastMonth] = useState(props.monthlyStatistics?.lastMonth? props.monthlyStatistics.lastMonth : {})
    const [last6MonthsAvg] = useState(props.monthlyStatistics?.last6MonthsAvg? props.monthlyStatistics.last6MonthsAvg : {})
    const [fullPeriodMonthlyAvg] = useState(props.monthlyStatistics?.lastMonth? props.monthlyStatistics.fullPeriodMonthlyAvg : {})

    const rowStyle = {
        border:'0.1em solid #090909',
        height: "25px",
        paddingTop: 0.8, 
        paddingBottom: 0
    };

    const rowMontStyle = {
        height: "25px",
        paddingTop: 0.8, 
        paddingBottom: 0
    };

    const cellStyle = {
        fontWeight: 'bold',
        textAlign: 'left',
    };

    const cellNumberStyle = {
        fontWeight: 'bold',
        textAlign: 'right',
    };

    const formatValue = (number) => {
        return symbol + " " + (number ? thousandSeparator(number) : "0.00")
    }

    const formatPercent = (number) => {
        return number ? (number).toFixed(2) + "%" : "0.00%"
    }

    return ( 
        <Card id="budgetStatistics" style={{ height: 200 }}>
            <div style={{ padding: '20px 20px 20px 20px', display: 'flex'}}>
                <div id="leftTable" style={{ maxWidth: '40%' }}>
                    <Table size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow style={rowStyle}>
                                <TableCell style={cellStyle}>{t("budget:Budget Expense")}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(generalStatistics.budgetExpense)}</TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={cellStyle}>{t("budget:Actual Expense")}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(generalStatistics.actualExpense)}</TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={cellStyle}>{t("budget:Budget Balance")}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(generalStatistics.budgetBalance)}</TableCell>
                            </TableRow>
                            <TableRow style={rowStyle}>
                                <TableCell style={cellStyle}>%{t("budget:Act / Budg")}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatPercent(generalStatistics.actualExpense/generalStatistics.budgetExpense*100)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
                <div id="rifghtTable" style={{ maxWidth: '60%', paddingLeft: 100 }}>
                    <Table size="small" aria-label="a dense table">
                        <TableBody>
                            <TableRow style={rowMontStyle}>
                                <TableCell/>
                                <TableCell style={cellNumberStyle}>{t("budget:Budget")}</TableCell>
                                <TableCell style={cellNumberStyle}>{t("budget:Actual")}</TableCell>
                                <TableCell style={cellNumberStyle}>%{t("budget:A/B")}</TableCell>
                            </TableRow>
                            <TableRow style={rowMontStyle}>
                                <TableCell style={cellStyle}>{t("budget:Last Month")}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(lastMonth.budgetExpense)}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(lastMonth.actualExpense)}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatPercent(lastMonth.actualExpense/lastMonth.budgetExpense*100)}</TableCell>
                            </TableRow>
                            <TableRow style={rowMontStyle}>
                                <TableCell style={cellStyle}>{t("budget:Last 6 months average")}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(last6MonthsAvg.budgetExpense)}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(last6MonthsAvg.actualExpense)}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatPercent(last6MonthsAvg.actualExpense/last6MonthsAvg.budgetExpense*100)}</TableCell>
                            </TableRow>
                            <TableRow style={rowMontStyle}>
                                <TableCell style={cellStyle}>{t("budget:Full Period monthly average")}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(fullPeriodMonthlyAvg.budgetExpense)}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatValue(fullPeriodMonthlyAvg.actualExpense)}</TableCell>
                                <TableCell style={cellNumberStyle}>{formatPercent(fullPeriodMonthlyAvg.actualExpense/fullPeriodMonthlyAvg.budgetExpense*100)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        </Card>
    )
}

export default BudgetReportStatistics
