/* eslint-disable no-unused-vars */
import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import {FormControl, FormControlLabel, Switch, Typography} from '@material-ui/core'

class SwitchInput extends Component {
  render() {
    const {required, classes, label, id, formState, onChangeHandler, inputId, t} = this.props;
    const inputLabel = required ? required(t(label)) : t(label);

    return (
      <FormControl className={classes.formControl} key={"formControl"+id}>
        <FormControlLabel
          control={
            <Switch
              checked={formState.mappedData[inputId]}
              onChange={event => onChangeHandler(inputId, event.target.checked)}
            />
          }
          className={classes.input}
          style={{margin:0,width:250, height:56}}
          label={<Typography>{inputLabel}</Typography>}
          key={"input"+id}
        />
      </FormControl>
    )
  }
}

export default withTranslation('fieldLabels')(SwitchInput);