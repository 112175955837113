import { FormCreator } from "../TableMapperCreator"

export default class FormCreatorCreator {
    constructor(params) {
        this.params = params;
    }

    createFormCreator = (formCreatorId, name, queries, onConfirmFormStrategy, onCancelFormStrategy) => {
        return new FormCreator(formCreatorId, name, queries, onConfirmFormStrategy, onCancelFormStrategy)
    }
}