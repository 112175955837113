import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DefaultClickStrategy, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription, 
    DateComponentCreator,
    DateDisplayCreator,
} from '../../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from "../../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllLegalEntities(
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $createdAt: String,
        $taxId: String,
        $status: Boolean,
        $name: String,
        $domain: String,
        $orderField: String,
        $description: String,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
    ) {
        allLegalEntities(
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            name:$name,
            domain:$domain,
            description:$description,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            status:$status,
            taxId:$taxId,
            createdAt:$createdAt,
            orderField:$orderField,
        ) {
            count
            edges {
                node {
                    domain {
                        id
                        name
                    }
                    status
                    id
                    name
                    createdAt
                    taxId
                    description
                }
            }
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editeLegalEntityMutation(
        $id: String!,
        $name: String!,
        $description: String,
        $taxId: String,
        $status: Boolean,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $domain: String
    ) {
        editLegalEntity(
            id: $id,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            name: $name,
            status: $status,
            domain: $domain,
            description: $description,
            taxId: $taxId
        ) {
            legalEntity {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createLegalEntityMutation(
        $name: String!,
        $description: String,
        $taxId: String,
        $status: Boolean,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $domain:String
    ) {
        createLegalEntity(
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            name: $name,
            status: $status,
            description: $description,
            taxId: $taxId,
            domain: $domain
        ) {
            legalEntity {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeLegalEntityMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeLegalEntity(ids: $ids, allRecords: $allRecords, filters: $filters) {
            legalEntity {
                id
            }
        }
    }
`

const unitQuery = `
    legalEntity(id: $id) {
        id
        name
        taxId
        status
        createdAt
        domain {
            name
            id
        }
        description
        customAttr1
        customAttr2
        customAttr3
    }
`

const InventoryLegalEntityTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class InventoryLegalEntityTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryLegalEntityTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InventoryLegalEntityTableCreatorQueries, new DefaultClickStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
        tableCreator.setInitialSortField('status', 'desc')
        tableCreator.addDescriptions ( [
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'taxId',
                new DataMapper("taxId"),
                new f.FilterDescription(new f.TextFilter("taxId")),
                {label: 'Tax Id'}
            ),
            new TableFieldDescription(
                'createdAt',
                new DataMapper("createdAt"),
                new f.FilterDescription(new f.DateFilter("createdAt")),
                {label: 'Creation Date', onlyForm: true, componentCreator: new DateComponentCreator(), excelDisplay: new DateDisplayCreator()}
            ),
            new TableFieldDescription(
                'status',
                new DataMapper("status"),
                new f.FilterDescription(new f.BooleanFilter("status")),
                {label: 'Status',  componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'description',
                new DataMapper("description"),
                new f.FilterDescription(new f.TextFilter("description")),
                {label: 'Description', onlyForm: true}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain'}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper("customAttr1"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper("customAttr2"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper("customAttr3"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 3', onlyForm: true}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryLegalEntityTableCreator)

export {
    InventoryLegalEntityTableCreator,
    InventoryLegalEntityTableCreatorQueries
}