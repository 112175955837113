import React, {useEffect} from 'react'
import SetupTableData from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import ConsolidatedSBOResultsTablerCreator from '../../tableDefinitions/ConsolidatedSBO/ConsolidatedSBOResultsTablerCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const Results = (props) => {
    useEffect(() => {
        props.onSelected(true)
    });

    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(ConsolidatedSBOResultsTablerCreator.getId(), {tcFilters: {first:30}})
    const tableCreator = tableCreatorCreator.getTableCreator("SBO Results")
 
    return (
      <SetupTableData
          TableCreator={tableCreator}
          stickyHeader
      />
  )
}

export default Results
