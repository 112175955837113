/* eslint-disable eqeqeq */
import React from 'react'

import { useTranslation } from 'react-i18next'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import ExcelExportsTableCreator from '../../../tableDefinitions/Jobs/ExcelExports/ExcelExportsTableCreator'

function ExcelExports(props){
    const { t } = useTranslation(['ExcelExports'])
    document.title = `${t('My Excel exports')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'My Excel exports'}
            tableCreatorCreatorId={ExcelExportsTableCreator.getId()}
            path={props.match.url}
        />
    )
}

export default ExcelExports