import React from 'react'

import TotalTableEstructure from './TotalTable'

export default function CostCenterTotals(props) {
    return (
        <TotalTableEstructure
            data={[
                {symbol:props.symbol,value:props.data.cost3},
                {symbol:props.symbol,value:props.data.cost2},
                {symbol:props.symbol,value:props.data.cost},
                {symbol:props.symbol,value:props.data.average}
            ]}
            visibleColumns={props.visibleColumns} 
        />
    )
}