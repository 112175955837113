import React from 'react'

import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import RankingTotals from './RankingTotals'
import RankingSpendsTableCreator from '../../tableDefinitions/Ranking/RankingSpendsTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

function RankingSpendsTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(RankingSpendsTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('Ranking Spends')

    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        >
            {(data, managerTable) =>
                <RankingTotals 
                    visibleColumns={managerTable.getVisibleFields(true).length}
                    symbol={props.symbol}
                    data={data ? { cost: data.rankingBySpend.totalCost } : { cost: 0 }}
                />
            }
        </Table>
    )
}

export default (RankingSpendsTable)