import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { ConceptConverterQueries } from './ConceptConverterTableCreator'

const allConcepts = `
    allConcepts {
        edges {
            node {
                id
                name
                technology {
                    name
                    id
                }
            }
        }
    }
`

const allChargeDescriptions = `
    allChargeDescriptions(onlyAvailable: true) {
        edges {
            node {
                id
                name
            }
        }
    }
`

class ConceptConverterFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "ConceptConverterFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Concept Converter', ConceptConverterQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'concept',
                new f.InputDescription(new f.DropDownCreator('concept'), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'Concept', queryAllDropdownOptions: allConcepts, transform: new DataTransformer(data => `${data.name} (${data.technology.name})`)}
            ),
            new FormFieldDescription(
                'chargeDescription',
                new f.InputDescription(new f.DropDownSearchCreator('chargeDescription'), true, new f.RequiredValidator(1)),
                generalSection,
                3,
                {label: 'Charge Description', queryAllDropdownOptions: allChargeDescriptions, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'chargeDescriptionDriver',
                new f.InputDescription(new f.TextCreator('chargeDescriptionDriver'), false),
                generalSection,
                4,
                {label: 'Charge Description Driver', readOnly: true, dataMapper: (data => data?.chargeDescription?.driver?.name ? data.chargeDescription.driver.name : null)}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(ConceptConverterFormCreator)

export default ConceptConverterFormCreator