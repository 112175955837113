import React from 'react'

import Loading from '../../../../../components/Loading/Loading'

export default function FormQuery(props) {
    const {  Form, close, refetch, QUERY } = props
    return (
        <div>
            <Loading query={QUERY} showLoading={false}>
                {(data) => {
                    return (
                        <div>
                            { React.createElement(Form,{data:data, refetch:refetch, close: close}) }
                        </div>
                    )
                }}
            </Loading>
        </div>
    )
}