import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { InventoryLegalEntityTableCreatorQueries } from './InventoryLegalEntityTableCreator'

const allDomain = `
    availableDomains {
        name
        id
    }
`

class InventoryLegalEntityFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "InventoryLegalEntityFormCreator"
    }

    getFormCreator = () => {
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Legal Entity', InventoryLegalEntityTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextCreator("name"), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Name'}
            ),
            new FormFieldDescription(
                'taxId',
                new f.InputDescription(new f.TextCreator("taxId"), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'Tax Id'}
            ),
            new FormFieldDescription(
                'status',
                new f.InputDescription(new f.BoolCreator("status"), false),
                generalSection,
                4,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'description',
                new f.InputDescription(new f.TextAreaCreator("description"), false),
                generalSection,
                4,
                {label: 'Description'}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator("customAttr1"), false),
                generalSection,
                5,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'domain',
                new f.InputDescription(new f.DropDownCreator('domain'), true, new f.RequiredValidator(1)),
                generalSection,
                8,
                {label: 'Domain', queryAllDropdownOptions: allDomain, transform: new DataTransformer(data => data.name)}
            ),    
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator("customAttr2"), false),
                generalSection,
                6,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator("customAttr3"), false),
                generalSection,
                7,
                {label: 'Custom Attribute 3'}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(InventoryLegalEntityFormCreator)

export default InventoryLegalEntityFormCreator;