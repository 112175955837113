import React from 'react'
import { useTranslation } from 'react-i18next'

import DestinationFormCreator from '../../../tableDefinitions/Billing/Destination/DestinationFormCreator'
import { DestinationTableCreator } from '../../../tableDefinitions/Billing/Destination/DestinationTableCreator'
import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'

const Destination = (props) => {
    const { t } = useTranslation('billManagement')
    document.title = `${t('Destinations')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Destinations'}
            tableCreatorCreatorId={DestinationTableCreator.getId()}
            formCreatorCreatorId={DestinationFormCreator.getId()}
            path = {props.match.url}
        />
    )
}

export default Destination