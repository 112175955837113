import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'


class NameValueInput extends Component {
    render() {
        const {readOnly, required, label, id, formState, inputId, t} = this.props;
        const inputLabel = required ? required(t(label)) : t(label);
        return (
			<div key={"name_value_"+id}>
				<div style={{paddingTop: 7, fontSize: 12, color: "gray"}} key={"name_"+id}>
                    {inputLabel}
				</div>
				<div style={{paddingTop: 2, fontSize: 18}} key={"value"+id}>
                    {formState.mappedData[inputId] !== null? formState.mappedData[inputId] : 'VALOR TEMPORAL'}
				</div>
			</div>
        )
    }
}

export default withTranslation('fieldLabels')(NameValueInput);