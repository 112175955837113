import { gql } from '@apollo/client'

export const QUERY = gql`
    query(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $status: Boolean,
        $invoice: String!,
        $validated: Boolean,
        $serviceNumber: String,
        $serviceType: String,
        $devices: String,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $sites: String,
        $updatedAt: String
    ) {
        unvalidatedInventoriesByInvoice(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            before:$before,
            invoice:$invoice,
            status: $status,
            validated: $validated,
            serviceType: $serviceType,
            devices: $devices,
            sites: $sites,
            serviceNumber: $serviceNumber,
            updatedAt: $updatedAt
        ) {
            edges {
                node {
                    id
                    serviceNumber
                    serviceType {
                        id
                        technology {
                            name
                            id
                        }
                        name
                    }
                    domain {
                        name
                    }
                    status
                    validated
                    updatedAt
                    customAttr1
                    customAttr2
                    customAttr3
                    devices {
                        device {
                            model {
                                name
                            }
                        }
                    }
                    sites {
                        id
                        site {
                            id
                            name
                        }
                    }
                    costCenters {
                        id
                        items {
                            id
                            costCenter {
                                id
                                displayNameWithParents
                            }
                            percentage
                        }
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

export const MUTATION = gql`
    mutation($invoice: String!) {
        validateAllInvoiceInventories(invoice: $invoice) {
            response
        }
    }
`

export const VALIDATE_SELECTED_MUTATION = gql`
    mutation($selectedInventories: [String], $invoice: String!) {
        validateSelectedInventories(selectedInventories: $selectedInventories, invoice: $invoice) {
            response
        }
    }
`