/* eslint-disable eqeqeq */
import React, { useState } from 'react'

import { gql } from '@apollo/client'
import { MenuItem, Select } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import Loading from '../Loading/Loading'
import { useAllCurrencies } from '../../redux/selectors'

const query = gql`
    query($period:String, $currency:String, $baseCurrency:String){
        currencyExchange(period:$period, currency:$currency, baseCurrency:$baseCurrency){
            value
        }
    }
`

export const CurrencyPicker = (props) => {
    const { year, month, selected, t } = props

    return (
        <Loading
            query={query}
            showLoading={false}
            variables={{ period: `${year}-${month}`, currency: selected, baseCurrency: "USD" }}
            showPreviousDataWhileLoading={true}
        >
            {(data) => {
                return (
                    <Currency
                        t={t}
                        value={data.currencyExchange?.value}
                        style={props.style}
                        selected={selected}
                        onChangeCurrency={props.onChangeCurrency}
                    />
                )
            }}
        </Loading>
    )
}

export const Currency = (props) => {
    const { t } = props
    const allCurrencies = useAllCurrencies()

    const [selected, setSelected] = useState(props.selected || allCurrencies[0].node.id);

    const handleChange = (event) => {
        setSelected(event.target.value)
        let codeFilter = allCurrencies.filter(item => item.node.code == event.target.value)
        let _symbol = codeFilter[0].node.symbol

        return (
            <>
                {props.onChangeCurrency ? props.onChangeCurrency(event.target.value, _symbol) : null}
            </>
        )
    }

    return (
        <div>
            <span style={{ color: '#0000008a' }}>{t("fieldLabels:Currency") + ": "}</span>
            <Select
                style={props.style}
                value={selected}
                onChange={handleChange}
            >
                {allCurrencies.map((currency) => (
                    <MenuItem
                        key={currency.node.id}
                        value={currency.node.id}>
                        {(currency.node.code + " - " + currency.node.name) || null}
                    </MenuItem>
                ))}
            </Select>

            <span style={{ marginLeft: "10px", color: '#0000008a' }}>{`1 USD = ${props.value.toFixed(2)}${selected}`}</span>
        </div>

    )
}

export default withTranslation("CurrencyPicker")(CurrencyPicker)