import { createContext, useContext } from 'react'

import axios from 'axios'

export const axiosClientCreator = (jwt, baseURL) => {
    return axios.create({
        baseURL: baseURL,
        headers: {
            'Authorization': `JWT ${jwt}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*/*'
        }
    })
}

export const AxiosContext = createContext()

export const useAxiosClient = () => useContext(AxiosContext)