import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer,
    GeneralChipComponentCreator,
    GeneralExcelDisplayCreator,
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription 
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllUsers(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $isActive: Boolean,
        $customAttr1: String,
        $customAttr2: String,
        $enterpriseId: String,
        $customAttr3: String,
        $firstName: String,
        $middleName: String,
        $dateHired: String,
        $dateTerminated: String,
        $language: String,
        $currency: String,
        $lastName: String,
        $title: String,
        $role: String,
        $email: String,
        $costCenter: String,
        $managerEnterpriseId: String,
        $managerFirstName: String,
        $managerLastName: String,
        $domain: String
    ) {
        allUsers(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            internalUser:false,
            customAttr1:$customAttr1,
            middleName:$middleName,
            dateHired:$dateHired,
            dateTerminated:$dateTerminated,
            language:$language,
            currency:$currency,
            enterpriseId:$enterpriseId,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            isActive:$isActive,
            firstName:$firstName,
            lastName:$lastName,
            title:$title,
            costCenter:$costCenter,
            role:$role,
            email:$email,
            managerEnterpriseId:$managerEnterpriseId,
            managerFirstName:$managerFirstName,
            managerLastName:$managerLastName,
            domain:$domain
        ) {
            edges {
                node {
                    id
                    email
                    enterpriseId
                    firstName
                    middleName
                    lastName
                    isActive
                    manager {
                        id
                        enterpriseId
                        firstName
                        lastName
                    }
                    title
                    customAttr1
                    customAttr2
                    customAttr3
                    language {
                        id
                        codename
                    }
                    currency {
                        id
                        code
                    }
                    dateHired
                    dateTerminated
                    role
                    domains {
                        id
                        name
                    }
                    costCenter {
                        id
                        displayName
                        enterpriseId
                    }
                    internalUser
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editUserMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $email: String,
        $role: String,
        $firstName: String,
        $middleName: String,
        $lastName: String,
        $isActive: Boolean,
        $costCenter: String,
        $manager: String,
        $title: String,
        $enterpriseId:String!,
        $language: String,
        $currency: String,
        $dateHired: String,
        $domains: [Int],
        $id: String!,
    ) {
        editUser(
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            email: $email,
            role: $role,
            firstName: $firstName,
            middleName: $middleName,
            costCenter: $costCenter,
            lastName: $lastName,
            enterpriseId: $enterpriseId,
            isActive: $isActive,
            manager: $manager,
            title: $title,
            language: $language,
            currency: $currency,
            dateHired: $dateHired,
            domains: $domains,
            id: $id
        ) {
            user {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createUserMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $dateHired: String,
        $email: String!,
        $role: String,
        $firstName: String,
        $middleName: String,
        $lastName: String,
        $costCenter: String,
        $isActive: Boolean,
        $enterpriseId:String!,
        $manager: String,
        $title: String,
        $language: String,
        $currency: String,
        $domains: [Int],
    ) {
        createUser(
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            dateHired : $dateHired,
            email: $email,
            firstName: $firstName,
            role: $role,
            middleName: $middleName,
            enterpriseId: $enterpriseId,
            costCenter: $costCenter,
            lastName: $lastName,
            isActive: $isActive,
            manager: $manager,
            title: $title,
            language: $language,
            currency: $currency,
            domains: $domains
        ) {
            user {
                id
            }
        }   
    }
`

const deleteMutator = gql`
    mutation removeUserMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeUser(ids: $ids, allRecords: $allRecords, filters: $filters) {
            user {
                id
            }
        }
    }
`

const unitQuery = `
    user(id: $id) {
        id
        email
        enterpriseId
        firstName
        middleName
        lastName
        isActive
        manager {
            id
            enterpriseId
            firstName
            lastName
            email
        }
        title
        customAttr1
        customAttr2
        customAttr3
        language {
            id
            codename
        }
        currency {
            id
            code
        }
        dateHired
        dateTerminated
        role
        domains {
            id
            name
        }
        costCenter {
            id
            displayName
            enterpriseId
            parent{
                enterpriseId
                displayName
            }
        }
    }
`


const InventoryUserTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class InventoryUserTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryUserTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InventoryUserTableCreatorQueries)
        tableCreator.setInitialSortField('isActive', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'Enterprise Id'}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("firstName"),
                new f.FilterDescription(new f.TextFilter("firstName")),
                {label: 'First Name'}
            ),
            new TableFieldDescription(
                'middleName',
                new DataMapper("middleName"),
                new f.FilterDescription(new f.TextFilter("middleName")),
                {label: 'Middle Name', onlyForm: true}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("lastName"),
                new f.FilterDescription(new f.TextFilter("lastName")),
                {label: 'Last Name'}
            ),
            new TableFieldDescription(
                'role',
                new DataMapper("role"),
                new f.FilterDescription(new f.UsersRoleFilter("role")),
                {label: 'Role', transform: new DataTransformer(({role}) => role.charAt(0) + role.slice(1).toLowerCase())}
            ),
            new TableFieldDescription(
                'title',
                new DataMapper("title"),
                new f.FilterDescription(new f.TextFilter("title")),
                {label: 'Title'}
            ),
            new TableFieldDescription(
                'email',
                new DataMapper("email"),
                new f.FilterDescription(new f.TextFilter("email")),
                {label: 'Email'}
            ),
            new TableFieldDescription(
                'costCenter',
                new DataMapper("costCenter.displayName"),
                new f.FilterDescription(new f.NullTextFilter("costCenter")),
                {label: 'Cost Center'}
            ),
            new TableFieldDescription(
                'dateHired',
                new DataMapper("dateHired"),
                new f.FilterDescription(new f.DateFilter("dateHired")),
                {label: 'Date Hired', onlyForm: true}
            ),
            new TableFieldDescription(
                'dateTerminated',
                new DataMapper("dateTerminated"),
                new f.FilterDescription(new f.DateFilter("dateTerminated")),
                {label: 'Date Terminated', onlyForm: true}
            ),
            new TableFieldDescription(
                'managerID',
                new DataMapper("manager.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("managerEnterpriseId")),
                {label: 'Manager ID',onlyForm:true}
            ),
            new TableFieldDescription(
                'managerFirstName',
                new DataMapper("manager.firstName"),
                new f.FilterDescription(new f.TextFilter("managerFirstName")),
                {label: 'Manager Name'}
            ),
            new TableFieldDescription(
                'managerLastName',
                new DataMapper("manager.lastName"),
                new f.FilterDescription(new f.TextFilter("managerLastName")),
                {label: 'Manager Surname',onlyForm:true}
            ),
            new TableFieldDescription(
                'domains',
                new DataMapper("domains"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {
                    label: 'Domains', 
                    excelDisplay : new GeneralExcelDisplayCreator('Domain', [{label: 'Name', path: 'name'}]),
                    componentCreator: new GeneralChipComponentCreator(["name"], ["name"], null, false), 
                    transform: new DataTransformer(({domains})=>domains)
                }
            ),
            new TableFieldDescription(
                'isActive',
                new DataMapper("isActive"),
                new f.FilterDescription(new f.BooleanFilter("isActive")),
                {label: 'Status',  componentCreator:new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper("customAttr1"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 1' , onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper("customAttr2"),
                new f.FilterDescription(new f.TextFilter("customAttr2")),
                {label: 'Custom Attribute 2' , onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper("customAttr3"),
                new f.FilterDescription(new f.TextFilter("customAttr3")),
                {label: 'Custom Attribute 3' , onlyForm: true}
            ),
            new TableFieldDescription(
                'language',
                new DataMapper("language.codename"),
                new f.FilterDescription(new f.TextFilter("language")),
                {label: 'Language', onlyForm: true}
            ),
            new TableFieldDescription(
                'currency',
                new DataMapper("currency.code"),
                new f.FilterDescription(new f.TextFilter("currency")),
                {label: 'Currency', onlyForm: true}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryUserTableCreator)

export {
    InventoryUserTableCreator,
    InventoryUserTableCreatorQueries
};