import React from 'react'

import { Card } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const InvoicePaymentPopupContent = (props) => {
    const { t } = useTranslation(['tableNames', 'popupDetails', 'fieldLabels'])
    const {data, TableObject} = props

    const invoiceData = (({ vendor2, accountId, accountDescription, invoiceId, invoiceDate, dueDate, state, invoicePaymentId }) => ({ 
        vendor2, accountId, accountDescription, invoiceId, invoiceDate, dueDate, state, invoicePaymentId 
    }))(data);

    const financialData = (({ legalEntity, siteName, siteAddress, siteState, siteCountry, groupId, currency, invoiceTotalCost }) => ({ 
        legalEntity, siteName, siteAddress, siteState, siteCountry, groupId, currency, invoiceTotalCost 
    }))(data);

    return (
        <div style={{display:'flex'}}>
            <Card key={'invoice_data'} style={{ marginTop: 10, marginLeft: 10 }}>
                <h4 style={{margin: 20, color: '#555555'}}>{`${t('popupDetails:INVOICE DATA')}`}</h4>
                <hr style={{border: 0, clear: 'both', display: 'block', width: '96%', color: 'black', backgroundColor: 'black', height: 3, opacity: 0.5, margin: '10px auto'}} />
                <table 
                    align='center' 
                    style={{
                        tableLayout: 'fixed', 
                        paddingRight: 10, 
                        marginLeft: 30, 
                        marginRight: 30,
                        color: '#555555'}}>
                    <tbody>
                        {Object.keys(invoiceData).map((id, index) => {
                            return (
                                <tr key={`row_${id}`} style={{maxHeight:'300px'}}>
                                    <th key={`col1_${index}`} style={{verticalAlign:'top'}}>
                                        {`${t(`fieldLabels:${TableObject.getFieldLabelById(id)}`)}:`}
                                    </th>
                                    <td key={`col2_${index}`} style={{paddingLeft: 10, maxHeight:'300px', overflow:'auto'}}>
                                        <div style={{maxHeight:'200px', overflow:'auto'}}>
                                            {(invoiceData[id].props.value === null || invoiceData[id].props.value === undefined) ? '' : String(invoiceData[id].props.value).split("\n").map((text, i) => i ? [<br/>, text] : text)}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Card>
            <Card key={'financial'} style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                <h4 style={{margin: 20, color: '#555555'}}>{`${t('popupDetails:FINANCIAL')}`}</h4>
                <hr style={{border: 0, clear: 'both', display: 'block', width: '96%', color: 'black', backgroundColor: 'black', height: 3, opacity: 0.5, margin: '10px auto'}} />
                <table 
                    align='center' 
                    style={{
                        tableLayout: 'fixed', 
                        paddingRight: 10, 
                        marginLeft: 30, 
                        marginRight: 30,
                        color: '#555555'}}>
                    <tbody>
                        {Object.keys(financialData).map((id, index) => {
                            return (
                                <tr key={`row_${id}`} style={{maxHeight:'300px'}}>
                                    <th key={`col1_${index}`} style={{verticalAlign:'top'}}>
                                        {`${t(`fieldLabels:${TableObject.getFieldLabelById(id)}`)}:`}
                                    </th>
                                    <td key={`col2_${index}`} style={{paddingLeft: 10, maxHeight:'300px', overflow:'auto'}}>
                                        <div style={{maxHeight:'200px', overflow:'auto'}}>
                                            {(financialData[id].props.value === null || financialData[id].props.value === undefined) ? '' : String(financialData[id].props.value).split("\n").map((text, i) => i ? [<br/>, text] : text)}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Card>
            <br />
            <br />
        </div>
    )
}

export default InvoicePaymentPopupContent