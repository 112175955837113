/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react'

import { makeStyles } from '@material-ui/styles'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import { CustomChip } from '../../../components/BPMTable/TableMapperCreator'
import UserMinimalTable from '../../../pages/Inventory/User/MinimalTable'
import DeviceMinimalTable from '../../../pages/Inventory/Devices/DiviceMinimalTable'
import SiteMinimalTable from '../../../pages/Inventory/Sites/MinimalTable'
import InventoryTypeMinimalTable from '../../../pages/Inventory/Inventory/InventoryTypeMinimalTable'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import { InventoryQueries } from './InventoryTableCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'

const allCostCenters = `
    allCostCenters2
`

const allDomain = `
    availableDomains {
        name
        id
    }
`

const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`
}

const useStyles = makeStyles({
    multipleChip: {
        margin: 2,
        backgroundColor: '#075e54',
        "&:hover": {
            backgroundColor: "#a7cbc7"
        }
    }
})

const inventoryTypeTable = new InventoryTypeMinimalTable({first: 15,orderDirection: "desc", orderField: "name"}) 
const userPicker = new UserMinimalTable({first: 15, isActive:true})
const devicePicker = new DeviceMinimalTable({first:15, isActive:true})
const sitePicker = new SiteMinimalTable({first:15, isActive:true})

const getValidatedCostCenters = (costCenterItems) => {
    const costCenters = costCenterItems?.items ? costCenterItems.items.filter(
        cc => { return cc.costCenter && cc.costCenter.id && cc.id != 'unassigned' && cc.percentage > 0}) : [];

    let ptc = 0
    costCenters.map(cc => ptc += parseFloat(cc.percentage) || 0)

    if (ptc < 100){
        costCenters.push({id: 'unassigned', costCenter: null, percentage: (100 - ptc).toFixed(2)})
    }
    return costCenters
}

const getFormCostCenters = (costCenterItems) => {
    const costCenters = getValidatedCostCenters(costCenterItems);
    return costCenters.map(cc => {
        const unassigned =  cc.costCenter === null;
        return(
            <CustomChip
                label={!unassigned ? cc.costCenter.displayNameWithParents : 'Not Assigned'}
                key={cc.id || generateKey('UnassignedCc') + 'chip'}
                color={!unassigned ? 'primary' : 'secondary'}
                ptc={cc.percentage} 
            />
        )
    })
}
const costCenterStateMapper = (costCenterItems) => {
    const costCenters = getValidatedCostCenters(costCenterItems);
    const result =  costCenters.map(cc => (
        cc.costCenter ? 
            {
                label : cc.costCenter.enterpriseId + ' - ' + cc.costCenter.displayNameWithParents,
                id: cc.costCenter.id,
                ptc: cc.percentage
            }  
            :   
            {
                label : 'Not Assigned',
                id: 'unassigned',
                ptc: cc.percentage
            }
    ))
    return JSON.stringify(result)
}
  
const cc_label_mapper = data => data.label

class InventoryFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "InventoryFormCreator"
    }

    getFormCreator = () => {
        // Sections
        const generalSection = new FormSection('General Seccion', 1)
        const serviceTypeSection = new FormSection('Service Type', 2)
        const deviceSection = new FormSection('Device', 3)
        const siteSection = new FormSection('Site', 4)
        const userAssociationSection = new FormSection('User Association', 5)
        const associationSection = new FormSection('Associations', 6)
        const optimizationSection = new FormSection('Optimization', 7)

        const formCreator = this.createFormCreator(this.constructor.getId(), 'Inventory', InventoryQueries)
        formCreator.addInputs([
            new FormFieldDescription(
                'serviceNumber',
                new f.InputDescription(new f.TextCreator('serviceNumber'), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Service Number'}
            ),
            new FormFieldDescription(
                'serviceType',
                new f.InputDescription(new f.ModelCreator('serviceType', inventoryTypeTable), false),
                serviceTypeSection,
                1,
                {label: 'Service Type' , transform: new DataTransformer(false, data => data)}
            ),
            new FormFieldDescription(
                'domain',
                new f.InputDescription(new f.DropDownCreator('domain'), true,  new f.RequiredValidator(1)),
                generalSection,
                4,
                {label: 'Domain', queryAllDropdownOptions: allDomain, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'status',
                new f.InputDescription(new f.BoolCreator('status', true), false),
                    generalSection,
                14,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'validated',
                new f.InputDescription(new f.BoolCreator('validated', true), false),
                    generalSection,
                14,
                {label: 'validated'}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                11,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                12,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                13,
                {label: 'Custom Attribute 3'}
            ),
            new FormFieldDescription(
                'associationsLastDate',
                new f.InputDescription(new f.DateCreator('associationsLastDate', new Date().toISOString().slice(0, 10)), false),
                generalSection,
                14,
                {label: 'Date'}
            ),
            new FormFieldDescription(
                'startDate',
                new f.InputDescription(new f.DateCreator('startDate'), false),
                generalSection,
                15,
                {label: 'Start Date'}
            ),
            new FormFieldDescription(
                'endDate',
                new f.InputDescription(new f.DateCreator('endDate'), false),
                generalSection,
                16,
                {label: 'End Date'}
            ),
            new FormFieldDescription(
                'costCenters',
                new f.InputDescription(new f.InventoryAssociationHelperCreator('costCenters', allCostCenters, cc_label_mapper), false),
                associationSection,
                1,
                {label: 'Cost Center Association', transform: new DataTransformer(getFormCostCenters, costCenterStateMapper)}
            ),
            new FormFieldDescription(
                'devices',
                new f.InputDescription(new f.ModelCreator('devices', devicePicker), false),
                deviceSection,
                1,
                {label: 'devices', transform: new DataTransformer(false , data=> data ? data.device : null)}),
            new FormFieldDescription(
                'sites',
                new f.InputDescription(new f.ModelCreator('sites',sitePicker)),
                siteSection,
                1,
                {label: 'Sites', transform: new DataTransformer(false, data => data? data.site : null)}
            ),
            new FormFieldDescription(
                'users',
                new f.InputDescription(new f.ModelCreator('users', userPicker), false),
                userAssociationSection,
                1,
                {label: 'User Association', transform: new DataTransformer(false, data => data.user ? data.user : null )}
            ),
            new FormFieldDescription(
                'excludedFromOptimizations',
                new f.InputDescription(new f.BoolCreator('excludedFromOptimizations', false), false),
                optimizationSection,
                1,
                {label: 'Ignore'}
            ),
            new FormFieldDescription(
                'excludedReason',
                new f.InputDescription(new f.TextAreaCreator('excludedReason'), false),
                optimizationSection,
                2,
                {label: 'Ignore Reason'}
            ),
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(InventoryFormCreator)

export default InventoryFormCreator
