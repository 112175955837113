import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    TablePickerDisplayStrategy,
    NumberComponentCreator,
    DataTransformer, 
    TableFieldDescription, 
    DataMapper, 
    NullClickStrategy
} from '../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,

        $period:String!,
        $costCenter:String!,
        $enterpriseId:String,
        $firstName:String,
        $lastName:String,
        $lineNumber:String,
        $serviceType:String,
        $currency:String,
    ) {
        myTeamCostCenterDetailReport(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,

            period:$period,
            costCenter:$costCenter,
            enterpriseId:$enterpriseId,
            firstName:$firstName,
            lastName:$lastName,
            lineNumber:$lineNumber,
            serviceType:$serviceType,
        ) {
            edges {
                node {
                    cost(currency:$currency)
                    user {
                        id
                        firstName
                        lastName
                        enterpriseId
                    }
                    costCenter {
                        id
                        displayName
                        enterpriseId
                    }
                    inventory {
                        serviceNumber
                        serviceType {
                            name
                        }
                    }
                }
            }
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const queries = {tableQuery}

class MyTeamPopupContentTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "MyTeamPopupContentTableCreator"
    }

    getTableCreator = (tableName) => {
        const finalTableName =  `${tableName} ${this.parentProps.period} ${this.parentProps?.rowData?.costCenter?.displayName ? this.parentProps.rowData.costCenter.displayName : 'None'}`
        const tableCreator =  this.createTableCreator(this.constructor.getId(), finalTableName, queries, new NullClickStrategy(), new TablePickerDisplayStrategy())
        tableCreator.setDescriptions([     
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("user.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'User Enterprise ID'}
            ),
            new TableFieldDescription(
                'firstName',
                new DataMapper("user.firstName"),
                new f.FilterDescription(new f.TextFilter("firstName")),
                {label: 'User Name'}
            ),
            new TableFieldDescription(
                'lastName',
                new DataMapper("user.lastName"),
                new f.FilterDescription(new f.TextFilter("lastName")),
                {label: 'User Last Name'}
            ),
            new TableFieldDescription(
                'lineNumber',
                new DataMapper("inventory.serviceNumber"),
                new f.FilterDescription(new f.TextFilter("lineNumber")),
                {label: 'Service Number'}
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper("inventory.serviceType.name"),
                new f.FilterDescription(new f.TextFilter("serviceType")),
                {label: 'Service Type'}
            ),
            new TableFieldDescription(
                'costCenterEnterpriseId',
                new DataMapper("costCenter.enterpriseId"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Cost Center Code'}
            ),
            new TableFieldDescription(
                'costCenterName',
                new DataMapper("costCenter.displayName"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Cost Center Name'}
            ),
            new TableFieldDescription(
                'currency',
                new DataMapper("cost"),
                new f.FilterDescription(new f.NullCreator()),
                // eslint-disable-next-line no-empty-pattern
                {label: 'Currency', hideSort: true, transform: new DataTransformer(({}) => this.parentProps.currency)}
            ),
            new TableFieldDescription(
                'cost',
                new DataMapper("cost"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.periodString, 
                    translateLabel: false, 
                    componentCreator:new NumberComponentCreator(this.parentProps.symbol),
                    transform: new DataTransformer(({cost}) => Number(cost))
                }
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(MyTeamPopupContentTableCreator)

export default MyTeamPopupContentTableCreator;
