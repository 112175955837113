/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../components/BPMTable/PagesCreator/BPMBasicPage'
import { BudgetTableCreator } from '../../tableDefinitions/Budget/BudgetTableCreator'
import BudgetFormCreator from '../../tableDefinitions/Budget/BudgetFormCreator'

const Budget = (props) => {
    const { t } = useTranslation('budget')

    document.title = `${t('Budgets')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Budgets'}
            tableCreatorCreatorId={BudgetTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={BudgetFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Budget