import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    HideDeletionDisplayStrategy, 
    TableFieldDescription,
    NullClickStrategy,
    HideNewDisplayStrategy
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllConceptsConverter(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $concept: String,
        $technology: String,
        $chargeDescription: String,
        $driver: String,
    ) {
        allConceptConverters(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            concept: $concept,
            technology: $technology,
            chargeDescription: $chargeDescription,
            driver: $driver,
        ) {
            edges {
                node {
                    id
                    concept {
                        id
                        name
                        technology {
                            id
                            name
                        }
                    }
                    chargeDescription {
                        id
                        name
                        driver {
                            name
                            identifier
                        }
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation modifyConceptConverterMutation($id: String!, $concept: String!, $chargeDescription: String!) {
        modifyConceptConverter(id: $id, concept: $concept, chargeDescription: $chargeDescription) {
            conceptConverter {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createConceptConverterMutation($concept: String!, $chargeDescription: String!) {
        createConceptConverter(concept: $concept, chargeDescription: $chargeDescription) {
            conceptConverter {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeAccountMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeAccount(ids: $ids, allRecords: $allRecords, filters: $filters) {
            account {
                id
            }
        }
    }
`

const unitQuery = `
    conceptConverter(id: $id) {
        id
        concept {
            id
            name
            technology {
                name
                id
            }
        }
        chargeDescription {
            id
            name
            driver {
                name
                identifier
            }
        }
    }
`

const ConceptConverterQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class ConceptConverterTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ConceptConverterTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, ConceptConverterQueries, new NullClickStrategy(), new HideNewDisplayStrategy())
        tableCreator.setDisplayStrategy(new HideDeletionDisplayStrategy())
        tableCreator.setInitialSortField('concept', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'concept',
                new DataMapper("concept.name"),
                new f.FilterDescription(new f.TextFilter("concept")),
                {label: 'Concept Name'}
            ),
            new TableFieldDescription(
                'technology',
                new DataMapper("concept.technology.name"),
                new f.FilterDescription(new f.TextFilter("technology")),
                {label: 'Concept Technology'}
            ),
            new TableFieldDescription(
                'chargeDescription',
                new DataMapper("chargeDescription.name"),
                new f.FilterDescription(new f.TextFilter("chargeDescription")),
                {label: 'Charge Description'}
            ),
            new TableFieldDescription(
                'driver',
                new DataMapper("chargeDescription.driver.name"),
                new f.FilterDescription(new f.TextFilter("driver")),
                {label: 'Charge Description Driver'}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ConceptConverterTableCreator)

export {
    ConceptConverterTableCreator,
    ConceptConverterQueries
}