import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useCurrentUser, useLatestPeriodForProcessedInvoices } from '../../../redux/selectors'

import ReportsFilter from '../../../components/ReportsFilter/ReportsFilter'
import BilingAccountsTable from './BillingAccountsTable'
import { shortMonthNames } from '../../../components/DateTools/MonthsNames'

function SetUpData(props){
    const lastPublishedPeriod = useLatestPeriodForProcessedInvoices()
    const currentUser = useCurrentUser()

    const [date, setDate] = useState({...lastPublishedPeriod})
    const [currency_symbol, SetSymbol] = useState(currentUser.currency ? currentUser.currency.symbol : null)
    const [currency_id, SetCurrencyId] = useState(currentUser.currency ? currentUser.currency.code : null)
    const [native_currency, SetNativeCurrency] = useState(false)

    const handleChangeCurrency = (currencyCode, symbol) =>{
        SetSymbol(symbol)
        SetCurrencyId(currencyCode)
    }

    const handleSwichStateChange = (e, flag) =>{
        SetNativeCurrency(flag)
    }

    function onChangeDate(year, month) {
        setDate({year: year, month: month});
    }

    function generateFilters(){
        return {period:`${date.year}-${date.month}`,currency:currency_id, nativeCurrency:native_currency}
    }

    const periodTransformer = shortMonthNames(props.t)

    const month0 = date.month - 1
    const month1 = (month0 - 1 < 0)? month0 + 11 : month0 -1
    const month2 = (month0 - 2 < 0)? month0 + 10 : month0 -2
    const month3 = (month0 - 3 < 0)? month0 +  9 : month0 -3
    const month4 = (month0 - 4 < 0)? month0 +  8 : month0 -4
    const month5 = (month0 - 5 < 0)? month0 +  7 : month0 -5
    const year0 = date.year
    const year1 = ( month1 > month0) ? year0 - 1 : year0
    const year2 = ( month2 > month0) ? year0 - 1 : year0
    const year3 = ( month3 > month0) ? year0 - 1 : year0
    const year4 = ( month4 > month0) ? year0 - 1 : year0
    const year5 = ( month5 > month0) ? year0 - 1 : year0

    const period5 = String(periodTransformer[month5]+ "-" + year5)
    const period4 = String(periodTransformer[month4]+ "-" + year4)
    const period3 = String(periodTransformer[month3]+ "-" + year3)
    const period2 = String(periodTransformer[month2]+ "-" + year2)
    const period1 = String(periodTransformer[month1]+ "-" + year1)
    const period0 = String(periodTransformer[month0]+ "-" + year0)

    document.title = 'Billing Accounts-BubbleBPM'
    var infoLegend = props.t('Semi-annual cost of your Billing Accounts') + '. ' +
                     props.t('Totals expressed in the selected currency')

    const buttonsData = [
        {
            type: 'swich',
            label: props.t("Native currency")
        }
    ]

    return (
        <>
            <ReportsFilter 
                onChangeDate={onChangeDate}
                buttons={buttonsData}
                date={date}
                info={{label:infoLegend}}
                onChangeCurrency={handleChangeCurrency}
                currencySelected = {currency_id}
                nativeCurrency = {native_currency}
                onSwichStateChange = {handleSwichStateChange}
                checkDisabledMonths={true}
                useInvoicePeriods={true}
            />
            <BilingAccountsTable
                t={props.t}
                symbol={currency_symbol}
                currencyCode={currency_id}
                nativeCurrency = {native_currency}
                filters={generateFilters()}
                periods={{
                    period0: year0 + "-" + (month0+1), 
                    period1: year1 + "-" + (month1+1), 
                    period2: year2 + "-" + (month2+1),
                    period3: year3 + "-" + (month3+1),
                    period4: year4 + "-" + (month4+1),
                    period5: year5 + "-" + (month5+1)}}
                per1={period1}
                per2={period2}
                per3={period3}
                per4={period4}
                per5={period5}
                per={period0}
            />
        </>
    )
}

export default withTranslation("BillingAccounts")(SetUpData)