/* eslint-disable eqeqeq */
import React from "react"

import {ReactComponent as XLSIcon} from "../../../../components/BPMTable/TableComponents/Icons/fileIcons/xls.svg"
import {ReactComponent as PDFIcon} from "../../../../components/BPMTable/TableComponents/Icons/fileIcons/pdf.svg"
import {ReactComponent as ZIPIcon} from "../../../../components/BPMTable/TableComponents/Icons/fileIcons/zip.svg"
import {ReactComponent as TXTIcon} from "../../../../components/BPMTable/TableComponents/Icons/fileIcons/txt.svg"
import {ReactComponent as CSVIcon} from "../../../../components/BPMTable/TableComponents/Icons/fileIcons/csv.svg"

export default function FileIcon(props) {
    if(props.extension.toLowerCase() == "xls" || props.extension.toLowerCase() == "xlsx"){
        return(<XLSIcon style={{width:28, cursor:"pointer"}} onClick={props.onClick}/>)
    }else if(props.extension.toLowerCase() == "pdf"){
        return(<PDFIcon style={{width:28, cursor:"pointer"}} onClick={props.onClick}/>)
    }else if(props.extension.toLowerCase() == "zip"){
        return(<ZIPIcon style={{width:28, cursor:"pointer"}} onClick={props.onClick}/>)
    }else if(props.extension.toLowerCase() == "csv"){
        return(<CSVIcon style={{width:28, cursor:"pointer"}} onClick={props.onClick}/>)
    }else{
        return(<TXTIcon style={{width:28, cursor:"pointer"}} onClick={props.onClick}/>)
    }
}