import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import InventoryCostCenterMinimalTableCreator from '../../../tableDefinitions/Inventory/CostCenter/InventoryCostCenterMinimalTableCreator'

export default class CostCenterMinimalTable {
    constructor(filters) {
        this.filters = filters
        this.tableFormMapper = [
            {mapper:data=>data.enterpriseId, label:"ID"},
            {mapper:data=>data.displayName, label:"Name"},
            {mapper:data=>data.parent ? data.parent.enterpriseId : null, label:"Parent ID"},
            {mapper:data=>data.parent ? data.parent.displayName : null, label:"Parent Name"},
        ]
    }

    getTableCreator = () => {
        var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(InventoryCostCenterMinimalTableCreator.getId(), {tcFilters: this.filters})
        return tableCreatorCreator.getTableCreator('Cost Center')
    }
}
