import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    StatusComponentCreator,
    BooleanExcelDisplayCreator,
    DefaultClickStrategy, 
    TableFieldDescription 
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllSites(
        $orderField: String, 
        $first: Int, 
        $last: Int, 
        $after: String, 
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $before: String,
        $domain: String,
        $isActive: Boolean,
        $name: String,
        $address: String,
        $type: String,
        $enterpriseId: String,
        $country: String,
        $postalCode: String,
        $state: String) {
            allSites(
                orderField: $orderField,
                first: $first, 
                last: $last, 
                after: $after, 
                customAttr1: $customAttr1,
                customAttr2: $customAttr2,
                customAttr3: $customAttr3,
                before: $before,
                domain: $domain,
                isActive: $isActive,
                name: $name,
                address: $address,
                type: $type,
                enterpriseId: $enterpriseId,
                country: $country,
                postalCode: $postalCode,
                state: $state
            ) {
                edges {
                    node {
                        id
                        name
                        address
                        domain {
                            id
                            name
                        }
                        postalCode
                        enterpriseId
                        state
                        type
                        customAttr1
                        customAttr2
                        customAttr3
                        country {
                            id
                            name
                        }
                        isActive
                    }
                }
                count
                pageInfo {
                    hasNextPage
                    startCursor
                    endCursor
                }
            }
        }
`

const editMutator = gql`
    mutation editSiteMutation(
        $id: String!,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $type: String,
        $state: String
        $isActive: Boolean,
        $enterpriseId: String!,
        $address: String!,
        $country: String!,
        $name: String!,
        $postalCode: String,
        $domain: String,
    ) {
        editSite(
            id: $id,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            type: $type,
            state: $state    
            isActive: $isActive,
            enterpriseId: $enterpriseId,
            address: $address,
            country: $country,
            name: $name,
            postalCode: $postalCode,
            domain: $domain,
        ) {
            site {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createSiteMutation(
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $type: String,
        $state: String
        $isActive: Boolean,
        $enterpriseId: String!,
        $address: String!,
        $country: String!,
        $name: String!,
        $postalCode: String,
        $domain: String,
    ) {
        createSite(
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            type: $type,
            state: $state    
            isActive: $isActive,
            enterpriseId: $enterpriseId,
            address: $address,
            country: $country,
            name: $name,
            postalCode: $postalCode,
            domain: $domain,
        ) {
            site {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeSiteMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeSite(id: $ids, allRecords: $allRecords, filters: $filters) {
            site {
                id
            }
        }
    }
`

const unitQuery = `
    site(id: $id) {
        id
        name
        address
        domain {
            id
            name
        }
        enterpriseId
        postalCode
        isActive
        state
        country {
            id
            name
        }
        type
        customAttr1
        customAttr2
        customAttr3
        dateOpened
        dateClosed
    }
`

const InventorySitesTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class InventorySitesTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventorySitesTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, InventorySitesTableCreatorQueries, new DefaultClickStrategy())
        //tableCreator.setTableFooterItems([new ExportFooterItemCreator()])
        tableCreator.setInitialSortField('isActive', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper('enterpriseId'),
                new f.FilterDescription(new f.TextFilter('enterpriseId')),
                {label: 'Enterprise Id'}
            ),
            new TableFieldDescription(
                'name',
                new DataMapper('name'),
                new f.FilterDescription(new f.TextFilter('name')),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'address',
                new DataMapper('address'),
                new f.FilterDescription(new f.TextFilter('address')),
                {label: 'Address'}
            ),
            new TableFieldDescription(
                'postalCode',
                new DataMapper('postalCode'),
                new f.FilterDescription(new f.TextFilter('postalCode')),
                {label: 'Postal Code'}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper('state'),
                new f.FilterDescription(new f.TextFilter('state')),
                {label: 'State'}
            ),
            new TableFieldDescription(
                'country',
                new DataMapper('country.name'),
                new f.FilterDescription(new f.TextFilter('country')),
                {label: 'Country'}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper('domain.name'),
                new f.FilterDescription(new f.TextFilter('domain')),
                {label: 'Domain'}
            ),
            new TableFieldDescription(
                'isActive',
                new DataMapper('isActive'),
                new f.FilterDescription(new f.BooleanFilter('isActive')),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'type',
                new DataMapper('type'),
                new f.FilterDescription(new f.TextFilter('type')),
                {label: 'Type'}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper('customAttr1'),
                new f.FilterDescription(new f.TextFilter('customAttr1')),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper('customAttr2'),
                new f.FilterDescription(new f.TextFilter('cutomsAttr2')),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper('customAttr3'),
                new f.FilterDescription(new f.TextFilter('customAttr3')),
                {label: 'Custom Attribute 3', onlyForm: true}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventorySitesTableCreator)

export {
    InventorySitesTableCreator,
    InventorySitesTableCreatorQueries
};