import React from 'react'

import { gql } from "@apollo/client"

import * as f from '../../../components/BPMTable/BPMInputs'
import { DataMapper,
    DataTransformer,
    NullClickStrategy, 
    NumberIconedComponentCreator,
    StatusComponentCreator,
    BooleanExcelDisplayCreator,
    OnlyFilterActionDisplayStrategy, 
    TableFieldDescription,
    NumberComponent
} from '../../../components/BPMTable/TableMapperCreator'
import {ExportFooterItemCreator} from "../../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'


const tableQuery = gql`
    query BillingAccountsReport(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,  
        $period:String!,
        $accountLegalEntityName:String,
        $accountName:String,
        $accountStatus:Boolean,
        $accountDescription:String,
        $accountIdentifier:String,
        $accountProviderName:String,
        $accountProviderCountryName:String,
        $accountTechnologyName:String
        $accountValidated:Boolean,
        $companyName:String,
        $currency:String,
        $nativeCurrency:Boolean!,
        $invoiceCurrency:String,
        $accountDomainName:String,
    ) {
        billingAccountsReport(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            period:$period,
            accountLegalEntityName:$accountLegalEntityName,
            accountName:$accountName,
            accountStatus:$accountStatus,
            accountDescription:$accountDescription
            accountIdentifier:$accountIdentifier,
            accountProviderName:$accountProviderName,
            accountProviderCountryName:$accountProviderCountryName,
            accountTechnologyName:$accountTechnologyName,
            accountValidated:$accountValidated,
            companyName:$companyName,
            invoiceCurrency:$invoiceCurrency,
            accountDomainName:$accountDomainName,
        ) {
            edges {
                node {
                    account {
                        id
                        domain {
                          id
                          name
                        }
                        name
                        status
                        description
                        identifier
                        validated
                        customAttr1
                        customAttr2
                        customAttr3
                        legalEntity {
                            id
                            name
                        }
                        provider {
                            id
                            name
                            country {
                                id
                                name
                            }
                        }
                        technology {
                            id
                            name
                        }
                    }
                    invoiceCurrency {
                        code
                        name
                        symbol
                    }
                    costs(currency:$currency, nativeCurrency:$nativeCurrency) {
                        cost
                        cost2
                        cost3
                        cost4
                        cost5
                        cost6
                        unvalidateds
                        unvalidateds2
                        unvalidateds3
                        unvalidateds4
                        unvalidateds5
                        unvalidateds6
                        symbol
                        nativeCurrency
                        invoices
                        invoices2
                        invoices3
                        invoices4
                        invoices5
                        invoices6
                    }
                }
            }
            costs(currency:$currency) {
                cost
                cost2
                cost3
                cost4
                cost5
                cost6
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

export const queries = {tableQuery}

class BillingAccountTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "BillingAccountTableCreator"
    }

    getButtonType = (invoices, unValidateds) => {
        if (!invoices) {
            return {type: "warnInvoicesIcon", caption: this.parentProps.t("No invoices were found for this period")};
        } else if (unValidateds && unValidateds > 0) {
            return {type: "warnUnValidatedsIcon", caption: this.parentProps.t("There is at least one Unvalidated invoice")};
        } else {
            return null;
        }
    }

    getNumberIconedObject = (data, idx) => {
        return {
            value: data['cost'+idx], 
            symbol: data['symbol'], 
            buttonType: this.getButtonType(data['invoices'+idx], data['unvalidateds'+idx])
        }
    }

    getCcySymbol = (data) => {
        if (data?.nativeCurrency) {
            return data?.symbol ? data?.symbol : '';
        } else {
            return this.parentProps.symbol;
        }
    }

    PeriodCells = (props) => {
        return( <NumberComponent value={props.data} style={{textAlign:"right"}} currencySymbol={this.parentProps.symbol}/>)
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'accountLegalEntityName',
                new DataMapper("account.legalEntity.name"),
                new f.FilterDescription(new f.TextFilter("accountLegalEntityName")),
                {label: 'Legal Entity'}
            ),
            new TableFieldDescription(
                'accountProviderCountryName',
                new DataMapper("account.provider.country.name"),
                new f.FilterDescription(new f.TextFilter("accountProviderCountryName")),
                {label: 'Country'}
            ),
            new TableFieldDescription(
                'accountProviderName',
                new DataMapper("account.provider.name"),
                new f.FilterDescription(new f.TextFilter("accountProviderName")),
                {label: 'Vendor'}
            ),
            new TableFieldDescription(
                'accountTechnologyName',
                new DataMapper("account.technology.name"),
                new f.FilterDescription(new f.TextFilter("accountTechnologyName")),
                {label: 'Technology'}
            ),
            new TableFieldDescription(
                'accountIdentifier',
                new DataMapper("account.identifier"),
                new f.FilterDescription(new f.TextFilter("accountIdentifier")),
                {label: 'Account ID'}
            ),
            new TableFieldDescription(
                'accountDescription',
                new DataMapper("account.description"),
                new f.FilterDescription(new f.TextFilter("accountDescription")),
                {label: 'Description'}
            ),
            new TableFieldDescription(
                'accountStatus',
                new DataMapper('account.status'),
                new f.FilterDescription(new f.BooleanFilter('accountStatus')),
                {label: 'Status', onlyForm: true, componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'accountValidated',
                new DataMapper('account.validated'),
                new f.FilterDescription(new f.BooleanFilter('accountValidated', 'Yes', 'No')),
                {label: 'Validated', onlyForm: true, componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator("Yes", "No")}
            ),
            new TableFieldDescription(
                'accountDomainName',
                new DataMapper('account.domain.name'),
                new f.FilterDescription(new f.TextFilter('accountDomainName')),
                {label: 'Domain', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper('account.customAttr1'),
                new f.FilterDescription(new f.TextFilter('accountCustomAttr1')),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper('account.customAttr2'),
                new f.FilterDescription(new f.TextFilter('accountCustomAttr2')),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper('account.customAttr3'),
                new f.FilterDescription(new f.TextFilter('accountCustomAttr3')),
                {label: 'Custom Attribute 3', onlyForm: true}
            ),
            new TableFieldDescription(
                'invoiceCurrency',
                new DataMapper("invoiceCurrency.code"),
                new f.FilterDescription(new f.TextFilter("invoiceCurrency")),
                {label: 'Currency'}
            ),
            new TableFieldDescription(
                'period-5',
                new DataMapper("costs"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per5, 
                    translateLabel: false, 
                    componentCreator: new NumberIconedComponentCreator(), 
                    transform: new DataTransformer(({costs}) => this.getNumberIconedObject(costs, 6)),
                    excelTransform: new DataTransformer(({costs}) => costs['cost6']),
                }
            ),
            new TableFieldDescription(
                'period-4',
                new DataMapper("costs"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per4, 
                    translateLabel: false, 
                    componentCreator: new NumberIconedComponentCreator(), 
                    transform: new DataTransformer(({costs}) => this.getNumberIconedObject(costs, 5)),
                    excelTransform: new DataTransformer(({costs}) => costs['cost5']),
                }
            ),
            new TableFieldDescription(
                'period-3',
                new DataMapper("costs"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per3, 
                    translateLabel: false, 
                    componentCreator: new NumberIconedComponentCreator(), 
                    transform: new DataTransformer(({costs}) => this.getNumberIconedObject(costs, 4)),
                    excelTransform: new DataTransformer(({costs}) => costs['cost4']),
                }
            ),
            new TableFieldDescription(
                'period-2',
                new DataMapper("costs"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per2, 
                    translateLabel: false, 
                    componentCreator: new NumberIconedComponentCreator(), 
                    transform: new DataTransformer(({costs}) => this.getNumberIconedObject(costs, 3)),
                    excelTransform: new DataTransformer(({costs}) => costs['cost3']),
                }
            ),
            new TableFieldDescription(
                'period-1',
                new DataMapper("costs"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per1, 
                    translateLabel: false, 
                    componentCreator: new NumberIconedComponentCreator(), 
                    transform: new DataTransformer(({costs}) => this.getNumberIconedObject(costs, 2)),
                    excelTransform: new DataTransformer(({costs}) => costs['cost2']),
                }
            ),
            new TableFieldDescription(
                'period',
                new DataMapper("costs"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: this.parentProps.per, 
                    translateLabel: false, 
                    componentCreator: new NumberIconedComponentCreator(), 
                    transform: new DataTransformer(({costs}) => this.getNumberIconedObject(costs, '')),
                    excelTransform: new DataTransformer(({costs}) => costs['cost']),
                }
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(BillingAccountTableCreator)

export default BillingAccountTableCreator;
