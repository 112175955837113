import React from 'react'

import Table from '../../components/BPMTable/TableComponents/TableWrapper/SetupTableData'
import CostCenterTotals from './CostCenterTotals'
import MyTeamCostCentersTableCreator from '../../tableDefinitions/MyTeam/MyTeamCostCentersTableCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

export default function CostCentersTable(props) {
    var tableCreatorCreator = tableCreatorCreatorFactoryCreator.getTableCreatorCreatorFromIdentifier(MyTeamCostCentersTableCreator.getId(), {tcFilters: props.filters})
    tableCreatorCreator.setParentProps(props)
    const tableCreator = tableCreatorCreator.getTableCreator('My Team Cost Centers')

    return (
        <Table
            TableCreator={tableCreator}
            stickyHeader
        >
            {(data, managerTable) =>
                <CostCenterTotals 
                    visibleColumns={managerTable.getVisibleFields().length}
                    symbol={props.symbol}
                    data={data ? data.myTeamCostCenterReport.costs : { cost: 0, cost2: 0, cost3: 0, average: 0}}
                />
            }
        </Table>
    )
}
