const nullFunc = () => null;
const nullCatch = (er) => console.log(er);
const nullLoading = (loading) => null

export const downloadFileFromUrl = (client, url, path, _then=nullFunc, _catch=nullCatch, isLoading=nullLoading) => {
    client.get(
        `${url}get_info/${path}`,
    ).then(
        response => {
            window.location.assign(`${client.defaults.baseURL}${url}files/${response.data}`);
        }
    ).then(_then).catch( error => {_catch(error)})
}