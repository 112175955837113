import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription,
    FormSection,
} from '../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreators from '../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { ConstractQueries } from './ContractTableCreator'

const availableVendors = `
    availableVendorsByAccounts {
        edges {
            node {
                id
                name
            }
        }
    }
`
const allContractKinds = `
    allContractKinds {
        edges {
            node {
                id
                name
            }
        }
    }
`

const accountPickerQuery = gql`
query ($provider: String!) {
    allAccounts(status:true, providerId: $provider) {
      edges {
        node {
          id
          name
          description
          provider {
            id
            name
          }
          technology {
            id
            name
          }
          domain {
            id
            name
          }
        }
      }
    }
}`

class ContractFormCreator extends FormCreatorCreator{
    constructor() {
        super()
    }

    static getId = () => {
        return "ContractFormCreator"
    }

    getFormCreator = () => {
        const tableMultiPickerColumnTransformers = [
            {mapper:accounts=>accounts.name, label:"Account"},
            {mapper:accounts=>accounts.description, label:"Description"},
            {mapper:accounts=>accounts.technology ? accounts.technology.name : null, label:"Technology"},
            {mapper:accounts=>accounts.domain ? accounts.domain.name : null, label:"Domain"},
        ]
        
        const formCreator = this.createFormCreator(this.constructor.getId(), 'Contract', ConstractQueries)
        //New/Edit Form Sections
        const generalSection = new FormSection('Contract details', 1)
        const billinigAccountsForm = new FormSection('Billing accounts', 2)  

        const billingAccountTransformer = new DataTransformer(accounts => accounts.edges.map(edge => edge.node), accounts => accounts.edges.map(edge => Number(edge.node.id)), [])

        formCreator.addInputs([
            new FormFieldDescription(
                'identifier',
                new f.InputDescription(new f.TextAreaCreator('identifier', ), true, new f.RequiredValidator(1)),
                generalSection,
                1,
                {label: 'Contract ID'}
            ),
            new FormFieldDescription(
                'name',
                new f.InputDescription(new f.TextAreaCreator('name'), true, new f.RequiredValidator(1)),
                generalSection,
                2,
                {label: 'Contract Name'}
            ),
            new FormFieldDescription(
                'contractKind',
                new f.InputDescription(new f.DropDownCreator('contractKind'), true, new f.RequiredValidator(1)),
                generalSection,
                4,
                {label: 'Contract type', queryAllDropdownOptions: allContractKinds, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'status',
                new f.InputDescription(new f.BoolCreator('status', true), false),
                generalSection,
                5,
                {label: 'Status'}
            ),
            new FormFieldDescription(
                'startDate',
                new f.InputDescription(new f.DateCreator('startDate'), true, new f.RequiredValidator(1)),
                generalSection,
                6,
                {label: 'Start date'}
            ),
            new FormFieldDescription(
                'endDate',
                new f.InputDescription(new f.DateCreator('endDate'), true, new f.RequiredValidator(1)),
                generalSection,
                7,
                {label: 'End date'}
            ),
            new FormFieldDescription(
                'description',
                new f.InputDescription(new f.TextAreaCreator('description'), true, new f.RequiredValidator(1)),
                generalSection,
                8,
                {label: 'Description'}
            ),
            new FormFieldDescription(
                'observations',
                new f.InputDescription(new f.TextAreaCreator('observations'), true, new f.RequiredValidator(1)),
                generalSection,
                8,
                {label: 'Observations'}
            ),
            new FormFieldDescription(
                'signed',
                new f.InputDescription(new f.BoolCreator('signed', true), false),
                generalSection,
                9,
                {label: 'Signed'}
            ),
            new FormFieldDescription(
                'signatureDate',
                new f.InputDescription(new f.DateCreator('signatureDate'), false),
                generalSection,
                10,
                {label: 'Signature date'}
            ),
            new FormFieldDescription(
                'signatory',
                new f.InputDescription(new f.TextAreaCreator('signatory'), true, new f.RequiredValidator(1)),
                generalSection,
                11,
                {label: 'Signatory'}
            ),
            new FormFieldDescription(
                'renewal',
                new f.InputDescription(new f.BoolCreator('renewal', true), false),
                generalSection,
                12,
                {label: 'Automatic renewal'}
            ),
            new FormFieldDescription(
                'contractFile',
                new f.InputDescription(new f.UploadCreator('contractFile', '/report/download/contracts/'), false),
                generalSection,
                13,
                {label: 'File name', dataMapper: (data => data.contractFile ? {downloadId:data.id, file:data.contractFile}  : null)}
            ),
            new FormFieldDescription(
                'customAttr1',
                new f.InputDescription(new f.TextAreaCreator('customAttr1'), false),
                generalSection,
                14,
                {label: 'Custom Attribute 1'}
            ),
            new FormFieldDescription(
                'customAttr2',
                new f.InputDescription(new f.TextAreaCreator('customAttr2'), false),
                generalSection,
                15,
                {label: 'Custom Attribute 2'}
            ),
            new FormFieldDescription(
                'customAttr3',
                new f.InputDescription(new f.TextAreaCreator('customAttr3'), false),
                generalSection,
                16,
                {label: 'Custom Attribute 3'}
            ),
            new FormFieldDescription(
                'vendor',
                new f.InputDescription(new f.DropDownCreator('vendor', false), true, new f.RequiredValidator(1)),
                billinigAccountsForm,
                1,
                {label: 'Vendor', queryAllDropdownOptions: availableVendors, transform: new DataTransformer(data => data.name)}
            ),
            new FormFieldDescription(
                'accounts',
                new f.InputDescription(new f.TableMultiPickerCreator(
                        'accounts', 
                        accountPickerQuery,
                        "allAccounts", 
                        tableMultiPickerColumnTransformers, 
                        ({vendor}) => ({ provider: vendor }), 
                        'vendor'), 
                    false),
                billinigAccountsForm,
                2,
                {label: 'Billing accounts', transform: billingAccountTransformer }
            ),
        ]);
    
        return formCreator;
    }
}

formCreatorCreatorFactoryCreators.addFormCreatorCreator(ContractFormCreator)

export default ContractFormCreator