import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { FormControl, InputLabel, Select, MenuItem, Input } from '@material-ui/core'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuprops = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        },
    },
};

export class OptionsDropDownInput extends Component {
  render() {
    const {required, classes, label, id, formState, onChangeHandler, options, inputId, t, translateElements} = this.props;
    const error =  Boolean(formState.errors[id]);
    const inputLabel = required ? required(t(label)) : t(label);
    return (
        <FormControl className={classes.formControl}  key={id}>
            <InputLabel htmlFor={label}>{inputLabel}</InputLabel>

            <Select
                value={formState.mappedData[inputId] || ''}
                onChange={ event => onChangeHandler(inputId , event.target.value)}
                error={error}
                menuprops={menuprops}
                style={{width:250}}
                className={classes.input}
                input={<Input/>}
            >
                <MenuItem key={''} value={null} className={classes.input}>
                        -
                </MenuItem>

                { options.map(item => (
                        <MenuItem key={"option_"+item} value={item} className={classes.input}>
                            {translateElements ? t(item.replace('_', ' ')) : item.replace('_', ' ')}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
  }
}

export default withTranslation('fieldLabels')(OptionsDropDownInput);