import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    StatusComponentCreator, 
    BooleanExcelDisplayCreator,
    TableFieldDescription, 
    DateComponentCreator,
    DateDisplayCreator
} from '../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query AllGlAccounts(
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $createdAt:String,
        $domain:String,
        $isActive:Boolean,
        $name:String,
        $manager:String,
        $customAttr1:String,
        $customAttr2:String,
        $customAttr3:String,
        $orderField:String,
        $parent:String,
        $type:String,
    ) {
        allGlAccounts(
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            name:$name,
            domain:$domain,
            manager:$manager,
            isActive:$isActive,
            createdAt:$createdAt,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
            orderField:$orderField,
            parent:$parent,
            type:$type,
        ) {
            count
            edges {
                node {
                    name
                    type
                    createdAt
                    id
                    domain {
                        id
                        name
                    }
                    isActive
                    manager {
                        id
                        enterpriseId
                    }
                    parent {
                        id
                        displayName
                    }
                    customAttr1
                    customAttr2
                    customAttr3
                }
            }
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation editGlAccountMutation(
        $id:String!,
        $name: String!,
        $type: String,
        $parent: String,
        $manager: String,
        $isActive: Boolean,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $domain:String
    ) {
        editGlAccount(
            id: $id,
            name: $name,
            type :$type,
            parent: $parent,
            isActive: $isActive,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            manager: $manager,
            domain:$domain
        ) {
            glAccount {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createGlAccountMutation(
        $name: String!,
        $type: String,
        $parent: String,
        $manager: String,
        $isActive: Boolean,
        $customAttr1: String,
        $customAttr2: String,
        $customAttr3: String,
        $domain:String
    ) {
        createGlAccount(
            name: $name,
            type :$type,
            parent: $parent,
            isActive: $isActive,
            customAttr1: $customAttr1,
            customAttr2: $customAttr2,
            customAttr3: $customAttr3,
            manager: $manager,
            domain:$domain
        ) {
            glAccount {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeGlAccountMutation($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeGlAccount(ids: $ids, allRecords: $allRecords, filters: $filters) {
            glAccount {
                id
            }
        }
    }
`

const unitQuery = `
    glAccount(id: $id) {
        name
        type
        domain {
            id
            name
        }
        createdAt
        id
        isActive
        manager {
            id
            enterpriseId
            firstName
            lastName
            email
        }
        parent {
            id
            displayName
            parent{
                id
                displayName
            }
        }
        customAttr1
        customAttr2
        customAttr3
    }
`

const GlAccountTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class GlAccountTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "GlAccountTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, GlAccountTableCreatorQueries)
        tableCreator.setInitialSortField('isActive', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'parent',
                new DataMapper("parent.displayName"),
                new f.FilterDescription(new f.TextFilter("parent")),
                {label: 'Parent'}
            ),
            new TableFieldDescription(
                'manager',
                new DataMapper("manager.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("manager")),
                {label: 'Manager'}
            ),
            new TableFieldDescription(
                'type',
                new DataMapper("type"),
                new f.FilterDescription(new f.TextFilter("type")),
                {label: 'Type'}
            ),
            new TableFieldDescription(
                'isActive',
                new DataMapper("isActive"),
                new f.FilterDescription(new f.BooleanFilter("isActive")),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain'}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper("customAttr1"),
                new f.FilterDescription(new f.TextFilter("customAttr1")),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper("customAttr2"),
                new f.FilterDescription(new f.TextFilter("customAttr2")),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper("customAttr3"),
                new f.FilterDescription(new f.TextFilter("customAttr3")),
                {label: 'Custom Attribute 3', onlyForm: true}
            ),
            new TableFieldDescription(
                'createdAt',
                new DataMapper("createdAt"),
                new f.FilterDescription(new f.DateFilter("createdAt")),
                {label: 'Creation Date', componentCreator: new DateComponentCreator(), excelDisplay: new DateDisplayCreator()}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(GlAccountTableCreator)

export { 
    GlAccountTableCreator,
    GlAccountTableCreatorQueries
};