import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    HideDeletionDisplayStrategy,  
    TableFieldDescription
} from '../../../components/BPMTable/TableMapperCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'


const tableQuery = gql`
    query AllChargeGroup(
        $orderField: String,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $name: String,
        $parent: String
    ) {
        allChargeGroup(
            orderField: $orderField,
            first: $first,
            last: $last,
            after: $after,
            before: $before,
            name: $name,
            parent: $parent
        ) {
            edges {
                node {
                    id
                    name
                    parent {
                        id
                        name
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const editMutator = gql`
    mutation modifyChargeGroup($id: String!, $parent: String!) {
        modifyChargeGroup(id: $id, parent: $parent) {
            chargeGroup {
                id
            }
        }
    }
`

const newMutator = gql`
    mutation createConceptConverterMutation($name: String!, $parent: String) {
        createChargeGroup(name: $name, parent: $parent) {
            chargeGroup {
                id
            }
        }
    }
`

const deleteMutator = gql`
    mutation removeChargeGroupConcept($ids: [String!], $allRecords: Boolean, $filters: String) {
        removeChargeGroupConcept(ids: $ids, allRecords: $allRecords, filters: $filters) {
            account {
                id
            }
        }
    }
`

const unitQuery = `
    chargeGroup(id: $id) {
        id
        name
        parent {
            id
            name
        }
    }
`
const chargeGroupTableCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class ChargeGroupTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ChargeGroupTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator = this.createTableCreator(this.constructor.getId(), tableName, chargeGroupTableCreatorQueries)
        tableCreator.setDisplayStrategy(new HideDeletionDisplayStrategy())
        tableCreator.setInitialSortField('name', 'desc')
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'name',
                new DataMapper("name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Name'}
            ),
            new TableFieldDescription(
                'parent',
                new DataMapper("parent.name"),
                new f.FilterDescription(new f.TextFilter("parent")),
                {label: 'Parent'}
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ChargeGroupTableCreator)

export {
    ChargeGroupTableCreator,
    chargeGroupTableCreatorQueries
};