import { useTranslation } from 'react-i18next'
import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import { 
    DataTransformer, 
    FormFieldDescription, 
    FormSection, 
} from '../../../components/BPMTable/TableMapperCreator'
import FormCreatorCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreator'
import formCreatorCreatorFactoryCreator from '../../../components/BPMTable/FormCreatorFactory/FormCreatorCreatorFactoryCreator'
import { WebCredentialsTableCreatorQueries } from './WebCredentialsTableCreator'

const allAccounts = gql`{
        allAccounts {
            edges {
                node {
                    id
                    name
                    identifier
                    provider{
                        name
                    }
                }
            }
        }
    }
`

const accountsMapper = data => data.identifier
const accountsName = data => data.provider ? data.provider.name : ""

class WebCredentialsFormCreator extends FormCreatorCreator {
    constructor() {
        super()
    }

    static getId = () => {
        return "WebCredentialsFormCreator"
    }

    getFormCreator = () => {
        const { t } = useTranslation('invoiceProcessing')
        const formCreator = this.createFormCreator(this.constructor.getId(), t('Web Credentials'), WebCredentialsTableCreatorQueries)
        const generalSection = new FormSection('General Seccion', 1)
        formCreator.addInputs([
            new FormFieldDescription(
                'accountSet',
                new f.InputDescription(new f.MultiPickerCreator('accountSet', allAccounts, "allAccounts", [accountsMapper, accountsName])),
                generalSection,
                1,
                {label: 'Account', transform: new DataTransformer(data => data.edges.map(edge => edge.node), data => data.edges.map(edge => parseInt(edge.node.id)))}
            ),
            new FormFieldDescription(
                'identifier',
                new f.InputDescription(new f.TextCreator('identifier'), true),
                generalSection,
                2,
                {label: 'Web Login'}
            ),
            new FormFieldDescription(
                'url',
                new f.InputDescription(new f.TextCreator('url'), true),
                generalSection,
                3,
                {label: 'Url'}
            ),
            new FormFieldDescription(
                'password',
                new f.InputDescription(new f.TextCreator('password'), true),
                generalSection,
                4,
                {label: 'Password'}
            ),
            new FormFieldDescription(
                'comment',
                new f.InputDescription(new f.TextAreaCreator('comment')),
                generalSection,
                6,
                {label: 'Comment'}
            ),
            new FormFieldDescription(
                'isActive',
                new f.InputDescription(new f.BoolCreator('isActive', true), true),
                generalSection,
                5,
                {label: 'Status'}
            )
        ])

        return formCreator
    }
}

formCreatorCreatorFactoryCreator.addFormCreatorCreator(WebCredentialsFormCreator)

export default WebCredentialsFormCreator