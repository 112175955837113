import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Business as BusinessIcon } from '@material-ui/icons'

const useStyles = makeStyles({
    root: {
        color: '#ffffff'
    },
    label: {
        textTransform: 'none'
    }
})

const CompanyButton = (props) => {
    const classes = useStyles()

    return (
        <Button classes={{root: classes.root, label: classes.label}} startIcon={<BusinessIcon />} {...props}>
            {props.label}
        </Button>
    )
}

export default CompanyButton