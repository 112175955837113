/* eslint-disable eqeqeq */
import React from 'react'
import { gql } from "@apollo/client"

import * as f from '../../components/BPMTable/BPMInputs'
import {
    DataMapper,
    DataTransformer,
    TableColumSection,
    NullClickStrategy,
    NumberComponent,
    NumberComponentCreator,
    StatusComponentCreator,
    BooleanExcelDisplayCreator,
    OnlyFilterActionDisplayStrategy,
    TableFieldDescription
} from '../../components/BPMTable/TableMapperCreator'
import PopUpComponentCreator from '../../components/BPMTable/TableComponents/CellComponents/PopupCellComponent'
import ExpenseBySitePopupContent from "../../pages/ExpensesBySite/ExpenseBySitePopupContent"
import {ExportFooterItemCreator} from "../../components/BPMTable/TableFooterItems"
import TableCreatorCreator from "../../components/BPMTable/TableCreatorFactory/TableCreatorCreator"
import tableCreatorCreatorFactoryCreator from "../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator"

const tableQuery = gql`
    query ExpenseBySite(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $period:String!,
        $currency:String,
        $name:String,
        $type:String,
        $enterpriseId:String,
        $status:Boolean,
        $state:String,
        $country:String,
        $address:String,
        $domain:String,
        $customAttr1:String,
        $customAttr2:String,
        $customAttr3:String,
    ) {
        expenseBySite(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            period:$period,
            name:$name
            type:$type,
            enterpriseId:$enterpriseId,
            status:$status,
            state:$state,
            country:$country,
            address:$address,
            domain:$domain,
            customAttr1:$customAttr1,
            customAttr2:$customAttr2,
            customAttr3:$customAttr3,
        ) {
            edges {
                node {
                    id
                    site {
                        id
                        name
                        address
                        isActive
                        state
                        country {
                            id
                            name
                        }
                        type
                        company {
                            id
                            name
                        }
                        enterpriseId
                        domain {
                            id
                            name
                        }
                        name
                        customAttr1
                        customAttr2
                        customAttr3
                    }
                    costs(currency:$currency) {
                        cost
                        cost2
                        cost3
                        qty
                        qty2
                        qty3
                    }
                }
            }
            costs(currency:$currency) {
            cost
            cost2
            cost3
            qty
            qty2
            qty3
        }
        count
        pageInfo {
            hasNextPage
            startCursor
            endCursor
        }
    }
}
`

const queries = {tableQuery};

class ExpensesBySiteTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "ExpensesBySiteTableCreator"
    }

    getTableCreator = (tableName) => {
        const CostsCells = (props) => {
            return( <NumberComponent value={props.data} style={{textAlign:"right"}} currencySymbol={this.parentProps.symbol}/>)
        }

        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()]);

        const period_0_table_section = new TableColumSection('period-0', this.parentProps.per, false);
        const period_1_table_section = new TableColumSection('period-1', this.parentProps.per1, false);
        const period_2_table_section = new TableColumSection('period-2', this.parentProps.per2, false);

        tableCreator.setDescriptions([
            new TableFieldDescription(
                'enterpriseId',
                new DataMapper("site.enterpriseId"),
                new f.FilterDescription(new f.TextFilter("enterpriseId")),
                {label: 'Enterprise ID'}
            ),
            new TableFieldDescription(
                'name',
                new DataMapper("site.name"),
                new f.FilterDescription(new f.TextFilter("name")),
                {label: 'Site name'}
            ),
            new TableFieldDescription(
                'country',
                new DataMapper("site.country.name"),
                new f.FilterDescription(new f.TextFilter("country")),
                {label: 'Country'}
            ),
            new TableFieldDescription(
                'status',
                new DataMapper("site.isActive"),
                new f.FilterDescription(new f.BooleanFilter("status")),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'type',
                new DataMapper("site.type"),
                new f.FilterDescription(new f.TextFilter("type")),
                {label: 'Type'}
            ),
            new TableFieldDescription(
                'address',
                new DataMapper("site.address"),
                new f.FilterDescription(new f.TextFilter("address")),
                {label: 'Address', onlyForm: true}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("site.state"),
                new f.FilterDescription(new f.TextFilter("state")),
                {label: 'State', onlyForm: true}
            ),
            new TableFieldDescription(
                'domain',
                new DataMapper("site.domain.name"),
                new f.FilterDescription(new f.TextFilter("domain")),
                {label: 'Domain', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr1',
                new DataMapper('site.customAttr1'),
                new f.FilterDescription(new f.TextFilter('customAttr1')),
                {label: 'Custom Attribute 1', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr2',
                new DataMapper('site.customAttr2'),
                new f.FilterDescription(new f.TextFilter('customAttr2')),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'customAttr3',
                new DataMapper('site.customAttr3'),
                new f.FilterDescription(new f.TextFilter('customAttr3')),
                {label: 'Custom Attribute 2', onlyForm: true}
            ),
            new TableFieldDescription(
                'quantity-3',
                new DataMapper("costs.qty3"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Qty',
                    section: period_2_table_section,
                    componentCreator:new NumberComponentCreator(''),
                    draggable: false
                }
            ),
            new TableFieldDescription(
                'period-2',
                new DataMapper("costs.cost3", "site", "id"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Cost',
                    section: period_2_table_section,
                    componentCreator: new PopUpComponentCreator(CostsCells, ExpenseBySitePopupContent, {periodString: this.parentProps.per2, symbol: this.parentProps.symbol, period: this.parentProps.periods.period2, currency: this.parentProps.currencyCode, t: this.parentProps.t}),
                    transform: new DataTransformer(({cost3, site, id})=> ({value: cost3, popupData: {site: site, reportId: id}})),
                    draggable: false
                }
            ),
            new TableFieldDescription(
                'quantity-2',
                new DataMapper("costs.qty2"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Qty',
                    section: period_1_table_section,
                    componentCreator:new NumberComponentCreator(''),
                    draggable: false
                }
            ),
            new TableFieldDescription(
                'period-1',
                new DataMapper("costs.cost2", "site", "id"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Cost',
                    section: period_1_table_section,
                    componentCreator: new PopUpComponentCreator(CostsCells, ExpenseBySitePopupContent, {periodString: this.parentProps.per1, period: this.parentProps.periods.period1, symbol: this.parentProps.symbol, currency: this.parentProps.currencyCode, t: this.parentProps.t}),
                    transform: new DataTransformer(({cost2, site, id})=> ({value: cost2, popupData: {site: site, reportId: id}})),
                    draggable: false
                }
            ),
            new TableFieldDescription(
                'quantity-0',
                new DataMapper("costs.qty"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Qty',
                    section: period_0_table_section,
                    componentCreator:new NumberComponentCreator(''),
                    draggable: false
                }
            ),
            new TableFieldDescription(
                'period-0',
                new DataMapper("costs.cost", "site", "id"),
                new f.FilterDescription(new f.NullCreator()),
                {
                    label: 'Cost',
                    section: period_0_table_section,
                    componentCreator: new PopUpComponentCreator(CostsCells, ExpenseBySitePopupContent, {periodString: this.parentProps.per, symbol: this.parentProps.symbol, period: this.parentProps.periods.period0, currency: this.parentProps.currencyCode, t: this.parentProps.t}),
                    transform: new DataTransformer(({cost, site, id})=> ({value: cost, popupData: {site: site, reportId: id}})),
                    draggable: false
                }
            )
        ])

        return tableCreator
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(ExpensesBySiteTableCreator)

export default ExpensesBySiteTableCreator