import { gql } from '@apollo/client'
import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription, 
    DataMapper,
    DataTransformer,
    StatusChipComponentCreator,
    DefaultClickStrategy,
    SimpleTableDisplayStrategy,
} from '../../../components/BPMTable/TableMapperCreator'
import { checkProcessDuration } from '../../../utils/processControlUtils'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query ProcessControlSubProcesses(
        $processControlId:String,
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
    ) {
        processControlSubProcesses(
            processControlId:$processControlId,
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
        ) {
            edges {
                node {
                    id
                    taskName
                    state
                    createdAt
                    updatedAt
                    message
                    duration
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

let editMutator

let newMutator

let deleteMutator

const unitQuery = `
    processControlSubProcess(id: $id) {
        id
        taskName
        state
        createdAt
        updatedAt
        message
        duration
    }
`

const SubProcessTableCreatorCreatorQueries = {tableQuery, editMutator, newMutator, deleteMutator, unitQuery}

class SubProcessTableCreatorCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "SubProcessTableCreatorCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, SubProcessTableCreatorCreatorQueries, new DefaultClickStrategy(), new SimpleTableDisplayStrategy());
        tableCreator.setDefaultRowHeight(25);

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'taskName',
                new DataMapper("taskName"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Task Name'}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("state"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'State', componentCreator: new StatusChipComponentCreator()}
            ),
            new TableFieldDescription(
                'duration',
                new DataMapper("duration", "createdAt", "state"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Duration', transform: new DataTransformer(({duration, createdAt, state}) => checkProcessDuration(duration, createdAt, state))}
            ),
            new TableFieldDescription(
                'message',
                new DataMapper("message"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Message'}
            ),
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(SubProcessTableCreatorCreator)

export default SubProcessTableCreatorCreator
