/* eslint-disable eqeqeq */
import { gql } from '@apollo/client'

import * as f from '../../components/BPMTable/BPMInputs'
import { 
    DataMapper, 
    DataTransformer, 
    NumberComponentCreator, 
    TableFieldDescription, 
    ThousandSeparatorCreator
} from '../../components/BPMTable/TableMapperCreator'

const tableQuery = gql`
    query Consumptions(
        $orderField:String,
        $first:Int,
        $last:Int,
        $after:String,
        $before:String,
        $period:String!,
        $inventory:String,
        $filterUserConsumptions:Boolean,
        $serviceNumber:String,
        $unit:String,
        $group:String,
        $subGroup:String,
        $concept:String,
        $employee:String,
        $firstName:String,
        $lastName:String,
        $enterpriseId:String,
        $currency:String,
        $serviceType:String,
        $provider:String,
        $country:String,
    ) {
        consumptions(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            period:$period,
            inventory:$inventory,
            filterUserConsumptions:$filterUserConsumptions,
            serviceNumber:$serviceNumber,
            unit:$unit,
            group:$group,
            subGroup:$subGroup,
            concept:$concept,
            employee:$employee,
            firstName:$firstName,
            lastName:$lastName,
            enterpriseId:$enterpriseId,
            serviceType:$serviceType,
            provider:$provider,
            country:$country,
        ) {
            edges {
                node {
                    inventory {
                        serviceNumber
                        serviceType {
                        	name
                      	}
                    }
                    user {
                        firstName
                        lastName
                        email
                        enterpriseId
                    }
                    unit {
                        name
                    }
                    concept {
                        name
                    }
                    chargeGroup {
                        name
                    }
                    subChargeGroup {
                        name
                    }
                    account {
                        provider {
                          name
                          country {
                            name
                          }
                        }
                    }
                    quantity
                    convertedCost(currency:$currency)
                }
            }
            totalCost(currency:$currency)
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const getConsumptionsTableDefinitions = (parentProps, excludedFields) => {
    const fields = {
        serviceNumber: new TableFieldDescription(
            'serviceNumber',
            new DataMapper("inventory.serviceNumber"),
            new f.FilterDescription(new f.TextFilter("serviceNumber")),
            {label: 'Service Number'}
        ),
        employee: new TableFieldDescription(
            'employee',
            new DataMapper("user.email"),
            new f.FilterDescription(new f.TextFilter("employee")),
            {label: 'Employee Email'}
        ),
        firstName: new TableFieldDescription(
            'firstName',
            new DataMapper("user.firstName"),
            new f.FilterDescription(new f.TextFilter("firstName")),
            {label: 'Employee Name'}
        ),
        lastName: new TableFieldDescription(
            'lastName',
            new DataMapper("user.lastName"),
            new f.FilterDescription(new f.TextFilter("lastName")),
            {label: 'Employee Surname'}
        ),
        enterpriseId: new TableFieldDescription(
            'enterpriseId',
            new DataMapper("user.enterpriseId"),
            new f.FilterDescription(new f.TextFilter("enterpriseId")),
            {label: 'Employee Code'}
        ),
        unit: new TableFieldDescription(
            'unit',
            new DataMapper("unit.name"),
            new f.FilterDescription(new f.TextFilter("unit")),
            {label: 'Unit'}
        ),
        group: new TableFieldDescription(
            'group',
            new DataMapper("chargeGroup.name"),
            new f.FilterDescription(new f.TextFilter("group")),
            {label: 'Group'}
        ),
        subGroup: new TableFieldDescription(
            'subGroup',
            new DataMapper("subChargeGroup.name"),
            new f.FilterDescription(new f.TextFilter("subGroup")),
            {label: 'Sub Group'}
        ), 
        concept: new TableFieldDescription(
            'concept',
            new DataMapper("concept.name"),
            new f.FilterDescription(new f.TextFilter("concept")),
            {label: 'Concept'}
        ),
        quantity: new TableFieldDescription(
            'quantity',
            new DataMapper("quantity"),
            new f.FilterDescription(new f.NullCreator()),
            {label: 'Quantity', componentCreator:new ThousandSeparatorCreator(),excelTransform:new DataTransformer(({quantity})=>Number(quantity))}
        ),
        cost: new TableFieldDescription(
            'cost',
            new DataMapper("convertedCost"),
            new f.FilterDescription(new f.NullCreator()),
            {label: 'Cost', componentCreator:new NumberComponentCreator(parentProps.symbol, 2), excelTransform:new DataTransformer(({convertedCost})=>Number(convertedCost).toFixed(2))}
        ),
        serviceType: new TableFieldDescription(
            'serviceType',
            new DataMapper("inventory.serviceType.name"),
            new f.FilterDescription(new f.TextFilter("serviceType")),
            {label: 'Service Type'}
        ),
        provider: new TableFieldDescription(
            'provider',
            new DataMapper("account.provider.name"),
            new f.FilterDescription(new f.TextFilter("provider")),
            {label: 'Vendor'}
        ),
        country: new TableFieldDescription(
            'country',
            new DataMapper("account.provider.country.name"),
            new f.FilterDescription(new f.TextFilter("country")),
            {label: 'Country'}
        ),
    };

    return Object.entries(fields)
        .filter(([key]) => !excludedFields.includes(key))
        .map(([key, value]) => ({...value}));
}

export { getConsumptionsTableDefinitions, tableQuery}