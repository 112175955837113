import React, { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
    Button,
    Switch,
    Typography,
    Paper,
    Popper,
    ClickAwayListener,
    Card,
    FormControl,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import MonthPicker from '../MonthPicker/MonthPicker'
import CurrencyPicker from '../CurrencyPicker/CurrencyPicker'

const ReportsFilter = (props) => {
    const { t, info, buttons, currencySelected, onChangeCurrency, date, onChangeDate, onSwichStateChange } = props
    const [anchorInfo, setAnchorInfo] = useState(null)

    const handleInfo = (event) => {
        setAnchorInfo(event.currentTarget)
    }

    const handleCloseInfo = () => {
        setAnchorInfo(null)
    }

    return (
        <div>
            <Card style={{ marginTop: 5, marginBottom: 10 }}>
                <FormControl style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: "100%", whiteSpace: 'nowrap' }}>
                        {info && info.label ?
                            <div style={{ width: "3%" }}>
                                <Button
                                    onMouseLeave={handleCloseInfo}
                                    onMouseEnter={handleInfo}
                                    style={{ marginLeft: "1%" }}
                                    size='small'
                                >
                                    <InfoIcon />
                                </Button>
                            </div>
                            : null}
                        {date ?
                            <div style={{ display: 'flex', width: "20%", maxHeight: 'fit-content' }}>
                                <span style={{ marginTop: '5px', color: '#0000008a' }}>{t('Reference Period') + ': '}</span>
                                <MonthPicker
                                    style={{ marginLeft: 5 }}
                                    month={date.month}
                                    year={date.year}
                                    onChangeDate={onChangeDate}
                                    checkDisabledMonths={props.checkDisabledMonths}
                                    useInvoicePeriods={props.useInvoicePeriods}
                                    t={t}
                                />
                            </div>
                            :
                            null
                        }
                        {currencySelected && onChangeCurrency ?
                            <div style={{ width: "30%", maxHeight: 'fit-content' }}>
                                <CurrencyPicker
                                    month={date.month}
                                    year={date.year}
                                    selected={currencySelected}
                                    onChangeCurrency={onChangeCurrency}
                                    style={{ marginLeft: 5 }}
                                />
                            </div> : null}
                        <div style={{ justifySelf: "flex-end", marginRight: "10px" }}>
                            {buttons ? buttons.map((button, index) =>
                                (button.type === 'swich') ?
                                    <div key={'swich_' + index} style={{ display: "flex" }}>
                                        <Switch
                                            onChange={onSwichStateChange}
                                        />
                                        <Typography style={{ marginLeft: "5px", marginTop: "5px" }}>
                                            {button.label}
                                        </Typography>
                                    </div>
                                    :
                                    <Link to={button.link ? button.link : '#'} key={'link_' + index} style={{ textDecoration: "none" }}>
                                        <Button
                                            style={{ backgroundColor: button.color, color: "white", marginLeft: "5px", marginBottom: "5px" }}
                                            variant={button.variant}
                                            onClick={button.onClick}
                                        >
                                            {button.label}
                                        </Button>
                                    </Link>)
                                : null}
                        </div>
                    </div>
                </FormControl>
            </Card>
            <Popper open={Boolean(anchorInfo)} anchorEl={anchorInfo} placement="right-start">
                <ClickAwayListener onClickAway={() => handleCloseInfo()}>
                    <Paper style={{ padding: 10 }}>
                        <Typography>{info ? info.label : null}</Typography>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    )
}

export default withTranslation("reportFilter")(ReportsFilter)