import PropTypes from 'prop-types'

import { useUser } from '../../contexts/User'

const Check = ({permission, anyPermission, allPermissions, children}) => {
    const { authorizer } = useUser()

    if (permission ) return authorizer.has_permission(permission) ? children : null
    
    if (anyPermission) return authorizer.has_any_permission(anyPermission) ? children : null
            
    if (allPermissions) return authorizer.has_all_permissions(allPermissions) ? children : null
        
    return null
}

Check.propTypes = {
    permission: PropTypes.string,
    anyPermission: PropTypes.arrayOf(PropTypes.string),
    allPermissions: PropTypes.arrayOf(PropTypes.string)
}

export default Check