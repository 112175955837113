export function shortMonthNames(t) {
    return [
        t("monthNames:Jan"), 
        t("monthNames:Feb"),
        t("monthNames:Mar"), 
        t("monthNames:Apr"),
        t("monthNames:May"),
        t("monthNames:Jun"), 
        t("monthNames:Jul"), 
        t("monthNames:Aug"), 
        t("monthNames:Sep"), 
        t("monthNames:Oct"), 
        t("monthNames:Nov"), 
        t("monthNames:Dec")
    ]
}

export function longMonthNames(t) {
    return [
        t("monthNames:January"),
        t("monthNames:February"),
        t("monthNames:March"),
        t("monthNames:April"),
        t("monthNames:May"),
        t("monthNames:June"),
        t("monthNames:July"),
        t("monthNames:August"),
        t("monthNames:September"),
        t("monthNames:October"),
        t("monthNames:November"),
        t("monthNames:December")
    ]
}
