import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription, 
    StatusComponentCreator,
    BooleanExcelDisplayCreator,
    DataTransformer, 
    DataMapper, 
} from '../../../components/BPMTable/TableMapperCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'

const tableQuery = gql`
    query AllDevices(
        $orderField:String,
        $first:Int,$last:Int,
        $after:String,
        $before:String,
        $isActive:Boolean,
        $description:String,
        $customAttr1:String,
        $customAttr2:String,
        $customAttr3:String,
        $brand:String,
        $model:String,
        $serviceType:String,
        $serialNumberType:String,
        $serialNumber:String
    ) {
        allDevices(
            orderField:$orderField,
                first:$first,
                last:$last,
                after:$after,
                before:$before,
                description:$description,
                customAttr1:$customAttr1,
                customAttr2:$customAttr2,
                customAttr3:$customAttr3,
                isActive:$isActive,
                brand:$brand,
                model:$model,
                serviceType:$serviceType,
                serialNumberType:$serialNumberType,
                serialNumber:$serialNumber
        ) {
            edges {
                node {
                    id
                    model {
                        brand {
                            name
                        }
                        name
                        serviceType {
                            name
                        }
                    }
                    isActive
                    serialNumber
                    serialNumberType {
                        name
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const queries = {tableQuery}

class DeviceMinimalTableCreatorCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "DeviceMinimalTableCreatorCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries)
        tableCreator.addDescriptions([
            new TableFieldDescription(
                'brand',
                new DataMapper("model.brand.name"),
                new f.FilterDescription(new f.TextFilter("brand")),
                {label: 'Brand'}
            ),
            new TableFieldDescription(
                'model',
                new DataMapper("model.name"),
                new f.FilterDescription(new f.TextFilter("model")),
                {label: 'Model'}
            ),
            new TableFieldDescription(
                'serviceType',
                new DataMapper("model.serviceType.name"),
                new f.FilterDescription(new f.TextFilter("serviceType")),
                {label: 'Service Type'}
            ),
            new TableFieldDescription(
                'serialNumber',
                new DataMapper("serialNumber"),
                new f.FilterDescription(new f.TextFilter("serialNumber")),
                {label: 'Serial Number'}
            ),
            new TableFieldDescription(
                'serialNumberType',
                new DataMapper("serialNumberType.name"),
                new f.FilterDescription(new f.TextFilter("serialNumberType")),
                {label: 'Serial Number Type'}
            ),
            new TableFieldDescription(
                'isActive',
                new DataMapper("isActive"),
                new f.FilterDescription(new f.BooleanFilter("isActive")),
                {label: 'Status', componentCreator: new StatusComponentCreator(), excelDisplay: new BooleanExcelDisplayCreator()}
            ),
            new TableFieldDescription(
                'description',
                new DataMapper("description"),
                new f.FilterDescription(new f.TextFilter("description")),
                {label: 'Description', onlyForm: true}
            )
        ])

        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(DeviceMinimalTableCreatorCreator)

export default DeviceMinimalTableCreatorCreator;