import React from 'react'
import TotalTableEstructure from '../MyTeam/TotalTable'


export default function ConsumptionsTotals(props) {
    return (
        <TotalTableEstructure
            data={[{symbol: props.symbol, value:props.data}]}
            visibleColumns={props.visibleColumns} 
        />
    )

}