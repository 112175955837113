import { gql } from '@apollo/client'

import * as f from '../../../components/BPMTable/BPMInputs'
import {
    TableFieldDescription,
    DataMapper,
    DataTransformer,
    NullClickStrategy,
    OnlyFilterActionDisplayStrategy,
} from  '../../../components/BPMTable/TableMapperCreator'
import { ExportFooterItemCreator } from '../../../components/BPMTable/TableFooterItems'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'

const tableQuery = gql`
    query (
            $orderField: String,
            $dateFrom: String,
            $dateUntil: String,
            $serialNumber: String,
            $serialNumberType: String,
            $brand: String,
            $model: String,
            $serviceNumber: String!
        ){
        deviceAssociations(
            orderField: $orderField,
            dateFrom: $dateFrom,
            dateUntil: $dateUntil,
            serialNumber: $serialNumber,
            serialNumberType: $serialNumberType,
            brand: $brand,
            model: $model,
            serviceNumber: $serviceNumber
        ) {
            edges {
                node {
                    association {
                        device {
                            serialNumber
                            serialNumberType {
                                name
                            }
                            model {
                                name
                                brand {
                                    name
                                }
                                serviceType {
                                    name
                                }
                            }
                        }
                        inventory {
                            serviceNumber
                        }
                    }
                    dateFrom
                    dateUntil
                }
            }
            count
        }
    }
`;

const queries = {tableQuery}

class InventoryDevicesAssociationTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InventoryDevicesAssociationTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new NullClickStrategy(), new OnlyFilterActionDisplayStrategy())
        tableCreator.setTableFooterItems([new ExportFooterItemCreator()])

        tableCreator.addDescriptions([
            new TableFieldDescription(
                'dateFrom',
                new DataMapper("dateFrom"),
                new f.FilterDescription(new f.DateFilter("dateFrom")),
                {label: 'From', transform: new DataTransformer(({dateFrom})=> dateFrom && dateFrom !== '1900-01-01' ? dateFrom : null)}
            ),
            new TableFieldDescription(
                'dateUntil',
                new DataMapper("dateUntil"),
                new f.FilterDescription(new f.DateFilter("dateUntil")),
                {label: 'Until', transform: new DataTransformer(({dateUntil})=> dateUntil && dateUntil !== '1900-01-01' ? dateUntil : null)}
            ),
            new TableFieldDescription(
                'serialNumber',
                new DataMapper("association.device.serialNumber"),
                new f.FilterDescription(new f.TextFilter("serialNumber")),
                {label: 'Serial Number'}
            ),
            new TableFieldDescription(
                'serialNumberType',
                new DataMapper("association.device.serialNumberType.name"),
                new f.FilterDescription(new f.TextFilter("serialNumberType")),
                {label: 'Type'}
            ),
            new TableFieldDescription(
                'brand',
                new DataMapper("association.device.model.brand.name"),
                new f.FilterDescription(new f.TextFilter("brand")),
                {label: 'Brand'}
            ),
            new TableFieldDescription(
                'moodel',
                new DataMapper("association.device.model.name"),
                new f.FilterDescription(new f.TextFilter("model")),
                {label: 'Model'}
            ),
        ])
        
        return tableCreator;
    }
}

tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InventoryDevicesAssociationTableCreator)

export default InventoryDevicesAssociationTableCreator;