/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import { InventoryTableCreator } from '../../../tableDefinitions/Inventory/Inventory/InventoryTableCreator'
import InventoryFormCreator from '../../../tableDefinitions/Inventory/Inventory/InventoryFormCreator'

const Inventory = (props) => {
    const { t } = useTranslation('inventoryManagement')
    document.title = `${t('Inventory')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Inventory'}
            tableCreatorCreatorId={InventoryTableCreator.getId()}
            tableCreatorParams={null}
            formCreatorCreatorId={InventoryFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default Inventory