import React from 'react'

import TotalTableEstructure from './TotalTable'

export default function CostCenterTotals(props) {
    return (
        <TotalTableEstructure
            data={[
                {value:props.data.qty3},
                {symbol:props.symbol,value:props.data.cost3},
                {value:props.data.qty2},
                {symbol:props.symbol,value:props.data.cost2},
                {value:props.data.qty},
                {symbol:props.symbol,value:props.data.cost},
            ]}
            visibleColumns={props.visibleColumns} 
        />
    )
}