import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import isDeepEqual from '../../../../utils/isDeepEqual'

import { withRouter } from 'react-router-dom'
import { Paper, Table } from '@material-ui/core'

import TableContent from './TableContent/TableContent'
import TableHeader from './TableHeader/TableHeader'
import BPMTableRow from '../BPMTableRow'
import TableFooter from "../TableFooter"

class TableWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            typingTimeout: 0,
            tableRows: [],
            tableData: [],
            startCursor: '',
            endCursor: '',
            hasNextPage: '',
            popupDisplay: false,
            clickedId: null,
            filtersDisplay: true,
            checkboxDisplay: true,
            preColumnDisplay: true,
            editButtonDisplay: true,
            footerDisplay: true,
            newButtonDisplay: true,
            massiveUploadsDisplay: true,
            sticky: false,
            customBodyWrapperStyles:null,
            customHeaderStyles:null,
            lastQueryResult:null,
            loadingData:false,
            loadingFirstPageOfData:false,
            lastRowBlankDisplay:true,
            refreshButtonDisplay:true,
        }

        this.abortController = new AbortController;
    }

    currentFilters = () => {
        let currentTableFilters = {...this.props.currentTableFilters.filters}

        if (this.props.TableObject.tableOrderFieldSelector) {
            currentTableFilters['orderField'] = (this.props.TableObject.tableOrderDirectionSelector == 'asc' ? "": "-") + this.props.TableObject.tableOrderFieldSelector;
        }

        return {
            ...this.props.additionalFilters,
            ...currentTableFilters
        };
    }

    getInitialSortFieldName = () => {
        return  this.props.currentTableFilters?.orderField ? this.props.currentTableFilters.orderField : this.props.TableObject.initialSortFieldName
    }

    getInitialSortFieldDirection = () => {
        return  this.props.currentTableFilters?.orderDirection ? this.props.currentTableFilters.orderDirection : this.props.TableObject.initialSortFieldDirection
    }

    initializeTable = () => {
        this.props.TableObject.displayStrategy.doFor(this);
        this.refreshTable();
    }

    componentDidMount = () => {
        this.props.TableObject.displayStrategy.doFor(this);

        this.scrollComponent = this.getScrollingComponent()
        this.scrollComponent.onscroll = () => {
            if (this.scrollComponent.scrollHeight - (this.scrollComponent.scrollTop + this.scrollComponent.clientHeight) < 10) {
                this.handleLoadMore();
            };
            this.setStyles(this.scrollComponent)
        };

        this.refreshTable(false);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevProps.additionalFilters) != JSON.stringify(this.props.additionalFilters) ) {
            this.refreshTable();
        }

        if (!isDeepEqual(prevProps.currentTableFilters, this.props.currentTableFilters)) {
            this.refreshTable()
        }

        if (!isDeepEqual(prevProps.currentTableColumns, this.props.currentTableColumns)) {
            const newRows = this.state.tableData.map((rowData, index) => this.processRowData(rowData, "tbl_row__" + index))
            this.setState({tableRows: newRows})
        }
    }

    componentWillUnmount = () => {
        this.scrollComponent.onscroll = null;
        this.abortController.abort();
    }

    getScrollingComponent = () => {
        let element = ReactDOM.findDOMNode(this);
        while (element.parentNode) {
            if (['overlay', 'auto'].includes(window.getComputedStyle(element)['overflow-y'])) {
                return element
            }
            element = element.parentNode;
        }

        return ReactDOM.findDOMNode(this);
    }

    // ********** Row Handlers **********

    handleSetTableSelectAll = (selectAll, newRows=[], newRefs=[]) => {
        const newState = {
            selectAll: selectAll,
            selectedRows: newRows,
            selectedRefs: newRefs
        }

        this.props.TableObject.setTableSelectAllState(newState)
    }

    processRowData = (rowData, key) => {
        return (
                <BPMTableRow
                    data={rowData.data}
                    key={key}
                    TableObject={this.props.TableObject}
                    onRowClickAction={(data)=>this.onRowClickAction(data, rowData.objectData)}
                    checkboxDisplay={this.state.checkboxDisplay}
                    preColumnDisplay={this.state.preColumnDisplay}
                    filtersDisplay={this.state.filtersDisplay}
                    handleClickCheckBox={this.handleClickCheckBox}
                    editButtonDisplay={this.state.editButtonDisplay}
                />
        )
    }

    addNewRows = (data, reset) => {
        const queryName = Object.keys(data)[0]
        const pageInfo = data[queryName].pageInfo || {}
        const startCursor = pageInfo.startCursor  ? pageInfo.startCursor : ''
        const endCursor = pageInfo.endCursor ? pageInfo.endCursor : ''
        const hasNextPage = pageInfo.hasNextPage ? pageInfo.hasNextPage : ''

        let count = ''
        if (data[queryName].hasOwnProperty("count")) {
            count = data[queryName].count
        } else if (data[queryName].hasOwnProperty("edges")) {
            count = data[queryName].edges.length
        }

        const current_length = reset ? 0 : this.state.tableData.length;

        const mappedData = this.props.TableObject.tableObjectMapper(data)
        const newRows = mappedData.map((rowData, index) => this.processRowData(rowData, "tbl_row__" + (current_length + index)))

        this.setState({
            startCursor,
            endCursor,
            hasNextPage,
            tableRows: reset ? [...newRows] : [...this.state.tableRows, ...newRows],
            tableData: reset ? [...mappedData] : [...this.state.tableData, ...mappedData],
            loadingFirstPageOfData: false,
            lastQueryResult: data,
            loadingData: false
        })

        this.props.TableObject.setTableCountState(count)
    }

    onRowClickAction = (data, rowData) => {
        this.props.TableObject.onClickStrategy.doFor(this, data, rowData)
    }

    // ********** Popup Handlers **********

    showPopupFor = (id) => {
        this.setState({
            popupDisplay: true,
            clickedId: id
        })
    }

    hidePopup = () => {
        this.setState({
            popupDisplay: false
        })
    }

    // ********** Display Functions **********

    hidePreColumn = () => {
        this.setState({
            preColumnDisplay: false
        })
    }

    hideFilters = () => {
        this.setState({
            filtersDisplay: false
        })
    }

    hideCheckbox = () => {
        this.setState({
            checkboxDisplay : false
        })
    }

    hideEditButton = () => {
        this.setState({
            editButtonDisplay: false
        })
    }

    hideFooter = () => {
        this.setState({
            footerDisplay: false
        })
    }

    hideNewButton = () => {
        this.setState({
            newButtonDisplay: false
        })
    }

    hideMassiveUploadsButton = () => {
        this.setState({
            massiveUploadsDisplay: false
        })
    }

    hideLastRowBlank = () => {
        this.setState({
            lastRowBlankDisplay: false
        })
    }

    hideRefreshButton = () => {
        this.setState({
            refreshButtonDisplay: false
        })
    }
 
    setCustomHeaderStyles = (styles) => {
        this.setState({customHeaderStyles : styles})
    }

    setCustomBodyWrapperStyles = (styles) => {
        this.setState({customBodyWrapperStyles : styles})
    }

    setStyles = scrollComp => {
        const tableHeadComp   = document.getElementById("tableHead");
        const bodyWrapperComp = document.getElementById("tableBodyWrapper");
        if (this.state.customHeaderStyles || this.state.customBodyWrapperStyles) {
            tableHeadComp.style = this.state.customHeaderStyles;
            bodyWrapperComp.style = this.state.customBodyWrapperStyles;
        } else if (!this.state.sticky) {
            this.setState({
                sticky: true
            });
        }
    }

    setCustomHeaderStyles = (styles) => {
        this.setState({customHeaderStyles : styles})
    }

    setCustomBodyWrapperStyles = (styles) => {
        this.setState({customBodyWrapperStyles : styles})
    }

    // ********** Pagination **********

    loadMore = () => {
        try {
            this.setState({loadingData: true})
            this.props.client.query({
                query: this.props.TableObject.tableQuery,
                variables: {
                    ...this.currentFilters(),
                    first: 15,
                    after: this.state.endCursor
                },
                options: {
                    context: { fetchOptions: { signal: this.abortController.signal } }
                },
                fetchPolicy: 'network-only',
            })
            .then(response => {
                this.addNewRows(response.data)
            })
        } catch (error) {
            console.log(error)
            this.setState({loadingData: false})
        }
    }

    handleLoadMore = () => {
        try {
            if(this.state.hasNextPage && !this.state.loadingData){
                this.loadMore()
            }
        } catch (error) {
            console.log(error)
        }
    }

    // ********** Filters and Sorting **********

    refreshTable = (doClear=true) => {
        if (doClear) this.clearSelections()

        try {
            this.setState({loadingFirstPageOfData: true});

            this.props.client.query({
                query: this.props.TableObject.tableQuery,
                variables: {
                    first: 50,
                    ...this.currentFilters(),
                },
                options: {
                    context: { fetchOptions: { signal: this.abortController.signal } }
                },
                fetchPolicy: 'network-only'
            })
            .then(response => {
                this.addNewRows(response.data, true);
            })
        } catch (error) {
            console.log(error)
            this.setState({loadingFirstPageOfData: false});
        }
    }

    checkFiltersStorage = (filters, orderField, orderDir) => {
        const newFilters = {
            filters: filters,
            orderField: orderField || this.getInitialSortFieldName(),
            orderDirection: orderDir || this.getInitialSortFieldDirection()
        }

        this.props.TableObject.setTableFiltersState(newFilters)
    }

    handleSetFilters = (filters) => {
        this.checkFiltersStorage(filters, this.props.TableObject.tableOrderFieldSelector, this.props.TableObject.tableOrderDirectionSelector)
    }

    clearFilters = () => {
        this.refreshTable()
    }

    handleClearFilters = () => {
        this.checkFiltersStorage({}, this.props.TableObject.tableOrderFieldSelector, this.props.TableObject.tableOrderDirectionSelector)
    }

    handleRequestSort = (event, fieldId) => {
        //this.clearSelections()
        event.stopPropagation();
        event.preventDefault();

        const newFilters = {
            filters: JSON.parse(JSON.stringify(this.props.currentTableFilters.filters)),
            orderField: fieldId,
            orderDirection: 'desc'
        }

        if (this.props.currentTableFilters.orderField === fieldId && this.props.currentTableFilters.orderDirection === 'desc') {
            newFilters.orderDirection = 'asc'
        }

        let fieldDescription = this.props.TableObject.fieldDescriptions.find(c => c.id === fieldId)
        newFilters.orderField = fieldDescription.orderIdentifier

        this.checkFiltersStorage(newFilters.filters, newFilters.orderField, newFilters.orderDirection)
    }

    // ********** Checkboxes Handlers **********

    clearSelections = () => {
        this.handleSetTableSelectAll(false)
    }

    removeRef = (refsArray, id) => {
        const newRefs = []
        refsArray.forEach(el => {
            if (el.id != id) {
                newRefs.push(el)
            }
        })
        return newRefs
    }

    serializeRow = (obj) => {
        const filteredObj = {};
    
        for (let key in obj) {
            if (typeof obj[key] !== 'object') {
                filteredObj[key] = obj[key];
            }
        }
    
        return filteredObj;
    };

    handleClickCheckBox = (event, id, ref) => {
        const selectAll = this.props.currentTableSlectAllState
        const selected = this.props.currentTableSelectedRows
        const selectedIndex = selected.findIndex(item => item.id === id.id)

        let newSelected = []
        let newRefs = [...this.props.currentTableSelectedRefs]

        if (selectedIndex === -1) {
            // Add new one
            newSelected = newSelected.concat(selected, this.serializeRow(id))
            newRefs.push({id: id.id})
        } else if (selectedIndex === 0) {
            // Remove the first one
            newSelected = newSelected.concat(selected.slice(1))
            newRefs = this.removeRef([...newRefs], id.id)
        } else if (selectedIndex === selected.length - 1) {
            // Remove the last one
            newSelected = newSelected.concat(selected.slice(0, -1))
            newRefs = this.removeRef([...newRefs], id.id)
        } else if (selectedIndex > 0) {
            // Remove in the middle
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
            newRefs = this.removeRef([...newRefs], id.id)
        }

        if (newSelected.length === 0) {
            this.handleSetTableSelectAll(selectAll, [], [])
        } else if (newSelected.length === this.props.currentTableCount) {
            this.handleSetTableSelectAll(!selectAll)
        } else {
            this.handleSetTableSelectAll(selectAll, newSelected, newRefs)
        }
    }

    // *****************************************

    render() {
        return (
            <div style={{ height: '110%' }}>
                <div>
                    {this.props.children ? this.props.children(this.state.lastQueryResult, this.props.TableObject) : null}
                    <Paper id="tablePaper" elevation={2} style={{marginBottom: 16}}>
                        <Table style={{tableLayout: 'fixed', cursor: this.props.TableObject.onClickStrategy.shouldShowCursor() ? 'pointer' : null}}>
                            <TableHeader
                                key={'t_header'}
                                TableObject={this.props.TableObject}
                                filtersDisplay={this.state.filtersDisplay}
                                handleClearFilters={this.handleClearFilters}
                                checkboxDisplay={this.state.checkboxDisplay}
                                preColumnDisplay={this.state.preColumnDisplay}
                                handleSelectAll={this.handleSetTableSelectAll}
                                onRequestSort={this.handleRequestSort}
                                stickyHeader={this.props.stickyHeader}
                                sticky={this.state.sticky}
                                refreshTable={this.refreshTable}
                                clearSelections={this.clearSelections}
                                refreshButtonDisplay={this.state.refreshButtonDisplay}
                            />
                            <TableContent
                                key={'t_content'}
                                tableRows={this.state.tableRows}
                                TableObject={this.props.TableObject}
                                client={this.props.client}
                                clickedId={this.state.clickedId}
                                popupDisplay={this.state.popupDisplay}
                                hidePopup={this.hidePopup}
                                handleSetFilters={this.handleSetFilters}
                                loadingFirstPageOfData={this.state.loadingFirstPageOfData}
                                lastRowBlankDisplay={this.state.lastRowBlankDisplay}
                            />
                        </Table>
                        { this.state.footerDisplay ?
                            <TableFooter
                                key={'t_footer'}
                                footerDisplay
                                TableObject={this.props.TableObject}
                                checkboxDisplay={this.state.checkboxDisplay}
                                newButtonDisplay={this.state.newButtonDisplay}
                                massiveUploadsDisplay={this.state.massiveUploadsDisplay}
                                client={this.props.client}
                                filters={this.currentFilters()}
                                clearSelections={this.clearSelections}
                                handleSetFilters={this.handleSetFilters}
                                refreshTable={this.refreshTable}
                            />
                        : null
                        }
                    </Paper>
                </div>
            </div>
        )
    }
}

export default withRouter(TableWrapper);
