import React from 'react'

import { gql } from '@apollo/client'
import { IconButton } from '@material-ui/core'
import { Description as DescriptionIcon } from '@material-ui/icons'
import {
    TableFieldDescription, 
    DataMapper, 
    DataTransformer, 
    InvoicePaymentGroupClickStrategy,
    ThousandSeparatorCreator,
} from  '../../../components/BPMTable/TableMapperCreator'
import * as f from '../../../components/BPMTable/BPMInputs'
import { useAxiosClient } from '../../../contexts/Axios'
import tableCreatorCreatorFactoryCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreatorFactoryCreator'
import TableCreatorCreator from '../../../components/BPMTable/TableCreatorFactory/TableCreatorCreator'
import { FieldLabelsTranslator } from '../../../translations/Utils'

const tableQuery = gql`
    query(
        $orderField:String,
        $first:Int,$last:Int,
        $after:String,
        $before:String,
        $id:String,
        $date:String,
        $state:String,
    ) {
        allInvoicePaymentGroups(
            orderField:$orderField,
            first:$first,
            last:$last,
            after:$after,
            before:$before,
            id:$id,
            date:$date,
            state:$state,
        ) {
            edges {
                node {
                    id
                    state
                    createdAt
                    invoicepaymentSet{
                        edges{
                            node{
                                id
                            }
                        }
                    }
                }
            }
            count
            pageInfo {
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`

const unitQuery = `
    invoicePaymentGroup(id:$id){
      id
      state
      createdAt
      apglFile
      updatedAt
    }
`;

const handleState = state => {
    
    const states = {
        'A_0' : <FieldLabelsTranslator ns='stateFilter' text='PENDING'/>,
        'A_1' : <FieldLabelsTranslator ns='stateFilter' text='PROCESSING'/>,
        'A_2' : <FieldLabelsTranslator ns='stateFilter' text='READY'/>,
        'A_3' : <FieldLabelsTranslator ns='stateFilter' text='CLOSED'/>
    }
    return states[state]
}


const onClickDownloadErrors = (client, id) => {
    client.get(
        `/invoice_payments/get_info/${id}`,
    ).then(
        response => {
            window.location.assign(`${client.defaults.baseURL}/invoice_payments/files/${response.data}`);
        }
    ).catch(
        error => console.log(error)
    )
}

const DownloadFile = props => {
    const { axiosClient } = useAxiosClient()
    return (
        <div key={`fileKey=${props.id}`} style={{textAlign:'center'}}>
        <IconButton onClick={ event => { event.stopPropagation(); onClickDownloadErrors(axiosClient, props.id)}}>
            <DescriptionIcon />
        </IconButton>
        </div>
    )
}

const queries = {tableQuery, unitQuery}

class InvoiceGroupTableCreator extends TableCreatorCreator {
    constructor(filters) {
        super(filters)
    }

    static getId = () => {
        return "InvoiceGroupTableCreator"
    }

    getTableCreator = (tableName) => {
        const tableCreator =  this.createTableCreator(this.constructor.getId(), tableName, queries, new InvoicePaymentGroupClickStrategy())
        tableCreator.setInitialSortField('date', 'desc')
        tableCreator.addDescriptions ([
            new TableFieldDescription(
                'id',
                new DataMapper("id"),
                new f.FilterDescription(new f.TextFilter("id")),
                {label: 'APGL ID'}
            ),
            new TableFieldDescription(
                'date',
                new DataMapper("createdAt"),
                new f.FilterDescription(new f.PeriodFilter("date")),
                {label: 'APGL Date'}
            ),
            new TableFieldDescription(
                'state',
                new DataMapper("state"),
                new f.FilterDescription(new f.InvoicePaymentGroupStateFilter("state")),
                {label: 'State', transform: new DataTransformer(({state})=> handleState(state))}
            ),
            new TableFieldDescription(
                'downloadFile',
                new DataMapper("id", "state"),
                new f.FilterDescription(new f.NullCreator()),
                // eslint-disable-next-line eqeqeq
                {label: 'Resource', hideSort:true, transform: new DataTransformer(({id, state}) => state=="A_2" ? <DownloadFile id={id}/> : null)}
            ),
            new TableFieldDescription(
                'invoicepaymentSet',
                new DataMapper("invoicepaymentSet.edges.length"),
                new f.FilterDescription(new f.NullCreator()),
                {label: 'Invoice Count', hideSort:true, componentCreator:new ThousandSeparatorCreator()}
            ),
        ])

        return tableCreator
    }
}
    
tableCreatorCreatorFactoryCreator.addTableCreatorCreator(InvoiceGroupTableCreator)
    
export default InvoiceGroupTableCreator
