import React from 'react'

import { useTranslation } from 'react-i18next'

import BPMBasicPage from '../../../components/BPMTable/PagesCreator/BPMBasicPage'
import WebCredentialsFormCreator from '../../../tableDefinitions/Invoice/WebCredentials/WebCredentialsFormCreator'
import { WebCredentialsTableCreator } from '../../../tableDefinitions/Invoice/WebCredentials/WebCredentialsTableCreator'

const WebCredentials = (props) => {
    const { t } = useTranslation('invoiceProcessing')
    document.title = `${t('Web Credentials')} - BubbleBPM`

    return (
        <BPMBasicPage
            tableName={'Web Credentials'}
            tableCreatorCreatorId={WebCredentialsTableCreator.getId()}
            formCreatorCreatorId={WebCredentialsFormCreator.getId()}
            formCreatorParams={null}
            path = {props.match.url}
        />
    )
}

export default WebCredentials