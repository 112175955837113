/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React from 'react'


import _ from 'lodash';

export default class TableManager extends React.Component{
    constructor( tableCreatorId,
                 tableName,
                 tableQuery,
                 deleteMutator,
                 unitQuery,
                 fieldDescriptions,
                 onClickStrategy,
                 displayStrategy,
                 selectedRowActionsStrategy,
                 popupContentDisplayStrategy,
                 massiveUploadName,
                 massiveUploadTemplateUrlContext,
                 tableMapper,
                 additionalFilters,
                 initialFilters,
                 tableTranslationStrategy,
                 initialSortFieldName,
                 initialSortFieldDirection,
                 client,
                 rightButtonDisplay,
                 tableObjectMapper,
                 tableFooterItems,
                 tableCreatorCreatorParentProps,
                 defaultRowHeight,
                 readOnlyRowEvaluator,
                 customRowStyleEvaluator,
                 customCellStyleEvaluator,
                 dispatch,
                 reducers,
                 stateSelectors,
    ){
        super();

        this.tableCreatorId = tableCreatorId ;
        this.tableName = tableName ;
        this.tableQuery = tableQuery;
        this.deleteMutator = deleteMutator;
        this.unitQuery = unitQuery;
        this.fieldDescriptions = fieldDescriptions;
        this.onClickStrategy = onClickStrategy;
        this.displayStrategy = displayStrategy;
        this.popupContentDisplayStrategy = popupContentDisplayStrategy;
        this.selectedRowActionsStrategy = selectedRowActionsStrategy;
        this.fieldDescriptionsBackup = _.cloneDeep(fieldDescriptions);
        this.massiveUploadName = massiveUploadName;
        this.massiveUploadTemplateUrlContext = massiveUploadTemplateUrlContext;
        this.additionalTableFooterItems = null;
        this.tableStateSubscriptions = [];
        this.tableMapperStrategy = tableMapper;
        this.additionalFilters = additionalFilters;
        this.initialFilters = initialFilters;
        this.tableTranslation = tableTranslationStrategy;
        this.initialSortFieldName = initialSortFieldName;
        this.initialSortFieldDirection = initialSortFieldDirection;
        this.client = client;
        this.rightButtonDisplay = rightButtonDisplay;
        this.tableObjectMapperStrategy = tableObjectMapper;
        this.tableFooterItems = tableFooterItems;
        this.tableCreatorCreatorParentProps = tableCreatorCreatorParentProps;
        this.defaultRowHeight = defaultRowHeight;
        this.readOnlyRowEvaluator = readOnlyRowEvaluator;
        this.customRowStyleEvaluator = customRowStyleEvaluator;
        this.customCellStyleEvaluator = customCellStyleEvaluator;
    
        this.setReduxConfiguration(dispatch, reducers, stateSelectors);
        this.setColumnConfiguration(this.tableColumnsSelector);
    }

    setReduxConfiguration(dispatch, reducers, stateSelectors) {
        this.dispatch = dispatch;

        if (reducers) {
            this.addBPMTable = reducers.addBPMTable;
            this.setBPMTableCount = reducers.setBPMTableCount;
            this.setBPMTableFilters = reducers.setBPMTableFilters;
            this.setBPMTableSelectAllState = reducers.setBPMTableSelectAllState;
            this.setBPMTableSelectedRows = reducers.setBPMTableSelectedRows;
            this.setBPMTableColumns = reducers.setBPMTableColumns;
            this.AddBPMApplicationMessages = reducers.AddBPMApplicationMessages;
            this.RemoveBPMApplicationMessages = reducers.RemoveBPMApplicationMessages;
        }

        if (stateSelectors) {
            this.tableStatesSelector = stateSelectors.tableStatesSelector;
            this.tableCountSelector = stateSelectors.tableCountSelector;
            this.tableFiltersSelector = stateSelectors.tableFiltersSelector; 
            this.tableOrderFieldSelector = stateSelectors.tableOrderFieldSelector;
            this.tableOrderDirectionSelector = stateSelectors.tableOrderDirectionSelector;
            this.tableSlectAllStateSelector = stateSelectors.tableSlectAllStateSelector;
            this.tableSelectedRowsSelector = stateSelectors.tableSelectedRowsSelector;
            this.tableSelectedRefsSelector = stateSelectors.tableSelectedRefsSelector;
            this.tableColumnsSelector = stateSelectors.tableColumnsSelector;
            this.currentUserSelector = stateSelectors.currentUserSelector;
            this.currentUserCompanySelector = stateSelectors.currentUserCompanySelector;
            this.currentUserMembershipSetSelector = stateSelectors.currentUserMembershipSetSelector;
        }
    }

    setTableCountState(count) {
        this.dispatch(this.setBPMTableCount({tableName: this.tableName, count: count}))
    }

    setTableFiltersState(newFilters) {
        this.dispatch(this.setBPMTableFilters({tableName: this.tableName, filters: newFilters}))
    }

    setTableSelectAllState(newState) {
        this.dispatch(this.setBPMTableSelectAllState({tableName: this.tableName, selectAllState: newState}))
    }
    
    setTableSelectedRowsState(selectedRows) {
        this.dispatch(this.setBPMTableSelectedRows({tableName: this.tableName, selectedRows: selectedRows}))
    }

    setTableColumnsState(columnsConfig) {
        this.dispatch(this.setBPMTableColumns({tableName: this.tableName, tableColumns: columnsConfig}))
    }

    addApplicationSnackMessage(snackMessage) {
        this.dispatch(this.AddBPMApplicationMessages(snackMessage))
    }

    removeApplicationSnackMessage(snackMessageId) {
        this.dispatch(this.RemoveBPMApplicationMessages(snackMessageId))
    }

    getAdditionalFilters() {
        return this.additionalFilters;
    }

    getInitialFilters() {
        let filters = {...this.initialFilters};

        if (this.initialSortFieldName) {
            let fieldDescription = this.fieldDescriptions.find(c=>c.id==this.initialSortFieldName)
            filters['orderField'] = (this.initialSortFieldDirection == 'asc' ? "": "-") + fieldDescription.orderIdentifier;
        }

        return filters;
    }

    setAdditionFooterItems(items){
        this.additionalTableFooterItems = items
    }

    getAdditionFooterItems(props){
        if (this.additionalTableFooterItems != null){
            return this.additionalTableFooterItems(props)
        }
        return null
    }

    setMassiveUploadTemplateUrlContext(urlContext){
        this.massiveUploadTemplateUrlContext = urlContext;
    }

    setColumnConfiguration(columns){
        if (columns && columns.length == this.fieldDescriptions.length){
            // using for instead of map to handle break statement
            let orderedFields = [];
            for (let i = 0; i < columns.length; i++) {
                const orderedField = columns[i]
                let thisField = null;
                this.fieldDescriptions.map(field => {
                    if (field.id == orderedField.id){
                        field.visibility = orderedField.visibility
                        thisField = field;
                    }
                })
                if (!thisField){
                    orderedFields = this.fieldDescriptions;
                    break
                }
                orderedFields.push(thisField)
            }
            this.fieldDescriptions = orderedFields

            return orderedFields;
        } else {
            return this.fieldDescriptions;
        }
    }

    getFieldsOrder (columns=[]){
        const fields = this.fieldDescriptions.map( field => {
            const matchingColumn = columns.find(column => column.id === field.id);

            if (matchingColumn) {
                return {...field, visibility: matchingColumn.visibility};
            }

            return {...field, visibility: field.onlyForm};
        })

        return fields
    }

    getVisibleFields(includeSecionsColumns=true){
        const fields = this.getFieldsOrder(this.tableColumnsSelector);
        return fields.filter(f => ((!f.visibility && (!f.section || includeSecionsColumns)) && !f.hidenField));
    }

    getSections(){
        return this.fieldDescriptions.filter(f => f.section);
    }

    resetColumnSetup(){
        this.fieldDescriptions = _.cloneDeep(this.fieldDescriptionsBackup);

        const fields = this.fieldDescriptionsBackup.map( column => {
            return {id: column.id, visibility: column.onlyForm} 
        })

        return fields;
    }

    popupContentStrategy(props){
        return this.popupContentDisplayStrategy.doFor(props, this)
    }
    
    selectedRowsActions(props){
        return this.selectedRowActionsStrategy.doFor(props)
    }

    rowMapper = (row_data) => {
        const row = {id : row_data.id};
        this.fieldDescriptions
            .map(column => {
                row[column.id] = column.transform(column.mapTableData(row_data));
        })
        return row
    }

    tableMapper(data){
        return this.tableMapperStrategy.mapWith(data, this.rowMapper)
    }

    tableObjectMapper(data){
        return this.tableObjectMapperStrategy.mapWith(data, this.rowMapper)
    }

    excelRowMapper = (row_data) => {
        const row = {id : row_data.id};
        this.fieldDescriptions.map(column=>{
            if (column.getVisibility(this.tableColumnsSelector) && column.showInExcelExport) {
                const attribute = column.excelTransform(column.mapTableData(row_data));
                row[column.id] =  attribute.rowData ;
            }
        })
        return row
    } 

    getExcelColumnsOrder = (row_data ) => {
        const columnsOrder = new Set()
        this.fieldDescriptions.map(column=>{
            if (column.getVisibility(this.tableColumnsSelector) && column.showInExcelExport) {
                const attribute = column.excelTransform(column.mapTableData(row_data));
                for(let k in attribute.rowData){
                    columnsOrder.add({[k]:attribute.order})
                }
            }
        })
        return columnsOrder
    }

    excelOrderer(data){
        const allSets =  this.tableMapperStrategy.mapWith(data, this.getExcelColumnsOrder)
        let largestSet = new Set()
        for(let set of allSets){
            if(set.size >= largestSet.size){
                largestSet = set
            }
        }

        let highiestOrder = 0 
        for(let headerData of largestSet){
            for(let k in headerData){
                if(headerData[k] > highiestOrder){
                    highiestOrder =headerData[k]
                }
            }
        }
        let orderedHeaders = []
        for(let i = 0; i <= highiestOrder; i++){
            for(let item of largestSet){
                for(let key in item){
                    if(item[key] == i){
                        orderedHeaders.push(key)
                    }
                }
            }
        }
       
        return orderedHeaders
    }


    excelMapper(data){
        return this.tableMapperStrategy.mapWith(data, this.excelRowMapper)
    }

    getFieldLabelById(id){
        return this.fieldDescriptions.find(f  => f.id == id).label
    }

    popupMapper(data){
        const mappedData = {rawData:data}
        const row_data = Object.values(data)[0]
        this.fieldDescriptions.map(field =>{
            mappedData[field.id] = field.componentCreator.createWith(field.transform(field.mapTableData(row_data)),row_data)
        })
        return mappedData;
    }


    getfiltersDisplay(filtersDisplay) {
        const filterFields  = [];
        this.fieldDescriptions.filter(f => (f.getVisibility(this.tableColumnsSelector))).map(field =>{
            const filter = field.inputDescription.component_filter;
            if (filter.type != 'null') {
                filterFields.push(filter);
            }
        })

        return filtersDisplay && (filterFields.length > 0);
    }

    getFilters(props){
        const filterFields  = [];
        this.fieldDescriptions.filter(f => (f.getVisibility(this.tableColumnsSelector))).map(field =>{
            const key = field.id;
            const filter = field.inputDescription.getFilter({onChange:props.onChange, filters: props.filters ,key});
            filterFields.push(filter);
        })
        return filterFields;
    }

    move_column_before  = (dragged, target) => {
        if (target >= this.fieldDescriptions.length) {
            var k = target - this.fieldDescriptions.length + 1;
            while (k--) {
                this.fieldDescriptions.push(undefined);
            }
        } else {
            var column = this.fieldDescriptions.at(target);
            if (column.section) {
                return;
            }
        }
        this.fieldDescriptions.splice(target, 0, this.fieldDescriptions.splice(dragged, 1)[0]);
    };

    getFooterItems = () => {
        return this.tableFooterItems
    }
}