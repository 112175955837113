/* eslint-disable eqeqeq */
import React, { Component } from 'react'
import { FormControl, InputLabel, Select, MenuItem, Input } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const menuprops = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

export class DropDownInput extends Component {
    sortedItems (data, id) {
        return data.sort((a, b) => a[id].localeCompare(b[id]))
    }

    render() {
        const { readOnly, required, classes, label, id, formState, onChangeHandler, inputId, t, nullable } = this.props;
        const error = Boolean(formState.errors[id]);
        const inputLabel = required ? required(t(label)) : t(label);
        return (
            <FormControl className={classes.formControl} key={id} error={error} >
                <InputLabel htmlFor={label}>{inputLabel}</InputLabel>

                <Select
                    value={formState.mappedData[inputId] || ''}
                    onChange={event => onChangeHandler(inputId, event.target.value)}
                    menuprops={menuprops}
                    style={{ width: 250 }}
                    className={classes.input}
                    input={<Input />}
                    disabled={readOnly ? true : false}
                >
                    {nullable &&
                        <MenuItem key={''} value={null} className={classes.input}>
                            -
                        </MenuItem>
                    }

                    {
                        formState.formData[id] && this.sortedItems(formState.formData[id], id).filter(d => d != undefined).map(item => (
                            <MenuItem key={item.id} value={item.id} className={classes.input}>
                                {item[id]}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )
    }
}

export default withTranslation('fieldLabels')(DropDownInput);