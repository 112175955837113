import React, { useState } from 'react'

import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const CustomSnackBar = (props) => {
    const [showSnack, setShowSnack] = useState(props.show)

    return(
        <Snackbar 
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            open={showSnack}
            autoHideDuration={3000}
            onClose={() => setShowSnack(false)}
        >
            <Alert variant='filled' severity='error'>{props.message}</Alert>
        </Snackbar>
    )
}

export default CustomSnackBar