function DeleteAccount (props,client,mutationDeleteQuery, refreshTable, allRowsSelected, filters) {
  const variables = {ids:props, allRecords: allRowsSelected, filters: JSON.stringify(filters)}
  console.log(variables)
  client.mutate( {mutation:mutationDeleteQuery, variables: variables}).then(
      ({loading }) => { 
        if (loading) return 'Loading...' ;
        refreshTable()
      }
  )

}


export default DeleteAccount